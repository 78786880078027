// import { apiInternAnchors } from './apiIntern';
// import { apiInternTable, getNewTableValues } from './intern';

import { getAutocompleteAddresses } from '../../../../utils/geocoding';
import { apiInternDevelopmentTable, getNewTableValues } from './intern';
import { getAddressByLevels } from './mapboxIntern';

export const initialState = {
  identification: {
    isLoading: false,
    isLoadingPagination: false,
    columns: [],
    rows: [],
    sorting: {},
    pagination: {}
  },
  details: {
    isLoading: false,
    isLoadingPagination: false,
    columns: [],
    rows: [],
    sorting: {},
    pagination: {}
  },
  areas: {
    isLoading: false,
    isLoadingPagination: false,
    columns: [],
    rows: [],
    sorting: {},
    pagination: {}
  },
  isLocRiskLoading: false,
  location: null,
  risks: null,
  isLoadingAutocomplete: null,
  autocomplete: []
};

const reducer = (state = initialState, { type, payload, meta, ...action }) => {
  let developmentTable = null;
  switch (type) {
    case 'FETCH_DEVELOPMENT_TABLE_LIST':
      developmentTable = payload.table;
      return {
        ...state,
        [developmentTable]: {
          ...state[developmentTable],
          isLoading: true
        }
      };
    case 'FETCH_DEVELOPMENT_TABLE_LIST_PAGE':
      developmentTable = payload.table;
      return {
        ...state,
        [developmentTable]: {
          ...state[developmentTable],
          isLoadingPagination: true
        }
      };
    case 'FETCH_DEVELOPMENT_TABLE_LIST_SUCCESS':
      developmentTable = meta.previousAction.payload.table;
      return {
        ...state,
        [developmentTable]: {
          ...state[developmentTable],
          isLoading: false,
          ...apiInternDevelopmentTable(payload.data, developmentTable)
        }
      };
    case 'FETCH_DEVELOPMENT_TABLE_LIST_PAGE_SUCCESS':
      developmentTable = meta.previousAction.payload.table;

      let newPageData = apiInternDevelopmentTable(payload.data, developmentTable);
      newPageData = {
        ...newPageData,
        rows: [...state[developmentTable].rows, ...newPageData.rows]
      };

      return {
        ...state,
        [developmentTable]: {
          ...state[developmentTable],
          isLoadingPagination: false,
          ...newPageData
        }
      };
    case 'SET_DEVELOPMENT_DATA_NEW_CELL':
      developmentTable = payload.table;
      return {
        ...state,
        [developmentTable]: {
          ...state[developmentTable],
          rows: getNewTableValues(state[developmentTable].rows, payload.data)
        }
      };
    case 'DEVELOPMENT_DATA_TABLE_SORT':
      developmentTable = payload.table;
      return {
        ...state,
        [developmentTable]: {
          ...state[developmentTable],
          sorting: payload.sorting
        }
      };
    case 'FETCH_DEVELOPMENT_LOCATION_RISK':
      return {
        ...state,
        isLocRiskLoading: true
      };
    case 'FETCH_DEVELOPMENT_LOCATION_RISK_SUCCESS':
      return {
        ...state,
        isLocRiskLoading: false,
        location: {
          ...payload.data.location
        },
        risks: {
          ...payload.data.risks
        }
      };
    case 'FETCH_DEVELOPMENT_LOCATION_RISK_FAIL':
      return {
        ...state,
        isLocRiskLoading: false
      };
    case 'UPDATE_LOCATION_RISK_DATA':
      const { parent, fieldId, value } = payload;

      return {
        ...state,
        [parent]: {
          ...state[parent],
          [fieldId]: value
        }
      };
    case 'FETCH_DEVELOPMENT_DATA_AUTOCOMPLETE':
      return {
        ...state,
        isLoadingAutocomplete: true
      };
    case 'FETCH_DEVELOPMENT_DATA_AUTOCOMPLETE_SUCCESS':
      return {
        ...state,
        isLoadingAutocomplete: false,
        autocomplete: getAutocompleteAddresses(payload.data)
      };
    case 'FETCH_DEVELOPMENT_DATA_AUTOCOMPLETE_FAIL':
      return {
        ...state,
        isLoadingAutocomplete: false,
        autocomplete: []
      };
    case 'FETCH_DEVELOPMENT_DATA_GEOCODING_SUCCESS':
      return {
        ...state,
        location: getAddressByLevels(state.location, payload.data)
      };
    default:
      return state;
  }
};

export default reducer;
