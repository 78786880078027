import { useState, useEffect } from 'react';

const useIsSmallScreen = (limit = 700) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight <= limit ? true : false);

  useEffect(() => {
    const handleResize = () => {
      const height = window.innerHeight;
      setIsSmallScreen(height <= limit ? true : false);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen]);

  return isSmallScreen;
};

export default useIsSmallScreen;
