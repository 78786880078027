import {
  IconAsset,
  IconArea,
  IconBuilding,
  IconNewHome,
  IconBedroom,
  IconBathroom,
  IconParking,
  IconElevator,
  IconStorage,
  IconSwimmingPool,
  IconTerrace,
  IconAirConditioning,
  IconCalendar,
  IconFitBound,
  IconWalk,
  IconCar,
  IconHeating,
  IconKitchen,
  IconCorner,
  IconShowcase,
  IconFacade,
  IconChimmey,
  IconSecurity,
  IconExterior,
  IconAutomaticDoor,
  IconLoadingDock,
  IconNew,
  IconTime,
  IconCompany,
  IconUser
} from '@uda/bento-components';
import { FILTER_FIELDS_ARRAY_ACCEPTED, FILTER_VALID_KEYS } from 'SmartValuation/constants.js';
import { LISTING_TYPE_EQUIVALENCE, PROPERTY_TYPE_EQUIVALENCE } from 'constants.js';
import { removeEmptyAndNullAndUndefinedValuesFromObject } from 'utils';
import IconNaturalGas from 'assets/icons/IconNaturalGas.svg';
import IconFactory from 'assets/icons/IconFactory.svg';
import IconElectricity from 'assets/icons/IconElectricity.svg';
import IconSewerage from 'assets/icons/IconSewerage.svg';
import IconPavement from 'assets/icons/IconPavement.svg';
import IconStreetLighting from 'assets/icons/IconStreetLighting.svg';
import IconWater from 'assets/icons/IconWater.svg';
import { FormattedMessage } from 'react-intl';

export const getComparablesData = values => {
  let result = Object.assign({}, removeEmptyAndNullAndUndefinedValuesFromObject(values));

  const validKeys = [
    'lon',
    'lat',
    'operation',
    'rooms',
    'source_id__in',
    'publishing_status__in',
    'construction_year',
    ...FILTER_VALID_KEYS
  ];

  Object.keys(result).forEach(key => {
    if (validKeys.includes(key)) {
      switch (key) {
        case 'lon':
        case 'lat':
        case 'listing_type':
          result[key] = Number(result[key]);
          break;
        case 'n_rooms':
        case 'n_baths':
          result[key] = Array.isArray(result[key])
            ? [Number(result[key][0])]
            : [Number(result[key])];

          break;
        default:
          break;
      }
    } else {
      delete result[key];
    }
  });

  return result;
};

export const getAssetValuationData = (values, isTheSameAddress) => {
  const featuresFormatted = getAssetValuationFeatures(values);
  const result = {
    ...getAssetValuationValues(values, isTheSameAddress),
    asset_features: { ...featuresFormatted, ...getAssetValuationValues(values, isTheSameAddress) }
  };

  return removeEmptyAndNullAndUndefinedValuesFromObject(result);
};

export const getAssetValuationValues = (values, isTheSameAddress) => {
  let result = Object.assign({}, removeEmptyAndNullAndUndefinedValuesFromObject(values));
  const sameAddressKeys = [
    'similarity',
    'similarity__in',
    'operation',
    'lat',
    'lon',
    'area',
    'listing_type',
    'address',
    'reference',
    'build_status',
    'property_type__in',
    'n_rooms',
    'n_baths'
  ];
  const differentAddressKeys = [
    'operation',
    'lat',
    'lon',
    'area',
    'listing_type',
    'address',
    'reference'
  ];

  const validKeys = isTheSameAddress ? sameAddressKeys : differentAddressKeys;

  Object.keys(result).forEach(key => {
    if (!validKeys.includes(key)) {
      delete result[key];
    }
  });

  if (result.reference === null) delete result.reference;
  return result;
};

export const getAssetValuationFeatures = values => {
  let result = Object.assign({}, removeEmptyAndNullAndUndefinedValuesFromObject(values));

  Object.keys(result).forEach(key => {
    if (FILTER_VALID_KEYS.includes(key)) {
      if (
        Array.isArray(result[key]) &&
        result[key].length === 1 &&
        !FILTER_FIELDS_ARRAY_ACCEPTED.includes(key)
      ) {
        if (result[key][0] === 'true') {
          result[key] = true;
        } else if (result[key][0] === 'false') {
          result[key] = false;
        } else if (result[key][0] === 'no_date') {
          result[key] = 'no_date';
        } else if (result[key][0] === 'all_date') {
          result[key] = 'all_date';
        } else {
          result[key] = Number(result[key][0]);
        }
      }
      if (key === 'property_type__in') {
        const value = result[key];
        result[key] = Array.isArray(value) ? value : [value];
      }
      if (key === 'n_rooms__in' || key === 'n_baths__in') {
        const arrayValue = Array.isArray(result[key]) ? result[key] : [result[key]];
        const parsedValues = arrayValue.map(n => Number(n)).sort((a, b) => a - b);

        result[key] = parsedValues.includes(4) ? [...parsedValues, null] : parsedValues;
      }
      if (key === 'listing_type' || key === 'n_rooms' || key === 'n_baths' || key === 'n_floors') {
        result[key] = Number(result[key]);
      }
      if (
        (key === 'geog__dwithin' || key === 'distance_on_foot' || key === 'distance_by_car') &&
        (result[key] === null || result[key] === 0)
      ) {
        delete result[key];
      }
      if ((key === 'date_main' || key === 'date_out') && result[key] === null) {
        delete result[key];
      }
    } else {
      delete result[key];
    }
  });

  return result;
};

export const getBoundariesFromPolygon = geojson => {
  return [geojson.features[0].geometry.coordinates[0]];
};
export const getIconByPropertyType = propertyType => {
  const icons = {
    n_baths__range: <IconBathroom />,
    has_automatic_door: <IconAutomaticDoor />,
    has_loading_dock: <IconLoadingDock />,
    is_new: <IconNew />,
    has_access_24h: <IconTime />,
    n_rooms__range: <IconBedroom />,
    property_type__in: <IconAsset />,
    area: <IconArea />,
    area__range: <IconArea />,
    floor: <IconBuilding />,
    n_floor: <IconBuilding />,
    construction_type__in: <IconBuilding />,
    build_status: <IconNewHome />,
    n_rooms: <IconBedroom />,
    rooms: <IconBedroom />,
    rooms__in: <IconBedroom />,
    bathrooms: <IconBathroom />,
    n_baths: <IconBathroom />,
    nbaths: <IconBathroom />,
    vendor_type__in: <IconCompany />,
    nbaths__in: <IconBathroom />,
    has_bathroom: <IconBathroom />,
    has_elevator: <IconElevator />,
    elevator: <IconElevator />,
    has_storage: <IconStorage />,
    has_terrace: <IconTerrace />,
    terrace: <IconTerrace />,
    has_pool: <IconSwimmingPool />,
    has_garage: <IconParking />,
    has_parking: <IconParking />,
    parking: <IconParking />,
    parking_spaces__range: <IconParking />,
    has_air_conditioner: <IconAirConditioning />,
    air_conditioning: <IconAirConditioning />,
    comparable__area__range: <IconArea />,
    comparable__custom_fields__rooms__in: <IconBedroom />,
    comparable__custom_fields__nbaths__in: <IconBathroom />,
    comparable__geog__dwithin: <IconFitBound />,
    geog__dwithin: <IconFitBound />,
    distance_on_foot: <IconWalk />,
    distance_by_car: <IconCar />,
    comparable__custom_fields__construction_year__range: <IconCalendar />,
    date_main: <IconCalendar />,
    has_heating: <IconHeating />,
    has_kitchen: <IconKitchen />,
    has_storefront: <IconShowcase />,
    is_on_the_corner: <IconCorner />,
    has_warehouse: <IconStorage />,
    has_facade: <IconFacade />,
    has_security_system: <IconSecurity />,
    has_smoke_outlet: <IconChimmey />,
    is_exterior: <IconExterior />,
    n_garages: <IconParking />,
    n_elevator: <IconElevator />,
    n_houses: <IconBuilding />,
    has_vigilance: <IconSecurity />,
    has_tenant: <IconUser />,
    has_pavement: <img src={IconPavement} alt="IconPavement" width="20px" height="20px" />,
    has_sewerage: <img src={IconSewerage} alt="IconSewerage" width="20px" height="20px" />,
    has_street_lighting: (
      <img src={IconStreetLighting} alt="IconStreetLighting" width="20px" height="20px" />
    ),
    has_natural_gas: <img src={IconNaturalGas} alt="IconNaturalGas" width="20px" height="20px" />,
    has_water: <img src={IconWater} alt="IconWater" width="20px" height="20px" />,
    has_electricity: (
      <img src={IconElectricity} alt="IconElectricity" width="20px" height="20px" hspace="40px" />
    )
  };

  return icons[propertyType] || null;
};

export const getIconByListingType = listing => {
  const equivalence = {
    [LISTING_TYPE_EQUIVALENCE.residential]: <IconBuilding />,
    [LISTING_TYPE_EQUIVALENCE.office]: <IconBuilding />,
    [LISTING_TYPE_EQUIVALENCE.local]: <IconFacade />,
    [LISTING_TYPE_EQUIVALENCE.hall]: (
      <img src={IconFactory} alt="IconFactory" width="24" height="24" />
    ),
    [LISTING_TYPE_EQUIVALENCE.garage]: <IconParking />,
    [LISTING_TYPE_EQUIVALENCE.storage]: <IconStorage />
  };
  return equivalence[listing];
};
export const getTitleTranslation = listing => {
  const equivalence = {
    [LISTING_TYPE_EQUIVALENCE.residential]: 'residential',
    [LISTING_TYPE_EQUIVALENCE.land]: 'land',
    [LISTING_TYPE_EQUIVALENCE.office]: 'offices',
    [LISTING_TYPE_EQUIVALENCE.local]: 'local',
    [LISTING_TYPE_EQUIVALENCE.hall]: 'hall',
    [LISTING_TYPE_EQUIVALENCE.garage]: 'garage',
    [LISTING_TYPE_EQUIVALENCE.storage]: 'storage',
    [LISTING_TYPE_EQUIVALENCE.building]: 'building'
  };
  return listing && <FormattedMessage id={`typology.${equivalence[listing]}`} />;
};
export const getPropertyTypeTranslation = propertyTypeID => {
  if (propertyTypeID && propertyTypeID !== 0) {
    const equivalence = {
      [PROPERTY_TYPE_EQUIVALENCE.urban_land]: 'urban_land',
      [PROPERTY_TYPE_EQUIVALENCE.developable_land]: 'developable_land',
      [PROPERTY_TYPE_EQUIVALENCE.agricultural_soil]: 'agricultural_soil',
      [PROPERTY_TYPE_EQUIVALENCE.shopping_center]: 'shopping_center',
      [PROPERTY_TYPE_EQUIVALENCE.at_street_level]: 'at_street_level',
      [PROPERTY_TYPE_EQUIVALENCE.mezzanine]: 'mezzanine',
      [PROPERTY_TYPE_EQUIVALENCE.underground]: 'underground',
      [PROPERTY_TYPE_EQUIVALENCE.others]: 'others',
      [PROPERTY_TYPE_EQUIVALENCE.moto_slot]: 'moto_slot',
      [PROPERTY_TYPE_EQUIVALENCE.car_slot]: 'car_slot',
      [PROPERTY_TYPE_EQUIVALENCE.card_moto_slot]: 'card_moto_slot',
      [PROPERTY_TYPE_EQUIVALENCE.two_card_moto_slot]: 'two_card_moto_slot',
      [PROPERTY_TYPE_EQUIVALENCE.small_slot]: 'small_slot',
      [PROPERTY_TYPE_EQUIVALENCE.big_slot]: 'big_slot',
      [PROPERTY_TYPE_EQUIVALENCE.parking_big_slot]: 'parking_big_slot',
      [PROPERTY_TYPE_EQUIVALENCE.flat]: 'flat',
      [PROPERTY_TYPE_EQUIVALENCE.penthouse]: 'penthouse',
      [PROPERTY_TYPE_EQUIVALENCE.duplex]: 'duplex',
      [PROPERTY_TYPE_EQUIVALENCE.studio_flat]: 'studio_flat',
      [PROPERTY_TYPE_EQUIVALENCE.terraced]: 'terraced',
      [PROPERTY_TYPE_EQUIVALENCE.semi_detached]: 'semi_detached',
      [PROPERTY_TYPE_EQUIVALENCE.chalet]: 'chalet',
      [PROPERTY_TYPE_EQUIVALENCE.rustic_house]: 'rustic_house',
      [PROPERTY_TYPE_EQUIVALENCE.store]: 'store'
    };

    return (
      propertyTypeID && (
        <FormattedMessage
          id={`smartvaluation.cards.property_type.${equivalence[propertyTypeID]}`}
        />
      )
    );
  }
};

export const sortByOrder = list => {
  return list.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });
};
