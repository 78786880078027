import { checkUserIsLogged, setLanguage } from './user';
import { CANCEL_ACTION_REQUESTS, CANCEL_ALL_ACTION_REQUESTS } from '../constants.js';
import { setInitFeatureFlags } from './flags';
import { history } from '../App';
import queryString from 'query-string';

export const initApplication = () => async dispatch => {
  dispatch(setLanguage());
  if (history.location.pathname === '/iframe') {
    const { token } = queryString.parse(history.location.search);
    dispatch(checkUserIsLogged(token || null));
  } else {
    dispatch(checkUserIsLogged());
  }

  dispatch(setInitFeatureFlags());
};

export const cancelActionRequest = actionType => ({ type: CANCEL_ACTION_REQUESTS, actionType });

export const cancelAllActionRequests = () => ({ type: CANCEL_ALL_ACTION_REQUESTS });
