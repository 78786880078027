const getPrice = item => {
  return item?.price?.primary?.data?.value || item?.price.primary?.value || 0;
};

const getUnitPrice = item => {
  return item?.price?.secondary?.data?.value || item?.sqm_value?.primary?.value || 0;
};

const maxMinAvg = items => {
  let max = items[0];
  let min = items[0];
  let sum = items[0];

  for (var i = 1; i < items.length; i++) {
    if (items[i] > max) {
      max = items[i];
    }
    if (items[i] < min) {
      min = items[i];
    }
    sum = sum + items[i];
  }
  return { max, min, avg: sum / items.length };
};

const priceFormatted = num => {
  return {
    currency: 'EUR',
    format: 'price',
    value: num
  };
};

export const getAverageValueOfComparable = comparableList => {
  let prices = maxMinAvg(comparableList.map(c => getPrice(c)));
  let unitPrices = maxMinAvg(comparableList.map(c => getUnitPrice(c)));

  return {
    isAverage: true,
    uda: {
      label: 'Valoración media',
      primary: priceFormatted(prices.avg),
      secondary: {
        area: true,
        ...priceFormatted(unitPrices.avg)
      }
    },
    tooltip: {
      avg: {
        primary: priceFormatted(prices.avg),
        secondary: {
          area: true,
          ...priceFormatted(unitPrices.avg)
        }
      },
      min: {
        primary: priceFormatted(prices.min),
        secondary: {
          area: true,
          ...priceFormatted(unitPrices.min)
        }
      },
      max: {
        primary: priceFormatted(prices.max),
        secondary: {
          area: true,
          ...priceFormatted(unitPrices.max)
        }
      }
    }
  };
};
