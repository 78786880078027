import * as APP_CONSTANTS from '../constants.js';
//   PAIS: 10,
//   COMUNIDAD_AUTONOMA: 11,
//   PROVINCIA: 12,
//   MUNICIPIO: 13,
//   DISTRITO: 14,
//   BARRIO: 15,
//   SECCION_CENSAL: 16
export const BOUNDARY_TYPE_BY_ZOOM = {
  country: [1, 4],
  state: [5, 7],
  province: [8, 9],
  city: [10, 11],
  district: [7, 9],
  neighborhood: [12, 20]
};
export const MIN_BOUNDARY_TYPE_BY_ZOOM = {
  country: 1,
  province: 2,
  city: 6,
  district: 8,
  neighborhood: 9
};

export const INDICATOR = {
  total_population: 'n_pob',
  '3y_variation_population': 'n_pob_3yy',
  '14_yo_population': 'p_ed_14',
  '14_39_yo_population': 'p_ed_39',
  '40_65_yo_population': 'p_ed_64',
  '65_yo_population': 'p_ed_ma',
  total_active_population: 'p_ed_39+p_ed_64',
  no_studies_level: 'p_e_sin',
  primary_studies_level: 'p_e_pri',
  secondary_studies_level: 'p_e_seg',
  university_studies_level: 'p_e_ter',
  employed_population: 'n_empl_t',
  household_income: 'rent_hog_dis',
  total_dwelling_transactions: 'o_u_4q',
  '1y_variation_dwelling_transactions': 'o_u_4q_yoy',
  '1000_plp_dwelling_transactions': 'o_u_4q*1000/n_pob',
  total_dwellings: 'n_viv',
  total_listed_dwellings: 's_u_4q',
  '1y_variation_listed_dwellings': 's_u_4q_yoy',
  dwellings_absorption: 'o_abn_4q',
  '1y_dwellings_absorption': 'o_abn_4q_yoy',
  avg_listing_time: 's_t',
  second_hand_listing_time: 's_t_second',
  new_constr_listing_time: 's_t_new',
  gross_yield: 'y_r',
  maximum_peak_unit_price: 'o_pu_rate2max',
  minimum_peak_unit_price: 'o_pu_rate2min',
  avg_unit_price: 'o_pu',
  maximum_unit_price: 'o_pu_max',
  minimum_unit_price: 'o_pu_min',
  '70_sqm_unit_price': 'o_pu_P01',
  '70_100_sqm_unit_price': 'o_pu_P02',
  '100_140_sqm_unit_price': 'o_pu_P03',
  '140_180_sqm_unit_price': 'o_pu_P04',
  '180_250_sqm_unit_price': 'o_pu_P05',
  '250_sqm_unit_price': 'o_pu_P06',
  avg_asset_price: 'o_pm',
  '70_sqm_asset_price': 'o_pm_P01',
  '70_100_asset_price': 'o_pm_P02',
  '100_140_sqm_asset_price': 'o_pm_P03',
  '140_180_sqm_asset_price': 'o_pm_P04',
  '180_250_sqm_asset_price': 'o_pm_P05',
  '250_sqm_asset_price': 'o_pm_P06'
};

export const MARKET_COLORS = {
  sociodemographic: {
    monochromatic: [
      '#F4EFF1',
      '#EFDDE3',
      '#E4C7D0',
      '#C2A4B6',
      '#A888A3',
      '#775175',
      '#512B58',
      '#2C003E',
      '#1D0A25'
    ],
    bichromatic: [
      '#B85959',
      '#E1897F',
      '#FDC0AC',
      '#F7E2D7',
      '#E4C7D0',
      '#A888A3',
      '#775175',
      '#512B58',
      '#2C003E'
    ]
  },
  real_estate: {
    monochromatic: [
      '#D7ECEA',
      '#BCDBD9',
      '#9BCFCC',
      '#6BB0AC',
      '#24A49B',
      '#1B817A',
      '#0E504C',
      '#09332C',
      '#021512'
    ],
    bichromatic: [
      '#CC7D34',
      '#D99455',
      '#EDBE96',
      '#F2DBC8',
      '#BCDBD9',
      '#1B817A',
      '#0E504C',
      '#09332C',
      '#021512'
    ]
  },
  financial: {
    monochromatic: [
      '#E4FAFF',
      '#CAF0F8',
      '#ADE8F4',
      '#90D8EF',
      '#48B5E4',
      '#008BC7',
      '#0077B6',
      '#023E8A',
      '#03045E'
    ],
    bichromatic: [
      '#B85959',
      '#E1897F',
      '#F9CDAD',
      '#F2DBC8',
      '#CAF0F8',
      '#48B5E4',
      '#008BC7',
      '#023E8A',
      '#03045E'
    ]
  }
};

export const DEFAULT_ZOOM = 8;
export const DEFAULT_LNG = -3.741;
export const DEFAULT_LAT = 40.432;
export const DEFAULT_OPERATION = 1;
export const DEFAULT_BOUNDARY_TYPE = APP_CONSTANTS.BOUNDARY_TYPE.province;
export const DEFAULT_INDICATOR = 'o_pu';
export const MIN_ZOOM = 4;
export const MAX_ZOOM = 12;
