import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormGroup, InputField, SelectField } from '@uda/bento-components';
import countries from '../actions/countries';
import PaymentsCheckoutBillingSkeleton from './PaymentsCheckoutBillingSkeleton';
import { sortAlphabetically } from '../../utils/sort';

const StyledInput = styled(props => <FormGroup {...props} />)`
  max-width: 400px;
`;

const StyledCif = styled(props => <FormGroup {...props} />)`
  max-width: 140px;
`;

const StyledPostalCode = styled(props => <FormGroup {...props} />)`
  max-width: 100px;
`;

const StyledSeparator = styled.i`
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  margin: ${({ theme }) => theme.spacings.medium1} 0;
  display: block;
`;

const PaymentsCheckoutBilling = ({ formik, isLoading }) => {
  const intl = useIntl();
  const { isSubmitting, errors, touched, handleBlur, handleChange, setFieldValue, values } = formik;

  const lang = useSelector(state => state.user.data.language);

  const { company, cif, country, city, address, postal_code } = values;

  const options = countries
    .map(item => ({ value: item.iso2, label: item[lang] }))
    .sort((a, b) => sortAlphabetically(a.label, b.label));

  return isLoading ? (
    <PaymentsCheckoutBillingSkeleton />
  ) : (
    <>
      <StyledInput>
        <InputField
          autoComplete="off"
          disabled={isSubmitting}
          error={!!errors.company && !!touched.company && !!errors.company}
          help={(errors.company && touched.company && errors.company) || ''}
          label={intl.formatMessage({ id: `payments.billing.company_optional` })}
          name="company"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'payments.billing.company_placeholder'
          })}
          type="text"
          value={company}
        />
      </StyledInput>
      <StyledCif>
        <InputField
          autoComplete="off"
          disabled={isSubmitting}
          error={!!errors.cif && !!touched.cif && !!errors.cif}
          help={(errors.cif && touched.cif && errors.cif) || ''}
          label={intl.formatMessage({ id: `payments.billing.cif_optional` })}
          name="cif"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'payments.billing.cif_placeholder'
          })}
          type="text"
          value={cif}
        />
      </StyledCif>
      <StyledSeparator />
      <StyledInput>
        <SelectField
          disabled={isSubmitting}
          label={intl.formatMessage({ id: 'payments.billing.country' })}
          defaultLabel={intl.formatMessage({ id: 'payments.billing.country_placeholder' })}
          name="country"
          onBlur={handleBlur}
          value={country || 'ES'}
          options={options}
          onChange={value => setFieldValue('country', value)}
        />
      </StyledInput>
      <StyledInput>
        <InputField
          autoComplete="off"
          disabled={isSubmitting}
          error={!!errors.city && !!touched.city && !!errors.city}
          help={(errors.city && touched.city && errors.city) || ''}
          label={intl.formatMessage({ id: `payments.billing.city` })}
          name="city"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'payments.billing.city_placeholder'
          })}
          type="text"
          value={city}
          required
        />
      </StyledInput>
      <StyledInput>
        <InputField
          autoComplete="off"
          disabled={isSubmitting}
          error={!!errors.address && !!touched.address && !!errors.address}
          help={(errors.address && touched.address && errors.address) || ''}
          label={intl.formatMessage({ id: `payments.billing.address` })}
          name="address"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'payments.billing.address_placeholder'
          })}
          type="text"
          value={address}
          required
        />
      </StyledInput>
      <StyledPostalCode>
        <InputField
          autoComplete="off"
          disabled={isSubmitting}
          error={!!errors.postal_code && !!touched.postal_code && !!errors.postal_code}
          help={(errors.postal_code && touched.postal_code && errors.postal_code) || ''}
          label={intl.formatMessage({ id: `payments.billing.postal_code` })}
          name="postal_code"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'payments.billing.postal_code_placeholder'
          })}
          type="text"
          value={postal_code}
          required
        />
      </StyledPostalCode>
    </>
  );
};

export default PaymentsCheckoutBilling;
