export const getNewRangeFilters = (filters, rangeFilters) => {
  const newFilters = { ...filters };
  Object.keys(newFilters).forEach(kpi => {
    const filter = rangeFilters.find(rangeFilter => rangeFilter.kpi === kpi);
    if (filter) {
      const [min, max] = filter.values;
      if(newFilters[kpi]){
      if (
        (newFilters[kpi][0] === null || newFilters[kpi][0] === min) &&
        (newFilters[kpi][1] === null || newFilters[kpi][1] === max)
      ) {
        delete newFilters[kpi];
      }}
    }
  });
  return newFilters;
};
