import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  features: {}
};

export const personalizationAssetSlice = createSlice({
  name: 'personalizationAsset',
  initialState,
  reducers: {
    setPersonalizationFeatures: (state, { payload }) => {
      state.features = payload;
    }
  }
});

export const { setPersonalizationFeatures } = personalizationAssetSlice.actions;

export default personalizationAssetSlice.reducer;
