import PATHS from '../../routes/paths';
import { useHistory } from 'react-router-dom';
import PaymentProcessingError from '../components/PaymentProcessingError';

const PaymentsErrorContainer = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push(PATHS.base);
  };

  return <PaymentProcessingError onClick={handleClick} />;
};

export default PaymentsErrorContainer;
