const dateFields = [
  'year',
  'avg_weighted_o_date',
  'avg_weighted_d_date',
  'default_date',
  'originate_in_date',
  'publishing_date',
  'last_update',
  'date_out',
  'date_main',
  'last_price',
  'last_pm2',
  'area'
];

export const formatSortParams = sorting => {
  const sortingParam = sorting.sort === 'year' ? `${sorting.sort},status,name` : sorting.sort;
  let sortingOrder = sorting.order === 'desc' ? '-' : '';
  if (dateFields.includes(sorting.sort)) {
    sortingOrder = sorting.order === 'desc' ? '' : '-';
  }

  return `${sortingOrder}${sortingParam}`;
};

export const parseSortParams = sorting => {
  if (sorting) {
    let sort = sorting[0] === '-' ? sorting.substring(1) : sorting;
    sort = sort === `year,status,name` ? 'year' : sort;

    let order = sorting[0] === '-' ? 'desc' : 'asc';
    if (dateFields.includes(sort)) order = sorting[0] === '-' ? 'asc' : 'desc';

    return { sort, order };
  }

  return {};
};

export const sortAlphabetically = (a, b) => {
  if (a < b) return -1;

  if (a > b) return 1;

  return 0;
};
