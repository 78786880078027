const countries = [
  {
    es: 'Afganistán',
    en: 'Afghanistan',
    fr: 'Afghanistan',
    iso2: 'AF',
    iso3: 'AFG',
    phone_code: '93'
  },
  {
    es: 'Albania',
    en: 'Albania',
    fr: 'Albanie',
    iso2: 'AL',
    iso3: 'ALB',
    phone_code: '355'
  },
  {
    es: 'Alemania',
    en: 'Germany',
    fr: 'Allemagne',
    iso2: 'DE',
    iso3: 'DEU',
    phone_code: '49'
  },
  {
    es: 'Algeria',
    en: 'Algeria',
    fr: 'Algérie',
    iso2: 'DZ',
    iso3: 'DZA',
    phone_code: '213'
  },
  {
    es: 'Andorra',
    en: 'Andorra',
    fr: 'Andorra',
    iso2: 'AD',
    iso3: 'AND',
    phone_code: '376'
  },
  {
    es: 'Angola',
    en: 'Angola',
    fr: 'Angola',
    iso2: 'AO',
    iso3: 'AGO',
    phone_code: '244'
  },
  {
    es: 'Anguila',
    en: 'Anguilla',
    fr: 'Anguilla',
    iso2: 'AI',
    iso3: 'AIA',
    phone_code: '1 264'
  },
  {
    es: 'Antártida',
    en: 'Antarctica',
    fr: "L'Antarctique",
    iso2: 'AQ',
    iso3: 'ATA',
    phone_code: '672'
  },
  {
    es: 'Antigua y Barbuda',
    en: 'Antigua and Barbuda',
    fr: 'Antigua et Barbuda',
    iso2: 'AG',
    iso3: 'ATG',
    phone_code: '1 268'
  },
  {
    es: 'Antillas Neerlandesas',
    en: 'Netherlands Antilles',
    fr: 'Antilles Néerlandaises',
    iso2: 'AN',
    iso3: 'ANT',
    phone_code: '599'
  },
  {
    es: 'Arabia Saudita',
    en: 'Saudi Arabia',
    fr: 'Arabie Saoudite',
    iso2: 'SA',
    iso3: 'SAU',
    phone_code: '966'
  },
  {
    es: 'Argentina',
    en: 'Argentina',
    fr: 'Argentine',
    iso2: 'AR',
    iso3: 'ARG',
    phone_code: '54'
  },
  {
    es: 'Armenia',
    en: 'Armenia',
    fr: "L'Arménie",
    iso2: 'AM',
    iso3: 'ARM',
    phone_code: '374'
  },
  {
    es: 'Aruba',
    en: 'Aruba',
    fr: 'Aruba',
    iso2: 'AW',
    iso3: 'ABW',
    phone_code: '297'
  },
  {
    es: 'Australia',
    en: 'Australia',
    fr: 'Australie',
    iso2: 'AU',
    iso3: 'AUS',
    phone_code: '61'
  },
  {
    es: 'Austria',
    en: 'Austria',
    fr: 'Autriche',
    iso2: 'AT',
    iso3: 'AUT',
    phone_code: '43'
  },
  {
    es: 'Azerbayán',
    en: 'Azerbaijan',
    fr: "L'Azerbaïdjan",
    iso2: 'AZ',
    iso3: 'AZE',
    phone_code: '994'
  },
  {
    es: 'Bélgica',
    en: 'Belgium',
    fr: 'Belgique',
    iso2: 'BE',
    iso3: 'BEL',
    phone_code: '32'
  },
  {
    es: 'Bahamas',
    en: 'Bahamas',
    fr: 'Bahamas',
    iso2: 'BS',
    iso3: 'BHS',
    phone_code: '1 242'
  },
  {
    es: 'Bahrein',
    en: 'Bahrain',
    fr: 'Bahreïn',
    iso2: 'BH',
    iso3: 'BHR',
    phone_code: '973'
  },
  {
    es: 'Bangladesh',
    en: 'Bangladesh',
    fr: 'Bangladesh',
    iso2: 'BD',
    iso3: 'BGD',
    phone_code: '880'
  },
  {
    es: 'Barbados',
    en: 'Barbados',
    fr: 'Barbade',
    iso2: 'BB',
    iso3: 'BRB',
    phone_code: '1 246'
  },
  {
    es: 'Belice',
    en: 'Belize',
    fr: 'Belize',
    iso2: 'BZ',
    iso3: 'BLZ',
    phone_code: '501'
  },
  {
    es: 'Benín',
    en: 'Benin',
    fr: 'Bénin',
    iso2: 'BJ',
    iso3: 'BEN',
    phone_code: '229'
  },
  {
    es: 'Bhután',
    en: 'Bhutan',
    fr: 'Le Bhoutan',
    iso2: 'BT',
    iso3: 'BTN',
    phone_code: '975'
  },
  {
    es: 'Bielorrusia',
    en: 'Belarus',
    fr: 'Biélorussie',
    iso2: 'BY',
    iso3: 'BLR',
    phone_code: '375'
  },
  {
    es: 'Birmania',
    en: 'Myanmar',
    fr: 'Myanmar',
    iso2: 'MM',
    iso3: 'MMR',
    phone_code: '95'
  },
  {
    es: 'Bolivia',
    en: 'Bolivia',
    fr: 'Bolivie',
    iso2: 'BO',
    iso3: 'BOL',
    phone_code: '591'
  },
  {
    es: 'Bosnia y Herzegovina',
    en: 'Bosnia and Herzegovina',
    fr: 'Bosnie-Herzégovine',
    iso2: 'BA',
    iso3: 'BIH',
    phone_code: '387'
  },
  {
    es: 'Botsuana',
    en: 'Botswana',
    fr: 'Botswana',
    iso2: 'BW',
    iso3: 'BWA',
    phone_code: '267'
  },
  {
    es: 'Brasil',
    en: 'Brazil',
    fr: 'Brésil',
    iso2: 'BR',
    iso3: 'BRA',
    phone_code: '55'
  },
  {
    es: 'Brunéi',
    en: 'Brunei',
    fr: 'Brunei',
    iso2: 'BN',
    iso3: 'BRN',
    phone_code: '673'
  },
  {
    es: 'Bulgaria',
    en: 'Bulgaria',
    fr: 'Bulgarie',
    iso2: 'BG',
    iso3: 'BGR',
    phone_code: '359'
  },
  {
    es: 'Burkina Faso',
    en: 'Burkina Faso',
    fr: 'Burkina Faso',
    iso2: 'BF',
    iso3: 'BFA',
    phone_code: '226'
  },
  {
    es: 'Burundi',
    en: 'Burundi',
    fr: 'Burundi',
    iso2: 'BI',
    iso3: 'BDI',
    phone_code: '257'
  },
  {
    es: 'Cabo Verde',
    en: 'Cape Verde',
    fr: 'Cap-Vert',
    iso2: 'CV',
    iso3: 'CPV',
    phone_code: '238'
  },
  {
    es: 'Camboya',
    en: 'Cambodia',
    fr: 'Cambodge',
    iso2: 'KH',
    iso3: 'KHM',
    phone_code: '855'
  },
  {
    es: 'Camerún',
    en: 'Cameroon',
    fr: 'Cameroun',
    iso2: 'CM',
    iso3: 'CMR',
    phone_code: '237'
  },
  {
    es: 'Canadá',
    en: 'Canada',
    fr: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
    phone_code: '1'
  },
  {
    es: 'Chad',
    en: 'Chad',
    fr: 'Tchad',
    iso2: 'TD',
    iso3: 'TCD',
    phone_code: '235'
  },
  {
    es: 'Chile',
    en: 'Chile',
    fr: 'Chili',
    iso2: 'CL',
    iso3: 'CHL',
    phone_code: '56'
  },
  {
    es: 'China',
    en: 'China',
    fr: 'Chine',
    iso2: 'CN',
    iso3: 'CHN',
    phone_code: '86'
  },
  {
    es: 'Chipre',
    en: 'Cyprus',
    fr: 'Chypre',
    iso2: 'CY',
    iso3: 'CYP',
    phone_code: '357'
  },
  {
    es: 'Ciudad del Vaticano',
    en: 'Vatican City State',
    fr: 'Cité du Vatican',
    iso2: 'VA',
    iso3: 'VAT',
    phone_code: '39'
  },
  {
    es: 'Colombia',
    en: 'Colombia',
    fr: 'Colombie',
    iso2: 'CO',
    iso3: 'COL',
    phone_code: '57'
  },
  {
    es: 'Comoras',
    en: 'Comoros',
    fr: 'Comores',
    iso2: 'KM',
    iso3: 'COM',
    phone_code: '269'
  },
  {
    es: 'Congo',
    en: 'Congo',
    fr: 'Congo',
    iso2: 'CG',
    iso3: 'COG',
    phone_code: '242'
  },
  {
    es: 'Congo',
    en: 'Congo',
    fr: 'Congo',
    iso2: 'CD',
    iso3: 'COD',
    phone_code: '243'
  },
  {
    es: 'Corea del Norte',
    en: 'North Korea',
    fr: 'Corée du Nord',
    iso2: 'KP',
    iso3: 'PRK',
    phone_code: '850'
  },
  {
    es: 'Corea del Sur',
    en: 'South Korea',
    fr: 'Corée du Sud',
    iso2: 'KR',
    iso3: 'KOR',
    phone_code: '82'
  },
  {
    es: 'Costa de Marfil',
    en: 'Ivory Coast',
    fr: "Côte-d'Ivoire",
    iso2: 'CI',
    iso3: 'CIV',
    phone_code: '225'
  },
  {
    es: 'Costa Rica',
    en: 'Costa Rica',
    fr: 'Costa Rica',
    iso2: 'CR',
    iso3: 'CRI',
    phone_code: '506'
  },
  {
    es: 'Croacia',
    en: 'Croatia',
    fr: 'Croatie',
    iso2: 'HR',
    iso3: 'HRV',
    phone_code: '385'
  },
  {
    es: 'Cuba',
    en: 'Cuba',
    fr: 'Cuba',
    iso2: 'CU',
    iso3: 'CUB',
    phone_code: '53'
  },
  {
    es: 'Dinamarca',
    en: 'Denmark',
    fr: 'Danemark',
    iso2: 'DK',
    iso3: 'DNK',
    phone_code: '45'
  },
  {
    es: 'Dominica',
    en: 'Dominica',
    fr: 'Dominique',
    iso2: 'DM',
    iso3: 'DMA',
    phone_code: '1 767'
  },
  {
    es: 'Ecuador',
    en: 'Ecuador',
    fr: 'Equateur',
    iso2: 'EC',
    iso3: 'ECU',
    phone_code: '593'
  },
  {
    es: 'Egipto',
    en: 'Egypt',
    fr: 'Egypte',
    iso2: 'EG',
    iso3: 'EGY',
    phone_code: '20'
  },
  {
    es: 'El Salvador',
    en: 'El Salvador',
    fr: 'El Salvador',
    iso2: 'SV',
    iso3: 'SLV',
    phone_code: '503'
  },
  {
    es: 'Emiratos Árabes Unidos',
    en: 'United Arab Emirates',
    fr: 'Emirats Arabes Unis',
    iso2: 'AE',
    iso3: 'ARE',
    phone_code: '971'
  },
  {
    es: 'Eritrea',
    en: 'Eritrea',
    fr: 'Erythrée',
    iso2: 'ER',
    iso3: 'ERI',
    phone_code: '291'
  },
  {
    es: 'Eslovaquia',
    en: 'Slovakia',
    fr: 'Slovaquie',
    iso2: 'SK',
    iso3: 'SVK',
    phone_code: '421'
  },
  {
    es: 'Eslovenia',
    en: 'Slovenia',
    fr: 'Slovénie',
    iso2: 'SI',
    iso3: 'SVN',
    phone_code: '386'
  },
  {
    es: 'España',
    en: 'Spain',
    fr: 'Espagne',
    iso2: 'ES',
    iso3: 'ESP',
    phone_code: '34'
  },
  {
    es: 'Estados Unidos de América',
    en: 'United States of America',
    fr: "États-Unis d'Amérique",
    iso2: 'US',
    iso3: 'USA',
    phone_code: '1'
  },
  {
    es: 'Estonia',
    en: 'Estonia',
    fr: "L'Estonie",
    iso2: 'EE',
    iso3: 'EST',
    phone_code: '372'
  },
  {
    es: 'Etiopía',
    en: 'Ethiopia',
    fr: 'Ethiopie',
    iso2: 'ET',
    iso3: 'ETH',
    phone_code: '251'
  },
  {
    es: 'Filipinas',
    en: 'Philippines',
    fr: 'Philippines',
    iso2: 'PH',
    iso3: 'PHL',
    phone_code: '63'
  },
  {
    es: 'Finlandia',
    en: 'Finland',
    fr: 'Finlande',
    iso2: 'FI',
    iso3: 'FIN',
    phone_code: '358'
  },
  {
    es: 'Fiyi',
    en: 'Fiji',
    fr: 'Fidji',
    iso2: 'FJ',
    iso3: 'FJI',
    phone_code: '679'
  },
  {
    es: 'Francia',
    en: 'France',
    fr: 'France',
    iso2: 'FR',
    iso3: 'FRA',
    phone_code: '33'
  },
  {
    es: 'Gabón',
    en: 'Gabon',
    fr: 'Gabon',
    iso2: 'GA',
    iso3: 'GAB',
    phone_code: '241'
  },
  {
    es: 'Gambia',
    en: 'Gambia',
    fr: 'Gambie',
    iso2: 'GM',
    iso3: 'GMB',
    phone_code: '220'
  },
  {
    es: 'Georgia',
    en: 'Georgia',
    fr: 'Géorgie',
    iso2: 'GE',
    iso3: 'GEO',
    phone_code: '995'
  },
  {
    es: 'Ghana',
    en: 'Ghana',
    fr: 'Ghana',
    iso2: 'GH',
    iso3: 'GHA',
    phone_code: '233'
  },
  {
    es: 'Gibraltar',
    en: 'Gibraltar',
    fr: 'Gibraltar',
    iso2: 'GI',
    iso3: 'GIB',
    phone_code: '350'
  },
  {
    es: 'Granada',
    en: 'Grenada',
    fr: 'Grenade',
    iso2: 'GD',
    iso3: 'GRD',
    phone_code: '1 473'
  },
  {
    es: 'Grecia',
    en: 'Greece',
    fr: 'Grèce',
    iso2: 'GR',
    iso3: 'GRC',
    phone_code: '30'
  },
  {
    es: 'Groenlandia',
    en: 'Greenland',
    fr: 'Groenland',
    iso2: 'GL',
    iso3: 'GRL',
    phone_code: '299'
  },
  {
    es: 'Guadalupe',
    en: 'Guadeloupe',
    fr: 'Guadeloupe',
    iso2: 'GP',
    iso3: 'GLP',
    phone_code: ''
  },
  {
    es: 'Guam',
    en: 'Guam',
    fr: 'Guam',
    iso2: 'GU',
    iso3: 'GUM',
    phone_code: '1 671'
  },
  {
    es: 'Guatemala',
    en: 'Guatemala',
    fr: 'Guatemala',
    iso2: 'GT',
    iso3: 'GTM',
    phone_code: '502'
  },
  {
    es: 'Guayana Francesa',
    en: 'French Guiana',
    fr: 'Guyane française',
    iso2: 'GF',
    iso3: 'GUF',
    phone_code: ''
  },
  {
    es: 'Guernsey',
    en: 'Guernsey',
    fr: 'Guernesey',
    iso2: 'GG',
    iso3: 'GGY',
    phone_code: ''
  },
  {
    es: 'Guinea',
    en: 'Guinea',
    fr: 'Guinée',
    iso2: 'GN',
    iso3: 'GIN',
    phone_code: '224'
  },
  {
    es: 'Guinea Ecuatorial',
    en: 'Equatorial Guinea',
    fr: 'Guinée Equatoriale',
    iso2: 'GQ',
    iso3: 'GNQ',
    phone_code: '240'
  },
  {
    es: 'Guinea-Bissau',
    en: 'Guinea-Bissau',
    fr: 'Guinée-Bissau',
    iso2: 'GW',
    iso3: 'GNB',
    phone_code: '245'
  },
  {
    es: 'Guyana',
    en: 'Guyana',
    fr: 'Guyane',
    iso2: 'GY',
    iso3: 'GUY',
    phone_code: '592'
  },
  {
    es: 'Haití',
    en: 'Haiti',
    fr: 'Haïti',
    iso2: 'HT',
    iso3: 'HTI',
    phone_code: '509'
  },
  {
    es: 'Honduras',
    en: 'Honduras',
    fr: 'Honduras',
    iso2: 'HN',
    iso3: 'HND',
    phone_code: '504'
  },
  {
    es: 'Hong kong',
    en: 'Hong Kong',
    fr: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
    phone_code: '852'
  },
  {
    es: 'Hungría',
    en: 'Hungary',
    fr: 'Hongrie',
    iso2: 'HU',
    iso3: 'HUN',
    phone_code: '36'
  },
  {
    es: 'India',
    en: 'India',
    fr: 'Inde',
    iso2: 'IN',
    iso3: 'IND',
    phone_code: '91'
  },
  {
    es: 'Indonesia',
    en: 'Indonesia',
    fr: 'Indonésie',
    iso2: 'ID',
    iso3: 'IDN',
    phone_code: '62'
  },
  {
    es: 'Irán',
    en: 'Iran',
    fr: 'Iran',
    iso2: 'IR',
    iso3: 'IRN',
    phone_code: '98'
  },
  {
    es: 'Irak',
    en: 'Iraq',
    fr: 'Irak',
    iso2: 'IQ',
    iso3: 'IRQ',
    phone_code: '964'
  },
  {
    es: 'Irlanda',
    en: 'Ireland',
    fr: 'Irlande',
    iso2: 'IE',
    iso3: 'IRL',
    phone_code: '353'
  },
  {
    es: 'Isla Bouvet',
    en: 'Bouvet Island',
    fr: 'Bouvet Island',
    iso2: 'BV',
    iso3: 'BVT',
    phone_code: ''
  },
  {
    es: 'Isla de Man',
    en: 'Isle of Man',
    fr: 'Ile de Man',
    iso2: 'IM',
    iso3: 'IMN',
    phone_code: '44'
  },
  {
    es: 'Isla de Navidad',
    en: 'Christmas Island',
    fr: 'Christmas Island',
    iso2: 'CX',
    iso3: 'CXR',
    phone_code: '61'
  },
  {
    es: 'Isla Norfolk',
    en: 'Norfolk Island',
    fr: 'Île de Norfolk',
    iso2: 'NF',
    iso3: 'NFK',
    phone_code: ''
  },
  {
    es: 'Islandia',
    en: 'Iceland',
    fr: 'Islande',
    iso2: 'IS',
    iso3: 'ISL',
    phone_code: '354'
  },
  {
    es: 'Islas Bermudas',
    en: 'Bermuda Islands',
    fr: 'Bermudes',
    iso2: 'BM',
    iso3: 'BMU',
    phone_code: '1 441'
  },
  {
    es: 'Islas Caimán',
    en: 'Cayman Islands',
    fr: 'Iles Caïmans',
    iso2: 'KY',
    iso3: 'CYM',
    phone_code: '1 345'
  },
  {
    es: 'Islas Cocos (Keeling)',
    en: 'Cocos (Keeling) Islands',
    fr: 'Cocos (Keeling',
    iso2: 'CC',
    iso3: 'CCK',
    phone_code: '61'
  },
  {
    es: 'Islas Cook',
    en: 'Cook Islands',
    fr: 'Iles Cook',
    iso2: 'CK',
    iso3: 'COK',
    phone_code: '682'
  },
  {
    es: 'Islas de Åland',
    en: 'Åland Islands',
    fr: 'Îles Åland',
    iso2: 'AX',
    iso3: 'ALA',
    phone_code: ''
  },
  {
    es: 'Islas Feroe',
    en: 'Faroe Islands',
    fr: 'Iles Féro',
    iso2: 'FO',
    iso3: 'FRO',
    phone_code: '298'
  },
  {
    es: 'Islas Georgias del Sur y Sandwich del Sur',
    en: 'South Georgia and the South Sandwich Islands',
    fr: 'Géorgie du Sud et les Îles Sandwich du Sud',
    iso2: 'GS',
    iso3: 'SGS',
    phone_code: ''
  },
  {
    es: 'Islas Heard y McDonald',
    en: 'Heard Island and McDonald Islands',
    fr: 'Les îles Heard et McDonald',
    iso2: 'HM',
    iso3: 'HMD',
    phone_code: ''
  },
  {
    es: 'Islas Maldivas',
    en: 'Maldives',
    fr: 'Maldives',
    iso2: 'MV',
    iso3: 'MDV',
    phone_code: '960'
  },
  {
    es: 'Islas Malvinas',
    en: 'Falkland Islands (Malvinas)',
    fr: 'Iles Falkland (Malvinas',
    iso2: 'FK',
    iso3: 'FLK',
    phone_code: '500'
  },
  {
    es: 'Islas Marianas del Norte',
    en: 'Northern Mariana Islands',
    fr: 'Iles Mariannes du Nord',
    iso2: 'MP',
    iso3: 'MNP',
    phone_code: '1 670'
  },
  {
    es: 'Islas Marshall',
    en: 'Marshall Islands',
    fr: 'Iles Marshall',
    iso2: 'MH',
    iso3: 'MHL',
    phone_code: '692'
  },
  {
    es: 'Islas Pitcairn',
    en: 'Pitcairn Islands',
    fr: 'Iles Pitcairn',
    iso2: 'PN',
    iso3: 'PCN',
    phone_code: '870'
  },
  {
    es: 'Islas Salomón',
    en: 'Solomon Islands',
    fr: 'Iles Salomon',
    iso2: 'SB',
    iso3: 'SLB',
    phone_code: '677'
  },
  {
    es: 'Islas Turcas y Caicos',
    en: 'Turks and Caicos Islands',
    fr: 'Iles Turques et Caïques',
    iso2: 'TC',
    iso3: 'TCA',
    phone_code: '1 649'
  },
  {
    es: 'Islas Ultramarinas Menores de Estados Unidos',
    en: 'United States Minor Outlying Islands',
    fr: 'États-Unis Îles mineures éloignées',
    iso2: 'UM',
    iso3: 'UMI',
    phone_code: ''
  },
  {
    es: 'Islas Vírgenes Británicas',
    en: 'Virgin Islands',
    fr: 'Iles Vierges',
    iso2: 'VG',
    iso3: 'VG',
    phone_code: '1 284'
  },
  {
    es: 'Islas Vírgenes de los Estados Unidos',
    en: 'United States Virgin Islands',
    fr: 'Îles Vierges américaines',
    iso2: 'VI',
    iso3: 'VIR',
    phone_code: '1 340'
  },
  {
    es: 'Israel',
    en: 'Israel',
    fr: 'Israël',
    iso2: 'IL',
    iso3: 'ISR',
    phone_code: '972'
  },
  {
    es: 'Italia',
    en: 'Italy',
    fr: 'Italie',
    iso2: 'IT',
    iso3: 'ITA',
    phone_code: '39'
  },
  {
    es: 'Jamaica',
    en: 'Jamaica',
    fr: 'Jamaïque',
    iso2: 'JM',
    iso3: 'JAM',
    phone_code: '1 876'
  },
  {
    es: 'Japón',
    en: 'Japan',
    fr: 'Japon',
    iso2: 'JP',
    iso3: 'JPN',
    phone_code: '81'
  },
  {
    es: 'Jersey',
    en: 'Jersey',
    fr: 'Maillot',
    iso2: 'JE',
    iso3: 'JEY',
    phone_code: ''
  },
  {
    es: 'Jordania',
    en: 'Jordan',
    fr: 'Jordan',
    iso2: 'JO',
    iso3: 'JOR',
    phone_code: '962'
  },
  {
    es: 'Kazajistán',
    en: 'Kazakhstan',
    fr: 'Le Kazakhstan',
    iso2: 'KZ',
    iso3: 'KAZ',
    phone_code: '7'
  },
  {
    es: 'Kenia',
    en: 'Kenya',
    fr: 'Kenya',
    iso2: 'KE',
    iso3: 'KEN',
    phone_code: '254'
  },
  {
    es: 'Kirgizstán',
    en: 'Kyrgyzstan',
    fr: 'Kirghizstan',
    iso2: 'KG',
    iso3: 'KGZ',
    phone_code: '996'
  },
  {
    es: 'Kiribati',
    en: 'Kiribati',
    fr: 'Kiribati',
    iso2: 'KI',
    iso3: 'KIR',
    phone_code: '686'
  },
  {
    es: 'Kuwait',
    en: 'Kuwait',
    fr: 'Koweït',
    iso2: 'KW',
    iso3: 'KWT',
    phone_code: '965'
  },
  {
    es: 'Líbano',
    en: 'Lebanon',
    fr: 'Liban',
    iso2: 'LB',
    iso3: 'LBN',
    phone_code: '961'
  },
  {
    es: 'Laos',
    en: 'Laos',
    fr: 'Laos',
    iso2: 'LA',
    iso3: 'LAO',
    phone_code: '856'
  },
  {
    es: 'Lesoto',
    en: 'Lesotho',
    fr: 'Lesotho',
    iso2: 'LS',
    iso3: 'LSO',
    phone_code: '266'
  },
  {
    es: 'Letonia',
    en: 'Latvia',
    fr: 'La Lettonie',
    iso2: 'LV',
    iso3: 'LVA',
    phone_code: '371'
  },
  {
    es: 'Liberia',
    en: 'Liberia',
    fr: 'Liberia',
    iso2: 'LR',
    iso3: 'LBR',
    phone_code: '231'
  },
  {
    es: 'Libia',
    en: 'Libya',
    fr: 'Libye',
    iso2: 'LY',
    iso3: 'LBY',
    phone_code: '218'
  },
  {
    es: 'Liechtenstein',
    en: 'Liechtenstein',
    fr: 'Liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
    phone_code: '423'
  },
  {
    es: 'Lituania',
    en: 'Lithuania',
    fr: 'La Lituanie',
    iso2: 'LT',
    iso3: 'LTU',
    phone_code: '370'
  },
  {
    es: 'Luxemburgo',
    en: 'Luxembourg',
    fr: 'Luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
    phone_code: '352'
  },
  {
    es: 'México',
    en: 'Mexico',
    fr: 'Mexique',
    iso2: 'MX',
    iso3: 'MEX',
    phone_code: '52'
  },
  {
    es: 'Mónaco',
    en: 'Monaco',
    fr: 'Monaco',
    iso2: 'MC',
    iso3: 'MCO',
    phone_code: '377'
  },
  {
    es: 'Macao',
    en: 'Macao',
    fr: 'Macao',
    iso2: 'MO',
    iso3: 'MAC',
    phone_code: '853'
  },
  {
    es: 'Macedônia',
    en: 'Macedonia',
    fr: 'Macédoine',
    iso2: 'MK',
    iso3: 'MKD',
    phone_code: '389'
  },
  {
    es: 'Madagascar',
    en: 'Madagascar',
    fr: 'Madagascar',
    iso2: 'MG',
    iso3: 'MDG',
    phone_code: '261'
  },
  {
    es: 'Malasia',
    en: 'Malaysia',
    fr: 'Malaisie',
    iso2: 'MY',
    iso3: 'MYS',
    phone_code: '60'
  },
  {
    es: 'Malawi',
    en: 'Malawi',
    fr: 'Malawi',
    iso2: 'MW',
    iso3: 'MWI',
    phone_code: '265'
  },
  {
    es: 'Mali',
    en: 'Mali',
    fr: 'Mali',
    iso2: 'ML',
    iso3: 'MLI',
    phone_code: '223'
  },
  {
    es: 'Malta',
    en: 'Malta',
    fr: 'Malte',
    iso2: 'MT',
    iso3: 'MLT',
    phone_code: '356'
  },
  {
    es: 'Marruecos',
    en: 'Morocco',
    fr: 'Maroc',
    iso2: 'MA',
    iso3: 'MAR',
    phone_code: '212'
  },
  {
    es: 'Martinica',
    en: 'Martinique',
    fr: 'Martinique',
    iso2: 'MQ',
    iso3: 'MTQ',
    phone_code: ''
  },
  {
    es: 'Mauricio',
    en: 'Mauritius',
    fr: 'Iles Maurice',
    iso2: 'MU',
    iso3: 'MUS',
    phone_code: '230'
  },
  {
    es: 'Mauritania',
    en: 'Mauritania',
    fr: 'Mauritanie',
    iso2: 'MR',
    iso3: 'MRT',
    phone_code: '222'
  },
  {
    es: 'Mayotte',
    en: 'Mayotte',
    fr: 'Mayotte',
    iso2: 'YT',
    iso3: 'MYT',
    phone_code: '262'
  },
  {
    es: 'Micronesia',
    en: 'Estados Federados de',
    fr: 'Federados Estados de',
    iso2: 'FM',
    iso3: 'FSM',
    phone_code: '691'
  },
  {
    es: 'Moldavia',
    en: 'Moldova',
    fr: 'Moldavie',
    iso2: 'MD',
    iso3: 'MDA',
    phone_code: '373'
  },
  {
    es: 'Mongolia',
    en: 'Mongolia',
    fr: 'Mongolie',
    iso2: 'MN',
    iso3: 'MNG',
    phone_code: '976'
  },
  {
    es: 'Montenegro',
    en: 'Montenegro',
    fr: 'Monténégro',
    iso2: 'ME',
    iso3: 'MNE',
    phone_code: '382'
  },
  {
    es: 'Montserrat',
    en: 'Montserrat',
    fr: 'Montserrat',
    iso2: 'MS',
    iso3: 'MSR',
    phone_code: '1 664'
  },
  {
    es: 'Mozambique',
    en: 'Mozambique',
    fr: 'Mozambique',
    iso2: 'MZ',
    iso3: 'MOZ',
    phone_code: '258'
  },
  {
    es: 'Namibia',
    en: 'Namibia',
    fr: 'Namibie',
    iso2: 'NA',
    iso3: 'NAM',
    phone_code: '264'
  },
  {
    es: 'Nauru',
    en: 'Nauru',
    fr: 'Nauru',
    iso2: 'NR',
    iso3: 'NRU',
    phone_code: '674'
  },
  {
    es: 'Nepal',
    en: 'Nepal',
    fr: 'Népal',
    iso2: 'NP',
    iso3: 'NPL',
    phone_code: '977'
  },
  {
    es: 'Nicaragua',
    en: 'Nicaragua',
    fr: 'Nicaragua',
    iso2: 'NI',
    iso3: 'NIC',
    phone_code: '505'
  },
  {
    es: 'Niger',
    en: 'Niger',
    fr: 'Niger',
    iso2: 'NE',
    iso3: 'NER',
    phone_code: '227'
  },
  {
    es: 'Nigeria',
    en: 'Nigeria',
    fr: 'Nigeria',
    iso2: 'NG',
    iso3: 'NGA',
    phone_code: '234'
  },
  {
    es: 'Niue',
    en: 'Niue',
    fr: 'Niou',
    iso2: 'NU',
    iso3: 'NIU',
    phone_code: '683'
  },
  {
    es: 'Noruega',
    en: 'Norway',
    fr: 'Norvège',
    iso2: 'NO',
    iso3: 'NOR',
    phone_code: '47'
  },
  {
    es: 'Nueva Caledonia',
    en: 'New Caledonia',
    fr: 'Nouvelle-Calédonie',
    iso2: 'NC',
    iso3: 'NCL',
    phone_code: '687'
  },
  {
    es: 'Nueva Zelanda',
    en: 'New Zealand',
    fr: 'Nouvelle-Zélande',
    iso2: 'NZ',
    iso3: 'NZL',
    phone_code: '64'
  },
  {
    es: 'Omán',
    en: 'Oman',
    fr: 'Oman',
    iso2: 'OM',
    iso3: 'OMN',
    phone_code: '968'
  },
  {
    es: 'Países Bajos',
    en: 'Netherlands',
    fr: 'Pays-Bas',
    iso2: 'NL',
    iso3: 'NLD',
    phone_code: '31'
  },
  {
    es: 'Pakistán',
    en: 'Pakistan',
    fr: 'Pakistan',
    iso2: 'PK',
    iso3: 'PAK',
    phone_code: '92'
  },
  {
    es: 'Palau',
    en: 'Palau',
    fr: 'Palau',
    iso2: 'PW',
    iso3: 'PLW',
    phone_code: '680'
  },
  {
    es: 'Palestina',
    en: 'Palestine',
    fr: 'La Palestine',
    iso2: 'PS',
    iso3: 'PSE',
    phone_code: ''
  },
  {
    es: 'Panamá',
    en: 'Panama',
    fr: 'Panama',
    iso2: 'PA',
    iso3: 'PAN',
    phone_code: '507'
  },
  {
    es: 'Papúa Nueva Guinea',
    en: 'Papua New Guinea',
    fr: 'Papouasie-Nouvelle-Guinée',
    iso2: 'PG',
    iso3: 'PNG',
    phone_code: '675'
  },
  {
    es: 'Paraguay',
    en: 'Paraguay',
    fr: 'Paraguay',
    iso2: 'PY',
    iso3: 'PRY',
    phone_code: '595'
  },
  {
    es: 'Perú',
    en: 'Peru',
    fr: 'Pérou',
    iso2: 'PE',
    iso3: 'PER',
    phone_code: '51'
  },
  {
    es: 'Polinesia Francesa',
    en: 'French Polynesia',
    fr: 'Polynésie française',
    iso2: 'PF',
    iso3: 'PYF',
    phone_code: '689'
  },
  {
    es: 'Polonia',
    en: 'Poland',
    fr: 'Pologne',
    iso2: 'PL',
    iso3: 'POL',
    phone_code: '48'
  },
  {
    es: 'Portugal',
    en: 'Portugal',
    fr: 'Portugal',
    iso2: 'PT',
    iso3: 'PRT',
    phone_code: '351'
  },
  {
    es: 'Puerto Rico',
    en: 'Puerto Rico',
    fr: 'Porto Rico',
    iso2: 'PR',
    iso3: 'PRI',
    phone_code: '1'
  },
  {
    es: 'Qatar',
    en: 'Qatar',
    fr: 'Qatar',
    iso2: 'QA',
    iso3: 'QAT',
    phone_code: '974'
  },
  {
    es: 'Reino Unido',
    en: 'United Kingdom',
    fr: 'Royaume-Uni',
    iso2: 'GB',
    iso3: 'GBR',
    phone_code: '44'
  },
  {
    es: 'República Centroafricana',
    en: 'Central African Republic',
    fr: 'République Centrafricaine',
    iso2: 'CF',
    iso3: 'CAF',
    phone_code: '236'
  },
  {
    es: 'República Checa',
    en: 'Czech Republic',
    fr: 'République Tchèque',
    iso2: 'CZ',
    iso3: 'CZE',
    phone_code: '420'
  },
  {
    es: 'República Dominicana',
    en: 'Dominican Republic',
    fr: 'République Dominicaine',
    iso2: 'DO',
    iso3: 'DOM',
    phone_code: '1 809'
  },
  {
    es: 'Reunión',
    en: 'Réunion',
    fr: 'Réunion',
    iso2: 'RE',
    iso3: 'REU',
    phone_code: ''
  },
  {
    es: 'Ruanda',
    en: 'Rwanda',
    fr: 'Rwanda',
    iso2: 'RW',
    iso3: 'RWA',
    phone_code: '250'
  },
  {
    es: 'Rumanía',
    en: 'Romania',
    fr: 'Roumanie',
    iso2: 'RO',
    iso3: 'ROU',
    phone_code: '40'
  },
  {
    es: 'Rusia',
    en: 'Russia',
    fr: 'La Russie',
    iso2: 'RU',
    iso3: 'RUS',
    phone_code: '7'
  },
  {
    es: 'Sahara Occidental',
    en: 'Western Sahara',
    fr: 'Sahara Occidental',
    iso2: 'EH',
    iso3: 'ESH',
    phone_code: ''
  },
  {
    es: 'Samoa',
    en: 'Samoa',
    fr: 'Samoa',
    iso2: 'WS',
    iso3: 'WSM',
    phone_code: '685'
  },
  {
    es: 'Samoa Americana',
    en: 'American Samoa',
    fr: 'Les Samoa américaines',
    iso2: 'AS',
    iso3: 'ASM',
    phone_code: '1 684'
  },
  {
    es: 'San Bartolomé',
    en: 'Saint Barthélemy',
    fr: 'Saint-Barthélemy',
    iso2: 'BL',
    iso3: 'BLM',
    phone_code: '590'
  },
  {
    es: 'San Cristóbal y Nieves',
    en: 'Saint Kitts and Nevis',
    fr: 'Saint Kitts et Nevis',
    iso2: 'KN',
    iso3: 'KNA',
    phone_code: '1 869'
  },
  {
    es: 'San Marino',
    en: 'San Marino',
    fr: 'San Marino',
    iso2: 'SM',
    iso3: 'SMR',
    phone_code: '378'
  },
  {
    es: 'San Martín (Francia)',
    en: 'Saint Martin (French part)',
    fr: 'Saint-Martin (partie française)',
    iso2: 'MF',
    iso3: 'MAF',
    phone_code: '1 599'
  },
  {
    es: 'San Pedro y Miquelón',
    en: 'Saint Pierre and Miquelon',
    fr: 'Saint-Pierre-et-Miquelon',
    iso2: 'PM',
    iso3: 'SPM',
    phone_code: '508'
  },
  {
    es: 'San Vicente y las Granadinas',
    en: 'Saint Vincent and the Grenadines',
    fr: 'Saint-Vincent et Grenadines',
    iso2: 'VC',
    iso3: 'VCT',
    phone_code: '1 784'
  },
  {
    es: 'Santa Elena',
    en: 'Ascensión y Tristán de Acuña',
    fr: 'Ascensión y Tristan de Acuña',
    iso2: 'SH',
    iso3: 'SHN',
    phone_code: '290'
  },
  {
    es: 'Santa Lucía',
    en: 'Saint Lucia',
    fr: 'Sainte-Lucie',
    iso2: 'LC',
    iso3: 'LCA',
    phone_code: '1 758'
  },
  {
    es: 'Santo Tomé y Príncipe',
    en: 'Sao Tome and Principe',
    fr: 'Sao Tomé et Principe',
    iso2: 'ST',
    iso3: 'STP',
    phone_code: '239'
  },
  {
    es: 'Senegal',
    en: 'Senegal',
    fr: 'Sénégal',
    iso2: 'SN',
    iso3: 'SEN',
    phone_code: '221'
  },
  {
    es: 'Serbia',
    en: 'Serbia',
    fr: 'Serbie',
    iso2: 'RS',
    iso3: 'SRB',
    phone_code: '381'
  },
  {
    es: 'Seychelles',
    en: 'Seychelles',
    fr: 'Les Seychelles',
    iso2: 'SC',
    iso3: 'SYC',
    phone_code: '248'
  },
  {
    es: 'Sierra Leona',
    en: 'Sierra Leone',
    fr: 'Sierra Leone',
    iso2: 'SL',
    iso3: 'SLE',
    phone_code: '232'
  },
  {
    es: 'Singapur',
    en: 'Singapore',
    fr: 'Singapour',
    iso2: 'SG',
    iso3: 'SGP',
    phone_code: '65'
  },
  {
    es: 'Siria',
    en: 'Syria',
    fr: 'Syrie',
    iso2: 'SY',
    iso3: 'SYR',
    phone_code: '963'
  },
  {
    es: 'Somalia',
    en: 'Somalia',
    fr: 'Somalie',
    iso2: 'SO',
    iso3: 'SOM',
    phone_code: '252'
  },
  {
    es: 'Sri lanka',
    en: 'Sri Lanka',
    fr: 'Sri Lanka',
    iso2: 'LK',
    iso3: 'LKA',
    phone_code: '94'
  },
  {
    es: 'Sudáfrica',
    en: 'South Africa',
    fr: 'Afrique du Sud',
    iso2: 'ZA',
    iso3: 'ZAF',
    phone_code: '27'
  },
  {
    es: 'Sudán',
    en: 'Sudan',
    fr: 'Soudan',
    iso2: 'SD',
    iso3: 'SDN',
    phone_code: '249'
  },
  {
    es: 'Suecia',
    en: 'Sweden',
    fr: 'Suède',
    iso2: 'SE',
    iso3: 'SWE',
    phone_code: '46'
  },
  {
    es: 'Suiza',
    en: 'Switzerland',
    fr: 'Suisse',
    iso2: 'CH',
    iso3: 'CHE',
    phone_code: '41'
  },
  {
    es: 'Surinám',
    en: 'Suriname',
    fr: 'Surinam',
    iso2: 'SR',
    iso3: 'SUR',
    phone_code: '597'
  },
  {
    es: 'Svalbard y Jan Mayen',
    en: 'Svalbard and Jan Mayen',
    fr: 'Svalbard et Jan Mayen',
    iso2: 'SJ',
    iso3: 'SJM',
    phone_code: ''
  },
  {
    es: 'Swazilandia',
    en: 'Swaziland',
    fr: 'Swaziland',
    iso2: 'SZ',
    iso3: 'SWZ',
    phone_code: '268'
  },
  {
    es: 'Tadjikistán',
    en: 'Tajikistan',
    fr: 'Le Tadjikistan',
    iso2: 'TJ',
    iso3: 'TJK',
    phone_code: '992'
  },
  {
    es: 'Tailandia',
    en: 'Thailand',
    fr: 'Thaïlande',
    iso2: 'TH',
    iso3: 'THA',
    phone_code: '66'
  },
  {
    es: 'Taiwán',
    en: 'Taiwan',
    fr: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    phone_code: '886'
  },
  {
    es: 'Tanzania',
    en: 'Tanzania',
    fr: 'Tanzanie',
    iso2: 'TZ',
    iso3: 'TZA',
    phone_code: '255'
  },
  {
    es: 'Territorio Británico del Océano Índico',
    en: 'British Indian Ocean Territory',
    fr: "Territoire britannique de l'océan Indien",
    iso2: 'IO',
    iso3: 'IOT',
    phone_code: ''
  },
  {
    es: 'Territorios Australes y Antárticas Franceses',
    en: 'French Southern Territories',
    fr: 'Terres australes françaises',
    iso2: 'TF',
    iso3: 'ATF',
    phone_code: ''
  },
  {
    es: 'Timor Oriental',
    en: 'East Timor',
    fr: 'Timor-Oriental',
    iso2: 'TL',
    iso3: 'TLS',
    phone_code: '670'
  },
  {
    es: 'Togo',
    en: 'Togo',
    fr: 'Togo',
    iso2: 'TG',
    iso3: 'TGO',
    phone_code: '228'
  },
  {
    es: 'Tokelau',
    en: 'Tokelau',
    fr: 'Tokélaou',
    iso2: 'TK',
    iso3: 'TKL',
    phone_code: '690'
  },
  {
    es: 'Tonga',
    en: 'Tonga',
    fr: 'Tonga',
    iso2: 'TO',
    iso3: 'TON',
    phone_code: '676'
  },
  {
    es: 'Trinidad y Tobago',
    en: 'Trinidad and Tobago',
    fr: 'Trinidad et Tobago',
    iso2: 'TT',
    iso3: 'TTO',
    phone_code: '1 868'
  },
  {
    es: 'Tunez',
    en: 'Tunisia',
    fr: 'Tunisie',
    iso2: 'TN',
    iso3: 'TUN',
    phone_code: '216'
  },
  {
    es: 'Turkmenistán',
    en: 'Turkmenistan',
    fr: 'Le Turkménistan',
    iso2: 'TM',
    iso3: 'TKM',
    phone_code: '993'
  },
  {
    es: 'Turquía',
    en: 'Turkey',
    fr: 'Turquie',
    iso2: 'TR',
    iso3: 'TUR',
    phone_code: '90'
  },
  {
    es: 'Tuvalu',
    en: 'Tuvalu',
    fr: 'Tuvalu',
    iso2: 'TV',
    iso3: 'TUV',
    phone_code: '688'
  },
  {
    es: 'Ucrania',
    en: 'Ukraine',
    fr: "L'Ukraine",
    iso2: 'UA',
    iso3: 'UKR',
    phone_code: '380'
  },
  {
    es: 'Uganda',
    en: 'Uganda',
    fr: 'Ouganda',
    iso2: 'UG',
    iso3: 'UGA',
    phone_code: '256'
  },
  {
    es: 'Uruguay',
    en: 'Uruguay',
    fr: 'Uruguay',
    iso2: 'UY',
    iso3: 'URY',
    phone_code: '598'
  },
  {
    es: 'Uzbekistán',
    en: 'Uzbekistan',
    fr: "L'Ouzbékistan",
    iso2: 'UZ',
    iso3: 'UZB',
    phone_code: '998'
  },
  {
    es: 'Vanuatu',
    en: 'Vanuatu',
    fr: 'Vanuatu',
    iso2: 'VU',
    iso3: 'VUT',
    phone_code: '678'
  },
  {
    es: 'Venezuela',
    en: 'Venezuela',
    fr: 'Venezuela',
    iso2: 'VE',
    iso3: 'VEN',
    phone_code: '58'
  },
  {
    es: 'Vietnam',
    en: 'Vietnam',
    fr: 'Vietnam',
    iso2: 'VN',
    iso3: 'VNM',
    phone_code: '84'
  },
  {
    es: 'Wallis y Futuna',
    en: 'Wallis and Futuna',
    fr: 'Wallis et Futuna',
    iso2: 'WF',
    iso3: 'WLF',
    phone_code: '681'
  },
  {
    es: 'Yemen',
    en: 'Yemen',
    fr: 'Yémen',
    iso2: 'YE',
    iso3: 'YEM',
    phone_code: '967'
  },
  {
    es: 'Yibuti',
    en: 'Djibouti',
    fr: 'Djibouti',
    iso2: 'DJ',
    iso3: 'DJI',
    phone_code: '253'
  },
  {
    es: 'Zambia',
    en: 'Zambia',
    fr: 'Zambie',
    iso2: 'ZM',
    iso3: 'ZMB',
    phone_code: '260'
  },
  {
    es: 'Zimbabue',
    en: 'Zimbabwe',
    fr: 'Zimbabwe',
    iso2: 'ZW',
    iso3: 'ZWE',
    phone_code: '263'
  }
];

export default countries;
