import Style from './MenuItem.style';

const MenuItem = ({ value, children, ...other }) => {
  return (
    <Style>
      <button value={value} {...other}>
        {children}
      </button>
    </Style>
  );
};

export default MenuItem;
