import { Children, cloneElement, useState, useRef } from 'react';
import { TextLink, IconArrowClose } from '@uda/bento-components';
import useOnclickOutside from 'react-cool-onclickoutside';

import Style from './index.style';

const Select = ({ value, label, children, onChange }) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef();

  useOnclickOutside(ref, () => {
    setOpened(false);
  });

  const handleClick = update => {
    onChange(update);
    setOpened(false);
  };

  const handleOpened = e => {
    e.preventDefault();
    setOpened(!opened);
  };

  return (
    <Style opened={opened} ref={ref}>
      <TextLink onClick={handleOpened} variant="secondary">
        {label}
        <IconArrowClose />
      </TextLink>
      <ul>
        {Children.map(children, child =>
          cloneElement(child, {
            onClick: () => handleClick(child.props.value),
            className: value === child.props.value ? 'selected' : null
          })
        )}
      </ul>
    </Style>
  );
};

export default Select;
