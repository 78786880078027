import EN_SMARTVALUATION from '../SmartValuation/translations/EN.json';
import ES_SMARTVALUATION from '../SmartValuation/translations/ES.json';
import PT_SMARTVALUATION from '../SmartValuation/translations/PT.json';
import IT_SMARTVALUATION from '../SmartValuation/translations/IT.json';
import EN_CAPTURE from '../Capture/translations/EN.json';
import ES_CAPTURE from '../Capture/translations/ES.json';
import IT_CAPTURE from '../Capture/translations/IT.json';
import PT_CAPTURE from '../Capture/translations/PT.json';
import EN_OPPORTUNITIES from '../Opportunities/translations/EN.json';
import ES_OPPORTUNITIES from '../Opportunities/translations/ES.json';
import IT_OPPORTUNITIES from '../Opportunities/translations/IT.json';
import PT_OPPORTUNITIES from '../Opportunities/translations/PT.json';
import EN_PORTFOLIO from '../Portfolio/translations/EN.json';
import ES_PORTFOLIO from '../Portfolio/translations/ES.json';
import PT_PORTFOLIO from '../Portfolio/translations/PT.json';
import IT_PORTFOLIO from '../Portfolio/translations/IT.json';
import EN_MARKET from '../Market/translations/EN.json';
import ES_MARKET from '../Market/translations/ES.json';
import PT_MARKET from '../Market/translations/PT.json';
import IT_MARKET from '../Market/translations/IT.json';
import EN from './EN.json';
import ES from './ES.json';
import IT from './IT.json';
import PT from './PT.json';

export const translations = {
  en: {
    ...EN,
    ...EN_SMARTVALUATION,
    ...EN_CAPTURE,
    ...EN_OPPORTUNITIES,
    ...EN_PORTFOLIO,
    ...EN_MARKET
  },
  es: {
    ...ES,
    ...ES_SMARTVALUATION,
    ...ES_CAPTURE,
    ...ES_OPPORTUNITIES,
    ...ES_PORTFOLIO,
    ...ES_MARKET
  },
  pt: {
    ...PT,
    ...PT_SMARTVALUATION,
    ...PT_CAPTURE,
    ...PT_OPPORTUNITIES,
    ...PT_PORTFOLIO,
    ...PT_MARKET
  },
  it: {
    ...IT,
    ...IT_SMARTVALUATION,
    ...IT_CAPTURE,
    ...IT_OPPORTUNITIES,
    ...IT_PORTFOLIO,
    ...IT_MARKET
  }
};

const browserLang = navigator.language.split(/[-_]/)[0];

export const locale = browserLang in translations ? browserLang : 'en';

export const isLanguageSupported = lang => (lang in translations ? true : false);

export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
}
