import { useEffect, useState } from 'react';

const useCalculateHeaderHeight = () => {
  const [calcHeaderHeight, setCalcHeaderHeight] = useState(0);

  const handleCalcHeight = () =>
    [...document.querySelectorAll('[data-header]')]
      .map(element => element.getBoundingClientRect().height)
      .reduce((prev, currentValue) => prev + currentValue, 0);

  useEffect(() => {
    window.addEventListener('resize', () => setCalcHeaderHeight(handleCalcHeight()));

    setCalcHeaderHeight(handleCalcHeight());

    return () => window.removeEventListener('resize', handleCalcHeight);
  }, [calcHeaderHeight]);

  return [calcHeaderHeight, () => setCalcHeaderHeight(handleCalcHeight())];
};

export default useCalculateHeaderHeight;
