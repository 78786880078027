import { LISTING_TYPE_EQUIVALENCE } from 'constants.js';

const parseActives = actives => {
  const listingTypeValues = Object.values(LISTING_TYPE_EQUIVALENCE);
  const response = Object.entries(actives).reduce((acc, [key, value]) => {
    if (!listingTypeValues.includes(Number(key))) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});

  return Object.keys(response).length ? response : null;
};

export const mapAddressPortals = res => {
  const { code, results, type } = res || {};
  const response = {
    portals: null,
    actives: null,
    recommendations: null
  };
  if (!code || code === 400 || !type) return response;

  const responseByType = {
    portals: () => (response.portals = results),
    exact: () => (response.actives = parseActives(results)),
    recommended: () => (response.recommendations = results)
  };
  responseByType[type] && responseByType[type]();

  return response;
};

export const mapActivesByPortalId = response => {
  const { results = {} } = response || {};

  return parseActives(results);
};
