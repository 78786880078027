import styled, { useTheme } from 'styled-components';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Modal,
  Grid,
  FormGroup,
  ToggleGroup,
  Separator,
  TextLink,
  InputField,
  Button,
  IconClose,
  TextareaField,
  Loader
} from '@uda/bento-components';
import { useFormik } from 'formik';
import PATHS from '../routes/paths';
import { useState } from 'react';
import { fetchEnterpriseEmail } from '../actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { getLastProduct, telRegExp, cleanEmptyObjectKeys } from '../utils';
import useFlag from '../utils/hooks/useFlag';

const StyledModal = styled(props => <Modal {...props} />)`
  width: 60%;
  @media only screen and (max-width: 1200px) {
    width: 80%;
    height: auto;
    max-height: auto;
  }

  @media only screen and (max-width: 1024px) {
    width: 70%;
    max-width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`;

const StyledGrid = styled(props => <Grid {...props} />)`
  min-height: 700px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column;
    min-height: 0;
  }
`;

const StyledText = styled.p`
  ${({ theme }) => theme.texts.p1}
  padding: ${({ theme: { spacings } }) => `${spacings.small3} 0 ${spacings.medium1} 0`};
`;

const StyledContactTitle = styled.p`
  ${({ theme }) => theme.texts.p1}
`;

const StyledSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledSuccessText = styled.p`
  ${({ theme }) => theme.texts.p1}
`;

const MessageWrapper = styled.div`
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.charcoal600};
  padding: ${({ theme }) => `${theme.spacings.small4} 0`};
`;

const FormContainer = styled.div`
  display: flex;
  flex-flow: column;
`;
const CommercialContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacings.small4}`};
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.primary700};
  color: ${({ theme }) => theme.color.white};

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledFormWrapper = styled(props => <FormGroup {...props} />)`
  padding: ${({ theme }) => `${theme.spacings.medium1} ${theme.spacings.large2}`};
  height: 100%;

  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: ${({ theme }) =>
      `${theme.spacings.small1} ${theme.spacings.medium4} ${theme.spacings.small1} ${theme.spacings.medium4}`};
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: ${({ theme }) =>
      `${theme.spacings.small1} ${theme.spacings.medium1} 0 ${theme.spacings.medium1}`};
  }
`;
const StyledCommercialItems = styled.h4`
  line-height: 2;
`;

const CloseButtonWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacings.small3} ${theme.spacings.small3} 0 0`};
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(props => <Button {...props} />)`
  @media only screen and (max-width: 600px) {
    height: ${({ theme }) => theme.spacings.medium3};
  }
`;
const StyledToggleGroup = styled(props => <ToggleGroup {...props} />)`
  padding-top: ${({ theme }) => `${theme.spacings.small3}`};
`;
const PhoneContainer = styled.div`
  padding-top: ${({ theme }) => `${theme.spacings.small4}`};
`;
const StyledSendButton = styled(props => <Button {...props} />)`
  width: 100%;
  height: ${({ theme }) => theme.spacings.medium4};
`;

const EnterpriseForm = ({ isOpen, onClose = () => {} }) => {
  const [contactVia, setContactVia] = useState('email');
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();

  const product = getLastProduct();

  const isSuccess = useSelector(state => state.user.isEnterpriseFormSendedSuccessfully);

  const validationSchema = Yup.object({
    message: Yup.string().required(intl.formatMessage({ id: 'errors.required' })),
    telephone: Yup.string().matches(telRegExp, intl.formatMessage({ id: 'errors.invalid_tel' }))
  });

  const formik = useFormik({
    initialValues: {
      product,
      message: '',
      contact_via: contactVia,
      telephone: ''
    },
    validationSchema: validationSchema,

    onSubmit(values, { setSubmitting }) {
      dispatch(fetchEnterpriseEmail(cleanEmptyObjectKeys(values)));
      setSubmitting(false);
    }
  });

  const handleChangeContactVia = ev => setContactVia(ev.target.value);

  const { handleChange, handleSubmit, handleBlur, dirty, isSubmitting, values, errors, touched } =
    formik;

  const isSubmitDisabled = isSubmitting || !dirty || (contactVia === 'phone' && !values.telephone);
  const showFotocasaLogo = useFlag('show_fotocasa_logo');

  return (
    <StyledModal isOpen={isOpen} closable={false} full onClose={onClose}>
      <StyledGrid columns="40% 60%">
        <CommercialContainer>
          <h2>
            <FormattedMessage id="enterprise.commercial_title" />
          </h2>
          <Separator width="180px" />
          <StyledCommercialItems>
            <FormattedMessage id="enterprise.commercial_sub_item1" />
          </StyledCommercialItems>
          <StyledCommercialItems>
            <FormattedMessage id="enterprise.commercial_sub_item2" />
          </StyledCommercialItems>
          <StyledCommercialItems>
            <FormattedMessage id="enterprise.commercial_sub_item3" />
          </StyledCommercialItems>
        </CommercialContainer>
        <FormContainer>
          <CloseButtonWrapper>
            <button onClick={onClose}>
              <IconClose customColor={theme.color.charcoal600} />
            </button>
          </CloseButtonWrapper>
          <StyledFormWrapper>
            {isSubmitting ? (
              <StyledSuccessContainer>
                <Loader size="small" />
              </StyledSuccessContainer>
            ) : null}
            {isSuccess && !isSubmitting ? (
              <StyledSuccessContainer>
                <div>
                  <h2>
                    <FormattedMessage id="enterprise.success_title" />
                  </h2>
                  <StyledSuccessText>
                    <FormattedMessage id="enterprise.success_text" />
                  </StyledSuccessText>
                </div>
              </StyledSuccessContainer>
            ) : (
              <>
                <h2>
                  <FormattedMessage id="enterprise.contact" />
                </h2>
                <StyledText>
                  <FormattedMessage id="enterprise.contact_description" />
                </StyledText>
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <TextareaField
                      maxlength={316}
                      label={intl.formatMessage({ id: 'enterprise.message' })}
                      name="message"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({ id: 'enterprise.message_placeholder' })}
                      value={values.message}
                      error={!!errors.message && !!touched.message}
                      help={errors.message || ''}
                    />
                    <StyledContactTitle>
                      <FormattedMessage id="enterprise.how_contact" />
                    </StyledContactTitle>
                    <StyledToggleGroup
                      variant="buttons"
                      value={contactVia}
                      onChange={handleChangeContactVia}
                    >
                      <StyledButton type="button" value="email" variant="secondary">
                        <FormattedMessage id="enterprise.contact_email" />
                      </StyledButton>
                      <StyledButton type="button" value="phone" variant="secondary">
                        <p>
                          <FormattedMessage id="enterprise.contact_phone" />
                        </p>
                      </StyledButton>
                    </StyledToggleGroup>
                    {contactVia === 'phone' ? (
                      <PhoneContainer>
                        <InputField
                          name="telephone"
                          label={intl.formatMessage({ id: 'enterprise.phone' })}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={intl.formatMessage({ id: 'enterprise.phone_placeholder' })}
                          type="text"
                          required={contactVia === 'phone'}
                          value={contactVia === 'phone' ? values.telephone : ''}
                          error={!!errors.telephone && !!touched.telephone}
                          help={errors.telephone || ''}
                        />
                      </PhoneContainer>
                    ) : null}
                    <MessageWrapper>
                      <FormattedMessage
                        id="enterprise.agree_message"
                        values={{
                          terms: (
                            <TextLink
                              href={showFotocasaLogo ? PATHS.legal : PATHS.termsOfUse}
                              title={intl.formatMessage({ id: 'paths.terms' })}
                              className="primary"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FormattedMessage id="paths.terms" />
                            </TextLink>
                          ),
                          privacy: (
                            <TextLink
                              href={showFotocasaLogo ? PATHS.legal : PATHS.privacyPolicy}
                              title={intl.formatMessage({ id: 'paths.privacy' })}
                              className="primary"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FormattedMessage id="paths.privacy" />
                            </TextLink>
                          )
                        }}
                      />
                    </MessageWrapper>
                    <StyledSendButton type="submit" disabled={isSubmitDisabled}>
                      <FormattedMessage id="enterprise.send_request" />
                    </StyledSendButton>
                  </FormGroup>
                </form>
              </>
            )}
          </StyledFormWrapper>
        </FormContainer>
      </StyledGrid>
    </StyledModal>
  );
};

export default EnterpriseForm;
