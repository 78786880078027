import { internPlans } from './intern';
export const initialState = {
  isLoading: false,
  countries: null,
  provincesAndAssets: [],
  isProcessingPayment: false,
  plans: null,
  total: null,
  isLoadingTotal: false,
  summary: {
    data: null,
    isLoading: false
  }
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'CALCULATE_ORDER':
      return {
        ...state,
        isLoadingTotal: true
      };
    case 'CALCULATE_ORDER_SUCCESS':
      return {
        ...state,
        total: payload.data,
        isLoadingTotal: false
      };
    case 'CALCULATE_ORDER_FAIL':
      return {
        ...state,
        total: null,
        isLoadingTotal: false
      };
    case 'FETCH_PLANS':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_PLANS_SUCCESS':
      return {
        ...state,
        plans: internPlans(payload.data),
        isLoading: false
      };
    case 'FETCH_PLANS_FAIL':
      return {
        ...state,
        plans: null,
        isLoading: false
      };
    case 'FETCH_PROVINCES_AND_NUM_OF_ASSETS':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_PROVINCES_AND_NUM_OF_ASSETS_SUCCESS':
      return {
        ...state,
        provincesAndAssets: payload.data,
        isLoading: false
      };
    case 'FETCH_PROVINCES_AND_NUM_OF_ASSETS_FAIL':
      return {
        ...state,
        provincesAndAssets: null,
        isLoading: false
      };
    case 'FETCH_SUBSCRIPTION_PAYMENT':
      return {
        ...state,
        isProcessingPayment: true
      };
    case 'FETCH_SUBSCRIPTION_PAYMENT_FAIL':
      return {
        ...state,
        isProcessingPayment: false
      };
    case 'UPDATE_PROCESSING_LOADING':
      return {
        ...state,
        isProcessingPayment: payload
      };
    case 'FETCH_SUMMARY':
      return {
        ...state,
        summary: {
          ...state.summary,
          data: null,
          isLoading: true
        }
      };
    case 'FETCH_SUMMARY_SUCCESS':
      return {
        ...state,
        summary: {
          ...state.summary,
          data: payload.data,
          isLoading: false
        }
      };
    case 'FETCH_SUMMARY_FAIL':
      return {
        ...state,
        summary: {
          ...state.summary,
          data: null,
          isLoading: false
        }
      };
    default:
      return state;
  }
};

export default reducer;
