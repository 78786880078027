import { Skeleton } from '@uda/bento-components';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledDescription = styled.p`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacings.small1};
`;

const StyledSkeleton = styled(props => <Skeleton {...props} />)`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const PaymentsCheckoutBillingTitles = ({ isLoading }) =>
  isLoading ? (
    <>
      <StyledSkeleton variant="text" height="36px" width="185px" />
      <StyledSkeleton variant="text" height="20px" width="200px" />
    </>
  ) : (
    <>
      <StyledTitle>
        <FormattedMessage id="payments.billing.title" />
      </StyledTitle>
      <StyledDescription>
        <FormattedMessage id="payments.billing.description" />
      </StyledDescription>
    </>
  );

export default PaymentsCheckoutBillingTitles;
