import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { updateModal } from '../Settings/actions/settings';
import {
  IconInfo,
  IconLogout,
  IconSettings,
  List,
  ListItem,
  NavListItem,
  IconAtSign,
  TextLink
} from '@uda/bento-components';
import { Link } from 'react-router-dom';
import PATHS from '../routes/paths';
import styled from 'styled-components';
import useFlag from '../utils/hooks/useFlag';
import { resetInitialState } from 'SmartValuation/actions/smartValuation';

const StyledList = styled(List)`
  background-color: transparent;
  > li {
    background-color: transparent;
    padding: 4px 0;
    margin: 0;
  }

  @media only screen and (max-width: 600px) {
    > hr {
      margin: 12px 16px;
    }
  }
`;

const StandardNavigationActions = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const showFotocasaLogo = useFlag('show_fotocasa_logo');

  return (
    <StyledList size={'large'}>
      <NavListItem
        leftContent={<IconSettings />}
        title={intl.formatMessage({ id: 'paths.settings' })}
        onClick={() => dispatch(updateModal(true))}
      >
        <FormattedMessage id="paths.settings" />
      </NavListItem>
      <NavListItem
        leftContent={<IconAtSign />}
        as={TextLink}
        href={useFlag('fotocasa_provider') ? PATHS.supportFotocasa : PATHS.support}
        title={intl.formatMessage({ id: 'paths.support' })}
        target="_blank"
      >
        <FormattedMessage id="paths.support" />
      </NavListItem>
      <NavListItem
        leftContent={<IconInfo />}
        as={TextLink}
        href={showFotocasaLogo ? PATHS.legal : intl.formatMessage({ id: 'legal.legal' })}
        title={intl.formatMessage({ id: 'paths.legal' })}
        target="_blank"
      >
        <FormattedMessage id="paths.legal" />
      </NavListItem>
      <ListItem separator />
      <NavListItem
        leftContent={<IconLogout />}
        as={Link}
        onClick={() => dispatch(resetInitialState())}
        to={PATHS.signOut}
        title={intl.formatMessage({ id: 'paths.sign_out' })}
      >
        <FormattedMessage id="paths.sign_out" />
      </NavListItem>
    </StyledList>
  );
};

export default StandardNavigationActions;
