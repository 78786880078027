import { NavigationBar } from '@uda/bento-components';
import logo from '../assets/images/logo-opportunities.svg';
import logoFotocasa from '../assets/images/logo-fotocasa-inversion.svg';
import logoMobile from '../assets/images/logo-opportunities-mobile.svg';
import useFlag from '../utils/hooks/useFlag';
import { Link } from 'react-router-dom';

const StaticLayout = ({ children }) => {
  const showFotocasaLogo = useFlag('show_fotocasa_logo');

  return (
    <>
      <NavigationBar
        header={
          <Link to={'/'}>
            <picture>
              <source
                srcSet={showFotocasaLogo ? logoFotocasa : logoMobile}
                media="(max-width: 600px), (max-height: 700px)"
              />

              <img
                src={showFotocasaLogo ? logoFotocasa : logo}
                alt={showFotocasaLogo ? 'Inversion Inmobiliaria' : 'Pulse'}
              />
            </picture>
          </Link>
        }
      />
      <div>{children}</div>
    </>
  );
};

export default StaticLayout;
