import * as Yup from 'yup';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputField, FormGroup, Button, IconCheck } from '@uda/bento-components';
import { useFormik } from 'formik';
import PATHS from '../../routes/paths';
import { Link, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useEffect, useState } from 'react';

const Heading = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  @media only screen and (max-width: 720px) {
    font-size: 24px;
  }
`;

const LoginWrapper = styled.div`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-top: ${({ theme }) => theme.spacings.small4};
`;

const Separator = styled.i`
  position: relative;
  display: block;
  margin: ${({ theme }) => theme.spacings.medium1} 0;
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.charcoal600};
  text-align: center;
  top: 9px;

  > span {
    background-color: white;
    padding: 0 12px;
    position: relative;
    top: -9px;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.charcoal400};
  }
`;

const HelpText = styled.p`
  color: ${({ theme }) => theme.color.charcoal600};
`;
const StyledTip = styled.span`
  color: ${({ isValid, theme }) => isValid && theme.color.emerald600};
`;

const PASSWORD_MIN_LENGTH = 8;

const AuthCreatePasswordForm = ({ verifyEmail = () => {} }) => {
  const intl = useIntl();
  const history = useHistory();
  const { token, email } = qs.parse(history.location.search);
  const [tips, setTips] = useState({ first: false, second: false, third: false, fourth: false });

  useEffect(() => {
    if (!token && !email) {
      history.push(PATHS.signUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, email]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(PASSWORD_MIN_LENGTH, 'errors.min_password_8')
      .matches(/^(?=.*[a-z])/, 'errors.include_lowercase')
      .matches(/^(?=.*[A-Z])/, 'errors.include_uppercase')
      .matches(/^(?=.*[0-9])/, 'errors.include_number')
      .required('errors.required')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      token,
      email
    },
    validationSchema: validationSchema,
    async onSubmit({ email, token, password }, { setSubmitting }) {
      await verifyEmail({ email, token, password });
    }
  });

  const { handleSubmit, isSubmitting, errors, touched, handleBlur, handleChange, values, dirty } =
    formik;

  useEffect(() => {
    setTips({
      first: values.password && values.password.length > 7,
      second: values.password && values.password.match(/^(?=.*[A-Z])/),
      third: values.password && values.password.match(/^(?=.*[a-z])/),
      fourth: values.password && values.password.match(/^(?=.*[0-9])/)
    });
  }, [values.password]);

  return (
    <div>
      <Heading>
        <FormattedMessage id="auth.create_password.title" />
      </Heading>

      <form onSubmit={handleSubmit} className={isSubmitting ? 'loading' : null}>
        <FormGroup>
          <InputField
            disabled={isSubmitting}
            error={!!errors.password && !!touched.password}
            help={
              <HelpText>
                <FormattedMessage
                  id="auth.create_password.tips"
                  values={{
                    first: (
                      <StyledTip isValid={tips.first}>
                        {tips.first ? <IconCheck size="small" /> : null}
                        <FormattedMessage id="auth.create_password.first" />
                      </StyledTip>
                    ),
                    second: (
                      <StyledTip isValid={tips.second}>
                        {tips.second ? <IconCheck size="small" /> : null}
                        <FormattedMessage id="auth.create_password.second" />
                      </StyledTip>
                    ),
                    third: (
                      <StyledTip isValid={tips.third}>
                        {tips.third ? <IconCheck size="small" /> : null}
                        <FormattedMessage id="auth.create_password.third" />
                      </StyledTip>
                    ),
                    fourth: (
                      <StyledTip isValid={tips.fourth}>
                        {tips.fourth ? <IconCheck size="small" /> : null}
                        <FormattedMessage id="auth.create_password.fourth" />
                      </StyledTip>
                    )
                  }}
                />
              </HelpText>
            }
            label={intl.formatMessage({ id: 'auth.sign_up.password' })}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'auth.sign_up.password_placeholder' })}
            required
            type="password"
            value={values.password}
          />
        </FormGroup>
        <Button
          type="submit"
          variant="primary"
          size="large"
          loading={isSubmitting}
          block
          disabled={isSubmitting || !!errors.password || !dirty}
        >
          <FormattedMessage id="auth.create_password.start" />
        </Button>
      </form>
      <Separator>
        <span>
          <FormattedMessage id="auth.sign_up.or" />
        </span>
      </Separator>
      <LoginWrapper>
        <FormattedMessage
          id="auth.sign_up.already"
          values={{
            link: (
              <Link
                to={PATHS.signIn}
                title={intl.formatMessage({ id: 'auth.sign_in.sign_in' })}
                className="primary"
              >
                <FormattedMessage id="auth.sign_in.sign_in" />
              </Link>
            )
          }}
        />
      </LoginWrapper>
    </div>
  );
};

export default AuthCreatePasswordForm;
