import * as Yup from 'yup';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputField, FormGroup, Button, TextLink, Checkbox } from '@uda/bento-components';
import { useFormik } from 'formik';
import PATHS from '../../routes/paths';
import { Link } from 'react-router-dom';
import googleIcn from '../../assets/images/icn-google.svg';
import GoogleLogin from 'react-google-login';
import FeatureFlag from '../../components/FeatureFlag';
import useFlag from '../../utils/hooks/useFlag';

const Heading = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  @media only screen and (max-width: 720px) {
    font-size: 24px;
  }
`;

const MessageWrapper = styled.div`
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.charcoal600};
`;

const LoginWrapper = styled.div`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-top: ${({ theme }) => theme.spacings.small4};
`;

const StyledImage = styled.img`
  position: relative;
  top: 2px;
`;

const Separator = styled.i`
  position: relative;
  display: block;
  margin: ${({ theme }) => theme.spacings.medium1} 0;
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.charcoal600};
  text-align: center;
  top: 9px;

  > span {
    background-color: white;
    padding: 0 12px;
    position: relative;
    top: -9px;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.charcoal400};
  }
`;

const StyledSignUpButton = styled(Button)`
  text-transform: uppercase;
`;

const CheckboxWrapper = styled.div`
  margin: ${({ theme }) => theme.spacings.small3} 0;

  border-radius: ${({ theme }) => theme.spacings.small1};
  border-radius: ${({ theme }) => theme.color.small1};

  ${({ theme, error, correct }) => {
    if (error) {
      return `border: 1px solid ${theme.color.primary500};`;
    }
    if (correct) {
      return `border: 1px solid ${theme.color.emerald600};`;
    }
  }};

  > label {
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacings.small2};
    input + div {
      ${({ theme, error, correct }) => {
        if (error) {
          return `border-color: ${theme.color.primary500};`;
        }
        if (correct) {
          return `
            border-color: ${theme.color.emerald600};
            background-color: ${theme.color.emerald600} !important;
          `;
        }
      }};
    }
  }
`;

const AuthSignUpForm = ({
  signUp = () => {},
  handleGoogleError = () => {},
  handleGoogleSuccess = () => {},
  closeNotification = () => {}
}) => {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('errors.invalid_email').required('errors.required'),
    validation: Yup.boolean().required('errors.validation').oneOf([true], 'errors.validation')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      validation: false
    },
    validationSchema: validationSchema,
    async onSubmit({ email }, { setSubmitting }) {
      await signUp({ email });
    }
  });

  const { handleSubmit, isSubmitting, errors, touched, handleBlur, handleChange, values } = formik;

  const showFotocasaLogo = useFlag('show_fotocasa_logo');

  return (
    <div>
      <Heading>
        <FormattedMessage id="auth.sign_up.title" />
      </Heading>
      <form onSubmit={handleSubmit} className={isSubmitting ? 'loading' : null}>
        <FormGroup>
          <InputField
            disabled={isSubmitting}
            error={!!errors.email && !!touched.email}
            help={(errors.email && touched.email && intl.formatMessage({ id: errors.email })) || ''}
            label={intl.formatMessage({ id: 'auth.sign_up.email' })}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'auth.sign_up.email_placeholder' })}
            required
            type="email"
            value={values.email}
          />
          <CheckboxWrapper
            error={!!errors.validation && !!touched.validation}
            correct={touched.validation && !!!errors.validation}
          >
            <Checkbox
              name="validation"
              checked={values.validation}
              label={
                <MessageWrapper>
                  <FormattedMessage
                    id="auth.sign_up.agree_message"
                    values={{
                      terms: (
                        <TextLink
                          href={showFotocasaLogo ? PATHS.legal : PATHS.termsOfUse}
                          title={intl.formatMessage({ id: 'paths.terms' })}
                          className="primary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FormattedMessage id="paths.terms" />
                        </TextLink>
                      ),
                      privacy: (
                        <TextLink
                          href={showFotocasaLogo ? PATHS.legal : PATHS.privacyPolicy}
                          title={intl.formatMessage({ id: 'paths.privacy' })}
                          className="primary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FormattedMessage id="paths.privacy" />
                        </TextLink>
                      )
                    }}
                  />
                </MessageWrapper>
              }
              onChange={handleChange}
            />
          </CheckboxWrapper>
        </FormGroup>
        <StyledSignUpButton
          type="submit"
          variant="primary"
          size="large"
          loading={isSubmitting}
          block
          disabled={isSubmitting}
        >
          <FormattedMessage id="auth.sign_up.create" />
        </StyledSignUpButton>
      </form>

      <FeatureFlag name="hide_google_auth">
        {isHidden =>
          !isHidden ? (
            <>
              <Separator>
                <span>
                  <FormattedMessage id="auth.sign_up.or" />
                </span>
              </Separator>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID}
                render={renderProps => (
                  <Button
                    size="large"
                    variant="secondary"
                    onClick={e => {
                      closeNotification();
                      renderProps.onClick(e);
                    }}
                    disabled={renderProps.disabled}
                    iconLeft={<StyledImage src={googleIcn} alt="Google" />}
                    block
                  >
                    <FormattedMessage id="auth.sign_up.sign_up_google" />
                  </Button>
                )}
                onSuccess={handleGoogleSuccess}
                onFailure={handleGoogleError}
                cookiePolicy={'single_host_origin'}
                accessType={'offline'}
                responseType={'code'}
              />
            </>
          ) : null
        }
      </FeatureFlag>
      <LoginWrapper>
        <FormattedMessage
          id="auth.sign_up.already"
          values={{
            link: (
              <Link
                to={PATHS.signIn}
                title={intl.formatMessage({ id: 'auth.sign_in.sign_in' })}
                className="primary"
              >
                <FormattedMessage id="auth.sign_in.sign_in" />
              </Link>
            )
          }}
        />
      </LoginWrapper>
    </div>
  );
};

export default AuthSignUpForm;
