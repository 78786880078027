import styled from 'styled-components';
import { Button } from '@uda/bento-components';
import { FormattedMessage } from 'react-intl';
import { updateModal } from '../actions/settings';
import { useDispatch } from 'react-redux';

const StyledContent = styled.footer`
  background-color: white;
  padding-top: ${({ theme }) => theme.spacings.small3};
  padding-bottom: ${({ theme }) => theme.spacings.medium1};
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: ${({ theme }) => theme.spacings.medium2};
  width: 100%;
  > button {
    margin-left: ${({ theme }) => theme.spacings.small2};
    width: 120px;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    right: 0;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    padding-top: 12px;
    padding-bottom: 12px;

    > button {
      margin: 0 ${({ theme }) => theme.spacings.small1};
      width: 140px;
      height: 48px;
    }
  }
`;

const SettingsActions = ({ isSubmitDisabled, isSubmitting }) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(updateModal(false));

  return (
    <StyledContent>
      <Button variant="secondary" block onClick={onClose}>
        <FormattedMessage id="settings.cancel" />
      </Button>
      <Button
        type="submit"
        variant="primary"
        block
        disabled={isSubmitDisabled}
        loading={isSubmitting}
      >
        <FormattedMessage id="settings.save" />
      </Button>
    </StyledContent>
  );
};

export default SettingsActions;
