import { Skeleton, Grid } from '@uda/bento-components';
import styled from 'styled-components';

const StyledSkeleton = styled(props => <Skeleton {...props} />)`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledGrid = styled(props => <Grid {...props} />)`
  padding: 21px;
  margin: 16px 0;
  grid-gap: ${({ theme }) => theme.spacings.small2};
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  border-bottom: 1px solid ${({ theme }) => theme.color.charcoal300};
`;

const PaymentsCheckoutScopeSkeleton = () => (
  <div>
    <StyledSkeleton variant="text" height="36px" width="185px" />
    <StyledSkeleton variant="text" height="20px" width="200px" />
    <StyledGrid columns="1fr 20px">
      <span>
        <Skeleton variant="text" height="20px" width="100px" />
      </span>
      <Skeleton variant="text" height="20px" />
    </StyledGrid>
    <StyledSkeleton variant="text" height="20px" width="200px" />
  </div>
);

export default PaymentsCheckoutScopeSkeleton;
