import styled, { useTheme } from 'styled-components';
import { IconArrowLeft } from '@uda/bento-components';
import { Link } from 'react-router-dom';
import PATHS from '../../routes/paths';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocalStorage } from '../../utils/storage';
import { PRODUCT_KEY } from '../../constants.js';

const StyledContainer = styled.aside`
  background-color: ${({ theme }) => theme.color.charcoal700};
  color: white;
  position: sticky;
  top: 40px;
  z-index: 1;

  a {
    padding: ${({ theme }) => theme.spacings.small2};
    display: inline-block;

    span {
      ${({ theme }) => theme.texts.p2b};
      color: ${({ theme }) => theme.color.charcoal300};
      padding-left: ${({ theme }) => theme.spacings.small1};
    }
  }
`;

const PaymentsGoBack = () => {
  const theme = useTheme();
  const intl = useIntl();
  const product = getLocalStorage(PRODUCT_KEY);

  return (
    <StyledContainer>
      <Link to={PATHS[product]} title={intl.formatMessage({ id: 'payments.back' }, { product })}>
        <IconArrowLeft customColor={theme.color.charcoal300} />
        <span>
          <FormattedMessage id="payments.back" values={{ product }} />
        </span>
      </Link>
    </StyledContainer>
  );
};

export default PaymentsGoBack;
