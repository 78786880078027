export const DEFAULT_BBBOX = [-14.436035, 35.281501, 6.49292, 43.548548];
export const DEFAULT_BOUNDARY_TYPE = 'province';
export const DEFEAULT_DISTRIBUTION = 'av';

export const portfolio_map_colors = {
  av: [
    '#D7ECEA',
    '#BCDBD9',
    '#9BCFCC',
    '#6BB0AC',
    '#24A49B',
    '#1B817A',
    '#0E504C',
    '#09332C',
    '#021512'
  ],
  rev: [
    '#E4FAFF',
    '#CAF0F8',
    '#ADE8F4',
    '#90D8EF',
    '#48B5E4',
    '#008BC7',
    '#0077B6',
    '#023E8A',
    '#03045E'
  ]
};

export const MIN_ZOOM = 3;
export const MAX_ZOOM = 7;
