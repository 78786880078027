import { getValueFormatted } from '../../utils';
import { FILTER_VALID_KEYS, FILTER_FIELDS_ARRAY_ACCEPTED } from '../constants.js';

export const formatSortParams = sorting => {
  const params = { sort: sorting?.sort || 'date_in', order: sorting?.order || 'desc' };
  const { sort, order } = params;
  const sortingParam = sort === 'year' ? `${sort},status,name` : sort;
  const sortingOrder = order === 'desc' ? '-' : '';

  return `${sortingOrder}${sortingParam}`;
};

export const parseSortParams = sorting => {
  if (sorting) {
    let sort = sorting[0] === '-' ? sorting.substring(1) : sorting;
    sort = sort === `year,status,name` ? 'year' : sort;

    const order = sorting[0] === '-' ? 'desc' : 'asc';

    return { sort, order };
  }

  return {};
};

export const getFiltersToAlert = (state, intl, lang) => {
  const { filtersApplied, filtersCollection } = state;

  let userFilters = [];

  Object.keys(filtersApplied).forEach(filter => {
    if (filtersCollection.some(e => e.id === filter)) {
      const filterFromCollection = filtersCollection.find(e => e.id === filter);
      if (filterFromCollection) {
        userFilters.push({
          id: filterFromCollection.id,
          label: filterFromCollection.label,
          value: filtersApplied[filter]
            .map(item =>
              getValueFormatted(
                { value: item, ...filterFromCollection.filters[0].data },
                intl,
                lang,
                false
              )
            )
            .join(' - ')
        });
      }
    }
  });

  return userFilters;
};

export const getAssetValuationFeatures = values => {
  let result = Object.assign({}, values);

  Object.keys(result).forEach(key => {
    if (FILTER_VALID_KEYS.includes(key)) {
      if (
        Array.isArray(result[key]) &&
        result[key].length === 1 &&
        !FILTER_FIELDS_ARRAY_ACCEPTED.includes(key)
      ) {
        if (result[key][0] === 'true') {
          result[key] = true;
        } else if (result[key][0] === 'false') {
          result[key] = false;
        } else if (result[key][0] === 'no_date') {
          result[key] = 'no_date';
        } else if (result[key][0] === 'all_date') {
          result[key] = 'all_date';
        } else {
          result[key] = result[key][0];
        }
      }
      if (key === 'property_type__in') {
        const value = result[key];
        result[key] = Array.isArray(value) ? value : [value];
      }
      if (key === 'n_rooms__in' || key === 'n_baths__in') {
        result[key] = Array.isArray(result[key]) ? result[key] : [result[key]];
        result[key] = Array.isArray(result[key]) && result[key].map(n => (n !== null ? n : null));
      }
      if (key === 'listing_type') {
        result[key] = Number(result[key]);
      }
      if (
        (key === 'geog__dwithin' || key === 'distance_on_foot' || key === 'distance_by_car') &&
        (result[key] === null || result[key] === 0)
      ) {
        delete result[key];
      }
      if (key === 'date_out' && result[key] === null) {
        delete result[key];
      }
    } else {
      delete result[key];
    }
  });
  return result;
};

export const getComparablesData = values => {
  let result = Object.assign({}, values);

  const validKeys = [
    'lon',
    'lat',
    'rooms',
    'source_id__in',
    'publishing_status__in',
    'construction_year',
    ...FILTER_VALID_KEYS
  ];

  Object.keys(result).forEach(key => {
    if (validKeys.includes(key)) {
      switch (key) {
        case 'listing_type':
          result[key] = Number(result[key]);
          break;
        case 'n_rooms':
        case 'n_baths':
          result[key] = Array.isArray(result[key]) ? [result[key][0]] : [result[key]];
          break;
        default:
          break;
      }
    } else {
      delete result[key];
    }
  });

  return result;
};
