import { forwardRef, useMemo, createRef, useEffect } from 'react';
import styled from 'styled-components';
import { InputField } from '@uda/bento-components';

const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium2};
  display: flex;
`;

const ForwardRefInput = forwardRef((props, ref) => <InputField {...props} ref={ref} />);

const StyledInputField = styled(ForwardRefInput)`
  width: 48px;
  height: 48px;
  margin-right: ${({ theme }) => theme.spacings.small3};
  input {
    text-align: center;
    text-indent: unset;
  }
  small {
    width: 400px;
  }
`;

const InputCode = ({
  disabled,
  values,
  pattern,
  help,
  error,
  onChange = () => {},
  onPaste = () => {}
}) => {
  const refs = useMemo(() => {
    return Array.from({ length: values.length }).map(() => createRef());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = index => evt => {
    const newValue = evt.target.value;
    let newValues = values.map((value, valueIndex) => {
      if (valueIndex === index && !isNaN(newValue)) {
        return newValue;
      }
      return value;
    });

    onChange(newValues);

    if (newValue.length && index < values.length - 1 && !isNaN(newValue)) {
      refs[index + 1].current.focus();
      refs[index + 1].current.select();
    }
  };

  useEffect(() => {
    refs[0].current.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.join('').length === values.length) {
      values.forEach((e, index) => {
        refs[index].current.blur();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <InputWrapper>
      {values.map((value, index) => (
        <StyledInputField
          onPaste={onPaste}
          key={index}
          disabled={disabled}
          error={error}
          help={index === 0 ? help : ''}
          name={`input${index}`}
          onChange={handleOnChange(index)}
          required
          pattern="[0-9]{1}"
          type="text"
          value={value}
          maxLength={1}
          tabIndex={index + ''}
          ref={refs[index]}
        />
      ))}
    </InputWrapper>
  );
};

export default InputCode;
