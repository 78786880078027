import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundComponent } from '../../containers/PageNotFoundContainer';
import { useDispatch } from 'react-redux';
import PaymentsCheckoutContainer from './PaymentsCheckoutContainer';
import PaymentsSummaryContainer from './PaymentsSummaryContainer';
import PaymentsErrorContainer from './PaymentsErrorContainer';
import PAYMENTS_PATHS from '../paths';
import { closeNotification } from '../../actions/notification';
import AssetSummaryContainer from './AssetSummaryContainer';
import AssetReportSummaryContainer from './AssetReportSummaryContainer';

const PaymentsContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(closeNotification());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Switch>
        <Route path={PAYMENTS_PATHS.base} exact component={PaymentsCheckoutContainer} />
        <Route path={PAYMENTS_PATHS.summary} exact>
          <PaymentsSummaryContainer product="opportunities" />
        </Route>
        <Route path={PAYMENTS_PATHS.error} exact component={PaymentsErrorContainer} />
        <Route path={PAYMENTS_PATHS.asset_summary} exact component={AssetSummaryContainer} />
        <Route
          path={PAYMENTS_PATHS.asset_report_summary}
          exact
          component={AssetReportSummaryContainer}
        />
        {/* <PaymentsSummaryContainer product="asset" />
        </Route> */}
        <Route path="*" exact component={NotFoundComponent} />
      </Switch>
    </>
  );
};

export default PaymentsContainer;
