import styled, { useTheme } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, TextLink, FormGroup, Skeleton } from '@uda/bento-components';
import PATHS from '../../routes/paths';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import visa from '../../assets/images/cards/visa.svg';
import mastercard from '../../assets/images/cards/mastercard.svg';
import amex from '../../assets/images/cards/amex.svg';
import { getValueFormatted } from '../../utils/formattedNumber';
import { useSelector } from 'react-redux';
import useIsScrollOnTop from '../../utils/hooks/useIsScrollOnTop';
import { useEffect, useState } from 'react';
import PaymentsCheckoutOrderSkeleton from './PaymentsCheckoutOrderSkeleton';
import useFlag from '../../utils/hooks/useFlag';

const StyledContainer = styled.div`
  // @toDo eliminar sticky
  // position: ${({ isScrollOnTop }) => (isScrollOnTop ? 'relative' : 'fixed')};
  // top: ${({ isScrollOnTop }) => (isScrollOnTop ? '0' : '100px')};
  // width: ${({ isScrollOnTop }) => (isScrollOnTop ? '100%' : '400px')};
  top: 0;
  width: 400px;
  position: relative;
  //
  border: 1px solid ${({ theme }) => theme.color.charcoal300};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0;
  }
`;

const StyledSummary = styled.div`
  padding: ${({ theme }) => theme.spacings.medium1};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
`;

const StyledWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings.medium1};
  background-color: ${({ theme }) => theme.color.charcoal200};
  border-radius: 21px 21px 0 0;
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
`;

const StyledAgreement = styled.p`
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.charcoal500};
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledParagraph = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledTotal = styled.p`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal800};
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  padding: ${({ theme }) => theme.spacings.small2} 0;
  margin-top: ${({ theme }) => theme.spacings.small1};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledList = styled.ul`
  list-style: none;
`;

const StyledListItem = styled.li`
  padding: ${({ theme }) => theme.spacings.small1} 0;

  &:first-child {
    > p {
      color: ${({ theme }) => theme.color.charcoal700};

      span:first-child:before {
        content: '';
      }
    }
  }

  p {
    ${({ theme }) => theme.texts.p1};
    color: ${({ theme }) => theme.color.charcoal600};

    span:first-child:before {
      content: '${({ isLoadingTotal }) => (isLoadingTotal ? '' : '+ ')}';
    }
  }
`;

const StyledLabel = styled.label`
  ${({ theme }) => theme.texts.p2};
`;

const StyledCardNumberElement = styled(props => <CardNumberElement {...props} />)`
  padding: 14px 24px;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.color.redclay500 : theme.color.charcoal400)};
  border-radius: 4px;
  background: ${({ error, theme }) => (error ? theme.color.redclay100 : 'white')};
  margin-bottom: ${({ theme }) => theme.spacings.small1};
`;

const StyledCardExpiryElement = styled(props => <CardExpiryElement {...props} />)`
  padding: 14px 24px;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.color.redclay500 : theme.color.charcoal400)};
  border-radius: 4px;
  background: ${({ error, theme }) => (error ? theme.color.redclay100 : 'white')};
  margin-bottom: ${({ theme }) => theme.spacings.small1};
`;

const StyledCardCvcElement = styled(props => <CardCvcElement {...props} />)`
  padding: 14px 24px;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.color.redclay500 : theme.color.charcoal400)};
  border-radius: 4px;
  background: ${({ error, theme }) => (error ? theme.color.redclay100 : 'white')};
  margin-bottom: ${({ theme }) => theme.spacings.small1};

  @media only screen and (max-width: 768px) {
    width: 80px;
  }
`;

const StyledFormGroup = styled(props => <FormGroup {...props} />)`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.small3};

  div {
    display: flex;
    gap: ${({ theme }) => theme.spacings.small2};
  }
`;

const StyledCard = styled.img`
  height: 26px;
  opacity: ${({ active, selected }) => {
    return active && selected ? 1 : active ? 0.25 : 1;
  }};
`;

const StyledErrorInfo = styled.span`
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.redclay500};
  padding-top: ${({ theme }) => theme.spacings.small1};
`;

const StyledDiscount = styled.span`
  ${({ theme }) => theme.texts.p2b};
  background-color: ${({ theme }) => theme.color.charcoal300};
  border-radius: 4px;
  padding: ${({ theme }) => `${theme.spacings.small1} ${theme.spacings.small2}`};
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  display: inline-block;
  color: ${({ theme }) => theme.color.charcoal600};
  margin-left: 12px;
`;
const StyledAmount = styled.span`
  position: relative;
  top: 3px;
`;

const PaymentsCheckoutOrder = ({
  isLoading,
  isLoadingTotal,
  order = [],
  total,
  onPayClick = () => {},
  isSubmitAvailable
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const elements = useElements();
  const stripe = useStripe();
  const lang = useSelector(state => state.user.data.language);
  const isScrollOnTop = useIsScrollOnTop(50);
  const [disabledPayButton, setDisabledPayButton] = useState(true);
  const [elementStatus, setElementStatus] = useState({
    cardNumber: { complete: false },
    cardExpiry: { complete: false },
    cardCvc: { complete: false }
  });

  const [cardType, setCardType] = useState();

  const style = {
    base: {
      fontSize: theme.texts.p1.fontSize,
      backgroundColor: 'transparent',
      color: theme.color.charcoal800,
      '::placeholder': {
        color: theme.color.charcoal400
      }
    },
    invalid: {
      color: theme.color.redclay500,
      backgroundColor: theme.color.redclay100
    }
  };

  const handlePayClick = () => {
    const card = elements.getElement(CardNumberElement);
    onPayClick({ stripe, card });
  };

  useEffect(() => {
    const elementsCompleted = Object.values(elementStatus).every(element => element.complete);

    setDisabledPayButton(!elementsCompleted || !isSubmitAvailable);
  }, [elementStatus, isSubmitAvailable]);

  const handleStripeElementChange = e => {
    if (e.brand) setCardType(e.brand);

    setElementStatus({
      ...elementStatus,
      [e.elementType]: { complete: e.complete, error: e.error?.message }
    });
  };

  const amount = total
    ? getValueFormatted({ ...total.data, decimals: 2 }, intl, lang, false, false)
    : '-';

  // @toDo API add discount
  const showFotocasaLogo = useFlag('show_fotocasa_logo');

  return (
    <StyledContainer isScrollOnTop={isScrollOnTop}>
      {isLoading && !isLoadingTotal ? (
        <PaymentsCheckoutOrderSkeleton orders={order.length || 1} />
      ) : (
        <>
          <StyledSummary>
            <StyledTitle>
              <FormattedMessage id="payments.order.title" />
            </StyledTitle>
            <StyledList>
              {order.length
                ? order.map(payment =>
                    payment ? (
                      <StyledListItem key={payment.label}>
                        <StyledParagraph>
                          <span>{payment.label}</span>
                          <span>{payment.price}</span>
                        </StyledParagraph>
                      </StyledListItem>
                    ) : null
                  )
                : null}
              {isLoadingTotal ? (
                <StyledListItem isLoadingTotal={isLoadingTotal}>
                  <StyledParagraph>
                    <span>
                      <Skeleton width="50px" height="16px" />
                    </span>
                    <span>
                      <Skeleton width="50px" height="16px" />
                    </span>
                  </StyledParagraph>
                </StyledListItem>
              ) : null}
            </StyledList>
            <StyledTotal>
              <span>
                <FormattedMessage id="payments.order.total" />
                <StyledDiscount>
                  <FormattedMessage id="opportunities.detail.blocked.discount" />
                </StyledDiscount>
              </span>
              <StyledAmount>
                {isLoadingTotal ? <Skeleton width="75px" height="16px" /> : amount}
              </StyledAmount>
            </StyledTotal>
          </StyledSummary>
          <StyledWrapper>
            <StyledHeader>
              <h4>
                <FormattedMessage id="payments.order.payment" />
              </h4>
              <div>
                <StyledCard
                  active={cardType}
                  selected={cardType === 'amex'}
                  src={amex}
                  alt="Amex"
                />
                <StyledCard
                  active={cardType}
                  selected={cardType === 'visa'}
                  src={visa}
                  alt="Visa"
                />
                <StyledCard
                  active={cardType}
                  selected={cardType === 'mastercard'}
                  src={mastercard}
                  alt="MasterCard"
                />
              </div>
            </StyledHeader>
            <>
              <FormGroup>
                <StyledLabel>
                  <FormattedMessage id="payments.order.card_number" />
                  <StyledCardNumberElement
                    options={{ style }}
                    onChange={handleStripeElementChange}
                    error={elementStatus.cardNumber.error}
                  />
                  <StyledErrorInfo>{elementStatus.cardNumber.error}</StyledErrorInfo>
                </StyledLabel>
              </FormGroup>
              <StyledFormGroup>
                <StyledLabel>
                  <FormattedMessage id="payments.order.expiration" />
                  <StyledCardExpiryElement
                    options={{ style }}
                    onChange={handleStripeElementChange}
                    error={elementStatus.cardExpiry.error}
                  />
                  <StyledErrorInfo>{elementStatus.cardExpiry.error}</StyledErrorInfo>
                </StyledLabel>
                <StyledLabel>
                  <FormattedMessage id="payments.order.cvc" />
                  <StyledCardCvcElement
                    options={{ style }}
                    onChange={handleStripeElementChange}
                    error={elementStatus.cardCvc.error}
                  />
                  <StyledErrorInfo>{elementStatus.cardCvc.error}</StyledErrorInfo>
                </StyledLabel>
              </StyledFormGroup>
            </>
            <StyledAgreement>
              <FormattedMessage
                id="payments.order.agreement"
                values={{
                  terms: (
                    <TextLink
                      href={showFotocasaLogo ? PATHS.legal : PATHS.termsOfUse}
                      title={intl.formatMessage({ id: 'paths.terms' })}
                      className="primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage id="paths.terms" />
                    </TextLink>
                  ),
                  privacy: (
                    <TextLink
                      href={showFotocasaLogo ? PATHS.legal : PATHS.privacyPolicy}
                      title={intl.formatMessage({ id: 'paths.privacy' })}
                      className="primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage id="paths.privacy" />
                    </TextLink>
                  )
                }}
              />
            </StyledAgreement>
            <Button
              onClick={handlePayClick}
              variant="primary"
              size="large"
              block
              disabled={disabledPayButton}
            >
              <FormattedMessage
                id="payments.order.cta"
                values={{
                  amount
                }}
              />
            </Button>
          </StyledWrapper>
        </>
      )}
    </StyledContainer>
  );
};

export default PaymentsCheckoutOrder;
