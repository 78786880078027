import PATHS from '../../routes/paths';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navigation from '../../components/Navigation';
import { getLocalStorage } from '../../utils';
import * as CONSTANTS from '../../constants.js';
import logoPulse from '../../assets/images/logo-pulse.svg';
import PaymentReportContainer from './PaymentReportContainer';

const AssetReportSummaryContainer = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const lastProduct = getLocalStorage(CONSTANTS.PRODUCT_KEY);

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <Redirect to={PATHS.base} />
        ) : (
          <>
            <Navigation
              productKey={lastProduct}
              header={<img src={logoPulse} alt={'Pulse'} />}
              clean
            />
            <PaymentReportContainer product={lastProduct} />
          </>
        )
      }
    />
  );
};

export default AssetReportSummaryContainer;
