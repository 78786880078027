import { Skeleton, Grid } from '@uda/bento-components';
import styled from 'styled-components';

const StyledSkeleton = styled(props => <Skeleton {...props} />)`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledSummary = styled.div`
  padding: ${({ theme }) => theme.spacings.medium1};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.small1} 0;
`;

const StyledWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings.medium1};
  background-color: ${({ theme }) => theme.color.charcoal200};
  border-radius: 21px 21px 0 0;
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
`;

const StyledTotal = styled.p`
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  padding: ${({ theme }) => theme.spacings.small2} 0;
  margin-top: ${({ theme }) => theme.spacings.small1};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledContent = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  padding: ${({ theme }) => theme.spacings.small3};
  border: 1px solid ${({ theme }) => theme.color.charcoal400};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
`;

const StyledTitle = styled(props => <Skeleton {...props} />)`
  margin-bottom: ${({ theme }) => theme.spacings.small2};
`;

const PaymentsCheckoutOrderSkeleton = ({ orders = 1 }) => (
  <>
    <StyledSummary>
      <StyledSkeleton variant="text" height="36px" width="185px" />

      <StyledItem>
        <Skeleton height="16px" width="90px" />
        <Skeleton height="16px" width="30px" />
      </StyledItem>

      <StyledTotal>
        <Skeleton height="16px" width="70px" />
        <Skeleton height="16px" width="70px" />
      </StyledTotal>
    </StyledSummary>
    <StyledWrapper>
      <StyledSkeleton variant="text" height="21px" width="185px" />
      <div>
        <StyledTitle variant="text" height="14px" width="50px" />
        <StyledContent>
          <Skeleton variant="text" height="16px" width="185px" />
        </StyledContent>
      </div>
      <Grid columns="1fr 1fr" gap="21px">
        <div>
          <StyledTitle variant="text" height="14px" width="50px" />
          <StyledContent>
            <Skeleton variant="text" height="16px" />
          </StyledContent>
        </div>
        <div>
          <StyledTitle variant="text" height="14px" width="50px" />
          <StyledContent>
            <Skeleton variant="text" height="16px" />
          </StyledContent>
        </div>
      </Grid>
      <StyledSkeleton variant="text" height="16px" />
      <Skeleton variant="rounded" height="54px" />
    </StyledWrapper>
  </>
);

export default PaymentsCheckoutOrderSkeleton;
