import { configureStore } from '@reduxjs/toolkit';
import user from './reducers/user';
import notification from './reducers/notification';
import layout from './reducers/layout';
import portfolio from './Portfolio/reducers/portfolio';
import development from './Portfolio/Development/reducers/development';
import developmentMarket from './Portfolio/Development/Market/reducers/market';
import developmentData from './Portfolio/Development/DevelopmentData/reducers/developmentData';
import developmentValuation from './Portfolio/Development/Valuation/reducers/valuation';
import market from './Market/reducers/market';
import smartValuation from './SmartValuation/reducers/smartValuation';
import opportunities from './Opportunities/reducers/opportunities';
import calculator from './Opportunities/reducers/calculator';
import restrictions from './Restrictions/reducers/restrictions';
import settings from './Settings/reducers/settings';
import auth from './Auth/reducers/auth';
import flags from './reducers/flags';
import payments from './Payments/reducers/payments';
import capture from './Capture/reducers/capture';
import comparables from './Portfolio/Development/Comparables/reducers/comparables';
import httpClientMiddleware from './interceptors/config';
import { createCancellationMiddleware } from './interceptors/cancelMiddleware';
import * as Sentry from '@sentry/react';
import sentryReduxOptions from './sentryReduxOptions';
import { comparablesAPI } from './Api/comparables';
import { cadastreAPI } from './Api/cadastre';
import { strapiAPI } from './Api/strapi';
import { portfolioAPI } from 'Api/portfolio';
import personalizationAssetSlice from 'SmartValuation/features/Personalization/context/personalizationSlice';
import { usersAPI } from 'Api/users/usersAPI';

const sentryReduxEnhancer = () => Sentry.createReduxEnhancer(sentryReduxOptions);
const store = configureStore({
  reducer: {
    user,
    settings,
    notification,
    auth,
    portfolio,
    market,
    layout,
    opportunities,
    payments,
    restrictions,
    calculator,
    comparables,
    development,
    developmentMarket,
    developmentData,
    developmentValuation,
    flags,
    smartValuation,
    capture,
    personalizationAsset: personalizationAssetSlice,
    [comparablesAPI.reducerPath]: comparablesAPI.reducer,
    [strapiAPI.reducerPath]: strapiAPI.reducer,
    [cadastreAPI.reducerPath]: cadastreAPI.reducer,
    [portfolioAPI.reducerPath]: portfolioAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
      .concat(createCancellationMiddleware())
      .concat(httpClientMiddleware)
      .concat(comparablesAPI.middleware)
      .concat(cadastreAPI.middleware)
      .concat(strapiAPI.middleware)
      .concat(portfolioAPI.middleware)
      .concat(usersAPI.middleware),

  enhancers: [sentryReduxEnhancer()]
});

export default store;
