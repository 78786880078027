import * as CONSTANTS from '../constants.js';
import { apiInternIndicators, parseFilterValuesNumber } from './apiIntern';

export const initialState = {
  map: {
    zoom: CONSTANTS.DEFAULT_ZOOM,
    lng: CONSTANTS.DEFAULT_LNG,
    lat: CONSTANTS.DEFAULT_LAT,
    center: [],
    minZoom: 1,
    error: { isDisplayed: false, code: null }
  },
  filters: {
    boundary_type: CONSTANTS.DEFAULT_BOUNDARY_TYPE,
    indicator: CONSTANTS.DEFAULT_INDICATOR,
    // @toDo desde la APi deberá recoger estos datos
    operation: CONSTANTS.DEFAULT_OPERATION,
    indicators: {}
  },
  isMapLoading: true,
  isIndicatorsLoading: true,
  classes: [],
  indicators: [],
  indicator: {},
  disabledTabs: [],
  displayZoomBanner: false,
  indicatorsFilterInfo: {},
  isIndicatorsInfoLoading: false,
  filterIndicator: null
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'SET_MAP':
      return {
        ...state,
        map: { ...state.map, ...payload }
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: { ...state.filters, ...payload }
      };
    case 'SET_BOUNDARY_TYPE':
      return {
        ...state,
        filters: { ...state.filters, boundary_type: payload }
      };
    case 'SET_INDICATOR':
      return {
        ...state,
        indicator: payload.indicator,
        filters: { ...state.filters, indicator: payload.indicatorId },
        classes: payload.classes
      };
    case 'SET_CLASSES':
      return {
        ...state,
        classes: payload
      };
    case 'FETCH_INDICATORS':
      return {
        ...state,
        isIndicatorsLoading: true
      };
    case 'SET_MAP_LOADED':
      return {
        ...state,
        isMapLoading: payload
      };
    case 'FETCH_INDICATORS_FAIL':
      return {
        ...state,
        indicators: [],
        isIndicatorsLoading: false
      };
    case 'FETCH_INDICATORS_SUCCESS':
      const { categories } = payload.data;
      return {
        ...state,
        isIndicatorsLoading: false,
        indicators: apiInternIndicators(categories)
      };
    case 'CLOSE_INDICATOR_INFO':
      return {
        ...state,
        isIndicatorsInfoLoading: false,
        filterIndicator: null
      };
    case 'FETCH_INDICATOR_INFO':
      return {
        ...state,
        isIndicatorsInfoLoading: true,
        filterIndicator: payload.indicator
      };
    case 'FETCH_INDICATOR_INFO_FAIL':
      return {
        ...state,
        indicatorsFilterInfo: {},
        isIndicatorsInfoLoading: false,
        filterIndicator: null
      };
    case 'FETCH_INDICATOR_INFO_SUCCESS':
      let {
        data: { id, ...operations }
      } = payload;
      return {
        ...state,
        indicatorsFilterInfo: {
          ...state.indicatorsFilterInfo,
          data: parseFilterValuesNumber(operations)
        },
        isIndicatorsInfoLoading: false
      };
    case 'SET_OPERATION':
      return {
        ...state,
        filters: { ...state.filters, operation: payload }
      };
    case 'SET_DISABLED_TABS':
      return {
        ...state,
        disabledTabs: payload
      };
    case 'SET_DISPLAY_ZOOM_BANNER':
      return {
        ...state,
        displayZoomBanner: payload
      };
    case 'SET_INDICATOR_FILTER_INFO':
      return {
        ...state,
        indicatorsFilterInfo: {
          indicator: { ...payload }
        }
      };
    case 'RESET_FILTER_INDICATOR':
      const { boundary, indicatorId } = payload;
      let result = {};
      const { [indicatorId]: currentIndicator, ...rest } = state.filters.indicators[boundary];
      result[boundary] = rest;

      return {
        ...state,
        filters: {
          ...state.filters,
          indicators: {
            ...state.filters.indicators,
            [boundary]: {
              ...rest
            }
          }
        }
      };
    case 'RESET_FILTERS_INDICATORS':
      return {
        ...state,
        filters: {
          ...state.filters,
          indicators: {
            ...state.filters.indicators,
            [payload]: null
          }
        }
      };
    case 'RESET_MARKET':
      return { ...initialState };

    default:
      return state;
  }
};

export default reducer;
