import { FormattedMessage } from 'react-intl';
import { Grid, Modal } from '@uda/bento-components';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { closeNotification } from '../../actions/notification';
import NotificationTranslate from '../../components/Notifications/NotificationTranslate';
import NotificationWrapper from '../../components/Notifications/NotificationWrapper';

const StyledContainer = styled.div`
  height: calc(100% - 40px);
  overflow: auto;
  width: 100%;
  min-width: 100%;
  padding: 48px;

  form > div {
    @media only screen and (max-width: 790px) {
      min-width: 100%;
      padding-right: 16px;
    }
  }
`;

const DesktopSettingsModal = styled(props => <Modal {...props} />)`
  @media only screen and (max-width: 768px) {
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const MenuWrapper = styled.div`
  padding: 16px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.color.charcoal300};
`;

const StyledNotificationWrapper = styled(props => <NotificationWrapper {...props} />)`
  right: ${({ theme }) => theme.spacings.small2};
`;

const SettingsModalDesktop = ({
  onClose = () => {},

  children,
  breakpoint,
  basicSettings,
  additionalSettings
}) => {
  const dispatch = useDispatch();
  const { isSettingsOpen, isCardOpen } = useSelector(state => state.settings);
  const notification = useSelector(state => state.notification);

  const transformErrors = error => {
    return { id: error.message };
  };
  const handleClose = () => {
    dispatch(closeNotification());
  };

  const isNotificationOpen = notification && notification.isOpen;

  const columns = breakpoint === 'm' ? '35% 65%' : '1fr 3fr';

  return (
    <DesktopSettingsModal
      enableClickOutside={false}
      style={{ height: 'inherit' }}
      header={
        <p>
          <FormattedMessage id="settings.title" />
        </p>
      }
      isOpen={isSettingsOpen}
      onClose={onClose}
      full={true}
      zIndex={1000}
    >
      {isNotificationOpen && !isCardOpen ? (
        <StyledNotificationWrapper>
          <NotificationTranslate
            transformErrors={transformErrors}
            notification={notification}
            onClose={handleClose}
          />
        </StyledNotificationWrapper>
      ) : null}
      <Grid columns={columns} style={{ height: '100%' }}>
        <MenuWrapper>
          {basicSettings}
          {additionalSettings}
        </MenuWrapper>

        <StyledContainer>{children}</StyledContainer>
      </Grid>
    </DesktopSettingsModal>
  );
};

export default SettingsModalDesktop;
