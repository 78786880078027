import { NavigationBar, TextLink } from '@uda/bento-components';
import { FormattedMessage, useIntl } from 'react-intl';
import UserNavbar from './UserNavbar';
import { NavLink } from 'react-router-dom';
import Notifications from './Notifications/Notifications';
import SettingsContainer from '../Settings/containers/SettingsContainer';
import Tag from '../components/Tag';
import styled from 'styled-components';
import MobileMenu from './MobileMenu';
import { useBreakpoint } from '../utils/hooks/useBreakpoint';
import { gaEventClick } from '../utils';
import NavigationMenuProducts from './NavigationMenuProducts';
import { useSelector } from 'react-redux';

const StyledTextLink = styled(props => <TextLink {...props} />)`
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
  }
`;

const DesktopNavigationBar = styled(NavigationBar)`
  border-top: ${({ theme, borderColor }) => {
    return `2px solid ${theme.color[borderColor]}`;
  }};

  [class^='NavigationBar__StyledMenu'] {
    background-color: ${({ theme, bg }) => theme.color[bg]};
    > svg {
      color: white;
    }
  }

  height: 40px;
  @media only screen and (max-width: 768px) {
    &.md-hidden {
      display: none;
    }
    > div {
      > div {
        margin: 0;
        > ul {
          > li {
            margin-right: ${({ theme }) => theme.spacings.small3};
          }
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    &.sm-hidden {
      display: none;
    }
  }
`;

const MobileNavigationBar = styled(props => <NavigationBar {...props} />)`
  border-top: ${({ theme, borderColor }) => {
    return `2px solid ${theme.color[borderColor]}`;
  }};
  display: none;
  height: 64px;
  z-index: 11;

  @media only screen and (max-width: 600px) {
    &.sm-show {
      display: flex;
    }
    height: 48px;
  }
`;

const Navigation = ({ productKey, header, sectionLinks = [], clean }) => {
  const intl = useIntl();
  const notifications = useSelector(state => state.notification);
  const isSettingsOpen = useSelector(state => state.settings.isSettingsOpen);
  const isScrollOnTop = useSelector(state => state.layout.isScrollOnTop);
  const breakpoint = useBreakpoint();
  const plan = useSelector(state => state.user.data.plans?.opportunities) || null;
  const userProducts = useSelector(state => state.user.data.products);
  const getProductColor = productKey => {
    //TODO move colors to bento
    const PRODUCT_COLORS_CONFIG = {
      capture: 'ocean800',
      opportunities: 'forest800',
      market: 'aubergine800',
      portfolio: 'charcoal700',
      'smart-valuation': 'primary500'
    };
    return PRODUCT_COLORS_CONFIG[productKey] || 'primary500';
  };
  const showProductsMenu = userProducts.length > 1 || plan === 'enterprise-opportunities';

  //Responsive Variables
  const fullWidthBreakpoints = ['l', 'xl', 'xxl'];

  const handleClickEvent = category => gaEventClick(category, 'Pulse Navigation');

  return (
    <>
      <DesktopNavigationBar
        borderColor={getProductColor(productKey)}
        bg={getProductColor(productKey)}
        className={'sm-hidden'}
        dropdownMenu={
          !clean && showProductsMenu && fullWidthBreakpoints.includes(breakpoint) ? (
            <NavigationMenuProducts productKey={productKey} />
          ) : null
        }
        header={header}
        rightContent={!clean ? <UserNavbar /> : null}
        sticked={!isScrollOnTop}
        data-header={true}
      >
        {sectionLinks.map(({ href, intlKey, disabled }, i) =>
          !disabled ? (
            !clean ? (
              <NavLink
                key={i}
                exact
                to={href}
                activeClassName="active"
                disabled={disabled}
                onClick={() => handleClickEvent(intl.formatMessage({ id: intlKey }))}
              >
                <FormattedMessage id={intlKey} />
              </NavLink>
            ) : null
          ) : !clean ? (
            <StyledTextLink
              key={i}
              variant={'secondary'}
              href={href}
              disabled
              title={intl.formatMessage({ id: 'paths.soon' })}
            >
              <FormattedMessage id={intlKey} />
              <Tag>
                <FormattedMessage id={`paths.soon`} />
              </Tag>
            </StyledTextLink>
          ) : null
        )}
        {!clean ? <Notifications notifications={notifications} /> : <p />}
      </DesktopNavigationBar>

      <MobileNavigationBar
        borderColor={getProductColor(productKey)}
        className={'sm-show'}
        header={header}
        rightContent={
          !clean ? (
            <>
              <MobileMenu notifications={notifications} sectionLinks={sectionLinks}>
                <NavigationMenuProducts productKey={productKey} />
              </MobileMenu>
            </>
          ) : null
        }
        sticked={!isScrollOnTop}
        data-header={true}
      />

      {isSettingsOpen ? <SettingsContainer /> : null}
    </>
  );
};

export default Navigation;
