import { IconBathroom, IconBedroom } from '@uda/bento-components';

export const parseFilters = data => {
  const filters = { ...data };
  delete filters.sources;

  const result = {
    ...filters,
    firstPriceRangeFilter: getFirstPriceRangeFilters(data),
    vendorTypeIn: getVendorTypeInFilters(data)
  };

  return result;
};

export const getSortingFields = data =>
  data
    .map(i => Object.assign({}, { id: i, label: `capture.sorting.${i}`, disabled: false }))
    .reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: { label: item.label, disabled: item.disabled } }),
      {}
    );

export const getFirstPriceRangeFilters = filters => {
  let result = {};
  Object.keys(filters).map(
    key =>
      (result = {
        ...result,
        [key]: filters[key].filter(filter => filter.id === 'first_price__range')
      })
  );
  return result;
};

export const getVendorTypeInFilters = filters => {
  let result = {};
  Object.keys(filters).map(
    key =>
      (result = {
        ...result,
        [key]: filters[key].filter(filter => filter.id === 'vendor_type__in')
      })
  );
  return result;
};

export const getTypologiesFromFilters = filters => {
  delete filters.sources;
  const keyValues = Object.keys(filters);
  const filterFormatter = keyValues.map(key => ({
    id: filters[key][0].id,
    value: filters[key][0].value
  }));
  return filterFormatter;
};

export const getInitialColumnState = data => {
  return data.map(e => ({
    colId: e.field,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null
  }));
};

export const getCellWidth = field => {
  switch (field) {
    case 'n_rooms':
    case 'n_baths':
    case 'is_exterior':
    case 'floor':
      return { width: 65, minWidth: 65 };
    case 'area':
    case 'source':
      return { width: 90, minWidth: 90 };
    case 'date_out':
    case 'date_in':
      return { width: 130, minWidth: 130 };
    default:
      return { width: 140, minWidth: 140 };
  }
};

const getCellIcon = (field, sort) => {
  const iconColor = sort ? '#1778FB' : '#747D93';
  switch (field) {
    case 'n_rooms':
      return <IconBedroom customColor={iconColor} />;
    case 'n_baths':
      return <IconBathroom customColor={iconColor} />;
    default:
      return null;
  }
};

export const apiInternTable = data => {
  const { columns, comparables, pagination, ...rest } = data;
  return {
    columns: columns.map(e => ({
      ...e,
      ...getCellWidth(e.field),
      icon: getCellIcon(e.field, e.sortDirection),
      align: getCellAlign(e.field),
      cellRenderer: getCellRenderer(e.field),
      variant: getCellVariant(e.field),
      sortType: getCellSortType(e.field),
      sort: e.sort,
      longCell: e.field === 'address'
    })),
    initialColumnState: getInitialColumnState(data.columns),
    pagination: pagination || {
      page: 1,
      page_size: 200,
      page_count: 200,
      total_count: comparables.length,
      next: ''
    },
    comparables: comparables.map(e => ({
      ...e,
      vendor_type: { primary: { format: 'string', value: e.vendor_type.primary.value.label } }
    })),
    ...rest
  };
};

// ('source');
// ('address');
// ('publishing_status__in');
// ('date_in');
// ('date_out');
// ('price');
// ('sqm_value');
// ('area');
// ('floor');
// ('n_rooms');
// ('n_baths');
// ('is_exterior');
// ('contact_phone');
// ('vendor_type');

export const getCellAlign = field => {
  switch (field) {
    case 'source':
    case 'address':
    case 'publishing_status__in':
    case 'date_in':
    case 'date_out':
      return 'left';
    case 'n_rooms':
    case 'n_baths':
    case 'is_exterior':
      return 'center';
    default:
      return 'right';
  }
};
const getCellRenderer = field => {
  switch (field) {
    case 'is_exterior':
      return 'booleanCell';
    default:
      return 'singleCell';
  }
};
const getCellVariant = field => {
  switch (field) {
    case 'price':
      return 'primary';
    default:
      return 'secondary';
  }
};

const getCellSortType = field => {
  switch (field) {
    case 'date_in':
    case 'date_out':
      return 'date';
    case 'sqm_value':
    case 'area':
    case 'n_rooms':
    case 'n_baths':
    case 'floor':
    case 'price':
      return 'number';
    default:
      return 'string';
  }
};

export const addFavourite = (favourites, comparables, id) => {
  if (!favourites || !comparables) {
    return favourites;
  }

  return [...favourites, comparables.find(comparable => comparable.id === id)];
};
export const deleteFavourite = (comparables, id) => {
  return comparables ? comparables.filter(c => c.id !== id) : comparables;
};
