import defaultTheme from 'context/theme';
import { isEmptyArray } from 'utils/index';

export const mapCompanyPersonalization = ({ data }) => {
  const personalization = data[0];
  const colorOverride =
    !isEmptyArray(data) && personalization?.theme
      ? getThemePropertyOverride(personalization?.theme, 'color')
      : {};
  const componentsOverride =
    !isEmptyArray(data) && personalization?.theme
      ? getThemePropertyOverride(personalization?.theme, 'components')
      : {};
  const features = personalization?.features
    ? personalization.features.reduce(
        (features, { config, field_id }) => ({ ...features, [field_id]: config }),
        {}
      )
    : {};
  const assets = personalization?.assets
    ? personalization.assets.reduce(
        (assets, { type, file }) => ({ ...assets, [type]: file?.url }),
        {}
      )
    : {};
  return { colorOverride, componentsOverride, features, assets };
};

const getThemePropertyOverride = (themeOverrides, property) => {
  return Object.keys(themeOverrides).reduce((acc, key) => {
    if (key in defaultTheme[property] && themeOverrides[key]?.value)
      acc[key] = themeOverrides[key].value;
    return acc;
  }, {});
};
