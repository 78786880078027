import { getNewRangeFilters } from '../../utils/filters';
import { DEFAULT_BBOX } from '../constants.js';
import { apiInternList, apiInternDetail, apiInternDetailTab, apiInternMapPopup } from './apiIntern';
import { apiInternFilters, internDetail } from './intern';

export const initialState = {
  alerts: {
    isLoading: false,
    alerts: null
  },
  grid: {
    map: {
      bbox: DEFAULT_BBOX,
      isPopupLoading: null,
      popup: null,
      geom: null,
      isUserDrawing: false,
      visible: true
    },
    isLoading: false,
    filtersCollection: { main: [], secondary: [] },
    filtersApplied: {},
    isFiltersOpen: false,
    segments: {
      province_id__in: [],
      strategy: [1],
      use__in: [1, 0]
    },
    controlBar: {},
    list: {
      isLoading: false,
      isLoadingPagination: false,
      items: null,
      sorting: {
        sort: 'gross_yield_asking_price',
        order: 'desc'
      },
      pagination: {
        pagesLoaded: []
      }
    },
    alerts: {
      isLoading: false,
      isSuccess: false
    }
  },
  provincesAndAssets: [],
  detail: {
    isLoading: false,
    isModalCalculatorOpened: false,
    blocked: {
      display: null,
      type: 'UPGRADE_REQUIRED'
    },
    isFavoriteLoading: false,
    isTabLoading: null,
    downloadLink: null,
    isFavorite: null,
    header: null,
    card: null,
    tab: 'business_plan',
    summary: null,
    market: null,
    business_plan: { title: 'Get info from card' },
    allTabs: ['business_plan', 'summary', 'market'],
    disabledTabs: ['documentation'],
    address: {
      is_exact_address: false,
      address: null,
      city: null,
      district: null,
      postal_code: null
    }
  }
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'FETCH_ALERTS':
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isLoading: true
        }
      };
    case 'FETCH_ALERTS_SUCCESS':
      return {
        ...state,
        alerts: {
          ...state.alerts,
          alerts: payload.data,
          isLoading: false
        }
      };
    case 'FETCH_CREATE_ALERT':
      return {
        ...state,
        grid: {
          ...state.grid,
          alerts: {
            ...state.grid.alerts,
            isLoading: true
          }
        }
      };
    case 'FETCH_CREATE_ALERT_SUCCESS':
      return {
        ...state,
        grid: {
          ...state.grid,
          alerts: {
            ...state.grid.alerts,
            isLoading: false,
            isSuccess: true
          }
        }
      };
    case 'CREATE_ALERT_SUCCEDED':
      return {
        ...state,
        grid: {
          ...state.grid,
          alerts: {
            ...state.grid.alerts,
            isSuccess: false
          }
        }
      };
    case 'REMOVE_POPUP_INFO':
      return {
        ...state,
        grid: {
          ...state.grid,
          map: {
            ...state.grid.map,
            popup: null
          }
        }
      };
    case 'FETCH_POPUP_INFO':
      return {
        ...state,
        grid: {
          ...state.grid,
          map: {
            ...state.grid.map,
            isPopupLoading: true
          }
        }
      };
    case 'FETCH_POPUP_INFO_SUCCESS':
      return {
        ...state,
        grid: {
          ...state.grid,
          map: {
            ...state.grid.map,
            isPopupLoading: false,
            popup: apiInternMapPopup(payload.data)
          }
        }
      };
    case 'FETCH_POPUP_INFO_FAIL':
      return {
        ...state,
        grid: {
          ...state.grid,
          map: {
            ...state.grid.map,
            isPopupLoading: false,
            popup: null
          }
        }
      };
    case 'OPPORTUNITIES_DETAIL_CALCULATOR_MODAL':
      return {
        ...state,
        detail: {
          ...state.detail,
          isModalCalculatorOpened: payload
        }
      };
    case 'UPDATE_OPPORTUNITIES_DETAIL_TAB':
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case 'FETCH_OPPORTUNITIES_DETAIL_TAB':
      return {
        ...state,
        detail: {
          ...state.detail,
          isTabLoading: true
        }
      };
    case 'FETCH_OPPORTUNITIES_DETAIL_TAB_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          [state.detail.tab]: apiInternDetailTab(payload.data),
          isTabLoading: false,
          blocked: {
            display: null,
            type: 'UPGRADE_REQUIRED'
          }
        }
      };
    case 'FETCH_OPPORTUNITIES_DETAIL_TAB_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          isTabLoading: false
        }
      };
    case 'FETCH_OPPORTUNITIES_DETAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: true
        }
      };
    case 'FETCH_OPPORTUNITIES_DETAIL_SUCCESS':
      let opportunitiesDetail = apiInternDetail(payload.data);
      opportunitiesDetail = internDetail(opportunitiesDetail);
      return {
        ...state,
        detail: {
          ...state.detail,
          ...opportunitiesDetail,
          isLoading: false
        }
      };
    case 'FETCH_OPPORTUNITIES_DETAIL_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          header: null,
          card: null,
          isLoading: false
        }
      };
    case 'FETCH_OPPORTUNITIES_LIST':
      return {
        ...state,
        grid: {
          ...state.grid,
          list: {
            ...state.grid.list,
            isLoading: true
          }
        }
      };
    case 'FETCH_OPPORTUNITIES_LIST_SUCCESS':
      const newItems = apiInternList(payload.data.opportunities);
      return {
        ...state,
        grid: {
          ...state.grid,
          list: {
            ...state.grid.list,
            items: newItems,
            isLoading: false,
            pagination: {
              ...payload.data.pagination,
              pagesLoaded: [payload.data.pagination.page]
            }
          }
        }
      };
    case 'FETCH_OPPORTUNITIES_LIST_FAIL':
      return {
        ...state,
        grid: {
          ...state.grid,
          list: {
            ...state.grid.list,
            isLoading: false
          }
        }
      };
    case 'OPPORTUNITIES_LIST_SORT':
      return {
        ...state,
        grid: {
          ...state.grid,
          list: {
            ...state.grid.list,
            sorting: payload
          }
        }
      };
    case 'FETCH_OPPORTUNITIES_LIST_PAGE':
      return {
        ...state,
        grid: {
          ...state.grid,
          list: {
            ...state.grid.list,
            isLoadingPagination: true
          }
        }
      };
    case 'FETCH_OPPORTUNITIES_LIST_PAGE_SUCCESS':
      const newOpportunities = apiInternList(payload.data.opportunities);
      const allOpportunities = [...state.grid.list.items, ...newOpportunities];
      return {
        ...state,
        grid: {
          ...state.grid,
          list: {
            ...state.grid.list,
            isLoadingPagination: false,
            items: allOpportunities,
            pagination: {
              ...payload.data.pagination,
              pagesLoaded: [...state.grid.list.pagination.pagesLoaded, payload.data.pagination.page]
            }
          }
        }
      };
    case 'FETCH_OPPORTUNITIES_LIST_PAGE_FAIL':
      return {
        ...state,
        grid: {
          ...state.grid,
          list: {
            ...state.grid.list,
            isLoadingPagination: false
          }
        }
      };
    case 'FETCH_PROVINCES_AND_NUM_OF_ASSETS_SUCCESS':
      return {
        ...state,
        provincesAndAssets: payload.data
      };
    case 'FETCH_OPPORTUNITIES_FILTER_COLLECTION':
      return {
        ...state,
        grid: {
          ...state.grid,
          isLoading: true
        }
      };
    case 'FETCH_OPPORTUNITIES_FILTER_COLLECTION_SUCCESS':
      return {
        ...state,
        grid: {
          ...state.grid,
          isLoading: false,
          filtersCollection: apiInternFilters(payload.data)
        }
      };
    case 'OPPORTUNITIES_SEGMENTS_FILTER':
      return {
        ...state,
        grid: {
          ...state.grid,
          segments: { ...state.grid.segments, [payload.id]: payload.value }
        }
      };
    case 'OPPORTUNITIES_ALL_SEGMENTS_FILTER':
      return {
        ...state,
        grid: {
          ...state.grid,
          segments: { ...payload }
        }
      };
    case 'UPDATE_OPPORTUNITIES_FILTERS_OPEN':
      return {
        ...state,
        grid: {
          ...state.grid,
          isFiltersOpen: payload
        }
      };
    case 'OPPORTUNITIES_FILTERS':
      const rangeFilters = (state.grid.filtersCollection.secondary || [])
        .map(filterGroup => filterGroup.filters)
        .flat()
        .map(filter => filter.filters)
        .flat()
        .filter(subFilter => subFilter.filter_type === 'range');

      const newFilters = getNewRangeFilters(payload, rangeFilters);

      return {
        ...state,
        grid: {
          ...state.grid,
          filtersApplied: newFilters
        }
      };
    case 'SET_OPPORTUNITIES_MAP_INFO':
      return {
        ...state,
        grid: {
          ...state.grid,
          map: {
            ...state.grid.map,
            ...payload
          }
        }
      };
    case 'FETCH_PROVINCES_BBOX_SUCCESS':
      return {
        ...state,
        grid: {
          ...state.grid,
          map: {
            ...state.grid.map,
            bbox: payload.data.bbox
          }
        }
      };
    case 'SET_OPPORTUNITIES_UPGRADE_MODAL_VISIBLE':
      return {
        ...state,
        grid: {
          ...state.grid,
          upgradeModalOpen: payload
        }
      };
    case 'RESET_OPPORTUNITIES':
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
