import * as Yup from 'yup';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { InputField, FormGroup, Button } from '@uda/bento-components';
import { useFormik } from 'formik';
import PATHS from '../../routes/paths';

const PASSWORD_MIN_LENGTH = 8;

const Heading = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  @media only screen and (max-width: 720px) {
    font-size: 24px;
  }
`;

const BackText = styled.p`
  color: ${({ theme }) => theme.color.charcoal400};
  margin-top: ${({ theme }) => theme.spacings.small3};
  font-family: ${({ theme }) => theme.global.fontFamilyBold};
`;

const AuthResetPasswordForm = ({ changePassword }) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(PASSWORD_MIN_LENGTH, intl.formatMessage({ id: 'errors.min_password_8' }))
      .matches(/^(?=.*[A-Z])/, intl.formatMessage({ id: 'errors.include_uppercase' }))
      .matches(/^(?=.*[a-z])/, intl.formatMessage({ id: 'errors.include_lowercase' }))
      .matches(/^(?=.*[0-9])/, intl.formatMessage({ id: 'errors.include_number' }))
      .required(intl.formatMessage({ id: 'errors.required' })),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      intl.formatMessage({ id: 'errors.match_password' })
    )
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: validationSchema,
    onSubmit({ password }, { setSubmitting }) {
      changePassword({ password });
      setSubmitting(false);
    }
  });

  const { handleSubmit, isSubmitting, errors, touched, handleBlur, handleChange, values, dirty } =
    formik;

  return (
    <div>
      <Heading>
        <FormattedMessage id="auth.change_password.title" />
      </Heading>
      <form onSubmit={handleSubmit} className={isSubmitting ? 'loading' : null}>
        <FormGroup>
          <InputField
            autoComplete="off"
            disabled={isSubmitting}
            error={!!errors.password && !!touched.password && !!errors.password}
            help={
              (errors.password && touched.password && errors.password) ||
              intl.formatMessage({ id: 'auth.sign_up.password_helptext' })
            }
            label={intl.formatMessage({ id: 'auth.change_password.password' })}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: 'auth.change_password.password_placeholder'
            })}
            required
            type="password"
            value={values.password}
          />
        </FormGroup>
        <FormGroup>
          <InputField
            autoComplete="off"
            disabled={isSubmitting}
            error={
              !!errors.passwordConfirmation &&
              !!touched.passwordConfirmation &&
              !!errors.passwordConfirmation
            }
            help={
              (errors.passwordConfirmation &&
                touched.passwordConfirmation &&
                errors.passwordConfirmation) ||
              ''
            }
            label={intl.formatMessage({ id: 'auth.change_password.password_confirm' })}
            name="passwordConfirmation"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: 'auth.change_password.password_confirm_placeholder'
            })}
            required
            type="password"
            value={values.passwordConfirmation}
          />
        </FormGroup>
        <Button
          type="submit"
          variant="primary"
          size="large"
          block
          disabled={isSubmitting || !!errors.password || !!errors.passwordConfirmation || !dirty}
        >
          <FormattedMessage id="auth.change_password.set" />
        </Button>

        <BackText>
          <FormattedMessage
            id="auth.change_password.back"
            values={{
              link: (
                <Link
                  to={PATHS.signIn}
                  title={intl.formatMessage({ id: 'paths.sign_in' })}
                  className="primary"
                >
                  <FormattedMessage id="paths.sign_in" />
                </Link>
              )
            }}
          />
        </BackText>
      </form>
    </div>
  );
};

export default AuthResetPasswordForm;
