import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import iconError from '../assets/images/icn-error.svg';
import portfolioLogo from '../assets/images/logo-portfolio.svg';
import marketLogo from '../assets/images/logo-market.svg';
import pulseLogo from '../assets/images/logo-pulse.svg';
import opportunitiesLogo from '../assets/images/logo-opportunities.svg';

import { history } from '../App';
import { Button, IconUpdate, TextLink } from '@uda/bento-components';
import PATHS from '../routes/paths';
import useFlag from '../utils/hooks/useFlag';

const StyledWrapper = styled.div``;

const StyledHeader = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.color.charcoal300};
  padding: ${({ theme }) => theme.spacings.small3} ${({ theme }) => theme.spacings.small4};
`;

const StyledContent = styled.div`
  max-width: 520px;
  text-align: center;
  margin: 130px auto;
`;

const StyledTitle = styled.h2`
  color: ${({ theme }) => theme.color.charcoal800};
`;

const StyledSubtitle = styled.h3`
  color: ${({ theme }) => theme.color.charcoal600};
`;

const StyledContact = styled.p`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal500};
  margin-bottom: ${({ theme }) => theme.spacings.medium4};
`;

const ErrorBoundary = () => {
  const fotocasaProvider = useFlag('fotocasa_provider');
  const [productSlug] = history.location.pathname.split('/').slice(1);

  let logo = null;
  switch (productSlug) {
    case 'portfolio':
      logo = portfolioLogo;
      break;
    case 'opportunities':
      logo = opportunitiesLogo;
      break;
    case 'market':
      logo = marketLogo;
      break;
    default:
      logo = pulseLogo;
      break;
  }

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <StyledWrapper>
      <StyledHeader>
        <img src={logo} alt="Logo error" />
      </StyledHeader>
      <StyledContent>
        <img src={iconError} alt="Error" />
        <StyledTitle>
          <FormattedMessage id="error.global.title" />
        </StyledTitle>
        <StyledSubtitle>
          <FormattedMessage id="error.global.description" />
        </StyledSubtitle>
        <StyledContact>
          <FormattedMessage
            id="error.global.support"
            values={{
              link: (
                <TextLink
                  href={fotocasaProvider ? PATHS.contactUsFotocasa : PATHS.contactUs}
                  title={<FormattedMessage id="error.global.contact" />}
                  className="p1b primary"
                  target="_blank"
                >
                  <FormattedMessage id="error.global.contact" />
                </TextLink>
              )
            }}
          />
        </StyledContact>
        <Button size="large" iconLeft={<IconUpdate />} onClick={refreshPage}>
          <FormattedMessage id="error.global.refresh" />
        </Button>
      </StyledContent>
    </StyledWrapper>
  );
};

export default ErrorBoundary;
