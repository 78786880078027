import styled from 'styled-components';

const AuthFooterStyle = styled.div`
  display: inline-block;
  position: relative;

  > a > svg {
    transition: transform 300ms ease-in-out;
    transform: rotate(${props => (props.opened ? '180deg' : '0')});
  }

  > ul {
    position: fixed;
    list-style: none;
    border: 0;
    opacity: ${props => (props.opened ? '1' : '0')};
    transform: translate(${props => (props.opened ? '-15px, -140%' : '-15px, -120%')});
    transition: all 300ms ease-in-out;
    pointer-events: ${props => (props.opened ? 'auto' : 'none')};

    @media only screen and (max-width: 768px) {
      transform: translate(${props => (props.opened ? '-15px, -140%' : '-15px, -120%')});
    }

    > li {
      > button {
        padding: ${({ theme }) => theme.spacings.small2} ${({ theme }) => theme.spacings.small3};
        background-color: white;
        border-radius: ${({ theme }) => theme.shapes.borderRadiusSmall};
        ${({ theme }) => theme.texts.p2}
        transition: all 300ms ease-in-out;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          content: '';
          margin-left: 1rem;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          display: inline-block;
          background-color: ${({ theme }) => theme.color.primary500};
          opacity: 0;
          transition: opacity 300ms ease-in-out;
        }

        &.selected {
          background-color: ${({ theme }) => theme.color.primary100};
          border-radius: ${({ theme }) => theme.shapes.borderRadiusSmall};
          font-family: ${({ theme }) => theme.global.fontFamilyBold};
          color: ${({ theme }) => theme.color.primary500};
          &::after {
            opacity: 1;
          }

          &:hover {
            font-family: ${({ theme }) => theme.global.fontFamilyBold};

            &::after {
              background-color: ${({ theme }) => theme.color.primary500};
            }
          }
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.charcoal300};
          color: ${({ theme }) => theme.color.charcoal800};
          font-family: ${({ theme }) => theme.global.fontFamilyRegular};

          &::after {
            opacity: 1;
            background-color: ${({ theme }) => theme.color.charcoal800};
          }
        }
      }
    }
  }
`;

export default AuthFooterStyle;
