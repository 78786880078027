import styled, { useTheme } from 'styled-components';
import { IconNotification, Dropdown, List, ListItem, ButtonLink } from '@uda/bento-components';
import { FormattedMessage } from 'react-intl';

import noNotifications from '../../assets/images/no-notifications.svg';

const StyledNoNotifications = styled.div`
  text-align: center;
  overflow: hidden;
  padding: 19px;

  > h3 {
    color: ${({ theme }) => theme.color.charcoal800};
    font-size: 14px;
    margin-bottom: 8px;
  }
  > p {
    font-size: 12px;
    color: ${({ theme }) => theme.color.charcoal600};
  }
`;

const NotificationContainer = styled.div`
  position: relative;

  &::after {
    content: '';
    display: ${props => (props.hasNotifications ? 'block' : 'none')};
    font-size: 11px;
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.color.primary500};
    border-radius: 50%;
    font-family: ${({ theme }) => theme.global.fontFamilyMedium};
    text-align: center;
    line-height: 19px;
    color: white;
    position: absolute;
    right: 0px;
    top: -1px;
    border: 2px solid white;
  }
  @media only screen and (max-width: 768px) {
    > button {
      padding-right: 0;
    }
  }
`;

const StyledSeparator = styled.i`
  color: ${({ theme }) => theme.color.charcoal400};
  ${({ separatorLocation }) =>
    separatorLocation === 'left' ? 'margin-right: 24px' : 'margin-right:12px; margin-left:10px'};
`;

const Notifications = ({ notifications, separatorLocation = 'left' }) => {
  const theme = useTheme();
  //Product requirement: hide until this functionality is implemented - 12/05/2021
  const isNotificationImplemented = false;
  return (
    <>
      {isNotificationImplemented ? (
        <>
          {separatorLocation === 'left' ? <StyledSeparator>|</StyledSeparator> : null}
          <Dropdown
            align="center"
            label={
              <NotificationContainer hasNotifications={notifications.length}>
                <ButtonLink>
                  <IconNotification customColor={theme.color.charcoal600} size="medium" />
                </ButtonLink>
              </NotificationContainer>
            }
          >
            {notifications.length > 0 ? (
              <List>
                {notifications.map(n => (
                  <ListItem>{n}</ListItem>
                ))}
              </List>
            ) : (
              <StyledNoNotifications>
                <img src={noNotifications} alt="Clear" />
                <h3>
                  <FormattedMessage id="notifications.no_notifications" />
                </h3>
                <p>
                  <FormattedMessage id="notifications.no_notifications_description" />
                </p>
              </StyledNoNotifications>
            )}
          </Dropdown>
          {separatorLocation === 'right' ? <StyledSeparator>|</StyledSeparator> : null}
        </>
      ) : null}
    </>
  );
};

export default Notifications;
