import { monthYear } from './dates';
import lang from '../translations/EN.json';
import { renderToString } from 'react-dom/server';
import { TextLink } from '@uda/bento-components';

const UNITS_AVAILABLE = Object.keys(lang.format);

export const getRoundedLetter = (value, lang) => {
  const num = parseFloat(value);
  const length = (Math.log(num) * Math.LOG10E + 1) | 0;
  if (length > 9) return lang === 'en' ? 'B' : 'MM';
  if (length > 6) return 'M';
  if (length > 5) return 'K';
  return '';
};

export const getRoundedNumber = value => {
  if (typeof value === 'string') return value;
  const num = parseFloat(value);

  if (num < 1) {
    return Number(num).toPrecision(2);
  }

  const length = (Math.log(num) * Math.LOG10E + 1) | 0;

  if (length > 9) return Number((num / 1000000000).toFixed(2));
  if (length > 8) return Number((num / 1000000).toFixed(1));
  if (length > 7) return Number((num / 1000000).toFixed(1));
  if (length > 6) return Number((num / 1000000).toFixed(2));
  if (length > 5) return Math.round(num / 1000);
  return Math.round(num.toFixed(0));
};

export const getNumberSign = value => (Math.sign(value) !== -1 ? '+' : '');
export const isNumberPositive = value => !!getNumberSign(value);

export const getCurrency = value => {
  const currency = value ? value.toUpperCase() : '';
  switch (currency) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
      return '$';
    default:
      return '';
  }
};

export const getValueFormatted = (
  data,
  intl,
  locale,
  rounded = true,
  destructured = false,
  noValue = '-'
) => {
  const { value, format, currency, delta, decimals, ...extraDefinitions } = data;

  if (value === null || value === undefined) return destructured ? { value: noValue } : noValue;
  let valueFormatted = {
    prefix: '',
    value: '',
    unit: '',
    suffix: ''
  };
  switch (format) {
    case 'year':
    case 'string':
      valueFormatted.value = value;
      break;
    case 'months':
      valueFormatted.value = value;
      valueFormatted.suffix = ` ${intl.formatMessage(
        { id: 'format.months' },
        { itemCount: value }
      )}`;
      break;
    case 'date':
      if (data.type) {
        switch (data.type) {
          case 'time':
            valueFormatted.value = value ? intl.formatTime(value) : '';
            break;
          case 'full':
            valueFormatted.value = value ? intl.formatDate(Number(value)) : '';
            break;
          default:
            valueFormatted.value = monthYear(value);
            break;
        }
      } else {
        valueFormatted.value = monthYear(value);
      }
      break;
    case 'percent_lien':
      valueFormatted.value = new Intl.NumberFormat(locale).format(value);
      valueFormatted.suffix = '% LIEN 1';
      break;
    case 'percent':
      valueFormatted.value = new Intl.NumberFormat(locale).format(value);
      valueFormatted.suffix = '%';

      if (data.hasOwnProperty('decimals')) {
        valueFormatted.value = new Intl.NumberFormat(locale, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals
        }).format(Number(value).toFixed(decimals));
      }
      break;
    case 'distance':
      valueFormatted.value = value;
      valueFormatted.suffix = ' m';
      break;
    case 'number':
      valueFormatted.value = new Intl.NumberFormat(locale).format(
        rounded ? getRoundedNumber(value) : value
      );

      if (data.hasOwnProperty('decimals')) {
        valueFormatted.value = new Intl.NumberFormat(locale, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals
        }).format(Number(value).toFixed(decimals));
      }

      valueFormatted.unit = rounded ? getRoundedLetter(value, locale) : '';
      break;
    case 'ordinal':
      if (value === 0) {
        valueFormatted.value = intl.formatMessage({ id: `format.ground_floor` });
      } else if (typeof value === 'string') {
        valueFormatted.value = value;
      } else {
        valueFormatted.value = intl.formatMessage(
          { id: `format.ordinal` },
          { itemCount: rounded ? getRoundedNumber(value) : value }
        );
      }
      break;
    case 'price':
      valueFormatted.value = new Intl.NumberFormat(locale).format(
        rounded ? getRoundedNumber(value) : value
      );

      if (data.hasOwnProperty('decimals')) {
        valueFormatted.value = new Intl.NumberFormat(locale, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals
        }).format(Number(value).toFixed(decimals));
      }

      valueFormatted.unit = rounded ? getRoundedLetter(value, locale) : '';
      valueFormatted.suffix = getCurrency(currency);
      break;
    case 'unit':
      valueFormatted.value = new Intl.NumberFormat(locale).format(value);
      valueFormatted.suffix = ` ${intl.formatMessage({ id: 'portfolio.cards.units' })}`;
      break;
    case 'area':
      valueFormatted.value = new Intl.NumberFormat(locale).format(value);
      valueFormatted.suffix = ` ${intl.formatMessage({ id: 'portfolio.cards.sqm' })}`;
      break;
    case 'useful_area':
      valueFormatted.value = new Intl.NumberFormat(locale).format(value);
      valueFormatted.suffix = ` ${intl.formatMessage({ id: 'portfolio.cards.useful_sqm' })}`;
      break;
    case 'n_floors':
      valueFormatted.value = new Intl.NumberFormat(locale).format(value);
      valueFormatted.suffix = ` ${intl.formatMessage({ id: 'portfolio.cards.n_floors' })}`;
      break;
    case 'weeks':
      const WEEKS_IN_A_MONTH = 4.345;
      const WEEKS_IN_A_YEAR = 52;

      let shouldBeMonths = value / WEEKS_IN_A_YEAR > 1;

      if (shouldBeMonths) {
        let months = value / WEEKS_IN_A_MONTH;

        let monthsToAdd = months <= Math.round(months) ? 1 : 0;
        valueFormatted.value = Math.floor(months) + monthsToAdd;
      } else {
        valueFormatted.value = value;
      }

      valueFormatted.suffix = ` ${intl.formatMessage(
        { id: shouldBeMonths ? 'format.months' : 'format.weeks' },
        { itemCount: value }
      )}`;
      break;
    case 'years':
      valueFormatted.value = value;
      valueFormatted.suffix = `\u00A0${intl.formatMessage(
        { id: 'format.years' },
        { itemCount: value }
      )}`;
      break;
    case '3yoy':
      valueFormatted.value = new Intl.NumberFormat(locale).format(value);
      valueFormatted.suffix = '% 3yoy';
      break;
    case 'frequency':
      switch (value) {
        case 'monthly_rate':
          valueFormatted.value = `${intl.formatMessage({
            id: 'payments.monthly'
          })}`;
          break;
        default:
          break;
      }
      break;
    case 'link':
      valueFormatted.value = renderToString(
        <TextLink external={true} href={value}>
          {extraDefinitions.label}
        </TextLink>
      );
      break;
    default:
      break;
    // case 'week':
    //   valueFormatted.value = value;
    //   valueFormatted.suffix = ` ${intl.formatMessage({ id: 'format.week' })}`;
    //   break;
  }

  if (delta) valueFormatted.value = getNumberSign(value) + valueFormatted.value;

  const extras = (extraDefinitions && Object.keys(extraDefinitions)) || [];

  extras.forEach(extra => {
    if (UNITS_AVAILABLE.includes(extra)) {
      switch (extra) {
        case 'bedrooms':
        case 'bathrooms':
          valueFormatted.suffix += ``;
          break;
        case 'collaterals':
        case 'yoy':
        case 'price_gap':
        case 'discount':
        case 'units':
          valueFormatted.suffix += ` ${intl.formatMessage(
            { id: `format.${extra}` },
            { itemCount: value }
          )}`;
          break;
        case 'floor':
          if (typeof value === 'string') {
            valueFormatted.suffix += ``;
          }
          if (value !== 0) {
            valueFormatted.suffix += ` ${intl.formatMessage(
              { id: `format.${extra}` },
              { itemCount: value }
            )}`;
          }
          break;
        default:
          valueFormatted.suffix += `/${intl.formatMessage(
            { id: `format.${extra}` },
            { itemCount: value }
          )}`;
      }
    }
  });

  return destructured ? valueFormatted : Object.values(valueFormatted).join('');
};
