export const removeEmptyAndNullValuesFromObject = obj => {
  Object.keys(obj).forEach(k => (obj[k] === '' || obj[k] === null) && delete obj[k]);
  return obj;
};

export const removeEmptyAndNullAndUndefinedValuesFromObject = obj => {
  Object.keys(obj).forEach(
    k =>
      (obj[k] === '' ||
        obj[k] === null ||
        obj[k] === undefined ||
        (Array.isArray(obj[k]) && !obj[k].length) ||
        (Array.isArray(obj[k]) && obj[k][0] === null)) &&
      delete obj[k]
  );
  return obj;
};
