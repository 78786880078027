import { history } from '../../App';
import qs from 'query-string';
import { featureCollection as turfFeatureCollection, lineString } from '@turf/helpers';
import { default as turfBbox } from '@turf/bbox';
import { formatSortParams, parseSortParams } from '../../utils/sort';
import PATHS from '../../routes/paths';
import { initialState } from '../reducers/opportunities';
import { updateUser } from '../../actions/user';
import { arrayEquals, getValueFormatted } from '../../utils';
import { truncateCoords } from '../../utils/truncateCoords';
import { getCoordinatesFromGeojson, getCoordPairsFromArray } from '../../utils/geojson';
import { fetchUser } from '../../actions/user';
import { isFreePlan } from '../../utils/isFreePlan';
import PAYMENTS_PATHS from '../../Payments/paths';
import { openNotification } from '../../actions/notification';
import { goToProduct } from '../../actions/redirections';
import { DEFAULT_BBOX } from '../constants.js';
import OPPORTUNITIES_PATHS from '../paths';

export const initOpportunities = () => (dispatch, getState) => {
  const {
    filters,
    segments: segmentsFromLocation,
    map: mapFromLocation,
    sorting: sortingFromLocation
  } = getPropertiesFromLocation();
  const { segments: defaultSegments } = getState().opportunities.grid;
  const { map: defaultMap } = getState().opportunities.grid;

  const initSegments = { ...defaultSegments, ...segmentsFromLocation };
  const initMap = { ...defaultMap, ...mapFromLocation };

  Object.keys(filters).length && dispatch(setFilters(filters));
  Object.keys(initSegments).length && dispatch(setAllSegmentsFilter(initSegments));
  Object.keys(initMap).length && dispatch(setMapInfo(initMap));
  Object.keys(sortingFromLocation).length && dispatch(setListSort(sortingFromLocation));

  dispatch(fetchOpportunitiesFilterCollection());
  dispatch(loadOpportunities());

  const userBoundaries = getState().user.data?.restrictions?.opportunities?.boundaries;

  let boundariesSearch = [];

  if (initSegments?.province_id__in?.length) {
    boundariesSearch = initSegments.province_id__in;
  } else if (userBoundaries?.length && !userBoundaries.includes('all')) {
    boundariesSearch = userBoundaries;
  }

  if (boundariesSearch.length) dispatch(fetchProvincesBbox(boundariesSearch));
};

export const removePopupInfo = () => (dispatch, getState) => {
  const { popup } = getState().opportunities.grid.map;
  if (popup) {
    dispatch({
      type: 'REMOVE_POPUP_INFO',
      payload: null
    });
  }
};

export const validateWelcomeAndRedirect = welcomeData => async dispatch => {
  const action = await dispatch(
    updateUser({
      name: welcomeData.userName,
      account_use: welcomeData.pulseUse,
      working_for: welcomeData.companyName,
      company_size: welcomeData.companySize,
      knowledge: welcomeData.knowledge,
      investment: welcomeData.investment,
      opportunities: { boundaries: [welcomeData.provinceCode] },
      showNotification: false
    })
  );

  if (action.type === 'UPDATE_USER_SUCCESS' && !action.error) {
    await dispatch(fetchUser());

    dispatch(goToProduct('opportunities'));
  }
};

export const transformFiltersToParams = filters => {
  let result = {
    sorting: '-publishing_date'
  };

  Object.keys(filters).forEach(property => {
    let key = `filter::${property}`;
    if (property === 'province_id__in' || property === 'geom' || property === 'bbox') {
      key = property;
    }

    if (filters[property].length) {
      result[key] = filters[property].join(',');
    }
  });

  return qs.stringify(result, { encode: false });
};

export const fetchPopupInfo = id => (dispatch, getState) => {
  const { isUserDrawing } = getState().opportunities.grid.map;

  if (!isUserDrawing) {
    dispatch({
      type: 'FETCH_POPUP_INFO',
      payload: {
        request: {
          method: 'GET',
          url: `/opportunities/pulse/opportunities/${id}/popup/`
        }
      }
    });
  }
};

export const fetchAlerts = () => ({
  type: 'FETCH_ALERTS',
  payload: {
    request: {
      method: 'GET',
      url: `/opportunities/pulse/opportunities/alerts/`
    }
  }
});

export const deleteAlert = id => async dispatch => {
  const action = await dispatch({
    type: 'DELETE_ALERT',
    payload: {
      request: {
        method: 'DELETE',
        url: `/opportunities/pulse/opportunities/alerts/${id}/`
      }
    }
  });

  if (action.type === 'DELETE_ALERT_SUCCESS' && !action.error) {
    dispatch(fetchAlerts());
  }
};

export const changeAlertFrequency = (id, frequency) => async dispatch => {
  const action = await dispatch({
    type: 'CHANGE_ALERT_FREQUENCY',
    payload: {
      request: {
        method: 'PATCH',
        url: `/opportunities/pulse/opportunities/alerts/${id}/`,
        data: { frequency }
      }
    }
  });

  if (action.type === 'CHANGE_ALERT_FREQUENCY_SUCCESS' && !action.error) {
    dispatch(fetchAlerts());
  }
};

export const loadOpportunities =
  (appendOpportunities = false, page = 1) =>
  async (dispatch, getState) => {
    const { filtersApplied, segments } = getState().opportunities.grid;
    const { geom } = getState().opportunities.grid.map;
    const { sorting } = getState().opportunities.grid.list;

    const order = formatSortParams(sorting);

    const filters = {
      ...filtersApplied,
      ...segments
    };
    const params = {
      filters,
      geom,
      order,
      page,
      page_size: 32
    };

    dispatch(
      appendOpportunities ? fetchOpportunitiesListPage(params) : fetchOpportunitiesList(params)
    );
  };

export const fetchOpportunitiesList = ({ filters, geom, ...params }) => {
  return {
    type: 'FETCH_OPPORTUNITIES_LIST',
    payload: {
      request: {
        method: 'POST',
        url: 'opportunities/pulse/opportunities/',
        params: params,
        data: { filters, custom_geometry: geom }
      }
    }
  };
};

export const fetchOpportunitiesListPage = ({ filters, geom, ...params }) => {
  return {
    type: 'FETCH_OPPORTUNITIES_LIST_PAGE',
    payload: {
      request: {
        method: 'POST',
        url: 'opportunities/pulse/opportunities/',
        params: params,
        data: { filters, custom_geometry: geom }
      }
    }
  };
};

export const fetchOpportunitiesFilterCollection = () => {
  return {
    type: 'FETCH_OPPORTUNITIES_FILTER_COLLECTION',
    payload: {
      request: {
        method: 'GET',
        url: 'opportunities/pulse/opportunities/filters/'
      }
    }
  };
};

export const fetchProvincesAndNumOfAssets = () => {
  return {
    type: 'FETCH_PROVINCES_AND_NUM_OF_ASSETS',
    payload: {
      request: {
        method: 'GET',
        url: 'opportunities/pulse/opportunities/by-province/'
      }
    }
  };
};

export const setListSort = sorting => ({
  type: 'OPPORTUNITIES_LIST_SORT',
  payload: sorting
});

export const updateSort =
  (sorting, { key, value }) =>
  dispatch => {
    const newSort = Object.assign({}, sorting, { sort: key, order: value });
    Object.keys(sorting).length && dispatch(setListSort(newSort));

    dispatch(segmentsAndFiltersToHistory());
    dispatch(loadOpportunities());
  };

export const updatePage = page => dispatch => {
  dispatch(loadOpportunities(true, page));
};

export const setSegmentsFilter = (id, value) => ({
  type: 'OPPORTUNITIES_SEGMENTS_FILTER',
  payload: { id, value }
});

export const setAllSegmentsFilter = segments => ({
  type: 'OPPORTUNITIES_ALL_SEGMENTS_FILTER',
  payload: segments
});

export const onSegmentsFilterChange = (id, value) => (dispatch, getState) => {
  if (id === 'strategy') {
    const strategyFilters = getState()
      .opportunities.grid.filtersCollection.main.find(filter => filter.id === 'strategy')
      .filters[0].values.find(option => option.value === value[0]).filters;

    dispatch(setSegmentsFilter(id, value));
    dispatch(updateFilters(strategyFilters));
  } else if (id === 'province_id__in') {
    if (value.length) {
      const { geom } = getState().opportunities.grid.map;
      if (geom) dispatch(setMapInfo({ geom: null }));

      dispatch(fetchProvincesBbox(value));
    } else {
      dispatch(setMapInfo({ bbox: DEFAULT_BBOX }));
    }

    dispatch(setSegmentsFilter(id, value));
    dispatch(loadOpportunities());
  } else {
    dispatch(setSegmentsFilter(id, value));
    dispatch(loadOpportunities());
  }
};

export const fetchProvincesBbox = provinces => ({
  type: 'FETCH_PROVINCES_BBOX',
  payload: {
    request: {
      method: 'GET',
      url: `/opportunities/pulse/provinces/geometry?boundary_id=${provinces.join(',')}`
    }
  }
});

export const updateFiltersOpen = value => (dispatch, getState) => {
  const currentStatus = getState().opportunities.grid.isFiltersOpen;
  if (currentStatus !== value)
    dispatch({
      type: 'UPDATE_OPPORTUNITIES_FILTERS_OPEN',
      payload: value
    });
};

export const updateFilters = filters => dispatch => {
  dispatch(setFilters(filters));
  dispatch(loadOpportunities());
};

export const setFilters = filters => ({
  type: 'OPPORTUNITIES_FILTERS',
  payload: filters
});

export const segmentsAndFiltersToHistory = () => (dispatch, getState) => {
  const state = getState().opportunities;

  const stateFlatten = {
    ...initialState.grid.segments
  };

  const dataFlatten = {
    ...state.grid.segments
  };

  const defaultSort = formatSortParams(initialState.grid.list.sorting);
  const currentSort = formatSortParams(state.grid.list.sorting);

  const sorting = defaultSort !== currentSort ? { sorting: currentSort } : {};

  const includeBbox = !arrayEquals(initialState.grid.map.bbox, state.grid.map.bbox);
  const bbox = includeBbox ? { bbox: state.grid.map.bbox.join(',') } : {};
  const customGeom = state.grid.map.geom;

  const geom = customGeom ? { geom: customGeom.map(e => e.join(',')).join('::') } : {};

  let filters = {};

  Object.keys(state.grid.filtersApplied).forEach(
    property => (filters[`filter::${property}`] = state.grid.filtersApplied[property].join(','))
  );

  let params = Object.entries(dataFlatten).reduce(
    (params, [key, value]) =>
      Object.assign(
        params,
        stateFlatten.hasOwnProperty(key) && arrayEquals(stateFlatten[key], dataFlatten[key])
          ? {}
          : { [key]: value.join(',') }
      ),
    {}
  );

  params = {
    ...params,
    ...filters,
    ...bbox,
    ...geom,
    ...sorting
  };

  history.replace({
    pathname: PATHS.opportunities,
    search: qs.stringify(params, { encode: false })
  });
};

export const getPropertiesFromLocation = () => {
  const params = qs.parse(history.location.search);
  const segments = Object.keys(initialState.grid.segments || {});
  const map = Object.keys(initialState.grid.map || {});
  const sorting = Object.keys(initialState.grid.list.sorting || {});

  const result = {
    filters: {},
    segments: {},
    map: {},
    sorting: {}
  };

  for (let [key, val] of Object.entries(params)) {
    if (segments.includes(key)) {
      if (key === 'province_id__in') {
        result.segments[key] = val.split(',').map(val => Number(val));
      } else {
        result.segments[key] = [isNaN(val) ? val : Number(val)];
      }
    }

    if (map.includes(key)) {
      if (key === 'geom') {
        const allCoords = val.split('::').map(e => e.split(','));

        result.map[key] = [allCoords.map(e => getCoordPairsFromArray(e))];
      } else {
        result.map[key] = val.split(',').map(e => Number(e));
      }
    }

    if (key === 'sorting') {
      result.sorting = formatSortParams(sorting) === val ? {} : parseSortParams(val);
    }

    const match = key.match(/^filter::(.*)$/);
    if (match && match.length) {
      result.filters[match[1]] = val.split(',').map(value => {
        if (value === '') {
          return null;
        }
        return isNaN(value) ? value : Number(value);
      });
    }
  }

  return Object.keys(result).length ? result : {};
};

const getDetailUrl = (newTab, allTabs, url) => {
  const isRoot = !allTabs.some(i => url.includes(i));
  if (isRoot) {
    return `${url}/${newTab}`;
  } else {
    return newTab === 'summary'
      ? `/${url.substring(1, url.lastIndexOf('/'))}`
      : `/${url.substring(1, url.lastIndexOf('/'))}/${newTab}`;
  }
};

export const updateDetailTab = (value, id) => (dispatch, getState) => {
  const detail = getState().opportunities.detail;

  const { tab, allTabs } = detail;

  if (tab !== value) {
    const url = history.location.pathname;
    if (!url.includes(value)) {
      history.replace({
        pathname: getDetailUrl(value, allTabs, url)
      });
    }

    dispatch({
      type: 'UPDATE_OPPORTUNITIES_DETAIL_TAB',
      payload: { tab: value }
    });
  }

  if (!detail[value]) {
    dispatch(loadDetailTab(value, id));
  }
};

export const loadDetailTab = (value, id) => async dispatch => {
  const action = await dispatch(fetchOpportunitiesDetailTab(value, id));

  if (action.type === 'FETCH_OPPORTUNITIES_DETAIL_TAB_FAIL' && !action.error?.message?.cancelled) {
    action.error.meta.code === 429 &&
      dispatch(openNotification('error', { error: { ...action.error.meta }, type: action.type }));
    dispatch({
      type: 'UPDATE_OPPORTUNITIES_DETAIL_TAB',
      payload: { blocked: { display: true, type: action.error.meta.code } }
    });
  }
};

export const fetchOpportunitiesDetailTab = (value, id) => ({
  type: 'FETCH_OPPORTUNITIES_DETAIL_TAB',
  payload: {
    request: {
      method: 'GET',
      url: `/opportunities/pulse/opportunities/${id}/${value}/`
    }
  }
});

export const initOpportunitiesDetail = id => (dispatch, getState) => {
  const { tab, allTabs } = getState().opportunities.detail;

  const url = history.location.pathname;
  const currentTab = url.split('/').pop();
  const needUpdate = allTabs.includes(currentTab);

  dispatch(fetchOpportunitiesDetail(id));
  dispatch(updateDetailTab(needUpdate ? currentTab : tab, id));
};

export const fetchOpportunitiesDetail = id => ({
  type: 'FETCH_OPPORTUNITIES_DETAIL',
  payload: {
    request: {
      method: 'GET',
      url: `/opportunities/pulse/opportunities/${id}/`
      // url: 'https://run.mocky.io/v3/dbd3b061-5f6b-45fa-89e8-1f4c034eb3f4'
    }
  }
});

export const setMapBbox = bounds => {
  const bbox = truncateCoords(bounds);
  return setMapInfo({ bbox });
};

export const setMapInfo = value => ({
  type: 'SET_OPPORTUNITIES_MAP_INFO',
  payload: value
});

// export const saveFavorite = id => ({
//   type: 'SAVE_FAVORITE',
//   payload: {
//     request: {
//       method: 'POST',
//       url: 'https://run.mocky.io/v3/45e29b58-a706-4265-ae43-4dc2f13ca043'
//     }
//   }
// });

export const resetOpportunities = () => ({
  type: 'RESET_OPPORTUNITIES',
  payload: null
});

export const handleDrawStart = () => dispatch => {
  dispatch(updateFiltersOpen(false));
  dispatch(setMapInfo({ isUserDrawing: true }));
};

export const handleDrawEnd = geom => (dispatch, getState) => {
  const { segments, map } = getState().opportunities.grid;
  let customGeom = null;
  let { bbox } = getState().opportunities.grid.map;

  if (!map.geom && !geom.features.length) {
    return dispatch(setMapInfo({ isUserDrawing: false }));
  }

  if (geom.features.length) {
    customGeom = getCoordinatesFromGeojson(geom);
    bbox = turfBbox(turfFeatureCollection(geom.features));
  }

  if (segments.province_id__in.length && customGeom)
    dispatch(setSegmentsFilter('province_id__in', []));

  dispatch(setMapInfo({ isUserDrawing: false, geom: customGeom, bbox }));
  dispatch(loadOpportunities());
};

export const setOpportunitiesAssetLeft = id => (dispatch, getState) => {
  const isOpportunitiesFree = isFreePlan(getState().user.data.plans?.opportunities || '');
  if (isOpportunitiesFree) {
    const { max, remaining, visited } = getState().restrictions.opportunities?.assets || 0;

    if (max && visited) {
      if (visited.length < max && !visited.includes(id)) {
        return dispatch({
          type: 'SET_OPPORTUNITIES_ASSETS_RESTRICTIONS',
          payload: { remaining: remaining - 1, visited: [...visited, id] }
        });
      }
    }
  }
};

export const handleCountBannerClick = remaining => {
  // if (remaining === 0) {
  history.push(PAYMENTS_PATHS.base);
  // }
};

export const setOpportunitiesPayment = () => {
  history.push(PAYMENTS_PATHS.base);
};

export const toggleMapVisibility = () => (dispatch, getState) => {
  const { visible } = getState().opportunities.grid.map;

  dispatch(setMapInfo({ visible: !visible }));
};

export const fetchContactAdvisory = data => ({
  type: 'FETCH_CONTACT_ADVISORY',
  payload: {
    request: {
      method: 'POST',
      url: '/users/advisory-form/',
      data
    }
  }
});

export const fetchRequestAdvisoryEmail = data => ({
  type: 'FETCH_REQUEST_ADVISORY',
  payload: {
    request: {
      method: 'POST',
      url: '/users/enterprise-form/',
      data
    }
  }
});

export const fetchCreateAlert = data => ({
  type: 'FETCH_CREATE_ALERT',
  payload: {
    request: {
      method: 'POST',
      url: '/opportunities/pulse/opportunities/alerts/',
      data
    }
  }
});

export const createAlert = name => async (dispatch, getState) => {
  const {
    filtersApplied: filters,
    segments,
    map: { geom: custom_geometry }
  } = getState().opportunities.grid;

  const userFiltersApplied = {
    ...filters,
    province_id__in: segments.province_id__in
  };

  let bbox = null;
  if (custom_geometry && custom_geometry.length) {
    bbox = turfBbox(lineString(custom_geometry[0][0]));
  }

  const data = {
    name,
    filters: userFiltersApplied,
    ...(custom_geometry && { custom_geometry }),
    front_url: transformFiltersToParams({
      ...userFiltersApplied,
      ...(bbox && { bbox }),
      ...(custom_geometry && { geom: custom_geometry })
    })
  };

  dispatch(fetchCreateAlert(data));
};

export const createAlertSucceded = () => ({
  type: 'CREATE_ALERT_SUCCEDED',
  payload: null
});

export const getFiltersToAlert = (intl, lang, state) => {
  const { filtersApplied, segments, filtersCollection, map } = state;

  let userFilters = [];

  const userFiltersApplied = {
    ...filtersApplied,
    province_id__in: {
      selected: segments.province_id__in
    }
  };

  Object.keys(userFiltersApplied).forEach((filter, i) => {
    filtersCollection.main.forEach(({ filters, label }) => {
      filters.forEach(info => {
        if (info.kpi === filter) {
          const selected = filtersApplied[filter]
            ? filtersApplied[filter]
            : userFiltersApplied[filter].selected
            ? userFiltersApplied[filter].selected
            : filtersApplied[info.kpi];
          userFilters.push({
            label,
            selected,
            info
          });
        }
      });
    });
    filtersCollection.secondary.length &&
      filtersCollection.secondary[0].filters.forEach(({ filters, label }) => {
        filters.forEach(info => {
          if (info.kpi === filter) {
            const selected = userFiltersApplied[filter].selected
              ? userFiltersApplied[filter].selected
              : filtersApplied[info.kpi];
            userFilters.push({
              label,
              selected,
              info
            });
          }
        });
      });
    filtersCollection.secondary.length &&
      filtersCollection.secondary[1].filters.forEach(({ filters, label }) => {
        filters.forEach(info => {
          if (info.kpi === filter) {
            const selected = userFiltersApplied[filter].selected
              ? userFiltersApplied[filter].selected
              : filtersApplied[info.kpi];
            userFilters.push({
              label,
              selected,
              info
            });
          }
        });
      });
  });

  if (map && map.geom && map.geom.length) {
    userFilters.push({
      selected: ['geom'],
      info: { filter_type: 'geom' }
    });
  }

  return transformFiltersToString(intl, lang, userFilters);
};

export const transformFiltersToString = (intl, lang, data) => {
  return data
    .filter(e => e.selected.length)
    .map(filter => {
      const { label, selected, info } = filter;
      let value = '';
      let fullLabel = label;
      switch (info.filter_type) {
        case 'range':
          selected.forEach((s, i) => {
            const number = s === 0 || s === null ? info.values[i] : s;
            if (info.kpi === 'time_to_sale__range' || info.kpi === 'time_to_rent__range') {
              fullLabel = `${label} (${info.label})`;
            }
            if (info.kpi === 'floor__range') {
              value = `${selected[0]} - ${selected[1]}`;
            } else {
              value += `${i > 0 ? ' - ' : ''}${
                number === 0
                  ? 0
                  : getValueFormatted({ ...info.data, value: number }, intl, lang, false, false)
              }`;
            }
          });
          break;
        case 'multiselect':
          if (info.kpi === 'risk_sale__in' || info.kpi === 'risk_rent__in') {
            fullLabel = `${label} (${info.label})`;
          }

          if (selected.length !== 0) {
            //   value = intl.formatMessage({ id: `opportunities.alerts.all` });
            // } else {
            selected.forEach((s, i) => {
              value += `${info.values.find(v => v.value === s).label}${
                i <= selected.length - 2 ? ', ' : ''
              }`;
            });
          }
          break;
        case 'select':
          selected.forEach((s, i) => {
            value = intl.formatMessage({ id: `common.${s === 'true' ? 'yes' : 'no'}` });
          });
          break;
        case 'geom':
          fullLabel = intl.formatMessage({ id: 'opportunities.alerts.custom_geom_label' });
          value = intl.formatMessage({ id: 'opportunities.alerts.custom_geom' });
          break;
        default:
          break;
      }

      return { label: fullLabel, value };
    });
};

export const setUpgradeModalVisible = visible => ({
  type: 'SET_OPPORTUNITIES_UPGRADE_MODAL_VISIBLE',
  payload: visible
});

export const redirectToCard = (id, breakpoint) => (dispatch, getState) => {
  const isOpportunitiesFree = isFreePlan(getState().user.data.plans?.opportunities || '');
  const tabletOrDesktop = breakpoint === 'l' || breakpoint === 'xl' || breakpoint === 'xxl';
  const tab = breakpoint === 'xl' || breakpoint === 'xxl' ? '_blank' : '_self';

  if (isOpportunitiesFree) {
    dispatch(setOpportunitiesAssetLeft(id));
    const { remaining } = getState().restrictions.opportunities?.assets || 0;

    if (remaining > 0 || tabletOrDesktop) {
      window.open(`${OPPORTUNITIES_PATHS.base}/asset/${id}`, tab);
    } else {
      dispatch(setUpgradeModalVisible(true));
    }
  } else {
    window.open(`${OPPORTUNITIES_PATHS.base}/asset/${id}`, tab);
  }
};
