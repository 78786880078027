import { getNewRangeFilters } from '../../../../utils/filters';
import { getReversGeocodingAddress, getAutocompleteAddresses } from '../../../../utils/geocoding';
import { apiInternTable, apiInternFiltersApplied } from './apiIntern';
import { formatSelectedAssets, formatSelectedComparables, formatSelectedRows } from './intern';

export const initialState = {
  list: {
    assetId: null,
    isLoading: true,
    isLoadingPagination: false,
    initialColumnState: [],
    columnState: null,
    columns: [],
    rows: [],
    sorting: {},
    pagination: {}
  },
  filters: null,
  filtersByAnchor: [],
  idAnchor: null,
  defaultValues: {
    filtersApplied: null,
    map: null
  },
  filtersApplied: {},
  map: null,
  assetMapSelected: null,
  isLoadingAutocomplete: null,
  autocomplete: [],
  selectedAssets: {},
  defaultFiltersAsset: null
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'FETCH_COMPARABLES_LIST':
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true
        }
      };
    case 'FETCH_COMPARABLES_LIST_SUCCESS':
      const initialComparables = apiInternTable(payload.data);
      const comparables = formatSelectedComparables(initialComparables, state.selectedAssets);

      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          ...comparables
        }
      };
    case 'FETCH_COMPARABLES_LIST_FAIL':
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          initialColumnState: [],
          columnState: null,
          columns: [],
          rows: [],
          sorting: {},
          pagination: {}
        }
      };
    case 'COMPARABLES_LIST_SORT':
      return {
        ...state,
        list: {
          ...state.list,
          sorting: payload
        }
      };
    case 'SET_COMPARABLES_MAP':
      return {
        ...state,
        map: {
          ...state.map,
          ...payload
        },
        assetMapSelected: null
      };
    case 'SET_ASSET_MAP_SELECTED':
      return {
        ...state,
        assetMapSelected: {
          ...state.assetMapSelected,
          ...payload
        }
      };
    case 'RESET_ASSET_MAP_SELECTED':
      return {
        ...state,
        assetMapSelected: null
      };
    case 'FETCH_ASSET_MAP_SELECTED_SUCCESS':
      return {
        ...state,
        assetMapSelected: {
          ...state.assetMapSelected,
          address: getReversGeocodingAddress(payload.data)
        }
      };
    case 'FETCH_ASSET_MAP_SELECTED_FAIL':
      return {
        ...state,
        assetMapSelected: {
          ...state.assetMapSelected,
          address: null
        }
      };
    case 'FETCH_COMPARABLES_FILTERS_SUCCESS':
      state.defaultFiltersAsset = payload.data.filters_applied
      let filters;      
      let aux = state?.filtersByAnchor?.find((item) => item?.idAnchor === state?.idAnchor)
      if(aux){
        filters = aux.filters
      } else {
        filters = payload.data.filters_applied
      }

      const initialFiltersApplied = apiInternFiltersApplied(filters);
      return {
        ...state,
        filters: payload.data.filters,
        defaultValues: {
          filtersApplied: initialFiltersApplied,
          map: payload.data.map
        },
        filtersApplied: initialFiltersApplied,
        map: payload.data.map,
        selectedAssets: payload.data.selected_comparables || {}
      };
    case 'FETCH_AUTOCOMPLETE_MAP':
      return {
        ...state,
        assetMapSelected: null,
        isLoadingAutocomplete: true,
        autocomplete: []
      };
    case 'FETCH_AUTOCOMPLETE_MAP_SUCCESS':
      return {
        ...state,
        assetMapSelected: null,
        isLoadingAutocomplete: false,
        autocomplete: getAutocompleteAddresses(payload.data)
      };
    case 'FETCH_AUTOCOMPLETE_MAP_FAIL':
      return {
        ...state,
        assetMapSelected: null,
        isLoadingAutocomplete: false,
        autocomplete: []
      };
    case 'FETCH_GEOCODING_MAP_SUCCESS':
      return {
        ...state,
        map: {
          ...state.map,
          address: getReversGeocodingAddress(payload.data)
        },
        assetMapSelected: null
      };
    case 'COMPARABLES_RESET_FILTERS':
      return {
        ...state,
        filtersApplied: { ...state.defaultValues.filtersApplied },
        map: { ...state.defaultValues.map },
        assetMapSelected: null
      };
    case 'COMPARABLES_FILTERS':
      const rangeFilters = (state.filters?.main || [])
        .concat((state.filters?.secondary || []).flat())
        .filter(filter => filter.filter_type === 'range');

      const newFilters = getNewRangeFilters(payload, rangeFilters);

      return {
        ...state,
        filtersApplied: newFilters
      };

      case 'COMPARABLES_FILTERS_BY_ANCHOR':
        const rangeFiltersByAnchor = (state.filtersByAnchor?.main || [])
          .concat((state.filtersByAnchor?.secondary || []).flat())
          .filter(filter => filter.filter_type === 'range');


        const newFiltersByAnchor = getNewRangeFilters(payload.filters, rangeFiltersByAnchor);
        const existingIndex = state.filtersByAnchor.findIndex(item => item.idAnchor === payload.anchorId);

        let updatedArray = state.filtersByAnchor;
      
        if (existingIndex !== -1) {
          let newArray;
          newArray = state.filtersByAnchor.map((item, index) => {
            if (index === existingIndex) {
              return {
                ...item,
                filters: newFiltersByAnchor
              }
            } else {
              return item
            }
          });
          updatedArray = newArray
        } else {
          updatedArray.push({
            filters: newFiltersByAnchor,
            idAnchor: payload.anchorId
          });
        }
        
        return {
          ...state,
          filtersByAnchor: updatedArray
        };
        case 'SET_ANCHOR_ID':
        return {
          ...state,
          idAnchor: payload
        };
    case 'COMPARABLES_SET_SELECTED':
      return {
        ...state,
        list: {
          ...state.list,
          rows: formatSelectedRows(state.list.rows, payload)
        },
        selectedAssets: formatSelectedAssets(state.selectedAssets, payload)
      };
    case 'COMPARABLES_REMOVE_SELECTED':
      const smartValuations = { ...state.selectedAssets };
      const [current] = payload;
      delete smartValuations[current.id];

      return {
        ...state,
        list: {
          ...state.list,
          rows: formatSelectedRows(state.list.rows, payload)
        },
        selectedAssets: { ...smartValuations }
      };
    case 'COMPARABLES_RESET':
      const stateByAnchor = {
        ...initialState,
        filtersByAnchor: state.filtersByAnchor
      }
      return stateByAnchor;
    default:
      return state;
  }
};

export default reducer;
