export const initialState = {
  isScrollOnTop: true,
  isTableExpanded: false,
  isNavigationOpen: false
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'SET_IS_SCROLL_ON_TOP':
      return {
        ...state,
        isScrollOnTop: state.isTableExpanded ? false : payload
      };
    case 'SET_IS_TABLE_EXPANDED':
      return {
        ...state,
        isScrollOnTop: !payload,
        isTableExpanded: payload
      };
    case 'UPDATE_MOBILE_MENU':
      return {
        ...state,
        isNavigationOpen: payload
      };
    case 'UPDATE_MODAL':
      return {
        ...state,
        isNavigationOpen: false
      };
    default:
      return state;
  }
};

export default reducer;
