import { FOTOCASA_CONTACT_EMAIL, UDA_CONTACT_EMAIL } from '../constants.js';

const PATHS = {
  // legal: 'https://urbandataanalytics.com/legal/',
  assetCheckout: 'https://assetcheckout.webflow.io/checkout-newasset/asset-checkout-',
  asset: '/_asset/',
  smartValuation: '/asset',
  capture: '/capture',
  assetAccess: '/originalasset',
  base: '/',
  detail: '/detail',
  changePassword: '/change-password',
  createPassword: '/create-password',
  checkout: '/checkout',
  checkEmail: '/check-email',
  contactUs: `mailto:${UDA_CONTACT_EMAIL}`,
  contactUsFotocasa: `mailto:${FOTOCASA_CONTACT_EMAIL}`,
  error: '/error',
  pulseExtensionAvm: '/extension',
  pulseExtensionMatch: '/extension',
  extension: '/extension',
  iframe: '/iframe',
  legal: '/legal',
  market: '/market',
  opportunities: '/opportunities',
  portfolio: '/portfolio',
  portfolioDevelopmentSheet:
    '/portfolio/:portfolioId-:portfolioName/developments/:developmentId/sheet',
  privacyPolicy: 'https://urbandataanalytics.com/legal/website-privacy-and-cookies',
  resetPassword: '/reset-password',
  settings: '/settings',
  signIn: '/sign-in',
  signOut: '/sign-out',
  signUp: '/sign-up',
  support: `mailto:${UDA_CONTACT_EMAIL}`,
  supportFotocasa: `mailto:${FOTOCASA_CONTACT_EMAIL}`,
  termsOfUse: 'https://urbandataanalytics.com/legal/terms-of-use',
  validate: '/validate',
  verify: '/verify',
  demo_form: 'https://www.urbandataanalytics.com/formulario-demo'
};

export default PATHS;
