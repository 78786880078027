import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from '../../components/Select';
import MenuItem from '../../components/Select/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { TextLink } from '@uda/bento-components';
import { setLanguage } from '../../actions/user';
import PATHS from '../../routes/paths';
import useFlag from '../../utils/hooks/useFlag';
import { useHistory } from 'react-router-dom';

const StyledAuthFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 16px;

  ${({ isSignUpPage }) =>
    isSignUpPage
      ? css`
          padding-left: 80px;

          @media only screen and (max-height: 768px) {
            padding-left: 56px;
          }
        `
      : css`
          @media only screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 0;
          }
        `};

  > a {
    white-space: nowrap;
    display: inline-block;
    font-size: 12px;
  }

  > i {
    margin: 0 20px;
    color: ${props => props.theme.color.charcoal400};
  }

  @media only screen and (max-width: 768px) {
    width: 80%;
    left: 0;
    position: relative;
    bottom: 0;
    padding: 0;
    max-width: 440px;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 720px) {
    width: 80%;
    padding-bottom: 24px;
  }
`;

const AuthFooter = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.user.data.language);
  const intl = useIntl();
  const history = useHistory();

  const handleChange = langKey => {
    dispatch(setLanguage(langKey));
  };

  const isSignUpPage = () =>
    history.location.pathname === PATHS.signUp ||
    history.location.pathname.startsWith(PATHS.createPassword) ||
    history.location.pathname.startsWith(PATHS.checkEmail);

  const showFotocasaLogo = useFlag('show_fotocasa_logo');
  const fotocasaProvider = useFlag('fotocasa_provider');

  return (
    <StyledAuthFooter isSignUpPage={isSignUpPage()}>
      {!isSignUpPage() ? (
        <>
          <TextLink
            href={fotocasaProvider ? PATHS.contactUsFotocasa : PATHS.contactUs}
            variant={'secondary'}
            title={intl.formatMessage({ id: 'paths.contact_us' })}
          >
            <FormattedMessage id={'paths.contact_us'} />
          </TextLink>
          <i>|</i>
          <TextLink
            href={
              showFotocasaLogo ? PATHS.legal : intl.formatMessage({ id: 'legal.terms_of_use_url' })
            }
            title={intl.formatMessage({ id: 'paths.terms' })}
            className="secondary"
            target="_blank"
          >
            <FormattedMessage id="paths.terms" />
          </TextLink>
          <i>|</i>
          <TextLink
            href={
              showFotocasaLogo
                ? PATHS.legal
                : intl.formatMessage({ id: 'legal.privacy_policy_url' })
            }
            title={intl.formatMessage({ id: 'paths.privacy' })}
            className="secondary"
            target="_blank"
          >
            <FormattedMessage id="paths.privacy" />
          </TextLink>
          <i>|</i>
        </>
      ) : null}
      <Select
        label={intl.formatMessage({ id: 'languages.language' })}
        value={language}
        onChange={handleChange}
      >
        <MenuItem value="en">
          <FormattedMessage id={'languages.english'} />
        </MenuItem>
        <MenuItem value="es">
          <FormattedMessage id={'languages.spanish'} />
        </MenuItem>
        <MenuItem value="pt">
          <FormattedMessage id={'languages.portuguese'} />
        </MenuItem>
        <MenuItem value="it">
          <FormattedMessage id={'languages.italian'} />
        </MenuItem>
      </Select>
    </StyledAuthFooter>
  );
};

export default AuthFooter;
