export const initialState = {
  token: '',
  strapiToken: '',
  isAuthenticated: null,
  redirect: null,
  inputError: null,
  stripeKey: null,
  loginReady: false
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'FETCH_USER_SUCCESS':
      return {
        ...state,
        isAuthenticated: true
      };
    case 'RESET_USER':
      return {
        ...initialState,
        isAuthenticated: false
      };
    case 'SAVE_TOKEN':
      return {
        ...state,
        token: payload
      };
    case 'SAVE_STRAPI_TOKEN':
      return {
        ...state,
        strapiToken: payload
      };
    case 'SET_REDIRECT':
      return {
        ...state,
        redirect: payload
      };
    case 'LOG_OUT':
      return {
        ...initialState,
        isAuthenticated: false
      };
    case 'SET_AUTH_INPUT_ERROR':
      return {
        ...state,
        inputError: payload
      };
    case 'FETCH_STRIPE_KEY_SUCCESS':
      return {
        ...state,
        stripeKey: payload.data.publishable_key
      };
    case 'LOGIN_READY':
    return {
      ...state,
      loginReady: payload
    }
    default:
      return state;
  }
};

export default reducer;
