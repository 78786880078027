export const getAddressByLevels = (location, data = {}) => {
  let newLocation = { ...location };

  if (data.features && data.features.length) {
    const [main] = data.features;
    const [lon, lat] = data.query;
    const street = `${main.text} ${main.address || ''}`;

    let zipcode,
      municipality,
      province = '';

    main.context.forEach(level => {
      if (level.id?.includes('postcode')) zipcode = level.text;
      if (level.id?.includes('place')) municipality = level.text;
      if (level.id?.includes('region')) province = level.text;
    });

    newLocation = {
      ...location,
      street,
      lat,
      lon,
      zipcode,
      municipality,
      province
    };
  }

  return newLocation;
};
