import {
  internGrid,
  internTable,
  internAssetCardsList,
  internHeader,
  internTreeTable
} from './intern';
import { getMapStylesByDistribution } from '../actions/portfolio';
import { initialAnalyticsWidgets, initialAnalyticsFilters } from './analyticsIntern';
import { PORTFOLIO_TYPES } from '../../constants.js';
import { history } from '../../App';
import { concatRows } from '../../utils/concatRows';
import { DEFAULT_BBBOX } from '../constants.js';

// import mock from '../../__mocks__/portfolioReoTable';
// import mock from '../../__mocks__/portfolioNplTable';
// import mock from '../../__mocks__/portfolioAssetTable';

export const initialState = {
  list: {
    layout: 'grid',
    tab: 'all',
    search: '',
    filtersCollection: [],
    filtersApplied: {},
    stats: {},
    permission: null,
    grid: {
      isFiltersOpen: false,
      isSearchOpen: false,
      isLoading: false,
      isLoadingPagination: false,
      items: null,
      actions: {
        size: 'small'
      },
      sorting: {
        sort: 'year',
        order: 'asc'
      },
      pagination: {},
      sortable_fields: []
    },
    table: {
      isLoading: false,
      is_default_preference: null,
      isLoadingPagination: false,
      initialColumnState: null,
      preference_code: null,
      columnState: null,
      columns: [],
      rows: [],
      sorting: {
        sort: 'year',
        order: 'asc'
      },
      pagination: {}
    }
  },
  detail: {
    layout: 'grid',
    tab: null,
    portfolioSlug: null,
    isDevelopmentTabAvailable: false,
    portfolioId: null,
    download: {
      isDownloading: false
    },
    header: {
      isLoadingHeader: null,
      data: null
    },
    map: {
      bbox: DEFAULT_BBBOX,
      data: {},
      isLoading: false,
      visible: true,
      error: { isDisplayed: false, preference_code: null }
    },
    grid: {
      list: {
        isLoading: false,
        isLoadingPagination: false,
        items: null,
        sorting: {
          sort: 'assessment_price',
          order: 'desc'
        },
        pagination: {},
        sortable_fields: []
      }
    },
    table: {
      preference_code: null,
      is_default_preference: null,
      isLoading: false,
      isLoadingPagination: false,
      initialColumnState: null,
      columnState: null,
      columns: [],
      rows: [],
      sorting: {
        sort: 'asset.assessment_price',
        order: 'desc'
      },
      pagination: {}
    },
    contracts: {
      preference_code: null,
      is_default_preference: null,
      isLoading: false,
      isLoadingPagination: false,
      initialColumnState: null,
      columnState: null,
      columns: [],
      rows: [],
      sorting: {
        sort: 'outstanding_balance',
        order: 'desc'
      },
      pagination: {}
    },
    developments: {
      preference_code: null,
      is_default_preference: null,
      isLoading: false,
      isLoadingPagination: false,
      initialColumnState: null,
      columnState: null,
      columns: [],
      rows: [],
      sorting: {
        sort: 'group_id_valuation_type',
        order: 'desc'
      },
      filter: null,
      pagination: {}
    },
    analytics: {
      map: {
        bbox: null,
        distributions: [],
        boundary_types: [],
        filters: {},
        data: {},
        classes: [],
        isLoading: false,
        error: { isDisplayed: false, preference_code: null }
      },
      variant: 'value',
      widgets: {}
    }
  }
};

export const concatCards = (oldItems, newItems, pagination) => {
  if (oldItems === null) {
    return newItems;
  }
  return pagination.page === 1
    ? { items: newItems.items }
    : { items: oldItems.concat(newItems.items) };
};

const reducer = (state = initialState, { type, payload, meta, ...action }) => {
  const { layout: stateLayout } = state.list || {};
  let widgetId = null;

  switch (type) {
    case 'PORTFOLIO_CARDS_SORT':
      return {
        ...state,
        detail: {
          ...state.detail,
          grid: {
            ...state.detail.grid,
            list: {
              ...state.detail.grid.list,
              sorting: payload
            }
          }
        }
      };
    case 'SET_PORTFOLIO_MAP_INFO':
      return {
        ...state,
        detail: {
          ...state.detail,
          map: {
            ...state.detail.map,
            ...payload
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_CARDS':
      return {
        ...state,
        detail: {
          ...state.detail,
          grid: {
            ...state.detail.grid,
            list: {
              ...state.detail.grid.list,
              isLoading: true
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_CARDS_SUCCESS':
      const newCardsItems = internAssetCardsList(payload.data.assets);
      return {
        ...state,
        detail: {
          ...state.detail,
          grid: {
            ...state.detail.grid,
            list: {
              ...state.detail.grid.list,
              items: newCardsItems,
              pagination: payload.data.pagination,
              sortable_fields: payload.data.metadata.sortable_fields,
              isLoading: false
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_CARDS_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          grid: {
            ...state.detail.grid,
            list: {
              ...state.detail.grid.list,
              isLoading: false
            }
          }
        }
      };

    case 'UPDATE_PORTFOLIO_DETAIL_LAYOUT':
      return {
        ...state,
        detail: {
          ...state.detail,
          layout: payload
        }
      };
    case 'UPDATE_PORTFOLIO_LIST_TABLE_SETTINGS':
      return {
        ...state
      };
    case 'UPDATE_PORTFOLIO_DETAIL_ASSETS_SETTINGS_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            preference_code: payload.data ? payload.data.code : state.detail.table.preference_code,
            is_default_preference: payload.data ? payload.data.is_default : true
          }
        }
      };

    case 'UPDATE_PORTFOLIO_DETAIL_CONTRACTS_SETTINGS_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            preference_code: payload.data
              ? payload.data.code
              : state.detail.contracts.preference_code,
            is_default_preference: payload.data ? payload.data.is_default : true
          }
        }
      };
    case 'UPDATE_PORTFOLIO_LIST_TABLE_SETTINGS_SUCCESS':
      return {
        ...state,
        list: {
          ...state.list,
          table: {
            ...state.list.table,
            preference_code: payload.data ? payload.data.code : state.list.table.preference_code,
            is_default_preference: payload.data ? payload.data.is_default : true
          }
        }
      };

    case 'LOG_OUT':
      return {
        ...initialState
      };
    case 'UPDATE_PORTFOLIO_LIST_GRID_FILTERS':
      return {
        ...state,
        list: {
          ...state.list,
          grid: {
            ...state.list.grid,
            isFiltersOpen: payload
          }
        }
      };
    case 'UPDATE_PORTFOLIO_LIST_SEARCH':
      return {
        ...state,
        list: {
          ...state.list,
          grid: {
            ...state.list.grid,
            isSearchOpen: payload
          }
        }
      };
    case 'UPDATE_PORTFOLIO_DETAIL_TAB':
      return {
        ...state,
        detail: {
          ...state.detail,
          tab: payload
        }
      };
    case 'SET_PORTFOLIO_DETAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          portfolioSlug: payload
        }
      };
    case 'PORTFOLIO_WIDGET_TABLE_SORT':
      widgetId = payload.widgetId;
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            widgets: {
              ...state.detail.analytics.widgets,
              [widgetId]: {
                ...state.detail.analytics.widgets[widgetId],
                table: {
                  ...state.detail.analytics.widgets[widgetId].table,
                  sorting: payload.sorting
                }
              }
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_WIDGET':
      widgetId = payload.widgetId;

      if (state.detail.analytics.widgets[widgetId]) {
        return {
          ...state,
          detail: {
            ...state.detail,
            analytics: {
              ...state.detail.analytics,
              widgets: {
                ...state.detail.analytics.widgets,
                [widgetId]: {
                  ...state.detail.analytics.widgets[widgetId],
                  table: {
                    ...state.detail.analytics.widgets[widgetId].table,
                    isLoading: true
                  }
                }
              }
            }
          }
        };
      }
      return state;
    case 'FETCH_PORTFOLIO_DETAIL_WIDGET_SUCCESS':
      widgetId = meta.previousAction.payload.widgetId;
      if (state.detail.analytics.widgets[widgetId]) {
        return {
          ...state,
          detail: {
            ...state.detail,
            analytics: {
              ...state.detail.analytics,
              widgets: {
                ...state.detail.analytics.widgets,
                [widgetId]: {
                  ...state.detail.analytics.widgets[widgetId],
                  table: {
                    ...state.detail.analytics.widgets[widgetId].table,
                    ...internTreeTable(
                      payload.data,
                      state.detail.analytics.widgets[widgetId].table.sorting,
                      state.detail.analytics.map.filters.distribution
                    ),
                    isLoading: false
                  }
                }
              }
            }
          }
        };
      }
      return state;
    case 'FETCH_PORTFOLIO_DETAIL_WIDGET_FAIL':
      widgetId = meta.previousAction.payload.widgetId;
      if (state.detail.analytics.widgets[widgetId]) {
        return {
          ...state,
          detail: {
            ...state.detail,
            analytics: {
              ...state.detail.analytics,
              widgets: {
                ...state.detail.analytics.widgets,
                [widgetId]: {
                  ...state.detail.analytics.widgets[widgetId],
                  table: {
                    isLoading: false,
                    rows: [],
                    columns: []
                  }
                }
              }
            }
          }
        };
      }
      return state;
    case 'SET_PORTFOLIO_DETAIL_DOWNLOAD':
      return {
        ...state,
        detail: {
          ...state.detail,
          download: {
            isDownloading: payload.isDownloading
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_HEADER':
      return {
        ...state,
        detail: {
          ...state.detail,
          header: {
            ...state.detail.header,
            isLoadingHeader: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_HEADER_SUCCESS':
      const portfolioId = meta.previousAction.payload.portfolioId;
      const portfolio_type = payload.data.portfolio_type;
      const isDevelopmentTabAvailable = payload.data.is_development_tab_available;
      const isAnalyticsTabAvailable = payload.data.is_analytics_tab_available;
      const params = history.location.pathname.split('/');

      return {
        ...state,
        detail: {
          ...state.detail,
          tab:
            params.length === 4
              ? params[3]
              : PORTFOLIO_TYPES[portfolio_type] === PORTFOLIO_TYPES.NPL
              ? 'contracts'
              : 'assets',
          portfolioId,
          isDevelopmentTabAvailable,
          isAnalyticsTabAvailable,
          header: {
            data: internHeader(payload.data),
            isLoadingHeader: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_HEADER_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          header: { ...state.detail.header, isLoadingHeader: false, data: null }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_GENERATE_EXCEL':
      return {
        ...state,
        detail: {
          ...state.detail,
          header: {
            ...state.detail.header,
            excel: {
              ...state.detail.header.excel,
              isLoading: true
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_GENERATE_EXCEL_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          header: {
            ...state.detail.header,
            excel: {
              ...state.detail.header.excel,
              task_id: payload.data.task_id
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_GENERATE_EXCEL_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          header: {
            ...state.detail.header,
            excel: {
              ...state.detail.header.excel,
              isLoading: false
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_GENERATE_EXCEL_END':
      return {
        ...state,
        detail: {
          ...state.detail,
          header: {
            ...state.detail.header,
            excel: {
              ...state.detail.header.excel,
              isLoading: false
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_LIST':
      return {
        ...state,
        list: {
          ...state.list,
          [stateLayout]: {
            ...state.list[stateLayout],
            isLoading: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_LIST_SUCCESS':
      const { layout } = payload.config.params;
      const newItems =
        layout === 'grid'
          ? internGrid(payload.data.portfolios)
          : internTable(payload.data, state.list.table.sorting);

      return {
        ...state,
        list: {
          ...state.list,
          stats: payload.data.stats,
          [layout]: {
            ...state.list[layout],
            ...newItems,
            ...payload.data.metadata,
            isLoading: false,
            pagination: payload.data.pagination
          }
        }
      };
    case 'FETCH_PORTFOLIO_LIST_FAIL':
      return {
        ...state,
        list: {
          ...state.list,
          grid: {
            ...state.list.grid,
            isLoading: false
          },
          table: {
            ...state.list.table,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_LIST_TABLE_SETTINGS':
      return {
        ...state,
        list: {
          ...state.list,
          table: {
            ...state.list.table,
            isLoading: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_LIST_TABLE_SETTINGS_SUCCESS':
      const { value: columns, metadata } = payload.data;
      const portfolios = state.list.table.rows;
      return {
        ...state,
        list: {
          ...state.list,
          table: {
            ...state.list.table,
            ...metadata,
            columns: internTable({ columns, portfolios }, state.list.table.sorting).columns,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_LIST_TABLE_SETTINGS_FAIL':
      return {
        ...state,
        list: {
          ...state.list,
          table: {
            ...state.list.table,
            isLoading: false
          }
        }
      };
    case 'RESET_PORTFOLIO_LIST':
      return {
        ...state,
        list: initialState.list
      };
    case 'PORTFOLIO_LIST_FILTERS':
      return {
        ...state,
        list: {
          ...state.list,
          filtersApplied: payload
        }
      };
    case 'PORTFOLIO_LIST_TAB':
      return {
        ...state,
        list: {
          ...state.list,
          tab: payload
        }
      };
    case 'FETCH_PORTFOLIO_LIST_PAGE':
      return {
        ...state,
        list: {
          ...state.list,
          [stateLayout]: {
            ...state.list[stateLayout],
            isLoadingPagination: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_LIST_PAGE_SUCCESS':
      const { layout: layoutPage } = payload.config.params;
      const newItemsPage =
        layoutPage === 'grid'
          ? internGrid(payload.data.portfolios)
          : internTable(payload.data, state.list.table.sorting);

      const items =
        layoutPage === 'grid'
          ? concatCards(state.list.grid.items, newItemsPage, payload.data.pagination)
          : concatRows(state.list.table.rows, newItemsPage, payload.data.pagination);

      return {
        ...state,
        list: {
          ...state.list,
          [layoutPage]: {
            ...state.list[layoutPage],
            ...items,
            isLoadingPagination: false,
            pagination: payload.data.pagination
          }
        }
      };
    case 'FETCH_PORTFOLIO_LIST_PAGE_FAIL':
      const { layout: layoutPageName } = meta.previousAction.config.params;

      return {
        ...state,
        list: {
          ...state.list,
          [layoutPageName]: { ...state.list[layoutPageName], isLoadingPagination: false }
        }
      };
    case 'PORTFOLIO_LIST_ACTION':
      const { action, value: actionValue } = payload;
      return {
        ...state,
        list: {
          ...state.list,
          [stateLayout]: {
            ...state.list[stateLayout],
            actions: {
              ...state.list[stateLayout].actions,
              [action]: actionValue
            }
          }
        }
      };
    case 'PORTFOLIO_LIST_SEARCH':
      return {
        ...state,
        list: {
          ...state.list,
          search: payload
        }
      };
    case 'PORTFOLIO_LIST_ACTIONS':
      return {
        ...state,
        list: {
          ...state.list,
          [stateLayout]: {
            ...state.list[stateLayout],
            actions: {
              ...state.list[stateLayout].actions,
              ...payload
            }
          }
        }
      };
    case 'PORTFOLIO_LIST_SORT':
      return {
        ...state,
        list: {
          ...state.list,
          [stateLayout]: {
            ...state.list[stateLayout],
            sorting: {
              ...state.list[stateLayout].sorting,
              ...payload
            }
          }
        }
      };
    case 'PORTFOLIO_LIST_TABLE_COLUMNS':
      const { field, value: columnValue } = payload;
      return {
        ...state,
        list: {
          ...state.list,
          table: {
            ...state.list.table,
            [field]: columnValue
          }
        }
      };
    case 'PORTFOLIO_LIST_LAYOUT':
      return {
        ...state,
        list: {
          ...state.list,
          layout: payload
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS':
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            isLoading: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS':
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            isLoading: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            ...internTable(payload.data, state.detail.table.sorting),
            ...payload.data.metadata,
            isLoading: false,
            pagination: payload.data.pagination
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            ...internTable(payload.data, state.detail.developments.sorting),
            ...payload.data.metadata,
            isLoading: false,
            pagination: payload.data.pagination
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...initialState.detail.developments,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...initialState.detail.table,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_PAGE':
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            isLoadingPagination: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_PAGE':
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            isLoadingPagination: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_PAGE_SUCCESS':
      const smartValuations = internTable(payload.data, state.detail.table.sorting);
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            ...concatRows(state.detail.table.rows, smartValuations, payload.data.pagination),
            isLoadingPagination: false,
            pagination: payload.data.pagination
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_PAGE_SUCCESS':
      const newDevelopments = internTable(payload.data, state.detail.developments.sorting);
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            ...concatRows(state.detail.developments.rows, newDevelopments, payload.data.pagination),
            isLoadingPagination: false,
            pagination: payload.data.pagination
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_SETTINGS':
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            isLoading: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_SETTINGS':
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            isLoading: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_SETTINGS_SUCCESS':
      const { value: assetsColumns, assetsMetadata } = payload.data;
      const assets = state.detail.table.rows;
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            columns: internTable({ columns: assetsColumns, assets }, state.detail.table.sorting)
              .columns,
            ...assetsMetadata,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_SETTINGS_SUCCESS':
      const { value: developmentsColumns, developmentsMetadata } = payload.data;
      const developments = state.detail.developments.rows;
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            columns: internTable(
              { columns: developmentsColumns, developments },
              state.detail.developments.sorting
            ).columns,
            ...developmentsMetadata,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_SETTINGS_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_SETTINGS_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            isLoading: false
          }
        }
      };
    case 'PORTFOLIO_DETAIL_SORT':
      return {
        ...state,
        detail: {
          ...state.detail,
          table: {
            ...state.detail.table,
            sorting: {
              ...state.detail.table.sorting,
              ...payload
            }
          }
        }
      };
    case 'PORTFOLIO_DETAIL_DEVELOPMENTS_SORT':
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            sorting: {
              ...state.detail.developments.sorting,
              ...payload
            }
          }
        }
      };
    case 'PORTFOLIO_CONTRACTS_SORT':
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            sorting: {
              ...state.detail.contracts.sorting,
              ...payload
            }
          }
        }
      };
    case 'INIT_WIDGETS':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            widgets: {
              ...initialAnalyticsWidgets(
                state.detail.header.data.portfolio_type,
                state.detail.analytics.variant
              )
            }
          }
        }
      };
    case 'UPDATE_ANALYTICS_FILTERS':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            map: {
              ...state.detail.analytics.map,
              filters: {
                ...state.detail.analytics.map.filters,
                [payload.key]: payload.value
              }
            }
          }
        }
      };
    case 'INIT_ANALYTICS_FILTERS':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            map: {
              ...state.detail.analytics.map,
              ...initialAnalyticsFilters(
                state.detail.header.data.portfolio_type,
                state.detail.analytics.variant
              )
            }
          }
        }
      };
    case 'RESET_PORTFOLIO_ANALYTICS':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: initialState.detail.analytics
        }
      };
    case 'RESET_WIDGETS':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            widgets: {}
          }
        }
      };
    case 'SET_ANALYTICS_VARIANT':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            variant: payload,
            widgets: initialAnalyticsWidgets(state.detail.header.data.portfolio_type, payload)
          }
        }
      };
    case 'PORTFOLIO_DETAIL_TABLE_COLUMNS':
      const activeDetailTable =
        state.detail.tab === 'developments' || state.detail.tab === 'contracts'
          ? state.detail.tab
          : 'table';

      const { field: detailColumnField, value: detailColumnValue } = payload;
      return {
        ...state,
        detail: {
          ...state.detail,
          [activeDetailTable]: {
            ...state.detail[activeDetailTable],
            [detailColumnField]: detailColumnValue
          }
        }
      };
    case 'PORTFOLIO_DETAIL_CONTRACTS_COLUMNS':
      const { field: contractsColumnField, value: contractsColumnValue } = payload;
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            [contractsColumnField]: contractsColumnValue
          }
        }
      };
    case 'PORTFOLIO_DETAIL_RESET':
      return {
        ...state,
        detail: initialState.detail
      };
    case 'FETCH_PORTFOLIO_DETAIL_MAP':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            map: {
              ...state.detail.analytics.map,
              data: { ...state.detail.analytics.map.data, items: [] },
              isLoading: true
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_MAP_SUCCESS':
      const { stops, id: distribution, bbox, ...data } = payload.data;
      const styles = getMapStylesByDistribution({ stops, distribution });

      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            map: {
              ...state.detail.analytics.map,
              bbox: bbox,
              classes: styles.classes,
              colors: styles.colors,
              data: data
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_MAP_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            map: {
              ...state.detail.analytics.map,
              data: null,
              isLoading: false
            }
          }
        }
      };
    case 'SET_DETAIL_MAP':
      return {
        ...state,
        detail: {
          ...state.detail,
          analytics: {
            ...state.detail.analytics,
            map: {
              ...state.detail.analytics.map,
              ...payload
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_FILTERS_COLLECTION_SUCCESS':
      return {
        ...state,
        list: {
          ...state.list,
          filtersCollection: payload.data.portfolio_filters
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS':
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            isLoading: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            ...internTable(payload.data, state.detail.contracts.sorting),
            ...payload.data.metadata,
            isLoading: false,
            pagination: payload.data.pagination
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...initialState.detail.contracts,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_PAGE':
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            isLoadingPagination: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_CARDS_PAGE':
      return {
        ...state,
        detail: {
          ...state.detail,
          grid: {
            ...state.detail.grid,
            list: {
              ...state.detail.grid.list,
              isLoadingPagination: true
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_CARDS_PAGE_SUCCESS':
      const newCardItemsPage = { items: internAssetCardsList(payload.data.assets) };
      const allAssets = concatCards(
        state.detail.grid.list.items,
        newCardItemsPage,
        payload.data.pagination
      );

      return {
        ...state,
        detail: {
          ...state.detail,
          grid: {
            ...state.detail.grid,
            list: {
              ...state.detail.grid.list,
              ...allAssets,
              isLoadingPagination: false,
              pagination: payload.data.pagination
            }
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_PAGE_SUCCESS':
      const newContracts = internTable(payload.data, state.detail.contracts.sorting);

      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            ...concatRows(state.detail.contracts.rows, newContracts, payload.data.pagination),
            isLoadingPagination: false,
            pagination: payload.data.pagination
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_SETTINGS':
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            isLoading: true
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_SETTINGS_SUCCESS':
      const { value: contractsColumns, contractsMetadata } = payload.data;
      const contracts = state.detail.contracts.rows;
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            columns: internTable(
              { columns: contractsColumns, contracts },
              state.detail.contracts.sorting
            ).columns,
            ...contractsMetadata,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_SETTINGS_FAIL':
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            isLoading: false
          }
        }
      };
    case 'FETCH_PORTFOLIOS_PERMISSION_SUCCESS':
      return {
        ...state,
        list: {
          ...state.list,
          permission: payload.data.permission
        }
      };
    case 'PORTFOLIO_DETAIL_DEVELOPMENTS_FILTER':
      return {
        ...state,
        detail: {
          ...state.detail,
          developments: {
            ...state.detail.developments,
            filter: payload
          }
        }
      };
    default:
      return state;
  }
};

export default reducer;
