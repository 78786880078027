import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import PATHS from '../../routes/paths';
import { useDispatch } from 'react-redux';
import { openNotification } from '../../actions/notification';
import { useEffect } from 'react';
import qs from 'query-string';
import { resendCode } from '../actions/auth';

const Heading = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  @media only screen and (max-width: 720px) {
    font-size: 24px;
  }
`;
const Description = styled.p`
  margin-bottom: ${({ theme }) => theme.spacings.medium1};
`;

const LoginWrapper = styled.div`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-top: ${({ theme }) => theme.spacings.small4};
`;

const Separator = styled.i`
  position: relative;
  display: block;
  margin: ${({ theme }) => theme.spacings.medium1} 0;
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.charcoal600};
  text-align: center;
  top: 9px;

  > span {
    background-color: white;
    padding: 0 12px;
    position: relative;
    top: -9px;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.charcoal400};
  }
`;

const AuthCheckEmailContainer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { email } = qs.parse(history.location.search);

  useEffect(() => {
    dispatch(
      openNotification('success', {
        message: intl.formatMessage({ id: 'auth.check_email.notification' })
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!email) {
      history.push(PATHS.signUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleResend = () => {
    return dispatch(resendCode({ email }));
  };

  return (
    <div>
      <Heading>
        <FormattedMessage id="auth.check_email.title" />
      </Heading>
      <Description>
        <FormattedMessage id="auth.check_email.description" />
      </Description>
      <LoginWrapper>
        <FormattedMessage
          id="auth.check_email.noreceive"
          values={{
            link: (
              <Link
                onClick={handleResend}
                to={'#'}
                title={intl.formatMessage({ id: 'auth.check_email.resend' })}
                className="primary"
              >
                <FormattedMessage id="auth.check_email.resend" />
              </Link>
            )
          }}
        />
      </LoginWrapper>
      <Separator>
        <span>
          <FormattedMessage id="auth.sign_up.or" />
        </span>
      </Separator>
      <LoginWrapper>
        <FormattedMessage
          id="auth.sign_up.already"
          values={{
            link: (
              <Link
                to={PATHS.signIn}
                title={intl.formatMessage({ id: 'auth.sign_in.sign_in' })}
                className="primary"
              >
                <FormattedMessage id="auth.sign_in.sign_in" />
              </Link>
            )
          }}
        />
      </LoginWrapper>
    </div>
  );
};

export default AuthCheckEmailContainer;
