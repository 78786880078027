import styled from 'styled-components';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useIsScrollOnTop from '../../utils/hooks/useIsScrollOnTop';
import amex from '../../assets/images/cards/amex.svg';
import mastercard from '../../assets/images/cards/mastercard.svg';
import visa from '../../assets/images/cards/visa.svg';
import { getValueFormatted } from '../../utils';

const StyledContainer = styled.div`
  position: ${({ isScrollOnTop }) => (isScrollOnTop ? 'relative' : 'fixed')};
  top: ${({ isScrollOnTop }) => (isScrollOnTop ? '0' : '50px')};
  width: ${({ isScrollOnTop }) => (isScrollOnTop ? '100%' : '400px')};
  border: 1px solid ${({ theme }) => theme.color.charcoal300};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
  margin-bottom: ${({ theme }) => theme.spacings.medium1};
  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0;
    width: 100%;
  }
`;

const StyledSummary = styled.div`
  padding: ${({ theme }) => theme.spacings.medium1};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};

  h3:nth-child(3) {
    margin-top: ${({ theme }) => theme.spacings.small4};
  }
`;

const StyledParagraph = styled.p`
  ${({ theme }) => theme.texts.p1};
  color: ${({ theme }) => theme.color.charcoal600};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.h3`
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacings.small4};
`;

const StyledTotal = styled.p`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal800};
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  padding: ${({ theme }) => theme.spacings.small2} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledListItem = styled.li`
  padding: ${({ theme }) => theme.spacings.small1} 0;
  list-style: none;
`;

const displayCard = cardName => {
  switch (cardName) {
    case 'visa':
      return { url: visa, label: 'Visa' };
    case 'mastercard':
      return { url: mastercard, label: 'Master Card' };
    case 'amex':
      return { url: amex, label: 'American Express' };
    default:
      return null;
  }
};

const PaymentsSummaryOrder = ({ data }) => {
  const intl = useIntl();
  const lang = useSelector(state => state.user.data.language);
  const isScrollOnTop = useIsScrollOnTop(50);

  const { card, date, order, summary, total, type } = data;
  const summaryTotal = getValueFormatted({ ...total, decimals: 2 }, intl, lang, false, false);

  return (
    <StyledContainer isScrollOnTop={isScrollOnTop}>
      <StyledSummary>
        <StyledTitle>
          <FormattedMessage id="payments.order.payment_detail" />
        </StyledTitle>
        <ul>
          <StyledListItem>
            <StyledParagraph>
              <span>
                <FormattedMessage id="payments.summary.order_number" />
              </span>
              <span>{order}</span>
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              <span>
                <FormattedMessage id="payments.summary.order_date" />
              </span>
              <span>
                <FormattedDate value={new Date(date)} year="numeric" month="long" day="2-digit" />
              </span>
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              <span>
                <FormattedMessage id="payments.summary.payment_type" />
              </span>
              <span>
                <img height={'26px'} src={displayCard(type).url} alt={displayCard(type).label} />
              </span>
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              <span>
                <FormattedMessage id="payments.summary.card_number" />
              </span>
              <span>**** **** **** {card}</span>
            </StyledParagraph>
          </StyledListItem>
        </ul>
        <StyledTitle>
          <FormattedMessage id="payments.order.title" />
        </StyledTitle>
        <ul>
          {summary.map(({ label, price }, index) => (
            <StyledListItem key={index}>
              <StyledParagraph>
                <span>{label}</span>
                <span>
                  {getValueFormatted({ ...price, decimals: 2 }, intl, lang, false, false)}
                </span>
              </StyledParagraph>
            </StyledListItem>
          ))}
        </ul>
        <StyledTotal>
          <span>
            <FormattedMessage id="payments.order.total" />
          </span>
          <span>{summaryTotal}</span>
        </StyledTotal>
      </StyledSummary>
    </StyledContainer>
  );
};

export default PaymentsSummaryOrder;
