import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';

const filter = node => {
  return !(typeof node.className === 'string' && node.className.includes('snapshot-exclude'));
};

export const getSnapshot = (id, fileName) => {
  const node = document.getElementById(id);

  if (!node) return;

  htmlToImage.toCanvas(node, { filter: filter }).then(canvas => {
    canvas.toBlob(blob => {
      saveAs(blob, `${fileName ? fileName : id}.png`);
    });
  });
};
