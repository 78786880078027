import { useDispatch, useSelector } from 'react-redux';
import { resendCode, setInputError, verifyCode } from '../actions/auth';
import AuthVerifyAccountForm from '../forms/AuthVerifyAccountForm';
import qs from 'query-string';
import { closeNotification } from '../../actions/notification';
import { useEffect } from 'react';
import PATHS from '../../routes/paths';
import { useHistory } from 'react-router-dom';

const AuthVerifyAccountContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = qs.parse(history.location.search);
  const { inputError } = useSelector(state => state.auth);

  // @toDo llevar toda la logica a actions/auth en la carga inicial useEffect []
  useEffect(() => {
    if (!email) {
      history.push(PATHS.signUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleVerify = ({ code }) => {
    return dispatch(verifyCode({ code, email }));
  };

  const handleResend = () => {
    return dispatch(resendCode({ email }));
  };

  const clearError = () => {
    dispatch(setInputError(null));
    dispatch(closeNotification());
  };

  return (
    <AuthVerifyAccountForm
      verify={handleVerify}
      resend={handleResend}
      error={inputError}
      onClearError={clearError}
    />
  );
};

export default AuthVerifyAccountContainer;
