import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserProduct } from '../actions/user';

const BaseContainer = () => {
  const dispatch = useDispatch();
  const { products, default_product } = useSelector(state => state.user.data);

  useEffect(() => {
    dispatch(checkUserProduct(products, default_product));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, products]);

  return <></>;
};

export default BaseContainer;
