import SettingsActiveProvinces from './SettingsActiveProvinces';
import { IconSuccess, Button } from '@uda/bento-components';
import styled from 'styled-components';
import { getValueFormatted } from '../../utils';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import SettingsCancelSubscription from './SettingsCancelSubscription';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const StyledPlanHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.small2};
  text-transform: capitalize;
`;

const StyledSecondaryText = styled.p`
  color: ${({ theme }) => theme.color.charcoal600};
  font-weight: 500;
  font-size: 16px;
`;

const StyledPlanAndInvoiceWrapper = styled.div`
  margin-bottom: 28px;
`;
const StyledPrice = styled.p`
  color: ${({ theme }) => theme.color.charcoal800};
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
`;

const StyledFrequency = styled(props => <StyledSecondaryText {...props} />)`
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 0;
  text-align: center;
`;

const PlanWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.charcoal200};
  border: 1px solid ${({ theme }) => theme.color.charcoal300};
  border-radius: 4px;
  padding: 24px 32px;

  @media only screen and (max-width: 768px) {
    padding: ${({ theme }) => theme.spacings.small3};
  }
`;

const PlanContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 8px;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column-reverse;
  }
`;
const PriceContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    align-self: flex-start;
    padding-bottom: ${({ theme }) => theme.spacings.small4};
  }
`;
const StyledList = styled.ul`
  padding: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.color.charcoal600};
  margin: 0;
  list-style: none;
  > li {
    margin-bottom: ${({ theme }) => theme.spacings.small2};
    > svg {
      position: relative;
      top: -1px;
    }
  }
`;

const InvoicingData = styled.div`
  display: flex;
  justify-content: flex-end;
  > div {
    :first-child {
      margin-right: 24px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-flow: column;
    align-items: flex-end;
    margin-top: ${({ theme }) => theme.spacings.small3};
    line-height: 1.5;
    > div {
      :first-child {
        margin-right: 0;
      }
    }
  }
`;

const InvoiceDataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

const StyledInvoiceLabel = styled(props => <StyledSecondaryText {...props} />)`
  color: ${({ theme }) => theme.color.charcoal500};
  font-size: 12px;
  margin-right: 8px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const StyledInvoiceData = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  color: ${({ theme }) => theme.color.charcoal800};
`;
const StyledListItem = styled.li`
  display: flex;
  > span {
    margin-left: 10px;
  }
`;
const Separator = styled.i`
  position: relative;
  display: block;
  ${({ theme }) => theme.texts.p2b};
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  }
`;
const PriceFullContainer = styled.div`
  width: fit-content;
`;

const StyledButton = styled(props => <Button {...props} />)`
  margin-top: 40px;
  height: 40px;
  padding: 11px 13px;
`;

const SettingsPlanOpportunities = ({
  opportunities,
  onConfirmProvince = () => {},
  onCancelProvince = () => {},
  onCancelSubscriptionConfirmation = () => {},
  isLoading
}) => {
  const intl = useIntl();
  const lang = useSelector(state => state.user.data.language);
  const provinceValues = useSelector(state => state.opportunities.provincesAndAssets);

  const [isCancelOpen, setIsCancelOpen] = useState(false);

  const { details, payment, items } = opportunities;
  const { label, price, features } = details;
  const { date_next, amount } = payment;
  const { boundaries } = items;

  const handleCancelSubscription = () => {
    setIsCancelOpen(true);
  };

  const handleCancelClose = () => {
    setIsCancelOpen(false);
  };

  const formattedPrice = () =>
    getValueFormatted(
      {
        value: price.value,
        format: 'price',
        currency: price.currency,
        decimals: 2
      },
      intl,
      lang,
      false,
      true
    );

  const formattedNextAmount = () =>
    getValueFormatted(
      {
        value: amount.value,
        format: 'price',
        currency: price.currency,
        decimals: 2
      },
      intl,
      lang,
      false,
      false
    );

  const formattedDate = value => (
    <FormattedDate value={new Date(value)} year="numeric" month="long" day="2-digit" />
  );

  // const formattedFrequency = value =>
  //   getValueFormatted({ value: value, format: 'frequency' }, intl, lang, false, false);

  return (
    <>
      <StyledPlanAndInvoiceWrapper>
        <PlanWrapper>
          <StyledPlanHeading>{label}</StyledPlanHeading>

          <PlanContainer>
            <StyledList>
              {features.map((feature, index) => (
                <StyledListItem key={index}>
                  <IconSuccess color="primary" /> <span>{feature.label}</span>
                </StyledListItem>
              ))}
            </StyledList>
            <PriceWrapper>
              <PriceFullContainer>
                <PriceContainer>
                  <StyledPrice>{formattedPrice().value}</StyledPrice>
                  <StyledSecondaryText>{formattedPrice().suffix}</StyledSecondaryText>
                </PriceContainer>
                <StyledFrequency>
                  <FormattedMessage id="format.monthly" />
                </StyledFrequency>
              </PriceFullContainer>
            </PriceWrapper>
          </PlanContainer>
        </PlanWrapper>

        <InvoicingData>
          <InvoiceDataWrapper>
            <StyledInvoiceLabel>
              <FormattedMessage id="settings.plan.next_invoice" />
            </StyledInvoiceLabel>
            <StyledInvoiceData>{formattedNextAmount()}</StyledInvoiceData>
          </InvoiceDataWrapper>
          <InvoiceDataWrapper>
            <StyledInvoiceLabel>
              <FormattedMessage id="settings.plan.issue_date" />
            </StyledInvoiceLabel>
            <StyledInvoiceData>{formattedDate(date_next.value)}</StyledInvoiceData>
          </InvoiceDataWrapper>
        </InvoicingData>
      </StyledPlanAndInvoiceWrapper>

      <div>
        <SettingsActiveProvinces
          activeProvinces={boundaries}
          onConfirmProvince={value => onConfirmProvince(value)}
          onCancelProvince={value => onCancelProvince(value)}
          provinceValues={provinceValues}
          isLoading={isLoading}
        />
      </div>

      <Separator />

      <StyledButton
        variant="dangerSecondary"
        value="cancelAccount"
        onClick={e => handleCancelSubscription(e)}
      >
        <FormattedMessage id="settings.plan.cancel" />
      </StyledButton>

      <SettingsCancelSubscription
        isOpen={isCancelOpen}
        onSubmit={onCancelSubscriptionConfirmation}
        onClose={handleCancelClose}
        isLoading={isLoading}
      />
    </>
  );
};

export default SettingsPlanOpportunities;
