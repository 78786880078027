import { useDispatch } from 'react-redux';
import AuthSignUpForm from '../forms/AuthSignUpForm';
import { handleGoogleSuccess, signUp } from '../actions/auth';
import { closeNotification, openNotification } from '../../actions/notification';
import { useLocation } from 'react-router-dom';
import { getQueryParam } from '../../utils';
import useFlag from '../../utils/hooks/useFlag';
import { PRODUCT } from '../../constants.js';

const DEFAULT_PRODUCT = PRODUCT['smart-valuation'];

const AuthSignUpContainer = () => {
  const location = useLocation();
  let product = getQueryParam(location, 'product', DEFAULT_PRODUCT); // send in request

  const defaultProductOpportunities = useFlag('default_product_opportunities');
  if (defaultProductOpportunities) {
    product = PRODUCT.opportunities;
  }
  const dispatch = useDispatch();

  const handleSignUp = ({ email }) => dispatch(signUp({ email, product }));

  const handleGoogleError = ({ error, details }) => {
    dispatch(openNotification('error', { message: details || `auth.sso.${error}` }));
  };

  const onGoogleSuccess = success =>
    dispatch(handleGoogleSuccess({ ...success, type: 'sign-up', product }));

  const handleCloseNotification = () => {
    dispatch(closeNotification());
  };

  return (
    <AuthSignUpForm
      signUp={handleSignUp}
      handleGoogleSuccess={onGoogleSuccess}
      handleGoogleError={handleGoogleError}
      closeNotification={handleCloseNotification}
    />
  );
};

export default AuthSignUpContainer;
