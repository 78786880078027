import useFlag from '../utils/hooks/useFlag';

const FeatureFlag = ({ name, children, render = children }) => {
  const hasFeature = useFlag(name);
  if (typeof children === 'function') {
    return render(hasFeature);
  }

  if (!hasFeature) {
    return null;
  }

  return render;
};

export default FeatureFlag;
