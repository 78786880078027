const PORTFOLIO_PATHS = {
  base: '/portfolio',
  baseId: '/portfolio/:portfolioId-:portfolioName',
  detail: '/portfolio/detail/:id',
  detailSection: '/portfolio/:portfolioId-:portfolioName/:section',
  analytics: '/portfolio/:portfolioId-:portfolioName/analytics',
  assets: '/portfolio/:portfolioId-:portfolioName/assets',
  developments: '/portfolio/:portfolioId-:portfolioName/developments',
  development:
    '/portfolio/:portfolioId-:portfolioName/developments/:developmentId/:developmentTab?',
  contracts: '/portfolio/:portfolioId-:portfolioName/contracts',
  asset_search: '/portfolio/:assetId/asset',
  comparables: '/portfolio/:assetId/comparables'
};

export default PORTFOLIO_PATHS;
