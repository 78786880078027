import { Skeleton } from '@uda/bento-components';
import styled from 'styled-components';

const StyledSkeleton = styled(props => <Skeleton {...props} />)`
  margin-bottom: ${({ theme }) => theme.spacings.small2};
`;

const StyledSeparator = styled.i`
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  margin: ${({ theme }) => theme.spacings.medium1} 0;
  display: block;
`;

const StyledContent = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  padding: ${({ theme }) => theme.spacings.small3};
  border: 1px solid ${({ theme }) => theme.color.charcoal400};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
  max-width: 400px;
`;

const PaymentsCheckoutBillingSkeleton = () => (
  <div>
    <div>
      <StyledSkeleton variant="text" height="14px" width="185px" />
      <StyledContent>
        <Skeleton variant="text" height="16px" width="185px" />
      </StyledContent>
    </div>
    <div>
      <StyledSkeleton variant="text" height="14px" width="80px" />
      <StyledContent style={{ width: 140 }}>
        <Skeleton variant="text" height="16px" />
      </StyledContent>
    </div>
    <StyledSeparator />
    <div>
      <StyledSkeleton variant="text" height="14px" width="185px" />
      <StyledContent>
        <Skeleton variant="text" height="16px" width="185px" />
      </StyledContent>
    </div>
    <div>
      <StyledSkeleton variant="text" height="14px" width="185px" />
      <StyledContent>
        <Skeleton variant="text" height="16px" width="185px" />
      </StyledContent>
    </div>
    <div>
      <StyledSkeleton variant="text" height="14px" width="185px" />
      <StyledContent>
        <Skeleton variant="text" height="16px" width="185px" />
      </StyledContent>
    </div>
    <div>
      <StyledSkeleton variant="text" height="14px" width="40px" />
      <StyledContent style={{ width: 100 }}>
        <Skeleton variant="text" height="16px" />
      </StyledContent>
    </div>
  </div>
);

export default PaymentsCheckoutBillingSkeleton;
