import { useState, useEffect, useRef, forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  Dropdown,
  IconArrowOpen,
  IconArrowClose,
  IconDot,
  List,
  ListItem,
  InputField
} from '@uda/bento-components';
import { getValueFormatted } from '../utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const StyledDropdownWrapper = styled(props => <Dropdown {...props} />)`
  width: 100%;
`;
const StyledLabel = styled.h5`
  color: ${({ theme }) => theme.color.charcoal600};
  margin-right: ${({ theme }) => theme.spacings.small3};
  display: inline-block;
  text-transform: uppercase;
`;

const StyledDropdown = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 21px;
  background-color: ${({ theme, isDropdownOpen }) =>
    isDropdownOpen ? `${theme.color.primary100} !important` : 'transparent'};
  &:hover {
    background-color: ${({ theme }) => theme.color.charcoal300};
  }
  border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  border-bottom: 1px solid ${({ theme }) => theme.color.charcoal300};
`;

const StyledSelectedListItem = styled(({ component, ...props }) => {
  const { active } = props;
  return <ListItem as={component} {...props} rightContent={active ? <IconDot /> : null} />;
})``;

const StyledValueLabel = styled.span`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme, isDropdownOpen }) =>
    isDropdownOpen ? theme.color.primary500 : theme.color.charcoal700};
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  svg {
    margin-left: ${({ theme }) => theme.spacings.small3};
  }
`;
const StyledValueLabelText = styled.span`
  width: 75%;
  ${({ theme }) => theme.texts.p1b};
  color: ${({ count, theme }) => (count === 0 ? theme.color.charcoal500 : theme.color.charcoal700)};
`;

const StyledChildrenContainer = styled.div`
  width: 300px;
`;

const StyledListResult = styled.div`
  max-height: 216px;
  overflow-y: auto;
`;

const StyledAssetsLabel = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.color.charcoal500};
  > span {
    margin-left: 5px;
  }
`;

const ForwardInputField = forwardRef((props, ref) => (
  <InputField ref={ref} {...props}></InputField>
));

const StyledSearch = styled(ForwardInputField)`
  margin-bottom: 8px;
  border-radius: 8px;
`;

const ProvincesDropdown = ({
  selectedProvince,
  handleChange,
  provinceValues,
  provincesToClean,
  zIndex
}) => {
  const intl = useIntl();
  const lang = useSelector(state => state.user.data.language);
  const theme = useTheme();
  const provinceInput = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownSearch, setDropdownSearch] = useState('');
  const [provinceList, setProvinceList] = useState([]);

  const onProvinceChange = option => {
    handleChange(option);
  };

  useEffect(() => {
    if (provinceValues.length > 0) {
      if (provincesToClean) {
        return setProvinceList(
          provinceValues.filter(option => !provincesToClean.includes(option.value))
        );
      }
      return setProvinceList(provinceValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      provinceInput.current.focus();
    }
  }, [isDropdownOpen]);

  const formattedNumber = value => {
    return getValueFormatted(
      {
        value: value,
        format: 'number'
      },
      intl,
      lang,
      false,
      false
    );
  };

  const getProvinceLabel = () => {
    if (!selectedProvince) {
      return intl.formatMessage({ id: 'auth.sign_up.welcome.select_province' });
    }

    const selected = provinceValues.find(province => province.value === selectedProvince);
    return selected ? selected.label : '';
  };

  const provinceLabel = getProvinceLabel();

  return (
    <StyledDropdownWrapper
      zIndex={zIndex}
      onChange={setIsDropdownOpen}
      label={
        <StyledDropdown isDropdownOpen={isDropdownOpen}>
          <StyledLabel>
            <FormattedMessage id="auth.sign_up.welcome.province" />
          </StyledLabel>
          <StyledValueLabel isDropdownOpen={isDropdownOpen}>
            <StyledValueLabelText count={selectedProvince ? 1 : 0}>
              {provinceLabel}
            </StyledValueLabelText>
            {isDropdownOpen ? (
              <IconArrowOpen customColor={theme.color.charcoal500} />
            ) : (
              <IconArrowClose customColor={theme.color.charcoal500} />
            )}
          </StyledValueLabel>
        </StyledDropdown>
      }
    >
      <StyledChildrenContainer>
        <StyledListResult>
          <StyledSearch
            value={dropdownSearch}
            onChange={evt => setDropdownSearch(evt.target.value)}
            placeholder={intl.formatMessage({ id: 'auth.sign_up.welcome.search' })}
            ref={provinceInput}
          />
          <List>
            {provinceList
              .filter(option => {
                if (!dropdownSearch) {
                  return true;
                }
                return option.label.toLowerCase().indexOf(dropdownSearch.toLowerCase()) !== -1;
              })
              .map(option => (
                <StyledSelectedListItem
                  key={option.value}
                  active={selectedProvince === option.value}
                  onClick={() => onProvinceChange(option.value)}
                  disabled={option.disabled}
                >
                  {option.label}
                  <StyledAssetsLabel>
                    {formattedNumber(option.asset_count)}
                    <span>
                      <FormattedMessage id="auth.sign_up.welcome.opportunities" />
                    </span>
                  </StyledAssetsLabel>
                </StyledSelectedListItem>
              ))}
          </List>
        </StyledListResult>
      </StyledChildrenContainer>
    </StyledDropdownWrapper>
  );
};

export default ProvincesDropdown;
