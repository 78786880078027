export const initialState = {
  isOpen: false,
  variant: 'success',
  showIcon: true,
  closable: true,
  message: null,
  icon: null
};

const reducer = (state = initialState, { type, payload, meta, ...action }) => {
  switch (type) {
    // Notification
    case 'OPEN_NOTIFICATION':
    case 'UPDATE_NOTIFICATION':
      return {
        ...state,
        ...payload
      };
    case 'CLOSE_NOTIFICATION':
      return {
        ...initialState,
        isOpen: false
      };
    case 'RESET_NOTIFICATION':
      return {
        ...initialState
      };
    // Auth
    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        ...showSuccessNotification(`auth.change_password.success`)
      };
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        ...showSuccessNotification(`auth.reset_password.success`)
      };
    // Settings
    case 'UPDATE_USER_SUCCESS':
      const showNotification = meta.previousAction.payload.showNotification;

      if (showNotification) {
        return {
          ...state,
          ...showSuccessNotification(`settings.success`)
        };
      }
      return {
        ...state
      };
    case 'FETCH_REQUEST_ADVISORY':
      return {
        ...state
      };
    case 'RESEND_CODE':
      return {
        ...state,
        ...showSuccessNotification(`auth.verify.resend_success`)
      };
    case 'ADD_PROVINCE_TO_PLAN_SUCCESS':
      return {
        ...state,
        ...showSuccessNotification(`settings.plan.add_province_success`)
      };
    case 'REMOVE_PROVINCE_TO_PLAN_SUCCESS':
      return {
        ...state,
        ...showSuccessNotification(`settings.plan.remove_province_success`)
      };
    case 'UPDATE_PAYMENT_METHOD_SUCCESS':
      return {
        ...state,
        ...showSuccessNotification(`settings.payment.success`)
      };
    case 'UPDATE_BILLING_INFO_SUCCESS':
      return {
        ...state,
        ...showSuccessNotification(`settings.billing.success`)
      };
    case 'CANCEL_SUBSCRIPTION_FAIL':
    case 'FETCH_CONTACT_ADVISORY_FAIL':
    case 'FETCH_REQUEST_ADVISORY_FAIL':
    case 'UPDATE_USER_FAIL':
    case 'RESET_PASSWORD_FAIL':
    case 'CHANGE_PASSWORD_FAIL':
    case 'FETCH_USER_FAIL_FAIL':
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_FAIL':
    case 'FETCH_PORTFOLIO_DETAIL_ASSETS_PAGE_FAIL':
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_FAIL':
    case 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_PAGE_FAIL':
    case 'FETCH_PORTFOLIO_LIST_FAIL':
    case 'FETCH_PORTFOLIO_LIST_PAGE_FAIL':
    case 'FETCH_PORTFOLIO_DETAIL_WIDGET_FAIL':
    case 'FETCH_PORTFOLIO_DETAIL_HEADER_FAIL':
    case 'FETCH_PORTFOLIO_DETAIL_MAP_FAIL':
    case 'FETCH_PORTFOLIO_FILTERS_COLLECTION_FAIL':
    case 'FETCH_INDICATORS_FAIL':
    case 'FETCH_INDICATORS_INFO_FAIL':
    case 'FETCH_OPPORTUNITIES_LIST_FAIL':
    case 'FETCH_OPPORTUNITIES_LIST_PAGE_FAIL':
    case 'FETCH_OPPORTUNITIES_FILTER_COLLECTION_FAIL':
    case 'FETCH_OPPORTUNITIES_DETAIL_FAIL':
    case 'ADD_PROVINCE_TO_PLAN_FAIL':
    case 'REMOVE_PROVINCE_TO_PLAN_FAIL':
    case 'FETCH_BILLING_INFO_FAIL':
    case 'UPDATE_BILLING_INFO_FAIL':
    case 'FETCH_POPUP_INFO_FAIL':
    case 'FETCH_PROVINCES_AND_NUM_OF_ASSETS_FAIL':
    case 'FETCH_PLANS_FAIL':
    case 'FETCH_DEVELOPMENT_DETAIL_FAIL':
    case 'FETCH_DEVELOPMENT_ANCHORS_DATA_FAIL':
    case 'FETCH_DEVELOPMENT_LOCATION_RISK_FAIL':
    case 'FETCH_SAVE_NEW_COMPARABLES_FAIL':
      if (action.error.message && action.error.message.cancelled === true) {
        return state;
      }
      return {
        ...state,
        ...showErrorNotification({ ...action.error.meta, action: type })
      };
    case 'SIGN_IN_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default reducer;

function showSuccessNotification(message, duration = 6000) {
  return {
    isOpen: true,
    variant: 'success',
    message,
    duration,
    error: null
  };
}

function showErrorNotification(error) {
  return {
    isOpen: true,
    variant: 'error',
    error
  };
}
