import { apiInternAnchors } from './apiIntern';
import { apiInternTable, getNewTableValues, getWeightTableValues } from './intern';

export const initialState = {
  comparablesModalOpen: false,
  anchors: null,
  isLoadingAnchors: false,
  isLoadingAddForm: false,
  isAddFormOpen: false,
  addForm: null,
  errorOnSave: false
};

const reducer = (state = initialState, { type, payload, meta, ...action }) => {
  let anchorId = null;

  switch (type) {
    case 'SET_COMPARABLES_MODAL_OPEN':
      return { ...state, comparablesModalOpen: payload };
    case 'FETCH_ANCHOR_COMPARABLES_LIST':
      anchorId = payload.anchorId;

      return {
        ...state,
        anchors: {
          ...state.anchors,
          [anchorId]: {
            ...state.anchors[anchorId],
            table: {
              ...state.anchors[anchorId].table,
              isTableLoading: true,
              sorting: {}
            }
          }
        }
      };
    case 'FETCH_ANCHOR_COMPARABLES_LIST_SUCCESS':
      anchorId = meta.previousAction.payload.anchorId;
      return {
        ...state,
        anchors: {
          ...state.anchors,
          [anchorId]: {
            ...state.anchors[anchorId],
            table: {
              ...state.anchors[anchorId].table,
              isTableLoading: false,
              ...apiInternTable(payload.data)
            },
            selectedOperation: 'comparables_sale'
          }
        }
      };
    case 'SET_ANCHOR_SELECTED_OPERATION':
      return {
        ...state,
        anchors: {
          ...state.anchors,
          [payload.id]: {
            ...state.anchors[payload.id],
            selectedOperation: payload.operation
          }
        }
      };
    case 'SET_ASSET_NEW_CELL_VALUES':
      return {
        ...state,
        anchors: {
          ...state.anchors,
          [payload.id]: {
            ...state.anchors[payload.id],
            table: {
              ...state.anchors[payload.id].table,
              [state.anchors[payload.id].selectedOperation]: getNewTableValues(
                state.anchors[payload.id].table[state.anchors[payload.id].selectedOperation],
                payload.data
              )
            }
          }
        }
      };
    case 'SET_WEIGHT_VALUES':
      return {
        ...state,
        anchors: {
          ...state.anchors,
          [payload.id]: {
            ...state.anchors[payload.id],
            table: {
              ...state.anchors[payload.id].table,
              [state.anchors[payload.id].selectedOperation]: getWeightTableValues(
                state.anchors[payload.id].table[state.anchors[payload.id].selectedOperation],
                payload.data
              )
            }
          }
        }
      };
    case 'FETCH_DEVELOPMENT_ANCHORS_DATA':
      return {
        ...state,
        isLoadingAnchors: true
      };
    case 'FETCH_DEVELOPMENT_ANCHORS_DATA_SUCCESS':
      return {
        ...state,
        isLoadingAnchors: false,
        anchors: apiInternAnchors(payload.data)
      };
    case 'FETCH_ANCHOR_SELECTED_VALUE':
      const anchorIdSelected = payload.request.data.anchorId;

      return {
        ...state,
        anchors: {
          ...state.anchors,
          [anchorIdSelected]: {
            ...state.anchors[anchorIdSelected],
            isLoadingSelectedValue: true
          }
        }
      };
    case 'FETCH_ANCHOR_SELECTED_VALUE_SUCCESS':
      anchorId = JSON.parse(payload.config.data).anchorId;

      return {
        ...state,
        anchors: {
          ...state.anchors,
          [anchorId]: {
            ...state.anchors[anchorId],
            isLoadingSelectedValue: false,
            selectedValues: {
              ...payload.data
            }
          }
        }
      };
    case 'DEVELOPMENT_COMPARABLES_SORT':
      return {
        ...state,
        anchors: {
          ...state.anchors,
          [payload.anchorId]: {
            ...state.anchors[payload.anchorId],
            table: {
              ...state.anchors[payload.anchorId].table,
              sorting: payload.sorting
            }
          }
        }
      };
    case 'RESET_DEVELOPMENT_MARKET':
      return {
        ...initialState
      };
    case 'SET_SELECTED_CORRECTED_VALUE':
      return {
        ...state,
        anchors: {
          ...state.anchors,
          [payload.anchorId]: {
            ...state.anchors[payload.anchorId],
            selectedValues: {
              ...state.anchors[payload.anchorId].selectedValues,
              [payload.operation]: { selected: payload.value }
            }
          }
        }
      };
    case 'FETCH_COMPARABLES_ADD_FORM':
      return {
        ...state,
        isLoadingAddForm: true
      };
    case 'FETCH_COMPARABLES_ADD_FORM_SUCCESS':
      return {
        ...state,
        addForm: payload.data,
        isAddFormOpen: true,
        isLoadingAddForm: false
      };
    case 'SET_COMPARABLES_ADD_FORM_OPEN':
      return {
        ...state,
        isAddFormOpen: payload
      };
    case 'FETCH_SAVE_NEW_COMPARABLES_SUCCESS':
      return {
        ...state,
        errorOnSave: false,
        isAddFormOpen: false
      };
    case 'SET_SAVE_NEW_COMPARABLE_ERROR':
      return {
        ...state,
        errorOnSave: payload
      };
    default:
      return state;
  }
};

export default reducer;
