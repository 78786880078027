import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsAccount from '../components/SettingsAccount';
import SettingsPassword from '../components/SettingsPassword';
import SettingsLanguage from '../components/SettingsLanguage';
import SettingsBilling from '../components/SettingsBilling';
import SettingsPlan from '../components/SettingsPlan';
import SettingsPayment from '../components/SettingsPayment';
import SettingsModalMobile from '../components/SettingsModalMobile';
import SettingsModalDesktop from '../components/SettingsModalDesktop';

import {
  addProvinceToPlan,
  cancelSubscription,
  changePaymentMethod,
  getSettingsDataTab,
  removeProvinceToPlan,
  updateModal,
  updateUserBillingInfo,
  fetchGdprStatus,
  gdprRequest
} from '../actions/settings';
import { updateUser } from '../../actions/user';
import { cleanEmptyObjectKeys, useBreakpoint, showAdditionalSettings } from '../../utils/';
import styled from 'styled-components';
import {
  IconBill,
  IconChevronRight,
  IconGlobal,
  IconPlan,
  IconSettings,
  IconUser,
  IconWallet,
  List,
  NavListItem
} from '@uda/bento-components';
import PaymentsCheckoutBillingSkeleton from '../../Payments/components/PaymentsCheckoutBillingSkeleton';

const DEFAULT_TAB = 'account';

const Separator = styled.i`
  position: relative;
  display: block;
  ${({ theme }) => theme.texts.p2b};
  text-align: center;
  padding-bottom: 5px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  }
`;

const SettingsContainer = () => {
  const data = useSelector(state => state.user.data);
  const { billingInfo, isLoading, tabOpen } = useSelector(state => state.settings);
  const [tab, setTab] = useState(tabOpen || DEFAULT_TAB);
  const [isDetailOpen, setIsDetailOpen] = useState(tabOpen ? true : false);
  const { name, surname, avatar, email, language, company, plans, products } = data;

  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  useEffect(() => {
    dispatch(getSettingsDataTab(tab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (breakpoint === 's') {
      setTab(tabOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (tab, isMobile) => {
    if (isMobile) {
      setIsDetailOpen(true);
    }
    return setTab(tab);
  };

  const handleBackToSettings = () => {
    return setIsDetailOpen(false);
  };

  const handleClose = () => {
    return dispatch(updateModal(false));
  };

  const handleSubmit = values => {
    dispatch(updateUser(cleanEmptyObjectKeys(values)));
  };

  const handleGdprRequest = () => {
    dispatch(gdprRequest());
  };

  const handleGdprStatus = () => {
    dispatch(fetchGdprStatus());
  };

  const handleChangePayment = data => {
    dispatch(changePaymentMethod(data));
  };

  const handleCancelProvince = value => {
    dispatch(removeProvinceToPlan(value));
  };

  const handleConfirmProvince = value => {
    dispatch(addProvinceToPlan(value));
  };

  const handleCancelSubscriptionConfirmation = () => {
    dispatch(cancelSubscription());
  };

  const handleSubmitBillingInfo = values => {
    dispatch(updateUserBillingInfo(cleanEmptyObjectKeys(values)));
  };

  const renderSettingsTab = value => {
    switch (value) {
      case 'account':
        return (
          <SettingsAccount
            onSubmit={handleSubmit}
            onGdprRequest={handleGdprRequest}
            onFetchGdprStatus={handleGdprStatus}
            initialState={{ name, surname, email, company, avatar }}
          />
        );
      case 'password':
        return <SettingsPassword onSubmit={handleSubmit} />;
      case 'language':
        return <SettingsLanguage onSubmit={handleSubmit} initialState={{ language }} />;
      case 'billing':
        return isLoading || !billingInfo ? (
          <PaymentsCheckoutBillingSkeleton />
        ) : (
          <SettingsBilling onSubmit={handleSubmitBillingInfo} values={billingInfo} />
        );
      case 'plan':
        return (
          <SettingsPlan
            onConfirmProvince={handleConfirmProvince}
            onCancelProvince={handleCancelProvince}
            onCancelSubscriptionConfirmation={handleCancelSubscriptionConfirmation}
          />
        );
      case 'payment':
        return <SettingsPayment onSubmit={handleChangePayment} />;
      default:
        break;
    }
  };

  const basicSettings = isMobile => {
    return (
      <List size={'large'}>
        <NavListItem
          active={tab === 'account'}
          onClick={() => handleClick('account', isMobile)}
          leftContent={<IconUser />}
          rightContent={isMobile ? <IconChevronRight color="secondary" /> : null}
        >
          <FormattedMessage id="settings.account.tab" />
        </NavListItem>
        <NavListItem
          active={tab === 'password'}
          onClick={() => handleClick('password', isMobile)}
          leftContent={<IconSettings />}
          rightContent={isMobile ? <IconChevronRight color="secondary" /> : null}
        >
          <FormattedMessage id="settings.password.tab" />
        </NavListItem>
        <NavListItem
          active={tab === 'language'}
          onClick={() => handleClick('language', isMobile)}
          leftContent={<IconGlobal />}
          rightContent={isMobile ? <IconChevronRight color="secondary" /> : null}
        >
          <FormattedMessage id="settings.language.tab" />
        </NavListItem>
      </List>
    );
  };

  const additionalSettings = isMobile => (
    <List>
      <Separator />
      <NavListItem
        active={tab === 'plan'}
        onClick={() => handleClick('plan', isMobile)}
        leftContent={<IconPlan />}
        rightContent={isMobile ? <IconChevronRight color="secondary" /> : null}
      >
        <FormattedMessage id="settings.plan.tab" />
      </NavListItem>
      <NavListItem
        active={tab === 'billing'}
        onClick={() => handleClick('billing', isMobile)}
        leftContent={<IconBill />}
        rightContent={isMobile ? <IconChevronRight color="secondary" /> : null}
      >
        <FormattedMessage id="settings.billing.tab" color="secondary" />
      </NavListItem>
      <NavListItem
        active={tab === 'payment'}
        onClick={() => handleClick('payment', isMobile)}
        leftContent={<IconWallet />}
        rightContent={isMobile ? <IconChevronRight color="secondary" /> : null}
      >
        <FormattedMessage id="settings.payment.tab" />
      </NavListItem>
    </List>
  );

  const additionalSettingsMobile =
    showAdditionalSettings(products, plans) && additionalSettings(true);

  const additionalSettingsDesktop =
    showAdditionalSettings(products, plans) && additionalSettings(false);

  return (
    <>
      {breakpoint === 's' ? (
        <SettingsModalMobile
          onClose={handleClose}
          isDetailOpen={isDetailOpen}
          basicSettings={basicSettings(true)}
          additionalSettings={additionalSettingsMobile}
          onBackToSettings={handleBackToSettings}
        >
          {renderSettingsTab(tab)}
        </SettingsModalMobile>
      ) : (
        <SettingsModalDesktop
          basicSettings={basicSettings(false)}
          additionalSettings={additionalSettingsDesktop}
          breakpoint={breakpoint}
          tab={tab}
          onClose={handleClose}
          onClickTab={value => setTab(value)}
        >
          {renderSettingsTab(tab)}
        </SettingsModalDesktop>
      )}
    </>
  );
};

export default SettingsContainer;
