import { IconActivity, IconAsset, IconGlobal } from '@uda/bento-components';

const getFilterGroupIcon = id => {
  switch (id) {
    case 'financial':
      return <IconActivity />;
    case 'assets':
      return <IconAsset />;
    case 'general':
      return <IconGlobal />;
    default:
      return null;
  }
};

export const apiInternFilters = filterCollection => {
  // TODO: to remove when strategies are back
  return {
    ...filterCollection,
    main: filterCollection.main.map(filter => {
      if (filter.id === 'strategy') {
        return {
          ...filter,
          filters: filter.filters.map(f => {
            return { ...f, values: f.values.filter(val => val.value === 1) };
          })
        };
      }
      return filter;
    }),
    secondary: filterCollection.secondary.map(filterGroup => {
      return {
        ...filterGroup,
        icon: getFilterGroupIcon(filterGroup.id)
      };
    })
  };
};

export const internDetail = detail => {
  if (detail.header.map.geojson) {
    detail.header.map.geojson.features = detail.header.map.geojson.features.map(feature => {
      if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
        feature.properties = {
          fill: '%231778FB',
          'fill-opacity': 0.1,
          stroke: '%231778FB',
          'stroke-width': 1
        };
      }
      if (feature.geometry.type === 'Point') {
        feature.properties = {
          'marker-color': '%2348AAF1'
        };
      }
      return feature;
    });
  }
  return detail;
};

export const internCalculator = (values, main) => {
  const [askingPrice] = main;
  const estimatedPrice = Math.round(askingPrice.primary.data.value / 1000) * 1000;
  const avmAlq = Math.round(values.avm_alq.data.value / 10) * 10;
  return {
    ...values,
    estimated_price: {
      ...values.estimated_price,
      data: {
        ...values.estimated_price.data,
        value: estimatedPrice,
        min: 1000,
        max: Math.round((estimatedPrice * 2) / 1000) * 1000
      }
    },
    avm_alq: {
      ...values.avm_alq,
      data: {
        ...values.avm_alq.data,
        value: avmAlq,
        min: 10,
        max: Math.round((avmAlq * 2) / 10) * 10
      }
    }
  };
};
