import { openNotification, resetNotification } from '../../actions/notification';
import { getLastProduct } from '../../utils';
import {
  fetchProvincesAndNumOfAssets,
  initOpportunities,
  fetchOpportunitiesDetail,
  loadDetailTab
} from '../../Opportunities/actions/opportunities';
import { fetchUser } from '../../actions/user';
import { initRestrictions } from '../../Restrictions/actions/restrictions';
import { PRODUCT } from '../../constants.js';
import { history } from '../../App';

export const fetchUserPlan = () => {
  return {
    type: 'FETCH_USER_PLAN',
    payload: {
      request: {
        method: 'GET',
        url: 'users/me/products/opportunities/plans/'
      }
    }
  };
};

export const fetchAddProvinceToPlan = (id, plan) => {
  return {
    type: 'ADD_PROVINCE_TO_PLAN',
    payload: {
      request: {
        method: 'POST',
        url: `users/me/plans/${plan}/boundaries/`,
        data: { id }
      }
    }
  };
};

export const addProvinceToPlan = id => async (dispatch, getState) => {
  const lastProduct = getLastProduct();
  const plan = getState().user.data.plans[lastProduct] || 'basic-opportunities';

  const action = await dispatch(fetchAddProvinceToPlan(id, plan));

  if (action.type === 'ADD_PROVINCE_TO_PLAN_SUCCESS' && !action.error) {
    dispatch(initRestrictions(PRODUCT.opportunities));
    dispatch(fetchUserPlan());
    dispatch(initOpportunities());
    const assetId = history.location.pathname.match(/\d+$/)?.join('');
    const { tab } = getState().opportunities.detail;

    if (assetId) {
      dispatch(fetchOpportunitiesDetail(assetId));
      dispatch(loadDetailTab(tab, assetId));
    }
  }
};

// This functionality has been cut off in the cancel subscription area . Pending implementation:

// export const fetchCancelReasons = () => {
//   return {
//     type: 'FETCH_CANCEL_REASONS',
//     payload: {
//       request: {
//         method: 'GET',
//         url: 'https://run.mocky.io/v3/ab5e9f2a-b11d-4fef-b771-944268b3ba93'
//       }
//     }
//   };
// };

export const removeProvinceToPlan = id => async (dispatch, getState) => {
  const lastProduct = getLastProduct();
  const plan = getState().user.data.plans[lastProduct] || 'basic-opportunities';

  const action = await dispatch(fetchRemoveProvinceToPlan(id, plan));

  if (action.type === 'REMOVE_PROVINCE_TO_PLAN_SUCCESS' && !action.error) {
    dispatch(initRestrictions(PRODUCT.opportunities));
    dispatch(fetchUserPlan());
    dispatch(initOpportunities());
    const assetId = history.location.pathname.match(/\d+$/)?.join('');
    const { tab } = getState().opportunities.detail;

    if (assetId) {
      dispatch(fetchOpportunitiesDetail(assetId));
      dispatch(loadDetailTab(tab, assetId));
    }
  }
};

export const fetchRemoveProvinceToPlan = (id, plan) => {
  return {
    type: 'REMOVE_PROVINCE_TO_PLAN',
    payload: {
      request: {
        method: 'DELETE',
        url: `users/me/plans/${plan}/boundaries/${id}/`
      }
    }
  };
};

export const updateModal = (value, tab = null) => ({
  type: 'UPDATE_MODAL',
  payload: {
    isSettingsOpen: value,
    tabOpen: tab
  }
});

export const fetchCancelSubscription = () => {
  return {
    type: 'CANCEL_SUBSCRIPTION',
    payload: {
      request: {
        method: 'PUT',
        url: '/payments/subscriptions/cancellation/'
      }
    }
  };
};

export const cancelSubscription = () => async (dispatch, getState) => {
  const action = await dispatch(fetchCancelSubscription());

  if (action.type === 'CANCEL_SUBSCRIPTION_SUCCESS' && !action.error) {
    dispatch(fetchUser());
    dispatch(fetchUserPlan());
    dispatch(updateModal(false));
    dispatch(initRestrictions(PRODUCT.opportunities));
    dispatch(initOpportunities());
    const assetId = history.location.pathname.match(/\d+$/)?.join('');
    const { tab } = getState().opportunities.detail;

    if (assetId) {
      dispatch(fetchOpportunitiesDetail(assetId));
      dispatch(loadDetailTab(tab, assetId));
    }
  }
};

export const fetchPaymentMethod = () => {
  return {
    type: 'FETCH_PAYMENT_METHOD',
    payload: {
      request: {
        method: 'GET',
        url: '/users/settings/payment-info'
      }
    }
  };
};

export const updateUserBillingInfo = data => {
  return {
    type: 'UPDATE_BILLING_INFO',
    payload: {
      request: {
        method: 'PUT',
        url: '/users/settings/billing-info',
        data
      }
    }
  };
};

export const fetchBillingInfo = () => {
  return {
    type: 'FETCH_BILLING_INFO',
    payload: {
      request: {
        method: 'GET',
        url: '/users/settings/billing-info'
      }
    }
  };
};

export const updatePaymentMethod = ({ payment_method_id }) => {
  return {
    type: 'UPDATE_PAYMENT_METHOD',
    payload: {
      request: {
        method: 'POST',
        url: '/users/settings/payment-info',
        data: {
          payment_method_id
        }
      }
    }
  };
};

export const changePaymentMethod =
  ({ stripe, card }) =>
  async dispatch => {
    const paymentCreation = await stripe.createPaymentMethod({
      type: 'card',
      card: card
    });
    dispatch(updateCardModal(false));
    dispatch(updatePaymentLoading(true));

    if (paymentCreation.error) {
      dispatch(openNotification('error', { message: paymentCreation.error.message }));
    } else {
      const payment_method = paymentCreation.paymentMethod.id;
      const action = await dispatch(updatePaymentMethod({ payment_method_id: payment_method }));

      if (action.type === 'UPDATE_PAYMENT_METHOD_SUCCESS') {
        dispatch(fetchPaymentMethod());
      } else {
        dispatch(openNotification('error', { ...action.error.meta, type: action.type }));
      }
    }
  };

export const updateCardModal = value => ({
  type: 'UPDATE_CARD_MODAL',
  payload: value
});

export const closeCardModal = () => dispatch => {
  dispatch(updateCardModal(false));
  dispatch(resetNotification());
};

export const updatePaymentLoading = value => ({
  type: 'UPDATE_PAYMENT_LOADING',
  payload: value
});

export const getSettingsDataTab = tab => dispatch => {
  switch (tab) {
    case 'plan':
      dispatch(fetchUserPlan());
      dispatch(fetchProvincesAndNumOfAssets());
      break;
    case 'payment':
      dispatch(fetchPaymentMethod());
      break;
    case 'billing':
      dispatch(fetchBillingInfo());
      break;
    case 'account':
    case 'password':
    case 'language':
    default:
      break;
  }
};

export const gdprRequest = () => async dispatch => {
  const action = await dispatch(fetchGdprRequest());
  if (action.type === 'FETCH_GDPR_REQUEST_SUCCESS' && !action.error?.message?.cancelled) {
    dispatch(fetchGdprStatus());
  }
};

export const fetchGdprRequest = () => ({
  type: 'FETCH_GDPR_REQUEST',
  payload: {
    request: {
      method: 'POST',
      url: 'users/me/gdpr/request/'
    }
  }
});

export const fetchGdprStatus = () => ({
  type: 'FETCH_GDPR_STATUS',
  payload: {
    request: {
      method: 'GET',
      url: 'users/me/gdpr/last/'
    }
  }
});
