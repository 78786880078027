export const GTag = options =>
  window.gtag('config', process.env.REACT_APP_GA_ID, options) || function () {};

export const gaEventClick = (event_category, event_label) => {
  if (window.gtag) {
    window.gtag('event', 'click', {
      event_category,
      event_label,
      transport_type: 'beacon'
    });
  }
};
