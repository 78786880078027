export const setIsScrollOnTop = value => ({
  type: 'SET_IS_SCROLL_ON_TOP',
  payload: value
});

export const setIsTableExpanded = value => dispatch => {
  dispatch({
    type: 'SET_IS_TABLE_EXPANDED',
    payload: value
  });

  setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
};

export const updateMobileMenu = value => ({
  type: 'UPDATE_MOBILE_MENU',
  payload: value
});
