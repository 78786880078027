export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const isEmptyArray = value => Array.isArray(value) && value.length === 0;
export const range = (start, end) =>
  (typeof start && typeof end) === 'number'
    ? Array(end - start + 1)
        .fill()
        .map((_, index) => start + index)
    : [];
