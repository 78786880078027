import { Modal, Button } from '@uda/bento-components';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledModal = styled(props => <Modal {...props} />)`
  @media only screen and (max-width: 600px) {
    &.is-mobile {
      position: absolute;
      bottom: 0;
    }
  }
`;

const Content = styled.div`
  padding: 0 16px;
`;
const StyledHeader = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.charcoal700};
`;

const StyledText = styled.p`
  color: ${({ theme }) => theme.color.charcoal600};
  margin: 0 0 24px 0;
`;

const StyledContent = styled.footer`
  background-color: white;
  padding-bottom: ${({ theme }) => theme.spacings.medium1};
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: ${({ theme }) => theme.spacings.medium2};
  width: 100%;
  > button {
    margin-left: ${({ theme }) => theme.spacings.small2};
    width: 120px;
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    right: 0;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    padding-top: 12px;
    padding-bottom: 12px;

    > button {
      margin: 0 ${({ theme }) => theme.spacings.small1};
      width: 140px;
      height: 48px;
    }
  }
`;
const SettingsCancelSubscription = ({ isOpen, onSubmit, onClose, isMobile = false, isLoading }) => {
  return (
    <StyledModal
      style={{ maxWidth: '480px' }}
      className={'is-mobile'}
      onClose={onClose}
      enableClickOutside={false}
      header={
        <StyledHeader>
          <FormattedMessage id="settings.plan.cancel_title" />
        </StyledHeader>
      }
      footer={
        <StyledContent>
          <Button variant="secondary" block onClick={onClose}>
            <FormattedMessage id="settings.plan.cancel_removal" />
          </Button>
          <Button
            type="submit"
            onClick={onSubmit}
            variant="dangerPrimary"
            loading={isLoading}
            disabled={isLoading}
          >
            <FormattedMessage id="settings.confirm" />
          </Button>
        </StyledContent>
      }
      isOpen={isOpen}
      zIndex={2000}
      closable={isMobile ? false : true}
    >
      <Content>
        <StyledText>
          <FormattedMessage id="settings.plan.cancel_message" />
        </StyledText>
      </Content>
    </StyledModal>
  );
};

export default SettingsCancelSubscription;

// Show Cancel Reasons
/*const [selectedReasons, setSelectedReasons] = useState([]);

  const handleChange = e => {
    let option = parseInt(e.currentTarget.value);
    if (selectedReasons.includes(option)) {
      return setSelectedReasons(selectedReasons.filter(reason => reason !== option));
    }
    return setSelectedReasons([...selectedReasons, option]);
  };

  const handleSubmit = () => {
    onSubmit(selectedReasons);
  };
 

        <StyledText>
          <FormattedMessage id="settings.plan.cancel_text" />
        </StyledText>
        {reasonsList && (
          <div>
            {reasonsList.map(({ id, label }) => (
              <StyledReason key={id.toString()}>
                <Checkbox
                  onChange={handleChange}
                  checked={selectedReasons.includes(id)}
                  value={id}
                  label={label}
                />
              </StyledReason>
            ))}
          </div>
        )} */
