import { createApi } from '@reduxjs/toolkit/query/react';
import { httpRequest } from 'libs/rtkquery';
import { mapComparablesList } from './adapter';

export const comparablesAPI = createApi({
  reducerPath: 'comparablesApi',
  baseQuery: httpRequest(process.env.REACT_APP_API_V2_BASE_URL_COMPARABLES),
  endpoints: builder => ({
    getComparables: builder.query({
      query: ({ page, ...params } = {}) => ({
        url: '/list',
        params: {
          operation_type: 1,
          typology_type: 1,
          ascending: 1,
          lat: 40.4,
          lon: -3.74,
          distance: 500,
          project:
            'is_accurate_address,source_id,id,id_uda,geometry,area,last_price,floor,x,y,address,baths,rooms,location,url',
          page_size: 200,
          page_num: page || 1,
          output: 'json',
          ...params
        }
      }),
      transformResponse: mapComparablesList
    }),
    getPopup: builder.query({
      query: ({ ...params } = {}) => ({
        url: '/popup',
        params: {
          operation_type: 1,
          typology_type: 1,
          lat: 40.4,
          lon: -3.74,
          distance: 500,
          project:
            'is_accurate_address,source_id,id,id_uda,geometry,area,last_price,floor,x,y,address,baths,rooms,location,url',

          output: 'json',
          ...params
        }
      }),
      transformResponse: mapComparablesList
    })
  })
});

export const { useGetComparablesQuery, useGetComparableDetailQuery, useGetPopupQuery } =
  comparablesAPI;
