const AN_HOUR_IN_MINUTES = 60;
const FORTY_EIGHT_HOURS_IN_MINUTES = 2880;
const A_DAY_IN_MINUTES = 1440;

export const getTimeLeftFormatted = (end, lang = 'es') => {
  const now = new Date().getTime() + new Date().getTimezoneOffset() * 60000;

  const difference = (end - now) / 60000;

  if (difference > 0) {
    if (difference < AN_HOUR_IN_MINUTES) {
      return { value: Math.floor(difference), unit: 'minutes' };
    }

    if (difference < FORTY_EIGHT_HOURS_IN_MINUTES) {
      return {
        value: Math.floor(difference / AN_HOUR_IN_MINUTES),
        unit: 'hours'
      };
    }

    return {
      value: Math.floor(difference / A_DAY_IN_MINUTES),
      unit: 'days'
    };
  }

  return null;
};
