import styled, { useTheme } from 'styled-components';
import { IconClose } from '@uda/bento-components';

const StyledWrapper = styled.div`
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 2000;
`;
const StyleHeading = styled.div``;

const StyledHeader = styled.div`
  display: flex;
  position: relative;
  top: 0;
  height: 70px;
  justify-content: space-between;
  padding: ${({ theme: { spacings } }) =>
    `${spacings.small4} ${spacings.medium1} ${spacings.small3}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.charcoal300};
`;

const StyledContent = styled.div`
  overflow: auto;
`;

const SettingsMobileWrapper = ({ header, onClose, isOpen, zIndex, children }) => {
  const theme = useTheme();

  return (
    <>
      {isOpen ? (
        <StyledWrapper>
          <StyledHeader>
            <StyleHeading>{header}</StyleHeading>
            <button onClick={onClose}>
              <IconClose customColor={theme.color.charcoal600} />
            </button>
          </StyledHeader>
          <StyledContent>{children}</StyledContent>
        </StyledWrapper>
      ) : null}
    </>
  );
};

export default SettingsMobileWrapper;
