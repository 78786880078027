import { DEFAULT_GEOG_DWITHIN, DEFAULT_DATE_MAIN_RANGE } from '../constants';

export const getDefaultValuesFilterByUse = filters => {
  const areaByTypology = filters?.assetFilters?.find(filter => filter.id === 'area')?.filters[0]
    ?.data?.value;
  const defaultDistanceByListingType = filters?.value === 1 ? DEFAULT_GEOG_DWITHIN : [1000];

  return {
    area: areaByTypology,
    listing_type: filters?.value,
    geog__dwithin: defaultDistanceByListingType,
    date_main_range: DEFAULT_DATE_MAIN_RANGE,
    n_baths__in: [null],
    n_rooms__in: [null]
  };
};
