export const initialState = [];

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'SET_FEATURE_FLAGS':
      return [...state, ...payload];
    default:
      return state;
  }
};

export default reducer;
