import { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { List, ListItem, ButtonLink, IconAdd, Loader } from '@uda/bento-components';
import ProvincesDropdown from '../../components/ProvincesDropdown';
import SettingsCancelProvince from './SettingsCancelProvince';

const StyledLoader = styled(props => <Loader {...props} />)`
  width: 15px;
  height: 15px;
  border: 3px solid ${({ theme }) => theme.color.charcoal400};
  background-color: ${({ theme }) => theme.color.charcoal400};
  > span {
    background-color: white;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledProvincesHeader = styled.h4`
  color: ${({ theme }) => theme.color.charcoal600};
  line-height: 2;
  margin-bottom: 20px;
`;
const StyledProvincesDropdown = styled(props => <ProvincesDropdown {...props} />)``;
const DropdownContainer = styled.div`
  height: 50px;
  width: 100%;
`;
const ProvincesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 65%;
  max-width: 500px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

const StyledList = styled(props => <List {...props} />)`
  > li {
    :hover {
      background-color: transparent;
      cursor: auto;
    }
    :first-child {
      border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
    }
    margin: 0;
  }
  &.new-items {
    > li {
      background-color: ${({ theme }) => theme.color.charcoal200};
      :first-child {
        border-top: none;
      }
      span {
        color: ${({ theme }) => theme.color.charcoal600};
      }
    }
    margin-bottom: 8px;
  }
`;
const StyledListItem = styled(props => <ListItem {...props} />)`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.color.charcoal300};
  height: ${({ theme }) => theme.spacings.medium4};
  > div {
    justify-content: space-between;
    color: red;
  }
`;
const StyledLabel = styled.span`
  ${({ theme }) => theme.texts.p1}
  color: ${({ removed, theme }) => (removed ? theme.color.charcoal500 : '')};
`;

const StyledButtonLink = styled(props => <ButtonLink {...props} />)`
  &.add-province {
    justify-content: flex-end;
    margin: 23px 0;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    > span > svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const ActionsContainer = styled.div`
  position: relative;
  button {
    margin-left: ${({ theme }) => theme.spacings.small2};
  }
  > span {
    margin-top: 15px;
  }
`;

const AddProvinceWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  justify-content: flex-end;
`;

const StyledRemoveLink = styled(props => <ButtonLink {...props} />)`
  ${({ theme }) => theme.texts.p2b}
  color: ${({ theme }) => theme.color.charcoal600};
`;
const SettingActiveProvinces = ({
  activeProvinces,
  onConfirmProvince,
  onCancelProvince,
  provinceValues,
  isLoading
}) => {
  const [newProvinces, setNewProvinces] = useState([]);
  const [isAddingProvince, setIsAddingProvince] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [provinceIdToRemove, SetProvinceIdToRemove] = useState(null);
  const handleProvinceSelection = selectedProvinceCode => {
    setNewProvinces([
      ...newProvinces,
      {
        label: getProvinceLabel(selectedProvinceCode),
        id: selectedProvinceCode
      }
    ]);
  };

  const handleAddProvince = () => {
    setIsAddingProvince(true);
  };

  const handleCancelProvince = option => {
    setNewProvinces(newProvinces => newProvinces.filter(item => item.id !== parseInt(option)));
    setIsAddingProvince(!isAddingProvince);
  };

  const getProvinceLabel = selectedProvinceCode => {
    if (selectedProvinceCode) {
      const selected = provinceValues.find(province => province.value === selectedProvinceCode);
      return selected ? selected.label : '';
    }
  };

  const handleCancelClose = () => {
    setIsCancelOpen(false);
  };

  const handleCancelOpen = province => {
    SetProvinceIdToRemove(province);

    setIsCancelOpen(true);
  };

  return (
    <Container>
      <StyledProvincesHeader>
        <FormattedMessage id="settings.plan.active_provinces" />
      </StyledProvincesHeader>
      <ProvincesWrapper>
        <div>
          {activeProvinces.length > 0 ? (
            <StyledList>
              {activeProvinces.map(({ label, id }) => (
                <StyledListItem key={id.toString()}>
                  <StyledLabel>{label}</StyledLabel>
                  <ActionsContainer>
                    {activeProvinces.length > 1 && newProvinces.length === 0 ? (
                      <StyledRemoveLink
                        onClick={() => handleCancelOpen({ label, id })}
                        variant="secondary"
                      >
                        <FormattedMessage id="settings.plan.remove" />
                      </StyledRemoveLink>
                    ) : null}
                  </ActionsContainer>
                </StyledListItem>
              ))}
            </StyledList>
          ) : null}

          {newProvinces.length > 0 ? (
            <StyledList className={'new-items'}>
              {newProvinces.map(({ label, id }) => (
                <StyledListItem key={id.toString()}>
                  <StyledLabel>{label}</StyledLabel>
                  {isLoading ? (
                    <ActionsContainer>
                      <StyledLoader color="secondary" />
                    </ActionsContainer>
                  ) : (
                    <ActionsContainer>
                      <ButtonLink
                        onClick={() => handleCancelProvince(id)}
                        variant="secondary"
                        size="small"
                      >
                        <FormattedMessage id="settings.plan.cancel_removal" />
                      </ButtonLink>
                      <ButtonLink
                        onClick={() => onConfirmProvince(id)}
                        variant="primary"
                        size="small"
                      >
                        <FormattedMessage id="settings.plan.confirm" />
                      </ButtonLink>
                    </ActionsContainer>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          ) : null}
        </div>
        {newProvinces.length === 0 ? (
          <AddProvinceWrapper>
            {isAddingProvince ? (
              <DropdownContainer>
                <StyledProvincesDropdown
                  provinceValues={provinceValues}
                  handleChange={handleProvinceSelection}
                  zIndex={3000}
                  provincesToClean={activeProvinces.map(({ id }) => id)}
                />
              </DropdownContainer>
            ) : (
              <StyledButtonLink
                onClick={handleAddProvince}
                className={'add-province'}
                title={''}
                iconLeft={<IconAdd />}
              >
                <FormattedMessage id="settings.plan.add" />
              </StyledButtonLink>
            )}
          </AddProvinceWrapper>
        ) : null}
      </ProvincesWrapper>

      {provinceIdToRemove ? (
        <SettingsCancelProvince
          isOpen={isCancelOpen}
          onSubmit={onCancelProvince}
          onClose={handleCancelClose}
          provinceIdToRemove={provinceIdToRemove}
          isLoading={isLoading}
        />
      ) : null}
    </Container>
  );
};

export default SettingActiveProvinces;
