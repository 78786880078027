import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from '@uda/bento-components';
import { useEffect, useState } from 'react';
import InputCode from '../components/InputCode';

const REGEX_PATTERN_NUMBER = '[0-9]{1}';
const EMPTY_CODE = ['', '', '', ''];

const Heading = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  @media only screen and (max-width: 720px) {
    font-size: 24px;
  }
`;

const Message = styled.p`
  margin-top: ${({ theme }) => theme.spacings.medium2};
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal500};
`;

const Description = styled.p`
  margin-bottom: ${({ theme }) => theme.spacings.medium2};
`;

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const AuthVerifyAccountForm = ({
  error,
  verify = () => {},
  resend = () => {},
  onClearError = () => {}
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [code, setCode] = useState(EMPTY_CODE);
  const [isCodeFilled, setIsCodeFilled] = useState(false);

  useEffect(() => {
    setIsCodeFilled(code.join('').length === code.length);
  }, [code]);

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsSubmitting(true);
    verify({ code: code.join('') });
    onClearError();
    setIsSubmitting(false);
  };

  const handleResend = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsSubmitting(false);
    setCode(EMPTY_CODE);
    onClearError();
    resend();
  };

  const handleOnChange = e => {
    onClearError();
    setCode(e);
  };

  const handleOnPaste = e => {
    const pasted = e.clipboardData.getData('text/plain');

    if (pasted.length === code.length && !isNaN(pasted)) {
      setCode(pasted.split(''));
    }
  };

  return (
    <div>
      <Heading>
        <FormattedMessage id="auth.verify.title" />
      </Heading>
      <Description>
        <FormattedMessage id="auth.verify.description" />
      </Description>
      <form onSubmit={handleSubmit} className={isSubmitting ? 'loading' : null}>
        <InputCode
          values={code}
          onPaste={handleOnPaste}
          onChange={handleOnChange}
          disabled={isSubmitting}
          pattern={REGEX_PATTERN_NUMBER}
          error={error}
          help={error || ''}
        />
        {error ? (
          <Button type="button" variant="primary" size="large" block onClick={handleResend}>
            <FormattedMessage id="auth.verify.send_new_button" />
          </Button>
        ) : (
          <Button
            type="submit"
            variant="primary"
            size="large"
            loading={isSubmitting}
            block
            disabled={isSubmitting || !isCodeFilled}
          >
            <FormattedMessage id="auth.verify.verify_button" />
          </Button>
        )}
      </form>
      <Message>
        <FormattedMessage
          id="auth.verify.resend"
          values={{
            link: (
              <StyledLink onClick={handleResend}>
                <FormattedMessage id="auth.verify.send_again" />
              </StyledLink>
            )
          }}
        />
      </Message>
    </div>
  );
};

export default AuthVerifyAccountForm;
