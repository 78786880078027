export const DEFAULT_PRODUCT = 'smart-valuation';
export const TOKEN_KEY = 'token';
export const STRAPI_TOKEN_KEY = 'strapiToken';
export const LANG_KEY = 'language';
export const PRODUCT_KEY = 'product';
export const ASSET_SERVER_KEY = 'server';
export const ASSET_USERJWT_KEY = 'userJWT';
export const ASSET_LANG_KEY = 'lang';

export const REGISTER_COOKIE_KEY = 'register';
export const ANALYTICS_COOKIE_KEY = 'analytics';
export const ADS_COOKIE_KEY = 'ads';

export const CURRENCY = { EUR: 'EUR', GBP: 'GBP', USD: 'USD' };

export const PRODUCT = {
  capture: 'capture',
  portfolio: 'portfolio',
  asset: 'asset',
  market: 'market',
  opportunities: 'opportunities',
  'smart-valuation': 'smart-valuation'
};
export const PORTFOLIO_TYPES = {
  REO: 'REO',
  NPL: 'NPL'
};
export const BOUNDARY_TYPE = {
  province: 12,
  region: 11,
  city: 13,
  district: 14,
  neighborhood: 15
};
export const OPERATION = {
  0: 'rent',
  1: 'sale'
};
export const OPERATION_VALUES = {
  sale: 1,
  rent: 0
};
// wiki: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
export const STATUS_CODE = {
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  NETWORK: 'network'
};

export const ID_STATUS_CODE = {
  EXISTING_ACCOUNT: 'EXISTING_ACCOUNT',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  FAILED_VERIFICATION: 'FAILED_VERIFICATION',
  NOT_EXISTING_ACCOUNT: 'NOT_EXISTING_ACCOUNT'
};

export const CANCEL_ACTION_REQUESTS = 'CANCEL_ACTION_REQUESTS';
export const CANCEL_ALL_ACTION_REQUESTS = 'CANCEL_ALL_ACTION_REQUESTS';

export const CANCEL_DATA = { cancelled: true };
export const RESTRICTED_COMPANIES = ['B2CG', 'B2CG_FOTOCASA', 'B2CG_PT', 'B2CG_IT'];

export const FOTOCASA_PROVIDER = 'fotocasa';
export const FOTOCASA_CONTACT_EMAIL = 'inversion@fotocasa.es';
export const UDA_CONTACT_EMAIL = 'support@udarealestate.com';

export const AVM_LISTING_TYPES = [1, 2, 5, 6, 7, 11];

export const LISTING_TYPE_EQUIVALENCE = {
  residential: 1,
  land: 8,
  office: 2,
  local: 5,
  hall: 6,
  garage: 7,
  storage: 11,
  building: 12
};
export const PROPERTY_TYPE_EQUIVALENCE = {
  urban_land: 38,
  developable_land: 42,
  agricultural_soil: 53,
  shopping_center: 23,
  at_street_level: 39,
  mezzanine: 24,
  underground: 25,
  others: 26,
  store: 43,
  moto_slot: 32,
  car_slot: 33,
  card_moto_slot: 34,
  two_card_moto_slot: 35,
  small_slot: 37,
  big_slot: 36,
  penthouse: 1,
  duplex: 2,
  chalet: 3,
  flat: 4,
  semi_detached: 5,
  terraced: 6,
  studio_flat: 7,
  rustic_house: 8
};
export const SPAIN_POI = {
  address: 'Calle De Huesca 27, 28020 Madrid, Madrid, Spain',
  lat: 40.458201946492096,
  lon: -3.6977902019855797
};
export const PORTUGAL_POI = {
  address: 'Belem Lisbon, Lisbon, Lisbon 1200-131, Portugal',
  lat: 38.706884,
  lon: -9.142542
};
export const ITALY_POI = {
  address: 'Via Del Corso 00187, 00186 Roma Rome, Italy',
  lat: 41.905123,
  lon: 12.478682
};
