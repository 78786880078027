import { useTheme } from 'styled-components';

const PortfolioListCardAvmLogo = () => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 58 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 50L55.9994 18V82L2 50Z" fill={theme.color.primary500} />
      <path d="M2 17.9657L28.5 2L56 18L2 50V17.9657Z" fill={theme.color.primary300} />
    </svg>
  );
};

export default PortfolioListCardAvmLogo;
