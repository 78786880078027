export const renameProvinceKeyName = data => ({
  opportunities: {
    ...data.opportunities,
    items: {
      ...data.opportunities.items,
      boundaries: data.opportunities.items.boundaries.map(({ name, id }) => {
        return {
          label: name,
          id
        };
      })
    }
  }
});
