import { useState, useEffect } from 'react';
import { throttle } from '..';

const getDeviceConfig = width => {
  if (width < 719) {
    return 'mobile'; // mobile
  } else if (width >= 720 && width <= 1023) {
    return 'tablet'; // tablet vertical
  } else if (width >= 1024 && width <= 1439) {
    return 'smallDesktop'; // tablet horizontal - small screen
  } else if (width >= 1440 && width <= 1919) {
    return 'desktop'; // desktop
  } else if (width >= 1920) {
    return 'bigDesktop'; // desktop retina ++
  }
};

const useThemeBreakpoints = () => {
  const [breakpoint, setBreakpoint] = useState(() => getDeviceConfig(window.innerWidth));

  // @param {Array} breakpoints - array of breakpoints to apply styles
  // example: ['mobile', 'tablet']
  // @param {String} styles - styles to apply
  // example: `background-color: red; padding: 10px;`
  const applyBreakpoints = (breakpoints, styles) =>
    Array.isArray(breakpoints) && breakpoints.includes(breakpoint) ? styles : '';

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBreakpoint(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return { breakpoint, applyBreakpoints };
};

export default useThemeBreakpoints;
