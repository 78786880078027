import { useState } from 'react';
import { Avatar, Dropdown, IconArrowClose } from '@uda/bento-components';
import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import StandardNavigationActions from './StandardNavigationActions';
import { isFreePlan } from '../utils/isFreePlan';

const StyledUserMenu = styled.div`
  padding: ${({ sticked }) => (sticked ? '4px' : '12px')} ${({ theme }) => theme.spacings.small3};
  display: flex;
  justify-content: flex-end;
  min-width: 190px;
  align-items: center;
  border-radius: 4px;
  margin-right: 8px;
  transition: background-color 0.5s linear;
  ${({ theme, isOpenDropdown }) =>
    isOpenDropdown && `background-color: ${theme.components.navigationMenuOpenBackgroundColor};`};

  &:hover {
    background-color: ${({ theme }) => theme.components.navigationMenuOpenBackgroundColor};
  }

  @media only screen and (max-width: 768px) {
    ${({ isButtonShown }) => (isButtonShown ? `min-width: 70px` : '')};
    padding: 0;
  }
`;

const StyledUserName = styled.span`
  margin: ${({ theme: { spacings } }) => `0 ${spacings.small3} 0 ${spacings.small2} `};
  font-weight: ${({ theme }) => theme.global.fontWeightMedium};
  font-size: 12px;
  color: ${({ theme }) => theme.color.charcoal600};

  @media only screen and (max-width: 1012px) {
    ${({ isButtonShown }) =>
      isButtonShown
        ? ` max-width: 104px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`
        : ''}
  }

  @media only screen and (max-width: 768px) {
    ${({ isButtonShown }) =>
      isButtonShown
        ? `display:none`
        : ` max-width: 104px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
  }
`;

const StyledDropdown = styled.div`
  height: 100%;

  div div:last-child {
    right: 8px;
  }

  @media only screen and (max-width: 768px) {
  }
`;

const StyledIconArrowClose = styled(({ theme }) => (
  <IconArrowClose customColor={theme.color.charcoal600} />
))``;

const UserNavbar = ({ sticked }) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const theme = useTheme();
  const { name, surname, email } = useSelector(state => state.user.data);
  const plan = useSelector(state => state.user.data.plans?.opportunities) || '';

  const handleDropdown = isOpen => setOpenDropdown(isOpen);

  const UserMenu = () => (
    <StyledUserMenu
      sticked={sticked}
      isOpenDropdown={isOpenDropdown}
      isButtonShown={isFreePlan(plan)}
    >
      {name || surname ? (
        <Avatar size={sticked ? 'small' : 'medium'}>
          {name} {surname}
        </Avatar>
      ) : null}
      <StyledUserName isButtonShown={isFreePlan(plan)}>
        {name || surname ? `${name} ${surname}` : email}
      </StyledUserName>
      <StyledIconArrowClose theme={theme} />
    </StyledUserMenu>
  );

  return (
    <StyledDropdown>
      <Dropdown
        align="right"
        zIndex={15}
        label={<UserMenu />}
        onChange={handleDropdown}
        childrenProps={{ style: { right: '8px' } }}
      >
        <StandardNavigationActions />
      </Dropdown>
    </StyledDropdown>
  );
};

export default UserNavbar;
