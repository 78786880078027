import { removeEmptyAndNullAndUndefinedValuesFromObject } from './removeEmptyAndNullValuesFromObject';
import { history } from '../App';

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(history.location.search);
  const urlParamsToObject = Object.fromEntries(urlParams.entries());
  const params = Object.entries(urlParamsToObject).reduce((obj, [key, value]) => {
    return { ...obj, [key]: value };
  }, {});
  return removeEmptyAndNullAndUndefinedValuesFromObject(params);
};
