import { Helmet } from 'react-helmet';

const PageTitle = ({
  title = null,
  suffix = 'Pulse urbanData Analytics · Real Estate SaaS data platform',
  section = '· Pulse',
  showFotocasaFavicon = false
}) => {
  return (
    <Helmet>
      <title>{title ? `${title} ${section}` : suffix}</title>
      {showFotocasaFavicon && (
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://www.fotocasa.es/inversion-inmobiliaria/static/favicons/favicon-32x32.png"
        />
      )}
    </Helmet>
  );
};

export default PageTitle;
