import { useDispatch } from 'react-redux';
import AuthCreatePasswordForm from '../forms/AuthCreatePasswordForm';
import { verifyEmail } from '../actions/auth';

const AuthCreatePasswordContainer = () => {
  const dispatch = useDispatch();

  const handleVerifyEmail = ({ email, token, password }) =>
    dispatch(verifyEmail({ email, token, password }));

  return <AuthCreatePasswordForm verifyEmail={handleVerifyEmail} />;
};

export default AuthCreatePasswordContainer;
