import { Modal, Button, FormGroup } from '@uda/bento-components';
import styled, { useTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import visa from '../../assets/images/cards/visa.svg';
import mastercard from '../../assets/images/cards/mastercard.svg';
import amex from '../../assets/images/cards/amex.svg';
import { useState } from 'react';

const Content = styled.div``;

const StyledModal = styled(props => <Modal {...props} />)`
  width: '500px';

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 60%;
  }
  @media only screen and (max-width: 600px) {
    &.is-mobile {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-width: 100%;
    }
  }
`;

const StyledCard = styled.img`
  height: 26px;
  opacity: ${({ active, selected }) => {
    return active && selected ? 1 : active ? 0.25 : 1;
  }};
`;

const StyledLabel = styled.label`
  ${({ theme }) => theme.texts.p2};
`;

const StyledContent = styled.footer`
  background-color: white;
  padding-bottom: ${({ theme }) => theme.spacings.medium1};
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: ${({ theme }) => theme.spacings.medium2};
  width: 100%;
  > button {
    margin-left: ${({ theme }) => theme.spacings.small2};
    width: 120px;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    right: 0;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    padding-top: 12px;
    padding-bottom: 12px;

    > button {
      margin: 0 ${({ theme }) => theme.spacings.small1};
      width: 140px;
      height: 48px;
    }
  }
`;

const StyledCardNumberElement = styled(props => <CardNumberElement {...props} />)`
  padding: 14px 24px;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.color.redclay500 : theme.color.charcoal400)};
  border-radius: 4px;
  background: ${({ error, theme }) => (error ? theme.color.redclay100 : 'white')};
  margin-bottom: ${({ theme }) => theme.spacings.small1};
`;

const StyledCardExpiryElement = styled(props => <CardExpiryElement {...props} />)`
  padding: 14px 24px;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.color.redclay500 : theme.color.charcoal400)};
  border-radius: 4px;
  background: ${({ error, theme }) => (error ? theme.color.redclay100 : 'white')};
  margin-bottom: ${({ theme }) => theme.spacings.small1};
`;

const StyledCardCvcElement = styled(props => <CardCvcElement {...props} />)`
  padding: 14px 24px;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.color.redclay500 : theme.color.charcoal400)};
  border-radius: 4px;
  background: ${({ error, theme }) => (error ? theme.color.redclay100 : 'white')};
  margin-bottom: ${({ theme }) => theme.spacings.small1};

  @media only screen and (max-width: 768px) {
    width: 80px;
  }
`;

const StyledWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings.small2} ${({ theme }) => theme.spacings.small4};
`;

const StyledFormGroup = styled(props => <FormGroup {...props} />)`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;

  margin-bottom: ${({ theme }) => theme.spacings.small3};
  > h4 {
    padding-right: ${({ theme }) => theme.spacings.small1};
  }

  div {
    display: flex;
    gap: ${({ theme }) => theme.spacings.small1};
  }

  @media only screen and (max-width: 600px) {
    flex-flow: column;
    align-items: flex-start;
    > div {
      margin-top: ${({ theme }) => theme.spacings.small3};
    }
  }
`;

const StyledErrorInfo = styled.span`
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.redclay500};
  padding-top: ${({ theme }) => theme.spacings.small1};
`;

const SettingsChangePayment = ({ isOpen, onSubmit, onClose }) => {
  const [cardType, setCardType] = useState();

  const [elementStatus, setElementStatus] = useState({
    cardNumber: { complete: false },
    cardExpiry: { complete: false },
    cardCvc: { complete: false }
  });
  const theme = useTheme();
  const elements = useElements();
  const stripe = useStripe();

  const checkCardComplete = () =>
    elementStatus.cardNumber.complete &&
    elementStatus.cardExpiry.complete &&
    elementStatus.cardCvc.complete;

  const handleStripeElementChange = e => {
    if (e.brand) setCardType(e.brand);

    setElementStatus({
      ...elementStatus,
      [e.elementType]: { complete: e.complete, error: e.error?.message }
    });
  };

  const style = {
    base: {
      fontSize: theme.texts.p1.fontSize,
      backgroundColor: 'transparent',
      color: theme.color.charcoal800,
      '::placeholder': {
        color: theme.color.charcoal400
      }
    },
    invalid: {
      color: theme.color.redclay500,
      backgroundColor: theme.color.redclay100
    }
  };

  const handlePaymentChange = () => {
    const card = elements.getElement(CardNumberElement);
    onSubmit({ stripe, card });
  };

  return (
    <StyledModal
      className={'is-mobile'}
      closable={false}
      onClose={onClose}
      enableClickOutside={false}
      footer={
        <StyledContent>
          <Button variant="secondary" block onClick={onClose}>
            <FormattedMessage id="settings.plan.cancel_removal" />
          </Button>
          <Button
            type="submit"
            onClick={handlePaymentChange}
            variant="primary"
            disabled={!checkCardComplete()}
          >
            <FormattedMessage id="settings.confirm" />
          </Button>
        </StyledContent>
      }
      isOpen={isOpen}
      zIndex={2000}
    >
      <Content>
        <StyledWrapper>
          <StyledHeader>
            <h4>
              <FormattedMessage id="settings.payment.change" />
            </h4>
            <div>
              <StyledCard active={cardType} selected={cardType === 'amex'} src={amex} alt="Amex" />
              <StyledCard active={cardType} selected={cardType === 'visa'} src={visa} alt="Visa" />
              <StyledCard
                active={cardType}
                selected={cardType === 'mastercard'}
                src={mastercard}
                alt="MasterCard"
              />
            </div>
          </StyledHeader>
          <>
            <FormGroup>
              <StyledLabel>
                <FormattedMessage id="payments.order.card_number" />
                <StyledCardNumberElement
                  options={{ style }}
                  onChange={handleStripeElementChange}
                  error={elementStatus.cardNumber.error}
                />
                <StyledErrorInfo>{elementStatus.cardNumber.error}</StyledErrorInfo>
              </StyledLabel>
            </FormGroup>
            <StyledFormGroup>
              <StyledLabel>
                <FormattedMessage id="payments.order.expiration" />
                <StyledCardExpiryElement
                  options={{ style }}
                  onChange={handleStripeElementChange}
                  error={elementStatus.cardExpiry.error}
                />
                <StyledErrorInfo>{elementStatus.cardExpiry.error}</StyledErrorInfo>
              </StyledLabel>
              <StyledLabel>
                <FormattedMessage id="payments.order.cvc" />
                <StyledCardCvcElement
                  options={{ style }}
                  onChange={handleStripeElementChange}
                  error={elementStatus.cardCvc.error}
                />
                <StyledErrorInfo>{elementStatus.cardCvc.error}</StyledErrorInfo>
              </StyledLabel>
            </StyledFormGroup>
          </>
        </StyledWrapper>
      </Content>
    </StyledModal>
  );
};

export default SettingsChangePayment;
