/* istanbul ignore file */

export const apiInternIndicators = data => {
  for (const i in data) {
    switch (data[i].id) {
      case 'sociodemographic':
        for (const k in data[i].segments) {
          data[i].segments[k].data = {
            format: 'number'
          };
        }
        break;
      case 'financial':
        for (const k in data[i].segments) {
          data[i].segments[k].data = {
            format: 'price',
            currency: 'EUR'
          };
        }
        break;
      case 'real_estate':
        for (const k in data[i].segments) {
          data[i].segments[k].data = {
            format: 'number'
          };
        }
        break;
      default:
        break;
    }
    for (const k in data[i].segments) {
      const segments = data[i].segments;
      switch (segments[k].indicator) {
        case 'o_abn_4q':
        case 'p_e_sin':
        case 'p_ed_14':
        case 'p_ed_39':
        case 'p_ed_64':
        case 'p_ed_ma':
        case 'p_e_seg':
        case 'p_e_ter':
        case 'p_e_pri':
        case 'y_r':
        case 's_t_4q':
          segments[k].data = {
            format: 'percent'
          };
          break;
        case 's_t_new':
        case 's_t_second':
        case 's_t':
          segments[k].data = {
            format: 'weeks'
          };
          break;
        case 'n_pob_3yy':
        case 'o_u_4q_yoy':
        case 'o_abn_4q_yoy':
        case 'o_pu_rate2max':
        case 'o_pu_rate2min':
        case 'so_u_4q_yoy':
          segments[k].data = {
            format: 'percent',
            delta: true
          };
          break;
        case 'rent_hog_dis':
          segments[k].data = {
            format: 'price',
            currency: 'EUR'
          };
          break;
        case 'o_pu_P_01':
        case 'o_pu_P_02':
        case 'o_pu_P_03':
        case 'o_pu_P_04':
        case 'o_pu_P_05':
        case 'o_pu_P_06':
        case 'o_pu':
        case 'o_pu_max':
        case 'o_pu_min':
          segments[k].data = {
            format: 'price',
            currency: 'EUR',
            area: true
          };
          break;
        default:
          break;
      }
    }
  }
  return data;
};

export const parseFilterValuesNumber = data => {
  Object.entries(data).forEach(([_, ranges]) => {
    ranges.min = ranges.min ? parseInt(ranges.min) : 0;
    ranges.max = ranges.max ? parseInt(ranges.max) : 0;
    ranges.avg = ranges.avg ? Number(parseFloat(ranges.avg).toFixed(2)) : 0;
  });

  return data;
};
