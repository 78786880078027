import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { verifyEmail } from '../actions/auth';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

const AuthVerifyLinkContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email, token } = qs.parse(history.location.search);

  useEffect(() => {
    dispatch(verifyEmail({ email, token }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return <p>Verifying...</p>;
};

export default AuthVerifyLinkContainer;
