import { useMemo } from 'react';
import useIsScrollOnTop from '../utils/hooks/useIsScrollOnTop';
import useIsSmallScreen from '../utils/hooks/useIsSmallScreen';
import { useDispatch } from 'react-redux';
import { setIsScrollOnTop } from '../actions/layout';

const LayoutController = () => {
  const dispatch = useDispatch();
  const isScrollOnTop = useIsScrollOnTop();
  const isSmallScreen = useIsSmallScreen();

  const scrollOnTopValue = isSmallScreen ? false : isScrollOnTop;

  useMemo(() => {
    dispatch(setIsScrollOnTop(scrollOnTopValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollOnTopValue]);

  return null;
};

export default LayoutController;
