import { createApi } from '@reduxjs/toolkit/query/react';
import { httpRequest } from 'libs/rtkquery';
import { mapDevelopmentsList, mapDevelopment } from './adapter';
import { range } from 'utils';

export const portfolioAPI = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: httpRequest(process.env.REACT_APP_API_BASE_URL),
  endpoints: builder => ({
    getDevelopmentsPdfList: builder.query({
      query: ({ portfolioId, page = 1, filter }) => {
        let url = `/properties/pulse/portfolios/${portfolioId}/developments?page=${page}`;
        if (filter)
          url = `/properties/pulse/portfolios/${portfolioId}/developments?page=${page}&filter=${filter}`;
        return { url };
      },
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        let data = [];
        const {
          data: { pagination, developments }
        } = await queryFulfilled;
        const pagesNumber = pagination?.page_count || 1;

        if (pagesNumber > 1 && pagesNumber !== Infinity) {
          const developmentRequests = range(2, pagesNumber).map(
            async page =>
              await dispatch(
                portfolioAPI.endpoints.getDevelopmentsPdfList.initiate({
                  portfolioId: params.portfolioId,
                  page
                })
              )
          );

          await Promise.all(developmentRequests).then(res => {
            data = res.map(value => value?.data.developments).flat();
          });
        }
        dispatch(
          portfolioAPI.util.updateQueryData('getDevelopmentsPdfList', params, cachedData => {
            const newData = {
              pagination,
              developments: [...developments, ...data],
              isFetched: true
            };
            Object.assign(cachedData, newData);
          })
        );
      },
      transformResponse: mapDevelopmentsList
    }),
    getDevelopment: builder.query({
      query: ({ developmentId }) => ({
        url: `/properties/pulse/developments/${developmentId}/info`
      }),
      cache: 'no-cache',
      transformResponse: (res, meta, args) => {
        return mapDevelopment(res, args.valuationDate);
      }
    })
  })
});

export const { useGetDevelopmentsPdfListQuery, useGetDevelopmentQuery } = portfolioAPI;
