import styled from 'styled-components';

const Container = styled.span`
  ${({ theme }) => theme.texts.p3b};
  color: ${({ theme, color }) => (color ? theme.color[color] : theme.color.white)};
  background-color: ${({ theme, background }) =>
    background ? theme.color[background] : theme.color.charcoal500};
  border-radius: ${({ theme, rounded }) => (rounded ? '50px' : theme.shapes.borderRadiusMedium)};

  padding: 2px ${({ theme }) => theme.spacings.small2};
  font-size: ${({ theme, large }) => (large ? theme.global.fontSize : '12px')};
  font-weight: ${({ theme, weight }) => (weight ? theme.global.fontWeightMedium : '500')};
`;

const Tag = ({ className, children, background, color, large, weight, rounded }) => (
  <Container
    className={className}
    rounded={rounded}
    large={large}
    weight={weight}
    background={background}
    color={color}
  >
    {children}
  </Container>
);

export default Tag;
