import styled from 'styled-components';

const NotificationWrapper = styled.div`
  position: relative;
  z-index: 11;
  > aside {
    position: absolute;
    padding: ${({ theme }) => theme.spacings.small3};
    top: ${({ theme }) => theme.spacings.small2};
    right: ${({ theme }) => theme.spacings.small2};
    width: auto;
  }

  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    > aside {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
    }
  }
`;

export default NotificationWrapper;
