import { Button, IconSuccess } from '@uda/bento-components';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import useCalculateHeaderHeight from '../../utils/hooks/useCalculateHeaderHeight';
import { FormattedMessage } from 'react-intl';
import { goToProduct } from '../../actions/redirections';
import { fetchUser } from '../../actions/user';

const StyledContent = styled.div`
  width: 60vw;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  height: calc(
    100vh -
      ${({ isScrollOnTop, offsetHeight }) => (isScrollOnTop ? `${offsetHeight + 56}px` : '96px')}
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.h2`
  margin-top: 28px;
  margin-bottom: ${({ theme }) => theme.spacings.small2};
`;

const StyledDesc = styled.p`
  color: ${({ theme }) => theme.color.charcoal800};
  font-size: 32px;
  font-weight: 300;
  margin-bottom: ${({ theme }) => theme.spacings.medium1};
  text-align: center;
`;

const StyledSub = styled.p`
  width: 510px;
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-bottom: ${({ theme }) => theme.spacings.medium1};
  text-align: center;
`;

const Payme = ({ product }) => {
  const isScrollOnTop = useSelector(state => state.layout.isScrollOnTop);
  const [headerHeight] = useCalculateHeaderHeight();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleGoToProduct = async () => {
    await dispatch(fetchUser());
    dispatch(goToProduct(product));
  };

  return (
    <StyledContent isScrollOnTop={isScrollOnTop} offsetHeight={headerHeight}>
      <IconSuccess customColor={theme.color.emerald600} />
      <StyledTitle>
        <FormattedMessage id="payments.report.success" />
      </StyledTitle>
      <StyledDesc>
        <FormattedMessage id="payments.report.description" />
      </StyledDesc>
      <StyledSub>
        <FormattedMessage id="payments.report.subtitle" />
      </StyledSub>
      <Button variant="secondary" onClick={handleGoToProduct}>
        <FormattedMessage id="payments.report.go_home" />
      </Button>
    </StyledContent>
  );
};

export default Payme;
