import { FOTOCASA_PROVIDER } from '../constants.js';
import { getCookie } from './storage';

export const featureFlagsByProvider = () => {
  const host = window.location.hostname.split('.');
  const [rootDomain] = host.slice(-2);
  let flags = [];
  const testFotocasa = getCookie('fotocasa-test');

  if (rootDomain === FOTOCASA_PROVIDER || testFotocasa) {
    flags.push(
      'hide_google_auth',
      'hide_applications_menu',
      'show_fotocasa_logo',
      'default_product_opportunities',
      'fotocasa_aside',
      'show_fotocasa_title',
      'show_fotocasa_favicon',
      'fotocasa_provider'
    );
  }

  return flags;
};
