import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import amex from '../../assets/images/cards/amex.svg';
import mastercard from '../../assets/images/cards/mastercard.svg';
import visa from '../../assets/images/cards/visa.svg';
import { Button, Skeleton } from '@uda/bento-components';
import SettingsChangePayment from './SettingsChangePayment';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardModal, closeCardModal } from '../actions/settings';

const StyledComponentWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    padding: ${({ theme: { spacings } }) => `${spacings.small3} ${spacings.medium1}`};
    margin-bottom: ${({ theme }) => theme.spacings.large2};
  }
`;

const StyledHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.small4};
`;
const Separator = styled.i`
  position: relative;
  display: block;
  ${({ theme }) => theme.texts.p2b};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacings.small4};
  height: ${({ theme }) => theme.spacings.small4};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  }
`;

const Container = styled.div`
  width: 400px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const PaymentDataContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  i {
    margin-right: ${({ theme }) => theme.spacings.small2};
  }
`;

const TextContainer = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spacings.small2};
  > p {
    margin-left: ${({ theme }) => theme.spacings.small1};
    margin-right: ${({ theme }) => theme.spacings.small1};
  }
`;

const StyledButton = styled(props => <Button {...props} />)`
  padding: 11px;
`;

const SettingsPayment = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { paymentMethod, isPaymentMethodLoading } = useSelector(state => state.settings);
  const { isCardOpen } = useSelector(state => state.settings);

  const displayCard = cardName => {
    switch (cardName) {
      case 'visa':
        return { url: visa, label: 'Visa' };
      case 'mastercard':
        return { url: mastercard, label: 'Master Card' };
      case 'amex':
        return { url: amex, label: 'American Express' };
      default:
        return null;
    }
  };

  return (
    <StyledComponentWrapper>
      <StyledHeading>
        <FormattedMessage id="settings.payment.title" />
      </StyledHeading>

      <Container>
        {isPaymentMethodLoading || paymentMethod === null ? (
          <PaymentDataContainer>
            <Skeleton variant="text" width="40px" height="26px" />
            <Skeleton variant="text" width="150px" height="16px" />
          </PaymentDataContainer>
        ) : (
          <PaymentDataContainer>
            <img
              height={'26px'}
              src={displayCard(paymentMethod.type).url}
              alt={displayCard(paymentMethod.type).label}
            />
            <TextContainer>
              <span>{displayCard(paymentMethod.type).label}</span>
              <p>
                <FormattedMessage id="settings.payment.ending" />{' '}
              </p>
              <span>{paymentMethod.card_number}</span>
            </TextContainer>
          </PaymentDataContainer>
        )}

        <Separator />

        <StyledButton
          variant={'secondary'}
          onClick={() => {
            dispatch(updateCardModal(true));
          }}
        >
          <FormattedMessage id="settings.payment.change" />
        </StyledButton>

        <SettingsChangePayment
          onSubmit={onSubmit}
          isOpen={isCardOpen}
          onClose={() => {
            dispatch(closeCardModal(false));
          }}
        />
      </Container>
    </StyledComponentWrapper>
  );
};

export default SettingsPayment;
