import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import SettingsPlanSkeleton from './SettingsPlanSkeleton';
import SettingsPlanOpportunities from './SettingsPlanOpportunities';

const PlanContainer = styled.div`
  width: 550px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: ${({ theme: { spacings } }) => `${spacings.small3} ${spacings.medium1}`};
    margin-bottom: ${({ theme }) => theme.spacings.large2};
  }
`;

const StyledHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.small4};
`;

const SettingsPlan = ({
  onConfirmProvince = () => {},
  onCancelProvince = () => {},
  onCancelSubscriptionOpen = () => {},
  onCancelSubscriptionConfirmation = () => {}
}) => {
  const { plan, isLoading, isActionLoading } = useSelector(state => state.settings);

  return (
    <PlanContainer>
      <StyledHeading>
        <FormattedMessage id="settings.plan.title" />
      </StyledHeading>
      {isLoading ? (
        <SettingsPlanSkeleton />
      ) : plan && plan.opportunities ? (
        <SettingsPlanOpportunities
          opportunities={plan.opportunities}
          onConfirmProvince={onConfirmProvince}
          onCancelProvince={onCancelProvince}
          onCancelSubscriptionOpen={onCancelSubscriptionOpen}
          onCancelSubscriptionConfirmation={onCancelSubscriptionConfirmation}
          isLoading={isActionLoading}
        />
      ) : null}
    </PlanContainer>
  );
};
export default SettingsPlan;
