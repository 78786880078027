import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../actions/auth';

const AuthLogoutContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signOut());
  }, [dispatch]);

  return <p>Logout...</p>;
};

export default AuthLogoutContainer;
