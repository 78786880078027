import {
  apiInternMethods,
  apiInternValuationTable,
  getNewMethodsValues,
  getNewTableValues
} from './intern';

export const initialState = {
  table: {
    isLoading: false,
    isLoadingPagination: false,
    columns: [],
    rows: [],
    sorting: {},
    pagination: {}
  },
  summary: {
    market_analysis_list: [],
    market_analysis: {}
  },
  methods: null,
  dafo: null
};

const reducer = (state = initialState, { type, payload, meta, ...action }) => {
  switch (type) {
    case 'FETCH_VALUATION_TABLE_LIST':
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true
        }
      };
    case 'FETCH_VALUATION_TABLE_LIST_PAGE':
      return {
        ...state,
        table: {
          ...state.table,
          isLoadingPagination: true
        }
      };
    case 'FETCH_VALUATION_TABLE_LIST_SUCCESS':
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: false,
          ...apiInternValuationTable(payload.data)
        }
      };
    case 'FETCH_VALUATION_TABLE_LIST_PAGE_SUCCESS':
      let newPageData = apiInternValuationTable(payload.data);
      newPageData = {
        ...newPageData,
        rows: [...state.table.rows, ...newPageData.rows]
      };

      return {
        ...state,
        table: {
          ...state.table,
          isLoadingPagination: false,
          ...newPageData
        }
      };
    case 'SET_VALUATION_DATA_NEW_CELL':
      return {
        ...state,
        table: {
          ...state.table,
          rows: getNewTableValues(state.table.rows, payload.data)
        }
      };
    case 'SET_VALUATION_METHOD_DATA_NEW_CELL':
      return {
        ...state,
        methods: getNewMethodsValues(state.methods, payload.data, payload.method)
      };
    case 'VALUATION_DATA_TABLE_SORT':
      return {
        ...state,
        table: {
          ...state.table,
          sorting: payload.sorting
        }
      };
    case 'FETCH_VALUATION_SUMMARY_SUCCESS':
      const values = Object.fromEntries(payload.data.map(e => [e.field, e.value]));
      return {
        ...state,
        summary: {
          ...state.summary,
          market_analysis_list: payload.data,
          market_analysis: values
        }
      };
    case 'VALUATION_SUMMARY_UPDATE':
      return {
        ...state,
        summary: {
          ...state.summary,
          market_analysis: { ...state.summary.market_analysis, ...payload }
        }
      };
    case 'FETCH_VALUATION_METHODS_SUCCESS':
      const data = apiInternMethods(payload.data.methods);
      return {
        ...state,
        dafo: payload.data.dafo,
        methods: data
      };
    case 'VALUATION_DAFO_UPDATE':
      return {
        ...state,
        dafo: state.dafo.map(dafo => {
          if (dafo.id === payload.key) {
            return { ...dafo, data: { ...dafo.data, value: payload.value } };
          }
          return dafo;
        })
      };
    case 'VALUATION_METHOD_UPDATE':
      return {
        ...state,
        methods: state.methods.map(method => {
          if (method.id === payload.method) {
            return {
              ...method,
              inputs: method.inputs.map(input => {
                if (input.id === payload.key) {
                  return { ...input, data: { ...input.data, value: payload.value } };
                }
                return input;
              })
            };
          }
          return method;
        })
      };
    default:
      return state;
  }
};

export default reducer;
