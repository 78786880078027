export const DEFAULT_BBOX = [-9.392884, 35.94685, 3.039484, 43.748338];
export const MIN_ZOOM = 4;
export const MAX_ZOOM = 17;
export const DEFAULT_ZOOM = 5;
export const LAT = 40.4280136;
export const LON = -3.6954159;
export const DEFAULT_ADDRESS = 'Calle De Almagro 1, 28010 Madrid, Madrid, Spain';
export const DEFAULT_N_ROOMS = 3;
export const DEFAULT_FLOOR = 3;
export const DEFAULT_N_BATHS = 2;
export const DEFAULT_AREA = 120;
export const N_COMPARABLE_TO_AVERAGE = 12;
export const DEFAULT_CURRENCY = 'EUR';
export const PRICE_CONFIG = {
  currency: 'EUR',
  format: 'price'
};

export const LATLNG_DECIMALS = 8;

export const TASK_STATUS_SUCCESS = 'SUCCESS';
export const TASK_STATUS_FAILURE = 'FAILURE';
export const TASK_STATUS_REVOKED = 'REVOKED';

export const ASSET_PREFERENCE = 'asset_preference';

export const FILTER_VALID_KEYS = [
  'n_rooms',
  'n_baths',
  'property_type',
  'property_type__in',
  'listing_type',
  'area',
  'date_out',
  'date_out__range',
  'area__range',
  'geog__dwithin',
  'distance_on_foot',
  'distance_by_car',
  'construction_year__range',
  'construction_type__in',
  'build_status__in',
  'distribution__in',
  'n_rooms__in',
  'n_baths__in',
  'n_floors',
  'floor',
  'parking_spaces__range',
  'has_elevator',
  'has_storage',
  'has_terrace',
  'has_pool',
  'has_garage',
  'has_air_conditioner',
  'has_smoke_outlet',
  'has_heating',
  'has_kitchen',
  'has_storefront',
  'has_bathroom',
  'is_on_the_corner',
  'has_facade',
  'has_warehouse',
  'has_security_system',
  'has_loading_dock',
  'has_access_24h',
  'orientation__in',
  'same_neighbour',
  'middle_floor',
  'features__in',
  'features_choices__in',
  'build_status',
  'n_floor',
  'has_automatic_door',
  'is_covered',
  'parking_type__in',
  'offices_distribution__in',
  'commercial_distribution',
  'is_exterior'
];

export const FILTER_FIELDS_ARRAY_ACCEPTED = [
  'features__in',
  'features_choices__in',
  'distribution__in',
  'offices_distribution__in',
  'construction_type__in',
  'orientation__in',
  'property_type__in',
  'parking_type__in'
];

export const DRAW_STYLES_MAP = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'draw_polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#1778FB',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'draw_polygon']],
    paint: {
      'fill-color': '#1778FB',
      'fill-outline-color': '#1778FB',
      'fill-opacity': 0.1
    }
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#1778FB',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  // vertex points
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 4,
      'circle-stroke-color': '#1778FB',
      'circle-stroke-width': 2,
      'circle-color': '#FFF'
    }
  },
  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'draw_polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#1778FB',
      'line-width': 2
    }
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'draw_polygon']],
    paint: {
      'fill-color': '#1778FB',
      'fill-outline-color': '#1778FB',
      'fill-opacity': 0
    }
  },
  // polygon outline
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'draw_polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#1778FB',
      'line-width': 2
    }
  }
];

export const MAP_DISTANCE_SOURCE_ID = 'distance';
export const MAP_DISTANCE_LAYER_ID = MAP_DISTANCE_SOURCE_ID;
export const MAP_NEIGHBOURHOOD_SOURCE_ID = 'neighbourhood';
export const MAP_NEIGHBOURHOOD_LAYER_OUTLINE_ID = MAP_NEIGHBOURHOOD_SOURCE_ID + 'outline';
export const MAP_NEIGHBOURHOOD_LAYER_FILL_ID = MAP_NEIGHBOURHOOD_SOURCE_ID + 'fill';
export const MAP_COMPARABLES_SOURCE_ID = 'comparables';
export const MAP_COMPARABLES_LAYER_ID = MAP_COMPARABLES_SOURCE_ID;
export const DELETE_DRAWING_ID = 'delete_drawing_layer';
export const DELETE_DRAWING_SOURCE_ID = 'delete_drawing_source';
export const MAP_CLOSE_IMAGE_ID = 'close_image';
