import * as Yup from 'yup';
import PATHS from '../../routes/paths';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputField, FormGroup, Button, Checkbox } from '@uda/bento-components';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import googleIcn from '../../assets/images/icn-google.svg';
import GoogleLogin from 'react-google-login';
import FeatureFlag from '../../components/FeatureFlag';
import { useSelector } from 'react-redux';

const Heading = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  @media only screen and (max-width: 720px) {
    font-size: 24px;
  }
`;

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacings.small3};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Separator = styled.i`
  position: relative;
  display: block;
  margin: ${({ theme }) => theme.spacings.medium1} 0;
  ${({ theme }) => theme.texts.p2b};
  color: ${({ theme }) => theme.color.charcoal600};
  text-align: center;
  top: 9px;

  > span {
    background-color: white;
    padding: 0 12px;
    position: relative;
    top: -9px;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.charcoal400};
  }
`;

const StyledImage = styled.img`
  position: relative;
  top: 2px;
`;

const SignUpWrapper = styled.div`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-top: ${({ theme }) => theme.spacings.small4};
`;

const AuthSignInForm = ({
  signIn = () => {},
  handleGoogleError = () => {},
  handleGoogleSuccess = () => {},
  closeNotification = () => {}
}) => {
  const intl = useIntl();

  const { loginReady } = useSelector(state => state.auth);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('errors.invalid_email').required('errors.required'),
    password: Yup.string().required('errors.required'),
    remember: Yup.bool()
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false
    },
    validationSchema: validationSchema,
    async onSubmit({ email, password, remember }, { setSubmitting }) {
      await signIn({ email, password, remember });
      setSubmitting(false);
    }
  });

  const { handleSubmit, isSubmitting, errors, touched, handleBlur, handleChange, values } = formik;

  return (
    <div>
      <Heading>
        <FormattedMessage id="auth.sign_in.title" />
      </Heading>
      <form onSubmit={handleSubmit} className={loginReady ? 'loading' : null}>
        <FormGroup>
          <InputField
            autoComplete="email"
            disabled={loginReady}
            error={!!errors.email && !!touched.email}
            help={(errors.email && touched.email && intl.formatMessage({ id: errors.email })) || ''}
            label={intl.formatMessage({ id: 'auth.sign_in.email' })}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'auth.sign_in.email_placeholder' })}
            required
            type="email"
            value={values.email}
          />
        </FormGroup>
        <FormGroup>
          <InputField
            autoComplete="current-password"
            disabled={loginReady}
            error={!!errors.password && !!touched.password}
            help={
              (errors.password &&
                touched.password &&
                intl.formatMessage({ id: errors.password })) ||
              ''
            }
            label={intl.formatMessage({ id: 'auth.sign_in.password' })}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'auth.sign_in.password_placeholder' })}
            required
            type="password"
            value={values.password}
          />
        </FormGroup>
        <Button type="submit" variant="primary" size="large" loading={loginReady} block>
          <FormattedMessage id="auth.sign_in.sign_in" />
        </Button>
        <Wrapper>
          <Checkbox
            checked={values.remember ? 'checked' : false}
            disabled={loginReady}
            name="remember"
            label={intl.formatMessage({ id: 'auth.sign_in.remember' })}
            onChange={handleChange}
            value={values.remember}
          />
          <Link
            className="primary large"
            title={intl.formatMessage({ id: 'auth.sign_in.forgot' })}
            to={PATHS.resetPassword}
          >
            <FormattedMessage id="auth.sign_in.forgot" />
          </Link>
        </Wrapper>
      </form>
      <FeatureFlag name="hide_google_auth">
        {isHidden =>
          !isHidden ? (
            <>
              <Separator>
                <span>
                  <FormattedMessage id="auth.sign_up.or" />
                </span>
              </Separator>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID}
                render={renderProps => (
                  <Button
                    size="large"
                    variant="secondary"
                    iconLeft={<StyledImage src={googleIcn} alt="Google" />}
                    block
                    onClick={e => {
                      closeNotification();
                      renderProps.onClick(e);
                    }}
                    disabled={renderProps.disabled}
                  >
                    <FormattedMessage id="auth.sign_in.sign_in_google" />
                  </Button>
                )}
                onSuccess={handleGoogleSuccess}
                onFailure={handleGoogleError}
                cookiePolicy={'single_host_origin'}
                accessType={'offline'}
                responseType={'code'}
              />
            </>
          ) : null
        }
      </FeatureFlag>
      <SignUpWrapper>
        <FormattedMessage
          id="auth.sign_in.no_account"
          values={{
            link: (
              <a
                href={PATHS.demo_form}
                title={intl.formatMessage({ id: 'auth.sign_up.create' })}
                className="primary"
              >
                <FormattedMessage id="auth.sign_up.create" />
              </a>
            )
          }}
        />
      </SignUpWrapper>
    </div>
  );
};

export default AuthSignInForm;
