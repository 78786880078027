import { useDispatch } from 'react-redux';
import AuthResetPasswordForm from '../forms/AuthResetPasswordForm';
import { resetPassword } from '../actions/auth';

const AuthResetPasswordContainer = () => {
  const dispatch = useDispatch();

  const handleResetPassword = ({ email }) => {
    dispatch(resetPassword({ email }));
  };
  return <AuthResetPasswordForm resetPassword={handleResetPassword} />;
};

export default AuthResetPasswordContainer;
