import { ANALYTICS_COOKIE_KEY, REGISTER_COOKIE_KEY, ADS_COOKIE_KEY } from '../constants.js';

export const userSetCookiesConfig = () => {
  if (window.location !== window.parent.location) {
    return true;
  } else {
    const analyticsPermissions = document.cookie.indexOf(`${ANALYTICS_COOKIE_KEY}=`) >= 0;
    const registerPermissions = document.cookie.indexOf(`${REGISTER_COOKIE_KEY}=`) >= 0;
    const adsPermissions = document.cookie.indexOf(`${ADS_COOKIE_KEY}=`) >= 0;

    return analyticsPermissions && registerPermissions && adsPermissions;
  }
};
