import { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import { IconChevronLeft, IconChevronRight, IconSuccess } from '@uda/bento-components';
import { useEffect } from 'react';
import useInterval from '../../utils/hooks/useInterval';

const StyledContainer = styled.div`
  position: relative;
  height: ${({ theme }) => theme.spacings.medium1};
  width: 100%;
  left: 0;
  top: 0;
  background-color: #303ab2;
  overflow: hidden;

  ul {
    list-style-type: none;
    width: 300vw;
    height: ${({ theme }) => theme.spacings.medium1};
  }
`;

const StyledListItem = styled.li`
  ${({ theme }) => theme.texts.p1b};
  float: left;
  position: relative;
  color: ${({ theme }) => theme.color.white};
  line-height: ${({ theme }) => theme.spacings.medium1};
  text-align: center;
  transform: translateX(-100vw);

  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  transition: ${({ theme }) => theme.global.transitionM};
  z-index: 0;

  h4 {
    line-height: 16px;
  }
`;

const StyledButton = styled.div`
  position: absolute;
  top: 0;
  color: white;
  background: #303ab2;
  width: ${({ theme }) => theme.spacings.medium3};
  height: ${({ theme }) => theme.spacings.medium1};
  line-height: ${({ theme }) => theme.spacings.medium1};
  ${({ left }) => (left ? `left: 0;` : `right: 0;`)}
  text-align: ${({ left }) => (left ? `right` : `left`)};

  & :hover {
    cursor: pointer;
  }
  z-index: 1;
`;

const INTERVAL_TIME = 3000;
const SLIDE_WIDTH = 100;
const tips = ['first', 'second', 'third'];

const AuthTipsFotocasa = () => {
  const theme = useTheme();
  const carouselRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentTransl, setCurrentTransl] = useState(tips.map(e => -SLIDE_WIDTH));
  const [isRunning, setIsRunning] = useState(true);

  useInterval(
    () => {
      if (carouselRef?.current?.children) {
        setNextTip();
      }
    },
    isRunning ? INTERVAL_TIME : null
  );

  useEffect(() => {
    const children = carouselRef.current.children;
    carouselRef.current.insertBefore(children[tips.length - 1], children[0]);
  }, [carouselRef]);

  const setNextTip = inde => {
    const outer = activeIndex % tips.length;
    let current = [...currentTransl];

    const children = carouselRef.current.children;

    tips.forEach((e, i) => {
      children[i].style.opacity = '1';
      children[i].style.transform = `translateX(${currentTransl[i] - SLIDE_WIDTH}vw)`;
      current[i] = current[i] - SLIDE_WIDTH;
    });

    const total = current[outer] + tips.length * SLIDE_WIDTH;

    children[outer].style.transform = `translateX(${total}vw)`;
    children[outer].style.opacity = '0';

    current[outer] = total;

    setCurrentTransl(current);

    setActiveIndex(activeIndex + 1);
  };

  const setPreviousTip = () => {
    let prev = activeIndex - 1;
    if (prev === -1) {
      prev = tips.length - 1;
    }

    const outer = prev % tips.length;
    let current = [...currentTransl];

    const children = carouselRef.current.children;

    tips.forEach((e, i) => {
      children[i].style.opacity = '1';
      children[i].style.transform = `translateX(${currentTransl[i] + SLIDE_WIDTH}vw)`;
      current[i] = current[i] + SLIDE_WIDTH;
    });

    const total = current[outer] - tips.length * SLIDE_WIDTH;

    children[outer].style.transform = `translateX(${total}vw)`;
    children[outer].style.opacity = '0';

    current[outer] = total;

    setCurrentTransl(current);
    setActiveIndex(prev);
  };

  return (
    <StyledContainer
      tips={tips.length}
      onMouseEnter={() => setIsRunning(false)}
      onMouseLeave={() => setIsRunning(true)}
    >
      <StyledButton left={true}>
        <IconChevronLeft onClick={setPreviousTip} customColor={theme.color.primary300} />
      </StyledButton>
      <ul ref={carouselRef}>
        {tips.map((e, i) => (
          <StyledListItem key={i} index={activeIndex}>
            <h4>
              <IconSuccess customColor={theme.color.white} />
            </h4>
            <FormattedMessage id={`auth.sign_up.fotocasa.${e}`} />
          </StyledListItem>
        ))}
      </ul>
      <StyledButton>
        <IconChevronRight onClick={setNextTip} customColor={theme.color.primary300} />
      </StyledButton>
    </StyledContainer>
  );
};

export default AuthTipsFotocasa;
