import { FormattedMessage } from 'react-intl';
import { Grid, IconChevronLeft } from '@uda/bento-components';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { closeNotification } from '../../actions/notification';
import NotificationTranslate from '../../components/Notifications/NotificationTranslate';
import NotificationWrapper from '../../components/Notifications/NotificationWrapper';
import SettingsMobileWrapper from './SettingsMobileWrapper';

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`;

const MenuWrapper = styled.div`
  padding: ${({ theme: { spacings } }) => `${spacings.small3} ${spacings.small4}`};
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.color.charcoal300};
`;

const StyledNotificationWrapper = styled(props => <NotificationWrapper {...props} />)`
  right: ${({ theme }) => theme.spacings.small2};
`;

const BackText = styled.p`
  ${({ theme }) => theme.texts.p1b};
  cursor: pointer;
  color: ${({ theme }) => theme.color.charcoal600};
`;

const SettingsModalMobile = ({
  children,
  isDetailOpen,
  basicSettings,
  additionalSettings,
  onClose = () => {},
  onBackToSettings = () => {}
}) => {
  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification);
  const isSettingsOpen = useSelector(state => state.settings.isSettingsOpen);

  const transformErrors = error => {
    return { id: error.message };
  };
  const handleClose = () => {
    dispatch(closeNotification());
  };

  const isNotificationOpen = notification && notification.isOpen;

  return (
    <>
      {isSettingsOpen && isDetailOpen ? (
        <SettingsMobileWrapper
          isOpen={isSettingsOpen && isDetailOpen}
          header={
            <ModalHeader>
              <IconChevronLeft color="secondary" />
              <BackText variant="secondary" onClick={onBackToSettings}>
                Back to settings
              </BackText>
            </ModalHeader>
          }
          onClose={onClose}
        >
          {isNotificationOpen ? (
            <StyledNotificationWrapper>
              <NotificationTranslate
                transformErrors={transformErrors}
                notification={notification}
                onClose={handleClose}
              />
            </StyledNotificationWrapper>
          ) : null}
          {children}
        </SettingsMobileWrapper>
      ) : (
        <SettingsMobileWrapper
          className={'full-width'}
          header={
            <h3>
              <FormattedMessage id="settings.title" />
            </h3>
          }
          onClose={onClose}
          isOpen={isSettingsOpen}
          full={true}
          zIndex={1000}
          enableClickOutside={false}
        >
          <Grid columns="1fr" style={{ height: '100%' }}>
            <MenuWrapper>
              {basicSettings}
              {additionalSettings}
            </MenuWrapper>
          </Grid>
        </SettingsMobileWrapper>
      )}
    </>
  );
};

export default SettingsModalMobile;
