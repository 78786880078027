import * as Yup from 'yup';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import SettingsActions from './SettingsActions';
import PaymentsCheckoutBilling from '../../Payments/components/PaymentsCheckoutBilling';
import { isValidSpanishId } from '../../utils/isValidSpanishId';

const StyledComponentWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    padding: ${({ theme: { spacings } }) => `${spacings.small3} ${spacings.medium1}`};
    margin-bottom: ${({ theme }) => theme.spacings.large2};
  }
`;

const StyledHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.small4};
`;

const SettingsBilling = ({ onSubmit, values }) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    address: Yup.string().required(intl.formatMessage({ id: 'errors.required' })),
    postal_code: Yup.string().matches(/^\d+$/, intl.formatMessage({ id: 'errors.only_numbers' })),
    cif: Yup.string()
      .nullable()
      .test('valid-id', intl.formatMessage({ id: 'errors.invalid_cif' }), value =>
        isValidSpanishId(value)
      ),
    city: Yup.string().matches(/^([^0-9]*)$/, intl.formatMessage({ id: 'errors.only_letters' }))
  });

  const { company, cif, country, city, address, postal_code } = values;

  const formik = useFormik({
    initialValues: {
      company,
      cif,
      country,
      city,
      address,
      postal_code
    },
    validationSchema: validationSchema,
    onSubmit(values, { setSubmitting }) {
      onSubmit(values);
      setSubmitting(false);
    }
  });

  const { handleSubmit, isSubmitting } = formik;

  const isSubmitDisabled = isSubmitting;

  function handleKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <StyledComponentWrapper>
      <StyledHeading>
        <FormattedMessage id="settings.billing.title" />
      </StyledHeading>
      <div>
        <form
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit}
          className={isSubmitting ? 'loading' : null}
        >
          <PaymentsCheckoutBilling isLoading={isSubmitDisabled || isSubmitting} formik={formik} />
          <SettingsActions isSubmitDisabled={isSubmitDisabled} isSubmitting={isSubmitting} />
        </form>
      </div>
    </StyledComponentWrapper>
  );
};

export default SettingsBilling;
