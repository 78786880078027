const formattedData = item => {
  const area = item?.area || 0;
  const price = item?.price?.value || 0;
  const sqm_value = item?.sqm_value?.value || 0;
  return { area, price, sqm_value };
};

export const averageCalc = (items, value) => {
  if (items.length === 0 || value === '') return null;

  const total = items.reduce((acc, item) => acc + formattedData(item)[value], 0);
  return total / items.length;
};
