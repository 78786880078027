export const formatSelectedAssets = (selectedAssets, rows) => {
  let assets = {};

  const selectedIds = Object.keys(selectedAssets);

  selectedIds.forEach(selected => {
    assets[selected] = { ...selectedAssets[selected] };
  });

  if (rows && rows.length) {
    rows.forEach(({ data, selected }) => {
      if (selected) {
        assets[data.id.primary.value] = {
          id: data.id.primary.value,
          price: data.asset_value.primary,
          unit_price: data.sqm_value.primary,
          area: data.area.primary,
          subtypology: data.usage.secondary[0].value
        };
      } else {
        delete assets[data.id.primary.value];
      }
    });
  }

  return assets;
};

export const formatSelectedRows = (listRows, rowsSelected) => {
  rowsSelected.forEach(row => {
    listRows[row.rowIndex] = {
      ...listRows[row.rowIndex],
      selected: {
        primary: {
          value: row.selected
        }
      }
    };
  });

  return listRows;
};

export const formatSelectedComparables = (comparables, rowsSelected) => {
  Object.keys(rowsSelected).forEach(idSelected => {
    const comparableIndex = comparables.rows.findIndex(
      ({ id }) => id.primary.value.toString() === idSelected.toString()
    );
    if (comparableIndex !== -1 && comparables.rows[comparableIndex].selected) {
      comparables.rows[comparableIndex] = {
        ...comparables.rows[comparableIndex],
        selected: {
          primary: {
            value: true
          }
        }
      };
    }
  });

  return comparables;
};
