import { LISTING_TYPE_EQUIVALENCE } from 'constants';
import { getUrlParams } from './getUrlParams';

export const getFiltersFromUrl = () => {
  const urlParams = getUrlParams();

  const allowedFilters = ['listing_type', 'area', 'n_rooms', 'n_baths', 'floor'];
  const filters = Object.entries(urlParams).reduce(
    (obj, [param, value]) => {
      if (!allowedFilters.includes(param)) return obj;
      return { ...obj, [param]: Number(value) };
    },
    { listing_type: LISTING_TYPE_EQUIVALENCE.residential }
  );
  return filters;
};
