import * as CONSTANTS from '../constants.js';
import PATHS from '../routes/paths';
import { setLocalStorage } from '../utils';
import { history } from '../App';
import USER_PAGES from '../routes/userPages';
import { stringToCamelCase } from '../utils/stringToCamelCase';

export const goToProduct =
  (product, isRedirect = true) =>
  () => {
    setLocalStorage(CONSTANTS.PRODUCT_KEY, product);
    history.push(PATHS[stringToCamelCase(product)] ? PATHS[stringToCamelCase(product)] : '/no-access')
  };

export const checkPrivateProduct = productSlug => (dispatch, getState) => {
  const slug = productSlug === 'originalasset' ? 'asset' : productSlug;
  const { products, default_product } = getState().user.data;
  const [firstProduct] = products;

  
  if (USER_PAGES.includes(slug)) return;
  
  if (slug && slug !== 'asset' && !products.includes(slug)) {
    // sales route redirection
    const altProduct = products.includes(default_product) ? default_product : firstProduct;

    altProduct && dispatch(goToProduct(altProduct, true));
  }
};

export const goToRedirection = (product, redirect) => () => {
  setLocalStorage(CONSTANTS.PRODUCT_KEY, product);
  history.push(redirect);
};
