import {
  IconBathroom,
  IconBedroom,
  IconElevator,
  IconParking,
  IconStorage,
  IconSwimmingPool,
  IconTerrace
} from '@uda/bento-components';

export const getInitialColumnState = data => {
  return data.map(e => ({
    colId: e.field,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null
  }));
};

export const getCellAlign = field => {
  switch (field) {
    case 'source':
    case 'usage':
    case 'cadastre':
    case 'address':
    case 'build_status':
      return 'left';
    case 'asset_value':
    case 'sqm_value':
    case 'construction_year':
    case 'date_out':
    case 'area':
    case 'floor':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'nbaths':
    case 'rooms':
    case 'time_published':
    case 'distance':
      return 'right';
    case 'operation':
    case 'has_elevator':
    case 'has_garage':
    case 'has_pool':
    case 'has_storage':
    case 'has_terrace':
    case 'new_dev':
    case 'selected':
    default:
      return 'center';
  }
};

export const getCellWidth = field => {
  switch (field) {
    case 'selected':
      return { width: 35, minWidth: 35 };
    case 'floor':
      return { width: 80, minWidth: 80 };
    case 'rooms':
    case 'nbaths':
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'new_dev':
      return { width: 65, minWidth: 65 };
    case 'n_duplicates':
    case 'id_uda':
    case 'lat':
    case 'lon':
      return { width: 90, minWidth: 90 };
    case 'sqm_value':
    case 'construction_year':
      return { width: 90, minWidth: 90 };
    case 'operation':
    case 'source':
    case 'usage':
      return { width: 105, minWidth: 105 };
    case 'distance':
      return { width: 105, minWidth: 105 };
    case 'asset_value':
    case 'date_out':
    case 'area':
      return { width: 120, minWidth: 120 };
    case 'time_published':
    case 'build_status':
      return { width: 130, minWidth: 130 };
    case 'cadastre':
    case 'address':
      return { width: 240, minWidth: 240 };
    default:
      return { width: 100 };
  }
};

const getCellIcon = (field, sort) => {
  const iconColor = sort ? '#1778FB' : '#747D93';
  switch (field) {
    case 'rooms':
      return <IconBedroom customColor={iconColor} />;
    case 'nbaths':
      return <IconBathroom customColor={iconColor} />;
    case 'has_elevator':
      return <IconElevator customColor={iconColor} />;
    case 'has_terrace':
      return <IconTerrace customColor={iconColor} />;
    case 'has_garage':
      return <IconParking customColor={iconColor} />;
    case 'has_storage':
      return <IconStorage customColor={iconColor} />;
    case 'has_pool':
      return <IconSwimmingPool customColor={iconColor} />;
    default:
      return null;
  }
};

const getCellRenderer = field => {
  switch (field) {
    case 'selected':
      return 'checkboxCell';
    case 'operation':
      return 'operationCell';
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'new_dev':
      return 'booleanCell';
    case 'source':
    case 'usage':
    case 'price':
    case 'area':
    case 'construction_year':
    case 'rooms':
    case 'nbaths':
    case 'floor':
    case 'date_out':
    case 'time_published':
    case 'cadastre':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'address':
    case 'distance':
    default:
      return 'singleCell';
  }
};

const getCellVariant = field => {
  switch (field) {
    case 'usage':
    case 'source':
      return 'secondary';
    case 'price':
    case 'selected':
    case 'operation':
    case 'area':
    case 'construction_year':
    case 'rooms':
    case 'nbaths':
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'floor':
    case 'date_out':
    case 'time_published':
    case 'new_dev':
    case 'cadastre':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'address':
    case 'distance':
    default:
      return 'primary';
  }
};

const getCellSortType = field => {
  switch (field) {
    case 'selected':
      return null;
    case 'date_out':
      return 'date';
    case 'asset_value':
    case 'sqm_value':
    case 'area':
    case 'construction_year':
    case 'rooms':
    case 'nbaths':
    case 'floor':
    case 'time_published':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'distance':
      return 'number';
    case 'operation':
    case 'source':
    case 'usage':
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'new_dev':
    case 'cadastre':
    case 'address':
    default:
      return 'string';
  }
};

export const apiInternTable = data => {
  return {
    columns: data.columns.map(e => ({
      ...e,
      ...getCellWidth(e.field),
      icon: getCellIcon(e.field, e.sortDirection),
      align: getCellAlign(e.field),
      cellRenderer: getCellRenderer(e.field),
      variant: getCellVariant(e.field),
      sortType: getCellSortType(e.field),
      pinned: e.field === 'selected' ? 'left' : false,
      lockPinned: e.field === 'selected',
      longCell: e.field === 'address'
    })),
    rows: data.comparables,
    initialColumnState: getInitialColumnState(data.columns),
    pagination: data.pagination || {
      page: 1,
      page_size: 60,
      page_count: 60,
      total_count: data.comparables.length,
      next: ''
    }
  };
};

export const apiInternFiltersApplied = (filters = {}) => {
  return Object.fromEntries(Object.entries(filters).filter(([_, v]) => v != null));
};
