import { renameProvinceKeyName } from './apiIntern';

export const initialState = {
  plan: null,
  isLoading: false,
  isSettingsOpen: false,
  tabOpen: null,
  cancelReasons: [],
  paymentMethod: null,
  isPaymentMethodLoading: false,
  isCardOpen: false,
  isDetailOpen: false,
  billingInfo: null,
  isActionLoading: false,
  gdprStatus: null,
  gdprRequestDate: null
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'FETCH_BILLING_INFO':
    case 'UPDATE_BILLING_INFO':
    case 'FETCH_USER_PLAN':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_BILLING_INFO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        billingInfo: payload.data
      };
    case 'UPDATE_BILLING_INFO_FAIL':
    case 'FETCH_BILLING_INFO_FAIL':
      return {
        ...state,
        isLoading: false,
        billingInfo: null
      };
    case 'UPDATE_BILLING_INFO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isSettingsOpen: false
      };
    case 'UPDATE_MODAL':
      return {
        ...state,
        ...payload
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        isSettingsOpen: false
      };
    case 'FETCH_USER_PLAN_SUCCESS':
      return {
        ...state,
        isLoading: false,
        plan: renameProvinceKeyName(payload.data)
      };
    case 'FETCH_USER_PLAN_FAIL':
      return {
        ...state,
        isLoading: false,
        plan: null
      };
    case 'FETCH_CANCEL_REASONS_SUCCESS':
      return {
        ...state,
        cancelReasons: payload.data
      };
    case 'FETCH_PAYMENT_METHOD':
      return {
        ...state,
        isPaymentMethodLoading: true
      };
    case 'FETCH_PAYMENT_METHOD_SUCCESS':
      return {
        ...state,
        isPaymentMethodLoading: false,
        paymentMethod: payload.data
      };
    case 'FETCH_PAYMENT_METHOD_FAIL':
      return {
        ...state,
        isPaymentMethodLoading: false
      };
    case 'UPDATE_PAYMENT_LOADING':
      return {
        ...state,
        isPaymentMethodLoading: payload
      };
    case 'UPDATE_CARD_MODAL':
      return {
        ...state,
        isCardOpen: payload
      };

    case 'ADD_PROVINCE_TO_PLAN':
    case 'REMOVE_PROVINCE_TO_PLAN':
    case 'CANCEL_SUBSCRIPTION':
      return {
        ...state,
        isActionLoading: true
      };
    case 'ADD_PROVINCE_TO_PLAN_SUCCESS':
    case 'REMOVE_PROVINCE_TO_PLAN_SUCCESS':
    case 'ADD_PROVINCE_TO_PLAN_FAIL':
    case 'REMOVE_PROVINCE_TO_PLAN_FAIL':
    case 'CANCEL_SUBSCRIPTION_SUCCESS':
    case 'CANCEL_SUBSCRIPTION_FAIL':
      return {
        ...state,
        isActionLoading: false
      };
    case 'FETCH_GDPR_STATUS_SUCCESS':
      return {
        ...state,
        gdprStatus: payload.data.status,
        gdprRequestDate: payload.data.date_requested
      };
    default:
      return state;
  }
};

export default reducer;
