import { useEffect } from 'react';
import { Button, Grid, IconSuccess } from '@uda/bento-components';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import useCalculateHeaderHeight from '../../utils/hooks/useCalculateHeaderHeight';
import PaymentsSummaryBilling from '../components/PaymentsSummaryBilling';
import PaymentsSummaryOrder from '../components/PaymentsSummaryOrder';
import { FormattedMessage } from 'react-intl';
import { goToProduct } from '../../actions/redirections';
import PaymentsGoBack from '../components/PaymentsGoBack';
import { initSummary } from '../actions/payments';
import { closeNotification } from '../../actions/notification';
import { fetchUser } from '../../actions/user';

const StyledContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  height: calc(
    100vh -
      ${({ isScrollOnTop, offsetHeight }) => (isScrollOnTop ? `${offsetHeight + 56}px` : '96px')}
  );
`;

const StyledGrid = styled(props => <Grid {...props} />)`
  overflow: auto;
  padding: ${({ theme }) => theme.spacings.medium5} ${({ theme }) => theme.spacings.small4} 0;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100;
    width: 100%;
    padding: ${({ theme }) => theme.spacings.small3} ${({ theme }) => theme.spacings.small4} 0;
  }
`;

const StyledDescription = styled.p`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledSuccess = styled.h3`
  color: ${({ theme }) => theme.color.emerald600};
  margin-bottom: ${({ theme }) => theme.spacings.small1};
  svg {
    margin-left: ${({ theme }) => theme.spacings.small2};
    vertical-align: middle;
  }
`;

const StyledTitle = styled.h3`
  margin-top: ${({ theme }) => theme.spacings.medium3};
  margin-bottom: ${({ theme }) => theme.spacings.small4};
`;

const StyledEmail = styled.span`
  color: ${({ theme }) => theme.color.charcoal800};
`;

const StyledContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium2};
`;

const StyledRightBlock = styled.div`
  text-align: right;
  margin-bottom: ${({ theme }) => theme.spacings.medium3};
`;

const PaymentsCheckoutContainer = ({ product }) => {
  const isScrollOnTop = useSelector(state => state.layout.isScrollOnTop);
  const [headerHeight] = useCalculateHeaderHeight();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: summaryData } = useSelector(state => state.payments.summary);

  useEffect(() => {
    dispatch(initSummary());

    return () => {
      dispatch(closeNotification());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!summaryData) {
    return null;
  }

  const handleGoToProduct = async () => {
    await dispatch(fetchUser());
    dispatch(goToProduct(product));
  };

  return (
    <div>
      <PaymentsGoBack />
      <StyledContent isScrollOnTop={isScrollOnTop} offsetHeight={headerHeight}>
        <StyledGrid columns="2fr minmax(400px, 1fr)" gap={theme.spacings.medium4}>
          <div>
            <StyledContainer>
              <StyledSuccess>
                <FormattedMessage id="payments.summary.success" /> <IconSuccess size="large" />
              </StyledSuccess>
              <StyledDescription>
                <FormattedMessage
                  id="payments.summary.description"
                  values={{ email: <StyledEmail> {summaryData.email}</StyledEmail> }}
                />
              </StyledDescription>
              <StyledTitle>
                <FormattedMessage id="payments.billing.title" />
              </StyledTitle>
              <PaymentsSummaryBilling data={summaryData.billing_detail} />
            </StyledContainer>
          </div>
          <StyledRightBlock>
            <PaymentsSummaryOrder data={summaryData.payment_detail} />
            <Button primary size="large" onClick={handleGoToProduct}>
              <FormattedMessage id="payments.summary.start" values={{ product }} />
            </Button>
          </StyledRightBlock>
        </StyledGrid>
      </StyledContent>
    </div>
  );
};

export default PaymentsCheckoutContainer;
