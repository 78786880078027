import { Tooltip } from '@uda/bento-components';
import PortfolioListCardAvmLogo from '../components/PortfolioListCardAvmLogo';
import styled from 'styled-components';
import {
  apiInternHeader,
  apiInternGrid,
  apiInternGridSegments,
  apiInternGridValues,
  apiInternGridDrivers,
  apiInternCellLabel,
  apiInternTreeTableRows,
  apiInternTableRows,
  apiInternSetSortType,
  apiInternAssetCardsList
} from './apiIntern';

/* istanbul ignore file */

const StyledPulseLogo = styled.div`
  width: 11px;
  float: left;
  margin-right: ${({ theme }) => theme.spacings.small2};
  margin-top: ${({ theme }) => theme.spacings.small1};
`;

const StyledInlineLogoLabel = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDot = styled.span`
  width: 6px;
  height: 6px;
  background: ${({ theme }) => theme.color.charcoal600};
  display: inline-block;
  border-radius: 6px;
  margin-right: 4px;
  &.active {
    background: ${({ theme }) => theme.color.primary500};
  }
`;

export const internHeader = data => {
  for (const k in data.portfolio_summary) {
    data.portfolio_summary.sort((a, b) => (a.order > b.order ? 1 : -1));
    data.portfolio_summary[k] = apiInternHeader(data.portfolio_summary[k]);
  }
  return data;
};

export const internGrid = data => {
  for (const i in data) {
    for (const k in data[i].portfolio_summary) {
      // Portfolio Summary
      const portfolio_summary = data[i].portfolio_summary;
      portfolio_summary.sort((a, b) => (a.order > b.order ? 1 : -1));
      portfolio_summary[k] = apiInternGrid(portfolio_summary[k]);
    }
    for (const k in data[i].segments) {
      // Portfolio Segments
      const segments = data[i].segments;
      segments.sort((a, b) => (a.order > b.order ? 1 : -1));
      segments[k] = apiInternGridSegments(segments[k]);
    }
    if (data[i].detail != null) {
      for (const k in data[i].detail.values) {
        // Portfolio Values
        const portfolio_values = data[i].detail.values;
        portfolio_values.sort((a, b) => (a.order > b.order ? 1 : -1));
        portfolio_values[k] = apiInternGridValues(portfolio_values[k]);
      }
      for (const k in data[i].detail.drivers) {
        // Portfolio Drivers
        const drivers = data[i].detail.drivers;
        drivers[k] = apiInternGridDrivers(drivers[k]);
      }
    }
  }
  return { items: data };
};

export const internTreeTable = (data, sorting, distribution) => {
  const { sort, order } = sorting;
  const { columns, rows, key = 'tableId' } = data;

  return {
    key,
    columns: columns
      .filter(({ id }) => id !== 'portfolio_id')
      .map(({ id, sort: sortKpis = [] }) => {
        return {
          headerName: apiInternCellLabel(id),
          field: id,
          ...setCellTreeWidth(id),
          cellRenderer: setCellRenderer(id),
          variant: setCellVariant(id),
          highlight: setCellTreeHighlight(id, distribution),
          align: setCellAlign(id),
          icon: setHeaderIcon(id),
          sort: sortKpis,
          sortType: apiInternSetSortType(id),
          sortDirection: sortKpis.find(kpi => kpi.value === sort) ? order : null
        };
      }),
    rows: apiInternTreeTableRows(rows)
  };
};

const setSort = (id, kpis) => {
  switch (id) {
    case 'valuer_name':
      return [];
    default:
      return kpis;
  }
};

export const internTable = (data, sorting) => {
  const { sort, order } = sorting;
  const { columns, portfolios, assets, contracts, developments, metadata } = data;
  columns.sort((a, b) => (a.order > b.order ? 1 : -1));
  const result = {
    ...metadata,
    columns: columns
      .filter(({ id }) => id !== 'portfolio_id')
      .map(({ id, sort: sortKpis = [], hide = null, ...metadata }) => {
        return {
          headerName: apiInternCellLabel(id),
          field: id,
          width: setCellWidth(id),
          minWidth: setCellWidth(id),
          cellRenderer: setCellRenderer(id),
          variant: setCellVariant(id),
          highlight: setCellHightlight(id),
          align: setCellAlign(id),
          icon: setHeaderIcon(id),
          sortType: apiInternSetSortType(id),
          hide: hide === null ? setDefaultIsColumnHide(id) : hide,
          sort: setSort(id, sortKpis),
          sortDirection: sortKpis.find(kpi => kpi.value === sort) ? order : null,
          metadata
        };
      }),
    rows: apiInternTableRows(portfolios || assets || contracts || developments)
  };
  return {
    columns: portfolios
      ? result.columns.concat({
          headerName: '',
          field: 'actions',
          width: 65,
          maxWidth: 65,
          minWidth: 65,
          cellRenderer: setCellRenderer('actions'),
          variant: setCellVariant('actions'),
          highlight: setCellHightlight('actions'),
          align: setCellAlign('actions'),
          icon: setHeaderIcon('actions'),
          sortDirection: null,
          pinned: 'right',
          lockPinned: true
        })
      : result.columns,
    rows: result.rows
  };
};

const setHeaderIcon = id => {
  switch (id) {
    case 'av_main':
    case 're_main':
    case 'units_main':
    case 'accuracy':
    case 'liquidity':
    case 'cycle':
    case 'risk':
    case 'affordability':
      return (
        <Tooltip title={['Refers only to', <br />, 'Residential typology']}>
          <StyledDot />
        </Tooltip>
      );
    case 'avm':
    case 'asset_avm':
      return (
        <StyledPulseLogo>
          <PortfolioListCardAvmLogo />
        </StyledPulseLogo>
      );
    default:
      return null;
  }
};

const setDefaultIsColumnHide = id => {
  switch (id) {
    default:
      return false;
  }
};

const setCellRenderer = id => {
  switch (id) {
    case 'actions':
      return 'actionsCell';
    case 'liquidity':
      return 'liquidityCell';
    case 'accuracy':
      return 'rankCell';
    case 'risk':
      return 'riskCell';
    case 'cycle':
      return 'cycleCell';
    case 'valuation_status':
      return 'statusCell';
    case 'portfolio_type':
      return 'portfolioTypeCell';
    case 'vpo':
    case 'proindividual':
    case 'ownership':
    case 'rented':
    case 'squatted':
    case 'contract_insolvency':
    case 'contract_judicial':
      return 'booleanCell';
    case 'discount':
      return 'discountCell';
    case 'av_dashboard':
    case 're_value_dashboard':
    case 'ob_dashboard':
      return 'dualCell';
    default:
      return 'singleCell';
  }
};

const setCellVariant = id => {
  switch (id) {
    case 'seller':
    case 'client':
    case 'av_location':
    case 'av_use':
    case 'segment':
    case 'transaction_date':
    case 'year':
    case 'borrower':
    case 'typology':
    case 'default_date':
    case 'origin_date':
    case 'province':
    case 'locality':
    case 'asset_segment':
    case 'develop_typology':
    case 'asset_typology':
    case 'full_address':
    case 'region':
    case 'usable_area':
    case 'cadastral_area':
    case 'residence':
    case 'contract_status':
    case 'contract_debtor':
    case 'contract_type':
    case 'av_subuse':
    case 'review_type':
    case 'valuation_type':
    case 'valuer_name':
    case 'valuation_status':
    case 'last_update':
    case 'location':
      return 'secondary';
    default:
      return 'primary';
  }
};

const setCellAlign = id => {
  switch (id) {
    case 'units':
    case 'units_dashboard':
    case 'av':
    case 're':
    case 'price':
    case 'contracts':
    case 'colaterals':
    case 'ob':
    case 'insolvency':
    case 'judicial':
    case 'ltv':
    case 'ltv_lien_1':
    case 'av_main':
    case 're_main':
    case 'units_main':
    case 'avm':
    case 'liquidity':
    case 'cycle':
    case 'risk':
    case 'affordability':
    case 'asset_av':
    case 'asset_re_value':
    case 'asset_avm':
    case 'latitude':
    case 'longitude':
    case 'monthly_rate':
    case 'id_client_asset':
    case 'id_client_contract':
    case 'av_dashboard':
    case 're_value_dashboard':
    case 'ob_dashboard':
    case 'ltv_dashboard':
    case 'collaterals_dashboard':
    case 'area':
    case 'upb':
    case 'built_year':
    case 'av_lien_1':
    case 'col_lien_1':
      return 'right';
    case 'accuracy':
    case 'portfolio_type':
    case 'vpo':
    case 'proindividual':
    case 'ownership':
    case 'rented':
    case 'squatted':
    case 'discount':
    case 'contract_insolvency':
    case 'contract_judicial':
      return 'center';
    default:
      return 'left';
  }
};

const setCellHightlight = id => {
  switch (id) {
    case 'liquidity':
    case 'cycle':
    case 'risk':
    case 'affordability':
    case 'av_main':
    case 're_main':
    case 'units_main':
    case 'avm':
    case 'accuracy':
    case 'asset_avm':
    case 'valuer_name':
    case 'review_type':
    case 'valuation_type':
    case 'valuation_status':
    case 'last_update':
      return true;
    default:
      return false;
  }
};

export const setCellTreeHighlight = (id, distribution) => {
  switch (id) {
    case 'units_dashboard':
      return distribution === 'units' ? true : false;
    case 'av_dashboard':
      return distribution === 'av' ? true : false;
    case 're_value_dashboard':
      return distribution === 'rev' ? true : false;
    default:
      return false;
  }
};

const setCellTreeWidth = id => {
  switch (id) {
    case 'discount':
    case 'units_dashboard':
      return { width: 57 };
    case 'ltv_dashboard':
      return { width: 60 };
    case 'collaterals_dashboard':
      return { width: 60 };
    case 'av_dashboard':
    case 'ob_dashboard':
    case 're_value_dashboard':
    case 'ob_contract_dashboard':
      return { width: 110, maxWidth: 120 };
    default:
      return null;
  }
};

const setCellWidth = id => {
  switch (id) {
    case 'discount':
    case 'units_dashboard':
      return 70;
    case 'area':
    case 'asset_id':
    case 'built_year':
    case 'contract_debtor':
    case 'contract_insolvency':
    case 'contract_judicial':
    case 'contract_status':
    case 'contract_type':
    case 'cycle':
    case 'default_date':
    case 'develop_id':
    case 'liquidity':
    case 'name':
    case 'origin_date':
    case 'ownership':
    case 'portfolio_type':
    case 'price':
    case 'proindividual':
    case 'rented':
    case 'risk':
    case 'squatted':
    case 'units':
    case 'vpo':
      return 110;
    case 'affordability':
    case 'asset_av':
    case 'asset_avm':
    case 'asset_re_value':
    case 'av':
    case 'av_dashboard':
    case 'av_lien_1':
    case 'av_main':
    case 'client':
    case 'col_lien_1':
    case 'insolvency':
    case 'judicial':
    case 'last_update':
    case 'latitude':
    case 'longitude':
    case 'ltv':
    case 'ltv_lien_1':
    case 'ob':
    case 'province':
    case 're_value_dashboard':
    case 'review_type':
    case 'seller':
    case 'units_main':
    case 'upb':
      return 120;
    case 'asset_segment':
    case 'asset_typology':
    case 'av_subuse':
    case 'avm':
    case 'borrower':
    case 'colaterals':
    case 'develop_typology':
    case 're':
    case 'segment':
    case 'typology':
    case 'usable_area':
    case 'valuation_status':
      return 130;
    case 'valuation_type':
    case 'accuracy':
    case 'contracts':
    case 'id_client_asset':
    case 'id_client_contract':
    case 'locality':
    case 'monthly_rate':
    case 're_main':
    case 'transaction_date':
    case 'year':
      return 140;
    case 'av_use':
    case 'cadastral_area':
    case 'residence':
      return 155;
    case 'av_location':
    case 'location':
      return 165;
    case 'region':
      return 175;
    case 'client_contract_id':
      return 200;
    case 'full_address':
    case 'valuer_name':
      return 290;
    default:
      return null;
  }
};

export const internAssetCardsList = cards =>
  cards.map(card => {
    card.main = card.main.map(item => {
      if (item.id === 'asset_avm') {
        item.label = (
          <StyledInlineLogoLabel>
            <StyledPulseLogo>
              <PortfolioListCardAvmLogo />
            </StyledPulseLogo>
            <span>AVM</span>
          </StyledInlineLogoLabel>
        );
      }
      return item;
    });
    card.main = apiInternAssetCardsList(card.main);
    card.kpis = apiInternAssetCardsList(card.kpis);
    card.properties = apiInternAssetCardsList(card.properties);
    return card;
  });
