import { setCookie, setLocalStorage } from '../../utils';
import * as CONSTANTS from '../../constants.js';
import { history } from '../../App';
import PATHS from '../../routes/paths';
import { initUser, resetUser } from '../../actions/user';
import { openNotification } from '../../actions/notification';

export const initApp =
  ({ token, remember, strapi_token }) =>
  async dispatch => {
    dispatch(setToken({ token, remember, strapiToken: strapi_token }));
    dispatch(initUser());
  };

export const initAppFromIframe = //google auth

    ({ token, strapi_token }) =>
    async dispatch => {
      dispatch(resetUser());
      dispatch(setToken({ token, strapiToken: strapi_token }));
      dispatch(initUser());

      history.push(PATHS.base);
    };

export const setToken =
  ({ token, remember = false, strapiToken }) =>
  dispatch => {
    if (remember) {
      setCookie(CONSTANTS.TOKEN_KEY, token, { expires: 365 });
      setCookie(CONSTANTS.STRAPI_TOKEN_KEY, strapiToken, { expires: 365 });
    } else {
      setLocalStorage(CONSTANTS.TOKEN_KEY, token);
      setLocalStorage(CONSTANTS.STRAPI_TOKEN_KEY, strapiToken);
    }

    dispatch({
      type: 'SAVE_TOKEN',
      payload: token
    });
    dispatch({
      type: 'SAVE_STRAPI_TOKEN',
      payload: strapiToken
    });
  };

export const resetPassword =
  ({ email }) =>
  async dispatch => {
    const action = await dispatch(fetchResetPassword({ email }));
    if (action.type === 'RESET_PASSWORD_SUCCESS' && !action.error) {
      history.push(PATHS.signIn);
    }
  };

export const fetchResetPassword = ({ email }) => ({
  type: 'RESET_PASSWORD',
  payload: {
    request: {
      method: 'GET',
      url: '/auth/reset-password/',
      params: {
        email
      }
    }
  }
});

export const changePassword =
  ({ password, token }) =>
  async dispatch => {
    const action = await dispatch(fetchChangePassword({ password, token }));
    if (action.type === 'CHANGE_PASSWORD_SUCCESS' && !action.error) {
      history.push(PATHS.signIn);
    }
  };

export const fetchChangePassword = ({ password, token }) => {
  return {
    type: 'CHANGE_PASSWORD',
    payload: {
      request: {
        method: 'PATCH',
        url: '/auth/change-password/',
        data: {
          password,
          token
        }
      }
    }
  };
};

const handleLoginReady = (dispatch, isReady) => dispatch(setLoginReady(isReady));

export const signIn =
  ({ email, password, remember }) =>
  async dispatch => {
    handleLoginReady(dispatch, true);
    const action = await dispatch(fetchSignIn({ email, password }));
    if (action.type === 'SIGN_IN_SUCCESS' && !action.error) {
      const { token, strapi_token } = action.payload.data;
      dispatch(initApp({ token, remember, strapi_token }));
    } else if (action.type === 'SIGN_IN_FAIL') {
      const error = action.error.meta;
      handleLoginReady(dispatch, false);
      if (error.code === CONSTANTS.ID_STATUS_CODE.PENDING_VERIFICATION) {
        history.push(`${PATHS.checkEmail}?email=${email}`);
      }

      dispatch(openNotification('error', { ...error, type: action.type }));
    }

    return action;
  };

export const fetchSignIn = ({ email, password }) => ({
  type: 'SIGN_IN',
  payload: {
    request: {
      method: 'POST',
      url: '/auth/login/',
      data: {
        email,
        password
      }
    }
  }
});

export const setLoginReady = isReady => ({
  type: 'LOGIN_READY',
  payload: isReady
});

export const signUp =
  ({ email, product = 'opportunities' }) =>
  async dispatch => {
    const action = await dispatch(fetchSignUp({ email, product }));
    if (action.type === 'SIGN_UP_SUCCESS' && !action.error) {
      history.push(`${PATHS.checkEmail}?email=${email}`);
    } else if (action.type === 'SIGN_UP_FAIL') {
      const error = action.error.meta;

      if (error.code === CONSTANTS.ID_STATUS_CODE.EXISTING_ACCOUNT) {
        history.push(PATHS.signIn);
      } else if (error.code === CONSTANTS.ID_STATUS_CODE.PENDING_VERIFICATION) {
        history.push(`${PATHS.checkEmail}?email=${email}`);
      }

      dispatch(openNotification('error', { ...error, type: action.type }));
    }
  };

export const fetchSignUp = ({ email, product = 'smart-valuation' }) => ({
  type: 'SIGN_UP',
  payload: {
    request: {
      method: 'POST',
      url: '/auth/sign-up/',
      data: {
        email,
        products: [product]
      }
    }
  }
});

export const logOut = () => ({
  type: 'LOG_OUT',
  payload: null
});

export const signOut =
  (shouldForceReload = false) =>
  async dispatch => {
    dispatch(resetUser());
    dispatch(logOut());
    // Sentry remove user
    // Sentry.configureScope(scope => scope.setUser(null));

    // Frogged logout
    // window.Froged && window.Froged('logout');

    if (shouldForceReload) {
      window.location.reload();
    } else {
      history.push(PATHS.signIn);
    }
  };

export const setRedirect = redirect => ({
  type: 'SET_REDIRECT',
  payload: redirect
});

export const fetchVerifyCode = ({ email, code }) => ({
  type: 'VERIFY_CODE',
  payload: {
    request: {
      method: 'POST',
      url: '/auth/verification/code/',
      data: {
        email,
        code
      }
    }
  }
});

export const verifyCode =
  ({ email, code }) =>
  async dispatch => {
    const action = await dispatch(fetchVerifyCode({ email, code }));

    if (action.type === 'VERIFY_CODE_SUCCESS' && !action.error) {
      const { token } = action.payload.data;
      dispatch(initApp({ token, remember: true }));
    } else if (action.type === 'VERIFY_CODE_FAIL') {
      const error = action.error.meta;

      if (error.code === CONSTANTS.ID_STATUS_CODE.NOT_EXISTING_ACCOUNT) {
        history.push(PATHS.signUp);
        dispatch(openNotification('error', { ...error, type: action.type }));
      } else if (error.code === CONSTANTS.ID_STATUS_CODE.EXISTING_ACCOUNT) {
        history.push(PATHS.signIn);
        dispatch(openNotification('error', { ...error, type: action.type }));
      } else dispatch(setInputError(action.error.meta.message));
    }

    return action;
  };

export const setInputError = error => (dispatch, getState) => {
  const { inputError } = getState().auth;

  if (inputError !== error)
    dispatch({
      type: 'SET_AUTH_INPUT_ERROR',
      payload: error
    });
};

export const fetchResendCode = ({ email }) => ({
  type: 'RESEND_CODE',
  payload: {
    request: {
      method: 'POST',
      url: '/auth/verification/resend/',
      data: {
        email
      }
    }
  }
});

export const resendCode =
  ({ email }) =>
  dispatch => {
    dispatch(fetchResendCode({ email }));
  };

export const handleGoogleSuccess =
  ({ code, type, product }) =>
  async dispatch => {
    const action =
      type === 'sign-up'
        ? await dispatch(validateGoogleToken({ code, product }))
        : await dispatch(signInWithGoogle({ code }));

    if (
      action.type === 'AUTH_GOOGLE_SIGN_UP_SUCCESS' ||
      action.type === 'AUTH_GOOGLE_SIGN_IN_SUCCESS'
    ) {
      const { token, strapi_token } = action.payload.data;
      dispatch(initApp({ token, remember: true, strapi_token }));
    } else {
      dispatch(openNotification('error', { ...action.error.meta, type: action.type }));
    }
  };

export const validateGoogleToken = ({ code, product }) => ({
  type: 'AUTH_GOOGLE_SIGN_UP',
  payload: {
    request: {
      method: 'POST',
      url: 'auth/google-sign-up/',
      data: { code, products: [product] }
    }
  }
});

export const signInWithGoogle = ({ code }) => ({
  type: 'AUTH_GOOGLE_SIGN_IN',
  payload: {
    request: {
      method: 'POST',
      url: 'auth/google-login/',
      data: { code }
    }
  }
});

export const fetchVerifyEmail = ({ email, token, password }) => ({
  type: 'VERIFY_EMAIL',
  payload: {
    request: {
      method: 'POST',
      url: '/auth/verification/token/',
      data: {
        email,
        verificationToken: token,
        password
      }
    }
  }
});

export const verifyEmail =
  ({ email, token, password }) =>
  async dispatch => {
    const action = await dispatch(fetchVerifyEmail({ email, token, password }));

    if (action.type === 'VERIFY_EMAIL_SUCCESS' && !action.error) {
      const { token } = action.payload.data;
      dispatch(initApp({ token, remember: true }));
    } else if (action.type === 'VERIFY_EMAIL_FAIL') {
      const error = action.error.meta;

      history.push(PATHS.signIn);
      dispatch(openNotification('error', { ...error, type: action.type }));
    }

    return action;
  };
