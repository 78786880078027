import { Grid, IconCheck } from '@uda/bento-components';
import styled, { useTheme } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { getValueFormatted } from '../../utils';
import { useSelector } from 'react-redux';
import PaymentsCheckoutPlanSkeleton from './PaymentsCheckoutPlanSkeleton';

const StyledWrapper = styled.div`
  color: ${({ theme, active }) => (active ? theme.color.primary500 : theme.color.charcoal600)};
  padding: ${({ theme }) => theme.spacings.small4};
  border: 1px solid
    ${({ theme, active }) => (active ? theme.color.primary500 : theme.color.charcoal300)};
  border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
  transition: ${({ theme }) => theme.global.transitionM};

  > header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${({ theme }) => theme.spacings.small2};

    > p {
      ${({ theme }) => theme.texts.p1b};
      color: ${({ theme, active }) => (active ? theme.color.primary500 : theme.color.charcoal600)};
      text-transform: capitalize;
    }

    h5 {
      text-transform: uppercase;
      > a {
        text-transform: uppercase;
      }
    }
  }

  :hover {
    background-color: ${({ theme, active }) => !active && theme.color.charcoal300};
    cursor: ${({ active }) => !active && `pointer`};
  }
`;

const StyledTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium3};
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  > li {
    margin-bottom: ${({ theme }) => theme.spacings.small2};
    > svg {
      position: relative;
      top: -1px;
    }
  }
`;

const StyledGrid = styled(props => <Grid {...props} />)`
  overflow: auto;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100;
    width: 100%;
    padding-top: ${({ theme }) => theme.spacings.small2};
  }
`;

const StyledParagraph = styled.span`
  text-decoration: line-through;
  display: block;
  color: ${({ theme }) => theme.color.charcoal500};
  text-align: right;
`;

const PlanContent = ({
  id,
  label,
  data,
  features,
  plan,
  intl,
  lang,
  handleSelectPlan,
  onOpenEnterpriseModal
}) => {
  // @toDo API add discount

  return (
    <StyledWrapper
      active={plan && id === plan}
      onClick={() => (data ? handleSelectPlan(id) : onOpenEnterpriseModal())}
    >
      <header>
        <p>{label}</p>
        <h5>
          {data ? (
            <>
              <span>
                {getValueFormatted(data, intl, lang, false, false)}/
                <FormattedMessage id="format.monthly" />
              </span>
              <StyledParagraph>20€</StyledParagraph>
            </>
          ) : (
            <span>
              <FormattedMessage id="payments.contact_us" />
            </span>
          )}
        </h5>
      </header>
      <StyledList>
        {features.map(({ label }, key) => (
          <li key={key}>
            <IconCheck /> {label}
          </li>
        ))}
      </StyledList>
    </StyledWrapper>
  );
};

const PaymentsCheckoutPlan = ({
  isLoading,
  plans = [],
  plan = 'basic-opportunities',
  handleSelectPlan,
  onOpenEnterpriseModal = () => {}
}) => {
  const intl = useIntl();
  const lang = useSelector(state => state.user.data.language);
  const theme = useTheme();

  return (
    <StyledContainer>
      {isLoading ? (
        <PaymentsCheckoutPlanSkeleton />
      ) : (
        <>
          <StyledTitle>
            <FormattedMessage id="payments.plans.title" />
          </StyledTitle>
          {plans && plans.length ? (
            <StyledGrid columns="1fr 1fr" gap={theme.spacings.small2}>
              {plans.map(({ id, label, data, features }, key) => (
                <PlanContent
                  key={key}
                  id={id}
                  label={label}
                  data={data}
                  features={features}
                  plan={plan}
                  intl={intl}
                  lang={lang}
                  handleSelectPlan={handleSelectPlan}
                  onOpenEnterpriseModal={onOpenEnterpriseModal}
                />
              ))}
            </StyledGrid>
          ) : null}
        </>
      )}
    </StyledContainer>
  );
};

export default PaymentsCheckoutPlan;
