import { fetchProvincesAndNumOfAssets } from '../../Opportunities/actions/opportunities';
import { openNotification } from '../../actions/notification';
import { history } from '../../App';
import PAYMENTS_PATHS from '../paths';

export const updateProcessingLoading = value => ({
  type: 'UPDATE_PROCESSING_LOADING',
  payload: value
});

const POLLING_TIMEOUT = 2000;
export let checkStatusInterval;

export const handleCheckoutPayment =
  ({ stripe, card, billing_info, scope, plan }) =>
  async dispatch => {
    const paymentCreation = await stripe.createPaymentMethod({
      type: 'card',
      card: card
    });

    if (paymentCreation.error) {
      dispatch(openNotification('error', { message: paymentCreation.error.message }));
    } else {
      const payment_method = paymentCreation.paymentMethod.id;

      const action = await dispatch(
        fetchPayment({ payment_method_id: payment_method, billing_info, scope, plan })
      );

      if (action.type === 'FETCH_SUBSCRIPTION_PAYMENT_SUCCESS') {
        const response = action.payload.data;

        if (response && response.payment_intent) {
          const confirmPayment = await stripe.confirmCardPayment(response.payment_intent, {
            payment_method
          });

          if (confirmPayment.error) {
            dispatch(openNotification('error', { message: confirmPayment.error.message }));
            dispatch(updateProcessingLoading(false));
          } else {
            initialCheckStatusPayment(
              () => dispatch(fetchCheckStatusPayment()),
              POLLING_TIMEOUT,
              () => {
                dispatch(updateProcessingLoading(false));
                history.push(PAYMENTS_PATHS.summary);
              }
            );
          }
        } else {
          dispatch(updateProcessingLoading(false));
          history.push(PAYMENTS_PATHS.summary);
        }
      } else {
        history.push(PAYMENTS_PATHS.error);
        dispatch(openNotification('error', { ...action.error.meta, type: action.type }));
      }
    }
  };

export const initialCheckStatusPayment = (fn, timeout = POLLING_TIMEOUT, onSuccess = () => {}) => {
  checkStatusInterval = setInterval(async () => {
    const result = await fn();

    if (result.payload && result.payload.data.status !== 'pending') {
      onSuccess();
    }
  }, timeout);
};

export const clearCheckStatusPayment =
  (interval = checkStatusInterval) =>
  () => {
    interval && clearInterval(interval);
  };

export const fetchCheckStatusPayment = () => ({
  type: 'FETCH_CHECK_STATUS_PAYMENT',
  payload: {
    request: {
      method: 'GET',
      url: '/payments/subscriptions/status/'
    }
  }
});

export const fetchPayment = data => ({
  type: 'FETCH_SUBSCRIPTION_PAYMENT',
  payload: {
    request: {
      method: 'POST',
      url: '/payments/subscriptions/',
      data: data
    }
  }
});

export const initCheckout = () => (dispatch, getState) => {
  dispatch(fetchPlans('opportunities', 'short'));
  dispatch(fetchProvincesAndNumOfAssets());
};

export const fetchPlans = (product, labels) => {
  return {
    type: 'FETCH_PLANS',
    payload: {
      request: {
        method: 'GET',
        url: `/products/${product}/plans/`,
        params: { labels }
      }
    }
  };
};

export const calculateOrder = (plan, items) => {
  return {
    type: 'CALCULATE_ORDER',
    payload: {
      request: {
        method: 'POST',
        url: 'payments/plans/total-price/',
        data: { plan, items }
      }
    }
  };
};

export const fetchSummary = () => {
  return {
    type: 'FETCH_SUMMARY',
    payload: {
      request: {
        method: 'GET',
        url: '/payments/subscriptions/summary/'
      }
    }
  };
};

export const initSummary = () => dispatch => {
  dispatch(fetchSummary());
};
