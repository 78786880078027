import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import countries from '../actions/countries';

const StyledBlock = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledLabel = styled.h5`
  color: ${({ theme }) => theme.color.charcoal500};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacings.small1};
`;

const StyledValue = styled.span`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal800};
`;

const Separator = styled.i`
  position: relative;
  display: block;
  ${({ theme }) => theme.texts.p2b};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacings.medium2};
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.charcoal300};
  }
`;

const PaymentsSummaryBilling = ({ data }) => {
  const lang = useSelector(state => state.user.data.language);
  const { company, cif, country, city, address, postal_code } = data;

  return (
    <div>
      <StyledBlock>
        <StyledLabel>
          <FormattedMessage id="payments.billing.company" />
        </StyledLabel>
        <StyledValue>{company || '-'}</StyledValue>
      </StyledBlock>
      <StyledBlock>
        <StyledLabel>
          <FormattedMessage id="payments.billing.cif" />
        </StyledLabel>
        <StyledValue>{cif || '-'}</StyledValue>
      </StyledBlock>
      <Separator />
      <StyledBlock>
        <StyledLabel>
          <FormattedMessage id="payments.billing.country" />
        </StyledLabel>
        <StyledValue>{countries.find(c => c.iso2 === country)[lang]}</StyledValue>
      </StyledBlock>
      <StyledBlock>
        <StyledLabel>
          <FormattedMessage id="payments.billing.city" />
        </StyledLabel>
        <StyledValue>{city}</StyledValue>
      </StyledBlock>
      <StyledBlock>
        <StyledLabel>
          <FormattedMessage id="payments.billing.address" />
        </StyledLabel>
        <StyledValue>{address}</StyledValue>
      </StyledBlock>
      <StyledBlock>
        <StyledLabel>
          <FormattedMessage id="payments.billing.postal_code" />
        </StyledLabel>
        <StyledValue>{postal_code}</StyledValue>
      </StyledBlock>
    </div>
  );
};

export default PaymentsSummaryBilling;
