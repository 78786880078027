import { IconAnchor } from '@uda/bento-components';
import { builtLandValueGetter, grossYieldValueGetter } from './calculator';

export const getIdentificationRow = asset => asset;

export const getCellAlign = field => {
  switch (field) {
    case 'asset_id':
    case 'typology':
    case 'market_value':
    case 'rent_value':
    case 'built_land_mv':
    case 'built_land_rv':
    case 'reviewed_seller_av':
    case 'reviewed_seller_av_dt':
    case 'name':
      return 'left';
    case 'seller_av_dt':
    case 'gross_yield':
      return 'right';
    case 'is_anchor':
    default:
      return 'center';
  }
};

export const getCellWidth = field => {
  switch (field) {
    case 'is_anchor':
      return { width: 56, minWidth: 56 };
    case 'phase_1_start':
    case 'phase_1_end':
    case 'phase_2_start':
    case 'phase_2_end':
    case 'phase_3_start':
    case 'phase_3_end':
      return { width: 65, minWidth: 65 };
    case 'typology':
    case 'seller_av_dt':
    case 'gross_yield':
      return { width: 121, minWidth: 121 };
    case 'built_land_mv':
    case 'built_land_rv':
      return { width: 140, minWidth: 140 };
    case 'asset_id':
      return { width: 100, minWidth: 100 };
    case 'market_value':
    case 'rent_value':
      return { width: 130, minWidth: 130 };

    case 'reviewed_seller_av':
      return { width: 180, minWidth: 180 };
    case 'reviewed_seller_av_dt':
      return { width: 200, minWidth: 200 };
    default:
      return { width: 121, minWidth: 121 };
  }
};

const getCellIcon = (field, sort) => {
  const iconColor = sort ? '#1778FB' : '#747D93';
  switch (field) {
    case 'is_anchor':
      return <IconAnchor customColor={iconColor} />;
    default:
      return null;
  }
};

const getCellRenderer = (field, table) => {
  switch (field) {
    case 'is_anchor':
      return 'iconCell';
    case 'market_value':
    case 'rent_value':
    case 'reviewed_seller_av':
    case 'reviewed_seller_av_dt':
      return 'editCell';
    case 'seller_av_dt':
      return 'customColor';
    case 'asset_id':
    case 'typology':
    case 'gross_yield':
    case 'built_land_mv':
    case 'built_land_rv':
    default:
      return 'singleCell';
  }
};

const getCellVariant = field => {
  switch (field) {
    default:
      return 'secondary';
  }
};

const getCellSortType = field => {
  switch (field) {
    case 'cadastral_ref_1_review':
    case 'cadastral_ref_2_review':
    case 'use_review':
    case 'typology_review':
      return null;
    case 'built_area':
    case 'land_area':
      return 'number';
    case 'is_anchor':
    case 'asset_id':
    case 'location':
    case 'inspection':
    case 'use':
    case 'typology':
    case 'area':
    case 'built_area_source':
    case 'land_area_source':
    default:
      return 'string';
  }
};

const getIsFullSelectLabel = field => {
  switch (field) {
    case 'built_area_source':
      return true;
    default:
      return false;
  }
};

const getCellValueGetter = field => {
  switch (field) {
    case 'built_land_mv':
      return builtLandValueGetter('market_value');
    case 'built_land_rv':
      return builtLandValueGetter('rent_value');
    case 'gross_yield':
      return grossYieldValueGetter;
    default:
      return null;
  }
};

export const apiInternValuationTable = data => {
  return {
    columns: data.columns.map(e => ({
      ...e,
      ...getCellWidth(e.field),
      icon: getCellIcon(e.field, e.sortDirection),
      align: getCellAlign(e.field),
      cellRenderer: getCellRenderer(e.field),
      variant: getCellVariant(e.field),
      highlight: false,
      sortType: getCellSortType(e.field),
      fullSelectLabel: getIsFullSelectLabel(e.field),
      valueGetter: getCellValueGetter(e.field),
      rounded: false
    })),
    rows: data.assets,
    pagination: data.pagination
  };
};

export const getNewTableValues = (rows, changes) => {
  rows[changes.index][changes.field] = changes.value;

  return rows;
};

export const getNewMethodsValues = (methods, changes, methodId) => {
  methods.forEach(method => {
    if (method.id === methodId) {
      method = {
        ...method,
        timeline_table: {
          ...method.timeline_table,
          rows: getNewTableValues(method.timeline_table.rows, changes)
        }
      };
    }
  });

  return methods;
};

const formatColumns = e => {
  return {
    ...e,
    ...getCellWidth(e.field),
    cellRenderer: e.field === 'name' ? 'singleCell' : 'editCell',
    pinnedRowCellRenderer: 'singleCell',
    valueGetter: methodsValueGetter,
    align: getCellAlign(e.field),
    variant: getCellVariant(e.field),
    highlight: false,
    rounded: false
  };
};

const methodsValueGetter = ({ data, colDef, node, api }) => {
  const { field } = colDef;
  if (node.rowPinned) {
    if (field !== 'name') {
      const rows = api?.getModel().gridOptionsWrapper.gridOptions.rowData;

      let max = null;
      rows.forEach(element => {
        let value = element[field].edit.value ? Number(element[field].edit.value) : null;
        if (value && (!max || value > max)) {
          max = value;
        }
      });

      return {
        primary: {
          value: max,
          format: 'string'
        }
      };
    }
  }
  return data[field];
};

const getPinnedRow = method => {
  if (method === 'residual_method_data') {
    return [
      {
        name: { primary: { value: 'Subtotal', format: 'string' } },
        phase_1_start: { primary: { value: null, format: 'string' } },
        phase_1_end: { primary: { value: null, format: 'string' } },
        phasenullstart: { primary: { value: null, format: 'string' } },
        phasenullend: { primary: { value: null, format: 'string' } },
        phase_3_start: { primary: { value: null, format: 'string' } },
        phase_3_end: { primary: { value: null, format: 'string' } }
      }
    ];
  }
  return null;
};

export const apiInternMethods = methods => {
  methods.forEach(method => {
    if (method.timeline_table) {
      method.timeline_table.columns = method.timeline_table.columns.map(e => {
        if (e.children) {
          return {
            ...e,
            children: e.children.map(item => formatColumns(item))
          };
        }
        return formatColumns(e);
      });
      method.timeline_table.pinnedRow = getPinnedRow(method.id);
    }
  });

  return methods;
};
