import { LISTING_TYPE_EQUIVALENCE } from 'constants.js';

export const parseComparablesFilters = filters => {
  const { property_type__in_comparables, property_type__in, listing_type, ...rest } = filters;

  return {
    ...rest,
    listing_type,
    property_type__in:
      listing_type === LISTING_TYPE_EQUIVALENCE.residential
        ? property_type__in_comparables
        : property_type__in
  };
};
