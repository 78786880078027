import { PRODUCT_KEY } from '../constants.js';
import { getLocalStorage } from './storage';

export const showAdditionalSettings = (products, plans) => {
  const product = getLocalStorage(PRODUCT_KEY);

  if (!plans || !product) {
    return false;
  }

  switch (product) {
    case 'opportunities':
      return products.includes('opportunities') && plans.opportunities === 'basic-opportunities';
    default:
      return false;
  }
};

export default showAdditionalSettings;
