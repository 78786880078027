import AuthChangePasswordContainer from '../Auth/containers/AuthChangePasswordContainer';
import AuthCheckEmailContainer from '../Auth/containers/AuthCheckEmailContainer';
import AuthCreatePasswordContainer from '../Auth/containers/AuthCreatePasswordContainer';
import AuthLogoutContainer from '../Auth/containers/AuthLogoutContainer';
import AuthResetPasswordContainer from '../Auth/containers/AuthResetPasswordContainer';
import AuthRoute from './AuthRoute';
import AuthSignInContainer from '../Auth/containers/AuthSignInContainer';
import AuthSignUpContainer from '../Auth/containers/AuthSignUpContainer';
import AuthVerifyAccountContainer from '../Auth/containers/AuthVerifyAccountContainer';
import AuthVerifyLinkContainer from '../Auth/containers/AuthVerifyLinkContainer';
import BaseContainer from '../containers/BaseContainer';
import LoadingLayout from '../components/LoadingLayout';
import PATHS from './paths';
import PaymentsContainer from '../Payments/containers/PaymentsContainer';
import PrivateRoute from './PrivateRoute';
import Support from '../components/Support/index';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import { Suspense } from 'react';
import ExtensionLogin from 'components/extensionOnly/extensionLogin.component';

const PortfolioContainer = loadable(() => import('../Portfolio/containers/PortfolioContainer'));
const CaptureContainer = loadable(() => import('../Capture/pages'));

const AssetContainer = loadable(() => import('../Asset/containers/AssetContainer'));
const SmartValuationContainer = loadable(() => import('../SmartValuation/pages'));
const MarketContainer = loadable(() => import('../Market/containers/MarketContainer'));
const OpportunitiesContainer = loadable(() =>
  import('../Opportunities/containers/OpportunitiesContainer')
);
const LegalContainer = loadable(() => import('../containers/LegalContainer'));
const IframeContainer = loadable(() => import('../containers/IframeContainer'));
const PageNotFoundContainer = loadable(() => import('../containers/PageNotFoundContainer'));

const Routes = () => {
  return (
    <Suspense fallback={<LoadingLayout full />}>
      <Switch>
        <PrivateRoute path={PATHS.base} exact component={BaseContainer} />
        <PrivateRoute path={PATHS.market} component={MarketContainer} />
        <PrivateRoute path={PATHS.portfolio} component={PortfolioContainer} />
        <PrivateRoute path={PATHS.opportunities} component={OpportunitiesContainer} />
        <PrivateRoute path={PATHS.checkout} component={PaymentsContainer} />

        <PrivateRoute path={PATHS.smartValuation} component={SmartValuationContainer} />
        <PrivateRoute path={PATHS.assetAccess} component={AssetContainer} />
        <PrivateRoute path={PATHS.capture} component={CaptureContainer} />
        <PrivateRoute path={PATHS.support} exact component={Support} />
        <PrivateRoute path={PATHS.signOut} exact component={AuthLogoutContainer} />
        <Route path={PATHS.pulseExtensionAvm} exact component={ExtensionLogin} />

        <AuthRoute path={PATHS.signIn} exact component={AuthSignInContainer} />
        <AuthRoute path={PATHS.resetPassword} exact component={AuthResetPasswordContainer} />
        <AuthRoute path={PATHS.changePassword} component={AuthChangePasswordContainer} />
        <AuthRoute path={PATHS.createPassword} component={AuthCreatePasswordContainer} />
        {/*   <AuthRoute path={PATHS.signUp} component={AuthSignUpContainer} /> */}
        <AuthRoute path={PATHS.verify} component={AuthVerifyAccountContainer} />
        <AuthRoute path={PATHS.validate} component={AuthVerifyLinkContainer} />
        <AuthRoute path={PATHS.checkEmail} component={AuthCheckEmailContainer} />

        <Route path={PATHS.legal} exact component={LegalContainer} />

        <Route path={PATHS.iframe} exact component={IframeContainer} />
        <Route path="*" component={PageNotFoundContainer} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
