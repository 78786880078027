import { Button, TextLink } from '@uda/bento-components';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

const Container = styled.div`
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 64px);
  }
`;
const StyledContent = styled.article`
  width: 50%;
  max-width: 550px;
  text-align: center;
  h2 {
    color: ${({ theme }) => theme.color.charcoal800};
    padding-bottom: ${({ theme }) => theme.spacings.small2};
  }

  p {
    color: ${({ theme }) => theme.color.charcoal600};
    text-align: center;
  }

  button {
    margin-top: ${({ theme }) => theme.spacings.medium1};
    width: 144px;
  }

  @media only screen and (max-width: 600px) {
    width: auto;
    max-width: 90%;
  }
`;

export const PaymentProcessingError = ({ onClick = () => {} }) => {
  const intl = useIntl();

  return (
    <Container>
      <StyledContent>
        <h2>
          <FormattedMessage id="payments.error.title" />
        </h2>
        <p className="p1b">
          <FormattedMessage id="payments.error.description" />
        </p>
        <p className="p1b">
          <FormattedMessage
            id="payments.error.support_text"
            values={{
              link: (
                <TextLink
                  href={intl.formatMessage({ id: 'payments.error.support' })}
                  title={intl.formatMessage({ id: 'payments.error.contact' })}
                  className="p1b primary"
                  target="_blank"
                >
                  <FormattedMessage id="payments.error.contact" />
                </TextLink>
              )
            }}
          />
        </p>

        <Button variant="secondary" block size="medium" onClick={onClick}>
          <FormattedMessage id={'payments.error.back'} />
        </Button>
      </StyledContent>
    </Container>
  );
};

export default PaymentProcessingError;
