export const FILTERS_CONFIG = {
  es: {
    listing_types: [
      {
        label: 'Residencial',
        value: 1,
        assetFilters: [
          {
            id: 'use',
            order: 100,
            filters: [
              {
                label: 'Tipo de inmueble',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Ático',
                    value: 1
                  },
                  {
                    label: 'Dúplex',
                    value: 2
                  },
                  {
                    label: 'Piso',
                    value: 4
                  },
                  {
                    label: 'Estudio',
                    value: 7
                  },
                  {
                    label: 'Chalet',
                    value: 3
                  },
                  {
                    label: 'Chalet pareado',
                    value: 5
                  },
                  {
                    label: 'Terraced House',
                    value: 6
                  },
                  {
                    label: 'Casa rústica',
                    value: 8
                  }
                ],
                description: 'Elige la tipología de residencial',
                filter_type: 'dropdown',
                internal_field: 'property_type'
              }
            ]
          },
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superficie de valoración',
                order: 100,
                description:
                  'Superficie construida con elementos comunes para la valoración de uDA.',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'floor',
            order: 300,
            filters: [
              {
                label: 'Planta',
                order: 100,
                filter_type: 'counter',
                internal_field: 'floor'
              }
            ]
          },
          {
            id: 'condition',
            order: 400,
            filters: [
              {
                label: 'Estado',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Para reformar',
                    value: 5
                  },
                  {
                    label: 'Reforma parcial',
                    value: 2
                  },
                  {
                    label: 'Buen estado',
                    value: 1
                  },
                  {
                    label: 'A estrenar',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Elige las características de tu activo',
            order: 600,
            filters: [
              {
                label: 'Habitaciones',
                order: 100,
                filter_type: 'counter',
                internal_field: 'n_rooms'
              },
              {
                label: 'Baños',
                order: 200,
                filter_type: 'counter',
                internal_field: 'n_baths'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'Ascensor',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_elevator'
              },
              {
                label: 'Trastero',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storage'
              },
              {
                label: 'Terraza',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_terrace'
              },
              {
                label: 'Piscina',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_pool'
              },
              {
                label: 'Parking',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_garage'
              },
              {
                label: 'AC',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Exterior',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_exterior'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'use',
            order: 100,
            filters: [
              {
                label: 'Tipo de inmueble',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Ático',
                    value: 1
                  },
                  {
                    label: 'Dúplex',
                    value: 2
                  },
                  {
                    label: 'Piso',
                    value: 4
                  },
                  {
                    label: 'Estudio',
                    value: 7
                  },
                  {
                    label: 'Chalet',
                    value: 3
                  },
                  {
                    label: 'Chalet pareado',
                    value: 5
                  },
                  {
                    label: 'Terraced House',
                    value: 6
                  },
                  {
                    label: 'Casa rústica',
                    value: 8
                  }
                ],
                multiple: true,
                description: 'Elige la tipología de residencial',
                filter_type: 'dropdown',
                internal_field: 'property_type__in_comparables'
              }
            ]
          },
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Rango de fecha',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sin fecha de salida',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas las fechas',
                    value: 'all_date'
                  }
                ],
                description:
                  'Fecha de publicación, despublicación y transacción en el período temporal marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Rango superficie comps.',
                order: 100,
                value: null,
                values: [0, 1000],
                description: 'Elige el rango de la superficie de los comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Elige la distancia de los comparables',
            order: 300,
            filters: [
              {
                label: 'Distancia máxima ',
                order: 200,
                value: null,
                values: [
                  {
                    label: '100 m',
                    value: 100
                  },
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'Tiempo andando',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'Tiempo en coche',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'En el mismo barrio que el activo',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marca la distancia máxima de los comparables con respecto a tu activo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Año de construcción',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          },
          {
            id: 'main_features_comparables',
            label: 'Elige el rango de los comparables',
            order: 500,
            filters: [
              {
                label: 'Habitaciones',
                order: 100,
                value: null,
                values: [
                  {
                    label: '0',
                    value: 0
                  },
                  {
                    label: '1',
                    value: 1
                  },
                  {
                    label: '2',
                    value: 2
                  },
                  {
                    label: '3',
                    value: 3
                  },
                  {
                    label: '+4',
                    value: 4
                  }
                ],
                filter_type: 'multiselect',
                internal_field: 'n_rooms__in'
              },
              {
                label: 'Baños',
                order: 200,
                value: null,
                values: [
                  {
                    label: '1',
                    value: 1
                  },
                  {
                    label: '2',
                    value: 2
                  },
                  {
                    label: '3',
                    value: 3
                  },
                  {
                    label: '+4',
                    value: 4
                  }
                ],
                filter_type: 'multiselect',
                internal_field: 'n_baths__in'
              }
            ],
            description: 'Elige el rango que prefieres ver en los resultados de los comparables'
          }
        ]
      },
      {
        label: 'Edificios',
        value: 12,
        order: 200,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Area total del edificio',
                order: 100,
                description: 'Superficie de tu activo para la valoración',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Propiedades',
            order: 200,
            filters: [
              {
                label: 'Número de activos',
                order: 100,
                filter_type: 'counter',
                internal_field: 'n_houses'
              },
              {
                label: 'Número de ascensores',
                order: 200,
                filter_type: 'counter',
                internal_field: 'n_elevator'
              },
              {
                label: 'Número de plazas de garaje',
                order: 300,
                filter_type: 'counter',
                internal_field: 'n_garages'
              }
            ]
          },
          {
            id: 'features',
            order: 300,
            filters: [
              {
                label: 'Tiene inquilinos',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_tenant'
              },
              {
                label: 'Tiene vigilancia',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_vigilance'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Rango de fecha',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sin fecha de salida',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas las fechas',
                    value: 'all_date'
                  }
                ],
                description:
                  'Fecha de publicación, despublicación y transacción en el período temporal marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Rango superficie comps.',
                order: 100,
                value: null,
                values: [0, 1000],
                description: 'Elige el rango de la superficie de los comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Elige la distancia de los comparables',
            order: 300,
            filters: [
              {
                label: 'Distancia máxima ',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'Tiempo andando',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'Tiempo en coche',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'En el mismo barrio que el activo',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marca la distancia máxima de los comparables con respecto a tu activo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Año de construcción',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          },
          {
            id: 'main_features_comparables',
            label: 'Elige el rango de los comparables',
            order: 500,
            filters: []
          }
        ]
      },
      {
        label: 'Terrenos',
        value: 8,
        assetFilters: [
          {
            id: 'property_type',
            order: 100,
            filters: [
              {
                label: 'Tipo de terreno',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Solar',
                    value: 38
                  },
                  {
                    label: 'Urbanizable',
                    value: 42
                  },
                  {
                    label: 'No urbanizable o agrícola',
                    value: 53
                  },
                  {
                    label: 'Indiferente',
                    value: 0
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'property_type'
              }
            ]
          },
          {
            id: 'area',
            order: 300,
            filters: [
              {
                data: {
                  value: 120,
                  format: 'area'
                },
                label: 'Área total del terreno',
                order: 100,
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'buildable_area',
            filters: [
              {
                data: {
                  value: 120,
                  format: 'area'
                },
                label: 'Area edificable',
                order: 100,
                filter_type: 'input',
                internal_field: 'buildable_area'
              }
            ]
          },

          {
            id: 'access_type',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Por carretera',
                    value: 3
                  },
                  {
                    label: 'Vía urbana',
                    value: 4
                  },
                  {
                    label: 'Por camino',
                    value: 2
                  },
                  {
                    label: 'Por autovía',
                    value: 1
                  }
                ],
                description: 'Tipo de acceso',
                filter_type: 'multicheck',
                internal_field: 'access_type__in'
              }
            ]
          },

          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'Electricidad',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_electricity'
              },
              {
                label: 'Gas natural',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_natural_gas'
              },
              {
                label: 'Con acera',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_pavement'
              },
              {
                label: 'Alcantarillado',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_sewerage'
              },
              {
                label: 'Alumbrado',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_street_lighting'
              },
              {
                label: 'Tiene agua',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_water'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Rango de fecha',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sin fecha de salida',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas las fechas',
                    value: 'all_date'
                  }
                ],
                description:
                  'Fecha de publicación, despublicación y transacción en el período temporal marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Rango superficie comps.',
                order: 100,
                value: null,
                values: [0, 900000],
                description: 'Elige el rango de la superficie de los comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Elige la distancia de los comparables',
            order: 300,
            filters: [
              {
                label: 'Distancia máxima ',
                order: 200,
                value: null,
                values: [
                  {
                    label: '1 km',
                    value: 1000
                  },

                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  },
                  {
                    label: '15 km',
                    value: 15000
                  },
                  {
                    label: '20 km',
                    value: 20000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'Tiempo andando',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'Tiempo en coche',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'En el mismo barrio que el activo',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marca la distancia máxima de los comparables con respecto a tu activo'
          }
        ]
      },
      {
        label: 'Garajes',
        value: 7,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 9,
                  format: 'area'
                },
                label: 'Superficie de valoración',
                order: 100,
                description: 'Superficie de tu activo para la valoración',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Elige las características de tu activo',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Plaza para moto (<8m2)',
                    value: 1
                  },
                  {
                    label: 'Plaza para coche (10-15m2)',
                    value: 2
                  },
                  {
                    label: 'Dos plazas de coche y/ o moto (15-50m2)',
                    value: 3
                  },
                  {
                    label: 'Aparcamiento pequeño (50-95m2)',
                    value: 4
                  },
                  {
                    label: 'Aparcamiento (>95m2)',
                    value: 5
                  }
                ],
                description: 'Tipo de plaza',
                filter_type: 'multicheck',
                internal_field: 'parking_type__in'
              }
            ]
          },
          {
            id: 'others_features',
            order: 300,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Puerta automática',
                    value: 1
                  },
                  {
                    label: 'Vigilancia y seguridad',
                    value: 2
                  },
                  {
                    label: 'Acceso con ascensor',
                    value: 3
                  },
                  {
                    label: 'Está cubierto',
                    value: 4
                  }
                ],
                description: 'Otros',
                filter_type: 'multicheck',
                internal_field: 'features_choices__in'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Rango de fecha',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sin fecha de salida',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas las fechas',
                    value: 'all_date'
                  }
                ],
                description:
                  'Fecha de publicación, despublicación y transacción en el período temporal marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Rango superficie comps.',
                order: 100,
                value: null,
                values: [0, 500],
                description: 'Elige el rango de la superficie de los comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Elige la distancia de los comparables',
            order: 300,
            filters: [
              {
                label: 'Distancia máxima ',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'Tiempo andando',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'Tiempo en coche',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'En el mismo barrio que el activo',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marca la distancia máxima de los comparables con respecto a tu activo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Año de construcción',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Locales',
        value: 5,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superficie de valoración',
                order: 100,
                description: 'Superficie de tu activo para la valoración',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Estado',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Para reformar',
                    value: 5
                  },
                  {
                    label: 'Reforma parcial',
                    value: 2
                  },
                  {
                    label: 'Buen estado',
                    value: 1
                  },
                  {
                    label: 'A estrenar',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'distribution',
            label: 'Tipo de distribución',
            order: 400,
            filters: [
              {
                order: 100,
                description: 'Número de estancias, puede ir de 0 a 100',
                filter_type: 'counter',
                internal_field: 'commercial_distribution'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Elige las características de tu activo',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Indiferente',
                    value: null
                  },
                  {
                    label: 'Centro comercial',
                    value: 23
                  },
                  {
                    label: 'A pie de calle',
                    value: 39
                  },
                  {
                    label: 'Entreplanta',
                    value: 24
                  },
                  {
                    label: 'Subterráneo',
                    value: 25
                  },
                  {
                    label: 'Otros',
                    value: 26
                  }
                ],
                description: 'Ubicación',
                filter_type: 'multicheck',
                internal_field: 'property_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 600,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Sda. humos',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_smoke_outlet'
              },
              {
                label: 'Calefacción',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_heating'
              },
              {
                label: 'Tiene cocina',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Escaparate',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storefront'
              },
              {
                label: 'Hace esquina',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_on_the_corner'
              },
              {
                label: 'Fachada',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_facade'
              },
              {
                label: 'Almacén',
                order: 800,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Rango de fecha',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sin fecha de salida',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas las fechas',
                    value: 'all_date'
                  }
                ],
                description:
                  'Fecha de publicación, despublicación y transacción en el período temporal marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Rango superficie comps.',
                order: 100,
                value: null,
                values: [0, 5000],
                description: 'Elige el rango de la superficie de los comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Elige la distancia de los comparables',
            order: 300,
            filters: [
              {
                label: 'Distancia máxima ',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'Tiempo andando',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'Tiempo en coche',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'En el mismo barrio que el activo',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marca la distancia máxima de los comparables con respecto a tu activo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Año de construcción',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Trastero',
        value: 11,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 4,
                  format: 'area'
                },
                label: 'Tamaño del trastero',
                order: 100,
                description: 'Superficie de tu activo para la valoración ',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Elige las características de tu activo',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Tiene muelle de carga',
                    value: 5
                  },
                  {
                    label: 'Acceso 24h',
                    value: 6
                  }
                ],
                description: 'Características',
                filter_type: 'multicheck',
                internal_field: 'features_choices__in'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Rango de fecha',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sin fecha de salida',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas las fechas',
                    value: 'all_date'
                  }
                ],
                description:
                  'Fecha de publicación, despublicación y transacción en el período temporal marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Rango superficie comps.',
                order: 100,
                value: null,
                values: [0, 500],
                description: 'Elige el rango de la superficie de los comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Elige la distancia de los comparables',
            order: 300,
            filters: [
              {
                label: 'Distancia máxima ',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'Tiempo andando',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'Tiempo en coche',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'En el mismo barrio que el activo',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marca la distancia máxima de los comparables con respecto a tu activo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Año de construcción',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Naves',
        value: 6,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 300,
                  format: 'area'
                },
                label: 'Superficie de valoración',
                order: 100,
                description: 'Superficie de tu activo para la valoración',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Estado',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Para reformar',
                    value: 5
                  },
                  {
                    label: 'Reforma parcial',
                    value: 2
                  },
                  {
                    label: 'Buen estado',
                    value: 1
                  },
                  {
                    label: 'A estrenar',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'distribution',
            label: 'Tipo de distribución',
            order: 400,
            filters: [
              {
                order: 100,
                description: 'Número de estancias, puede ir de 0 a 100',
                filter_type: 'counter',
                internal_field: 'commercial_distribution'
              }
            ]
          },
          {
            id: 'main_features',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Indiferente',
                    value: null
                  },
                  {
                    label: 'Centro comercial',
                    value: 23
                  },
                  {
                    label: 'A pie de calle',
                    value: 39
                  },
                  {
                    label: 'Entreplanta',
                    value: 24
                  },
                  {
                    label: 'Subterráneo',
                    value: 25
                  },
                  {
                    label: 'Otros',
                    value: 26
                  }
                ],
                description: 'Ubicación',
                filter_type: 'multicheck',
                internal_field: 'property_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 600,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Sda. humos',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_smoke_outlet'
              },
              {
                label: 'Calefacción',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_heating'
              },
              {
                label: 'Tiene cocina',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Escaparate',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storefront'
              },
              {
                label: 'Hace esquina',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_on_the_corner'
              },
              {
                label: 'Fachada',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_facade'
              },
              {
                label: 'Almacén',
                order: 800,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Rango de fecha',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sin fecha de salida',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas las fechas',
                    value: 'all_date'
                  }
                ],
                description:
                  'Fecha de publicación, despublicación y transacción en el período temporal marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Rango superficie comps.',
                order: 100,
                value: null,
                values: [300, 500000],
                description: 'Elige el rango de la superficie de los comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Elige la distancia de los comparables',
            order: 300,
            filters: [
              {
                label: 'Distancia máxima ',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'Tiempo andando',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'Tiempo en coche',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'En el mismo barrio que el activo',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marca la distancia máxima de los comparables con respecto a tu activo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Año de construcción',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Oficinas',
        value: 2,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superficie de valoración',
                order: 100,
                description: 'Superficie de tu activo para la valoración',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'floor',
            order: 200,
            filters: [
              {
                label: 'Número de plantas de la oficina',
                order: 100,
                description: 'Número de plantas que abarca la oficina',
                filter_type: 'counter',
                internal_field: 'n_floors'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Estado',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Para reformar',
                    value: 5
                  },
                  {
                    label: 'Reforma parcial',
                    value: 2
                  },
                  {
                    label: 'Buen estado',
                    value: 1
                  },
                  {
                    label: 'A estrenar',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'parking_spaces',
            order: 400,
            filters: [
              {
                label: 'Plazas de garaje',
                order: 100,
                value: null,
                values: [0, 100],
                overflow: true,
                description: 'Número de plazas de garaje incluidas en el precio',
                filter_type: 'range',
                internal_field: 'parking_spaces__range'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Elige las características de tu activo',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Distribución diáfana',
                    value: 1
                  },
                  {
                    label: 'Mamparas',
                    value: 4
                  },
                  {
                    label: 'Distribución tabicada',
                    value: 5
                  }
                ],
                description: 'Tipo de distribución',
                filter_type: 'multicheck',
                internal_field: 'offices_distribution__in'
              }
            ]
          },
          {
            id: 'orientation',
            order: 600,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Norte',
                    value: 1
                  },
                  {
                    label: 'Sur',
                    value: 2
                  },
                  {
                    label: 'Este',
                    value: 3
                  },
                  {
                    label: 'Oeste',
                    value: 4
                  }
                ],
                description: 'Tipo de orientación',
                filter_type: 'multicheck',
                internal_field: 'orientation__in'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Ascensor',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_elevator'
              },
              {
                label: 'Tiene Baño',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_bathroom'
              },
              {
                label: 'Tiene cocina',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Almacén',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              },
              {
                label: 'Vigilancia y seguridad',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_security_system'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Rango de fecha',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sin fecha de salida',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas las fechas',
                    value: 'all_date'
                  }
                ],
                description:
                  'Fecha de publicación, despublicación y transacción en el período temporal marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Rango superficie comps.',
                order: 100,
                value: null,
                values: [0, 200000],
                description: 'Elige el rango de la superficie de los comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Elige la distancia de los comparables',
            order: 300,
            filters: [
              {
                label: 'Distancia máxima ',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'Tiempo andando',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'Tiempo en coche',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'En el mismo barrio que el activo',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marca la distancia máxima de los comparables con respecto a tu activo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Año de construcción',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      }
    ],
    publishing_status: [
      {
        label: 'Publishing Status',
        order: 100,
        value: null,
        values: [
          {
            label: 'Todo',
            value: null
          },
          {
            label: 'Publicado',
            value: 1
          },
          {
            label: 'Despublicado',
            value: 0
          },
          {
            label: 'Vendido',
            value: 2
          }
        ],
        filter_type: 'multiselect',
        internal_field: 'publishing_status__in'
      }
    ],
    publishing_status__in: [
      {
        label: 'Publicado',
        order: 100,
        value: 1,
        filter_type: 'chip-boolean',
        internal_field: 'published'
      },
      {
        label: 'Despublicado',
        order: 100,
        value: 0,
        values: [
          {
            tag: 'Closing uDA',
            title: 'Último precio revisado',
            value: 'closing_uda',
            description:
              'Ajuste sobre el último precio del anuncio antes de la despublicación. uDA aplica un reajuste de precio en base a los datos del mercado.'
          },
          {
            tag: 'Original',
            title: 'Último precio del anuncio',
            value: 'original',
            description:
              'Último precio visto del anuncio antes de despublicarse del portal. Precio original sin retoques.'
          }
        ],
        filter_type: 'chip-select',
        internal_field: 'price_status'
      },
      {
        label: 'Vendido',
        order: 100,
        value: 2,
        filter_type: 'chip-boolean',
        internal_field: 'sold'
      },
      {
        label: 'Tasación',
        order: 100,
        value: 3,
        filter_type: 'chip-boolean',
        internal_field: 'appraisal'
      }
    ]
  },
  pt: {
    listing_types: [
      {
        label: 'Residencial',
        value: 1,
        assetFilters: [
          {
            id: 'use',
            order: 100,
            filters: [
              {
                label: 'Tipo de Propriedade',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Cobertura',
                    value: 1
                  },
                  {
                    label: 'Duplex',
                    value: 2
                  },
                  {
                    label: 'Apartamento',
                    value: 4
                  },
                  {
                    label: 'Estudo',
                    value: 7
                  },
                  {
                    label: 'Moradia',
                    value: 3
                  },
                  {
                    label: 'Casa geminada',
                    value: 5
                  },
                  {
                    label: 'Terraced House',
                    value: 6
                  },
                  {
                    label: 'Vila rústica',
                    value: 8
                  }
                ],
                description: 'Escolha a tipologia de habitação',
                filter_type: 'dropdown',
                internal_field: 'property_type'
              }
            ]
          },
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superfície de avaliação',
                order: 100,
                description: 'Área construída com elementos comuns para a avaliação uDA.',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'floor',
            order: 300,
            filters: [
              {
                label: 'Andar',
                order: 100,
                filter_type: 'counter',
                internal_field: 'floor'
              },
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Andar intermediário',
                    value: false
                  }
                ],
                filter_type: 'check',
                internal_field: 'middle_floor'
              }
            ]
          },
          {
            id: 'condition',
            order: 400,
            filters: [
              {
                label: 'Condições Gerais',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Precisa de reforma',
                    value: 5
                  },
                  {
                    label: 'Parcial atualizado',
                    value: 2
                  },
                  {
                    label: 'Boas condições',
                    value: 1
                  },
                  {
                    label: 'A estrear',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Escolha as características do seu ativo',
            order: 600,
            filters: [
              {
                label: 'Quartos',
                order: 100,
                filter_type: 'counter',
                internal_field: 'n_rooms'
              },
              {
                label: 'Casas de banho',
                order: 200,
                filter_type: 'counter',
                internal_field: 'n_baths'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'Elevador',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_elevator'
              },
              {
                label: 'Despensa',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storage'
              },
              {
                label: 'Terraço',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_terrace'
              },
              {
                label: 'Piscina',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_pool'
              },
              {
                label: 'Estacionamento',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_garage'
              },
              {
                label: 'AC',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Exterior',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_exterior'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'use',
            order: 100,
            filters: [
              {
                label: 'Tipo de Propriedade',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Cobertura',
                    value: 1
                  },
                  {
                    label: 'Duplex',
                    value: 2
                  },
                  {
                    label: 'Apartamento',
                    value: 4
                  },
                  {
                    label: 'Estudo',
                    value: 7
                  },
                  {
                    label: 'Moradia',
                    value: 3
                  },
                  {
                    label: 'Casa geminada',
                    value: 5
                  },
                  {
                    label: 'Terraced House',
                    value: 6
                  },
                  {
                    label: 'Vila rústica',
                    value: 8
                  }
                ],
                multiple: true,
                description: 'Escolha a tipologia de habitação',
                filter_type: 'dropdown',
                internal_field: 'property_type__in_comparables'
              }
            ]
          },
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervalo de datas',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Data de saída',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas as datas ',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data de publicação, despublicação e transação dentro do período de tempo marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Faixa de superfície comparável',
                order: 100,
                value: null,
                values: [0, 1000],
                description: 'Escolha a faixa de superfície de comparáveis',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Escolha a distância de comparáveis',
            order: 300,
            filters: [
              {
                label: 'Distância máxima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '100 m',
                    value: 100
                  },
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A pé',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'De carro',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Mesmo bairro',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marque a distância máxima dos comparáveis ​​em relação ao seu ativo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Ano de construção',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          },
          {
            id: 'main_features_comparables',
            label: 'Escolha a gama de comparáveis',
            order: 500,
            filters: [
              {
                label: 'Quartos',
                order: 100,
                value: null,
                values: [
                  {
                    label: '0',
                    value: 0
                  },
                  {
                    label: '1',
                    value: 1
                  },
                  {
                    label: '2',
                    value: 2
                  },
                  {
                    label: '3',
                    value: 3
                  },
                  {
                    label: '+4',
                    value: 4
                  }
                ],
                filter_type: 'multiselect',
                internal_field: 'n_rooms__in'
              },
              {
                label: 'Casas de banho',
                order: 200,
                value: null,
                values: [
                  {
                    label: '1',
                    value: 1
                  },
                  {
                    label: '2',
                    value: 2
                  },
                  {
                    label: '3',
                    value: 3
                  },
                  {
                    label: '+4',
                    value: 4
                  }
                ],
                filter_type: 'multiselect',
                internal_field: 'n_baths__in'
              }
            ],
            description: 'Escolha o intervalo que você prefere ver nos resultados comparáveis'
          }
        ]
      },
      {
        label: 'Edifícios',
        value: 12,
        order: 200,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Área total do edifício',
                order: 100,
                description: 'Área do seu ativo para avaliação',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Propiedades',
            order: 200,
            filters: [
              {
                label: 'Número de ativos',
                filter_type: 'counter',
                internal_field: 'n_houses'
              },
              {
                label: 'Número de elevadores',
                filter_type: 'counter',
                internal_field: 'n_elevator'
              },
              {
                label: ' Número de vagas de garagem',
                filter_type: 'counter',
                internal_field: 'n_garages'
              }
            ]
          },
          {
            id: 'features',
            order: 300,
            filters: [
              {
                label: 'Tem inquilino',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_tenant'
              },
              {
                label: 'Tem vigilância',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_vigilance'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervalo de datas',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Data de saída',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas as datas ',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data de publicação, despublicação e transação dentro do período de tempo marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Faixa de superfície comparável',
                order: 100,
                value: null,
                values: [0, 500],
                description: 'Escolha a faixa de superfície de comparáveis',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Escolha a distância de comparáveis',
            order: 300,
            filters: [
              {
                label: 'Distância máxima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A pé',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'De carro',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Mesmo bairro',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marque a distância máxima dos comparáveis ​​em relação ao seu ativo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Ano de construção',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          },
          {
            id: 'main_features_comparables',
            label: 'Escolha a gama de comparáveis',
            order: 500,
            filters: []
          }
        ]
      },
      {
        label: 'Land',
        value: 8,
        assetFilters: [
          {
            id: 'property_type',
            order: 100,
            filters: [
              {
                label: 'Tipo de propriedade',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Terreno urbano',
                    value: 38
                  },
                  {
                    label: 'Terreno passível de desenvolvimento',
                    value: 42
                  },
                  {
                    label: 'Solo agrícola',
                    value: 53
                  },
                  {
                    label: 'Indiferente',
                    value: 0
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'property_type'
              }
            ]
          },
          {
            id: 'area',
            order: 300,
            filters: [
              {
                data: {
                  value: 120,
                  format: 'area'
                },
                label: 'Superfície total do terreno',
                order: 100,
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'buildable_area',
            filters: [
              {
                data: {
                  value: 120,
                  format: 'area'
                },
                label: 'Área construtível',
                order: 100,
                filter_type: 'input',
                internal_field: 'buildable_area'
              }
            ]
          },

          {
            id: 'access_type',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Por estrada',
                    value: 3
                  },
                  {
                    label: 'Rua urbana',
                    value: 4
                  },
                  {
                    label: 'Por trilha',
                    value: 2
                  },
                  {
                    label: 'Por rodovia',
                    value: 1
                  }
                ],
                description: 'Tipo de acesso',
                filter_type: 'multicheck',
                internal_field: 'access_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'Possui eletricidade',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_electricity'
              },
              {
                label: 'Gás natural',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_natural_gas'
              },
              {
                label: 'Pavimentação',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_pavement'
              },
              {
                label: 'Rede de esgoto',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_sewerage'
              },
              {
                label: 'Via iluminada',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_street_lighting'
              },
              {
                label: 'Possui água',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_water'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervalo de datas',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Data de saída',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas as datas fora',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data de publicação, despublicação e transação dentro do período de tempo marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Faixa de superfície comparável',
                order: 100,
                value: null,
                values: [0, 900000],
                description: 'Escolha a faixa de superfície de comparáveis',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Escolha a distância de comparáveis',
            order: 300,
            filters: [
              {
                label: 'Distância máxima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '1 km',
                    value: 1000
                  },

                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  },
                  {
                    label: '15 km',
                    value: 15000
                  },
                  {
                    label: '20 km',
                    value: 20000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A pé',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'De carro',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Mesmo bairro',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marque a distância máxima dos comparáveis ​​em relação ao seu ativo'
          }
        ]
      },
      {
        label: 'Garagens',
        value: 7,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 9,
                  format: 'area'
                },
                label: 'Superfície de avaliação',
                order: 100,
                description: 'Área do seu ativo para avaliação',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Escolha as características do seu ativo',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Estacionamento para moto (<8m2)',
                    value: 1
                  },
                  {
                    label: 'Ranhura de estacionamento para carro (10-15m2)',
                    value: 2
                  },
                  {
                    label: 'Ranhura de estacionamento para carro e moto (15-50m2)',
                    value: 3
                  },
                  {
                    label: 'Estacionamento pequeno (50-95m2)',
                    value: 4
                  },
                  {
                    label: 'Estacionamento (>95m2)',
                    value: 5
                  }
                ],
                description: 'Espaço de estacionamento',
                filter_type: 'multicheck',
                internal_field: 'parking_type__in'
              }
            ]
          },
          {
            id: 'others_features',
            order: 300,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Porta automática',
                    value: 1
                  },
                  {
                    label: 'Serviços de vigilância e segurança',
                    value: 2
                  },
                  {
                    label: 'Acesso por elevador',
                    value: 3
                  },
                  {
                    label: 'Interior',
                    value: 4
                  }
                ],
                description: 'Outros',
                filter_type: 'multicheck',
                internal_field: 'features_choices__in'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervalo de datas',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Data de saída',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas as datas fora',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data de publicação, despublicação e transação dentro do período de tempo marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Faixa de superfície comparável',
                order: 100,
                value: null,
                values: [0, 60],
                description: 'Escolha a faixa de superfície de comparáveis',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Escolha a distância de comparáveis',
            order: 300,
            filters: [
              {
                label: 'Distância máxima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A pé',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'De carro',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Mesmo bairro',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marque a distância máxima dos comparáveis ​​em relação ao seu ativo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Ano de construção',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Local',
        value: 5,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superfície de avaliação',
                order: 100,
                description: 'Área do seu ativo para avaliação',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Condições Gerais',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Precisa de reforma',
                    value: 5
                  },
                  {
                    label: 'Parcial atualizado',
                    value: 2
                  },
                  {
                    label: 'Boas condições',
                    value: 1
                  },
                  {
                    label: 'A estrear',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'distribution',
            label: 'Tipo de distribuição',
            order: 400,
            filters: [
              {
                order: 100,
                description: 'Número de quartos, de 0 a 100',
                filter_type: 'counter',
                internal_field: 'commercial_distribution'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Escolha as características do seu ativo',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Indiferente',
                    value: null
                  },
                  {
                    label: 'Centro comercial',
                    value: 23
                  },
                  {
                    label: 'Ao nível da rua',
                    value: 39
                  },
                  {
                    label: 'Mezzanine',
                    value: 24
                  },
                  {
                    label: 'Subterrâneo',
                    value: 25
                  },
                  {
                    label: 'Outros',
                    value: 26
                  }
                ],
                description: 'Localização',
                filter_type: 'multicheck',
                internal_field: 'property_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 600,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Saída de fumaça',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_smoke_outlet'
              },
              {
                label: 'Aquecimento',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_heating'
              },
              {
                label: 'Cozinha',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Mostruário',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storefront'
              },
              {
                label: 'Prop. de esquina',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_on_the_corner'
              },
              {
                label: 'Fachada',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_facade'
              },
              {
                label: 'Armazém',
                order: 800,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervalo de datas',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sem data de saída',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas as datas fora',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data de publicação, despublicação e transação dentro do período de tempo marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Faixa de superfície comparável',
                order: 100,
                value: null,
                values: [0, 5000],
                description: 'Escolha a faixa de superfície de comparáveis',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Escolha a distância de comparáveis',
            order: 300,
            filters: [
              {
                label: 'Distância máxima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A pé',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'De carro',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Mesmo bairro',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marque a distância máxima dos comparáveis ​​em relação ao seu ativo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Ano de construção',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Armazenamento',
        value: 11,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 4,
                  format: 'area'
                },
                label: 'Armazenamento de superfície',
                order: 100,
                description: 'Área do seu ativo para avaliação',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Escolha as características do seu ativo',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Baía de carregamento',
                    value: 5
                  },
                  {
                    label: 'Acesso 24h',
                    value: 6
                  }
                ],
                description: 'Características',
                filter_type: 'multicheck',
                internal_field: 'features_choices__in'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervalo de datas',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sem data de saída',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas as datas fora',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data de publicação, despublicação e transação dentro do período de tempo marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Faixa de superfície comparável',
                order: 100,
                value: null,
                values: [0, 500],
                description: 'Escolha a faixa de superfície de comparáveis',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Escolha a distância de comparáveis',
            order: 300,
            filters: [
              {
                label: 'Distância máxima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A pé',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'De carro',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Mesmo bairro',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marque a distância máxima dos comparáveis ​​em relação ao seu ativo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Ano de construção',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Armazéns',
        value: 6,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 300,
                  format: 'area'
                },
                label: 'Superfície de avaliação',
                order: 100,
                description: 'Área do seu ativo para avaliação',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Condições Gerais',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Precisa de reforma',
                    value: 5
                  },
                  {
                    label: 'Parcial atualizado',
                    value: 2
                  },
                  {
                    label: 'Boas condições',
                    value: 1
                  },
                  {
                    label: 'A estrear',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'distribution',
            label: 'Tipo de distribuição',
            order: 400,
            filters: [
              {
                order: 100,
                description: 'Número de quartos, de 0 a 100',
                filter_type: 'counter',
                internal_field: 'commercial_distribution'
              }
            ]
          },
          {
            id: 'main_features',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Indiferente',
                    value: null
                  },
                  {
                    label: 'Centro comercial',
                    value: 23
                  },
                  {
                    label: 'Ao nível da rua',
                    value: 39
                  },
                  {
                    label: 'Mezzanine',
                    value: 24
                  },
                  {
                    label: 'Subterrâneo',
                    value: 25
                  },
                  {
                    label: 'Outros',
                    value: 26
                  }
                ],
                description: 'Localização',
                filter_type: 'multicheck',
                internal_field: 'property_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 600,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Saída de fumaça',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_smoke_outlet'
              },
              {
                label: 'Aquecimento',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_heating'
              },
              {
                label: 'Cozinha',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Mostruário',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storefront'
              },
              {
                label: 'Prop. de esquina',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_on_the_corner'
              },
              {
                label: 'Fachada',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_facade'
              },
              {
                label: 'Armazém',
                order: 800,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervalo de datas',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sem data de saída',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas as datas fora',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data de publicação, despublicação e transação dentro do período de tempo marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Faixa de superfície comparável',
                order: 100,
                value: null,
                values: [300, 500000],
                description: 'Escolha a faixa de superfície de comparáveis',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Escolha a distância de comparáveis',
            order: 300,
            filters: [
              {
                label: 'Distância máxima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A pé',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'De carro',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Mesmo bairro',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marque a distância máxima dos comparáveis ​​em relação ao seu ativo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Ano de construção',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Escritórios',
        value: 2,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superfície de avaliação',
                order: 100,
                description: 'Área do seu ativo para avaliação',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'floor',
            order: 200,
            filters: [
              {
                label: 'Número de andares de escritórios',
                order: 100,
                description: 'Número de andares cobertos por este escritório',
                filter_type: 'counter',
                internal_field: 'n_floors'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Condições Gerais',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Precisa de reforma',
                    value: 5
                  },
                  {
                    label: 'Parcial atualizado',
                    value: 2
                  },
                  {
                    label: 'Boas condições',
                    value: 1
                  },
                  {
                    label: 'A estrear',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'parking_spaces',
            order: 400,
            filters: [
              {
                label: 'Vagas de estacionamento',
                order: 100,
                value: null,
                values: [0, 100],
                overflow: true,
                description: 'Número de lugares de estacionamento incluídos neste preço',
                filter_type: 'range',
                internal_field: 'parking_spaces__range'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Escolha as características do seu ativo',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Distribuição diáfana',
                    value: 1
                  },
                  {
                    label: 'Ecrãs',
                    value: 4
                  },
                  {
                    label: 'Distribuição particionada',
                    value: 5
                  }
                ],
                description: 'Tipo de distribuição',
                filter_type: 'multicheck',
                internal_field: 'offices_distribution__in'
              }
            ]
          },
          {
            id: 'orientation',
            order: 600,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Norte',
                    value: 1
                  },
                  {
                    label: 'Sul',
                    value: 2
                  },
                  {
                    label: 'Leste',
                    value: 3
                  },
                  {
                    label: 'Oeste',
                    value: 4
                  }
                ],
                description: 'Tipo de orientação',
                filter_type: 'multicheck',
                internal_field: 'orientation__in'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Elevador',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_elevator'
              },
              {
                label: 'Casa de banho',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_bathroom'
              },
              {
                label: 'Cozinha',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Armazém',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              },
              {
                label: 'Serviços de vigilância e segurança',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sim',
                    value: 'true'
                  },
                  {
                    label: 'Não',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_security_system'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervalo de datas',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sem data de saída',
                    value: 'no_date'
                  },
                  {
                    label: 'Todas as datas fora',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data de publicação, despublicação e transação dentro do período de tempo marcado',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Faixa de superfície comparável',
                order: 100,
                value: null,
                values: [0, 200000],
                description: 'Escolha a faixa de superfície de comparáveis',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Escolha a distância de comparáveis',
            order: 300,
            filters: [
              {
                label: 'Distância máxima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A pé',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'De carro',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Mesmo bairro',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Marque a distância máxima dos comparáveis ​​em relação ao seu ativo'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Ano de construção',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      }
    ],

    publishing_status: [
      {
        label: 'Publishing Status',
        order: 100,
        value: null,
        values: [
          {
            label: 'Todos',
            value: null
          },
          {
            label: 'Publicado',
            value: 1
          },
          {
            label: 'Não publicado',
            value: 0
          },
          {
            label: 'Vendido',
            value: 2
          }
        ],
        filter_type: 'multiselect',
        internal_field: 'publishing_status__in'
      }
    ],
    publishing_status__in: [
      {
        label: 'Publicado',
        order: 100,
        value: 1,
        filter_type: 'chip-boolean',
        internal_field: 'published'
      },
      {
        label: 'Não publicado',
        order: 100,
        value: 0,
        values: [
          {
            tag: 'Closing uDA',
            title: 'Último preço revisado',
            value: 'closing_uda',
            description:
              'Ajuste sobre o último preço do comparável antes do despublicação. uDA aplica um reajuste de preço com base em dados de mercado.'
          },
          {
            tag: 'Original',
            title: 'Último preço de listagem',
            value: 'original',
            description:
              'Último preço visto do comparável antes de ser inédito no portal. Preço original sem retoques.'
          }
        ],
        filter_type: 'chip-select',
        internal_field: 'price_status'
      },
      {
        label: 'Vendido',
        order: 100,
        value: 2,
        filter_type: 'chip-boolean',
        internal_field: 'sold'
      },
      {
        label: 'Avaliação',
        order: 100,
        value: 3,
        filter_type: 'chip-boolean',
        internal_field: 'appraisal'
      }
    ]
  },
  en: {
    listing_types: [
      {
        label: 'Residential',
        value: 1,
        assetFilters: [
          {
            id: 'use',
            order: 100,
            filters: [
              {
                label: 'Property type',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Penthouse',
                    value: 1
                  },
                  {
                    label: 'Duplex',
                    value: 2
                  },
                  {
                    label: 'Apartment',
                    value: 4
                  },
                  {
                    label: 'Study',
                    value: 7
                  },
                  {
                    label: 'Detached House',
                    value: 3
                  },
                  {
                    label: 'Semi-detached house',
                    value: 5
                  },
                  {
                    label: 'Terraced House',
                    value: 6
                  },
                  {
                    label: 'Rustic villa',
                    value: 8
                  }
                ],
                description: 'Choose the typology of residential',
                filter_type: 'dropdown',
                internal_field: 'property_type'
              }
            ]
          },
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Valuation surface',
                order: 100,
                description: 'Built area with common elements for uDA valuation.',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'floor',
            order: 300,
            filters: [
              {
                label: 'Floor',
                order: 100,
                filter_type: 'counter',
                internal_field: 'floor'
              },
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Middle floor',
                    value: false
                  }
                ],
                filter_type: 'check',
                internal_field: 'middle_floor'
              }
            ]
          },
          {
            id: 'condition',
            order: 400,
            filters: [
              {
                label: 'General conditions',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Needs refurbishment',
                    value: 5
                  },
                  {
                    label: 'Parcial updated',
                    value: 2
                  },
                  {
                    label: 'Good condition',
                    value: 1
                  },
                  {
                    label: 'Brand new',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Choose the characteristics of your asset',
            order: 600,
            filters: [
              {
                label: 'Rooms',
                order: 100,
                filter_type: 'counter',
                internal_field: 'n_rooms'
              },
              {
                label: 'Bathrooms',
                order: 200,
                filter_type: 'counter',
                internal_field: 'n_baths'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'Elevator',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_elevator'
              },
              {
                label: 'Storeroom',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storage'
              },
              {
                label: 'Terrace',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_terrace'
              },
              {
                label: 'Pool',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_pool'
              },
              {
                label: 'Parking',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_garage'
              },
              {
                label: 'AC',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Exterior',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_exterior'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'use',
            order: 100,
            filters: [
              {
                label: 'Property type',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Penthouse',
                    value: 1
                  },
                  {
                    label: 'Duplex',
                    value: 2
                  },
                  {
                    label: 'Apartment',
                    value: 4
                  },
                  {
                    label: 'Study',
                    value: 7
                  },
                  {
                    label: 'Detached House',
                    value: 3
                  },
                  {
                    label: 'Semi-detached house',
                    value: 5
                  },
                  {
                    label: 'Terraced House',
                    value: 6
                  },
                  {
                    label: 'Rustic villa',
                    value: 8
                  }
                ],
                multiple: true,
                description: 'Choose the typology of residential',
                filter_type: 'dropdown',
                internal_field: 'property_type__in_comparables'
              }
            ]
          },
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Date range',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'No date out',
                    value: 'no_date'
                  },
                  {
                    label: 'All dates out',
                    value: 'all_date'
                  }
                ],
                description:
                  'Publication date, unpublish date and transaction within the marked time period',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Comparables surface range',
                order: 100,
                value: null,
                values: [0, 1000],
                description: 'Choose the surface range of comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Choose the distance of comparables',
            order: 300,
            filters: [
              {
                label: 'Maximum distance',
                order: 200,
                value: null,
                values: [
                  {
                    label: '100 m',
                    value: 100
                  },
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'On foot',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'By car',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Same neighborhood',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Mark the maximum distance of the comparables with respect to your asset'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Year of construction',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          },
          {
            id: 'main_features_comparables',
            label: 'Choose the range of comparables',
            order: 500,
            filters: [
              {
                label: 'Rooms',
                order: 100,
                value: null,
                values: [
                  {
                    label: '0',
                    value: 0
                  },
                  {
                    label: '1',
                    value: 1
                  },
                  {
                    label: '2',
                    value: 2
                  },
                  {
                    label: '3',
                    value: 3
                  },
                  {
                    label: '+4',
                    value: 4
                  }
                ],
                filter_type: 'multiselect',
                internal_field: 'n_rooms__in'
              },
              {
                label: 'Bathrooms',
                order: 200,
                value: null,
                values: [
                  {
                    label: '1',
                    value: 1
                  },
                  {
                    label: '2',
                    value: 2
                  },
                  {
                    label: '3',
                    value: 3
                  },
                  {
                    label: '+4',
                    value: 4
                  }
                ],
                filter_type: 'multiselect',
                internal_field: 'n_baths__in'
              }
            ],
            description: 'Choose the range you prefer to see in the comparables results'
          }
        ]
      },
      {
        label: 'Building',
        value: 12,
        order: 200,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Total area of the building',
                order: 100,
                description: 'Area of your asset for valuation',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Propiedades',
            order: 200,
            filters: [
              {
                label: 'Number of assets',
                filter_type: 'counter',
                internal_field: 'n_houses'
              },
              {
                label: 'Number of elevators',
                filter_type: 'counter',
                internal_field: 'n_elevator'
              },
              {
                label: 'Number of parking spaces',
                filter_type: 'counter',
                internal_field: 'n_garages'
              }
            ]
          },
          {
            id: 'features',
            order: 300,
            filters: [
              {
                label: 'Has tenant',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_tenant'
              },
              {
                label: 'Has surveillance',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_vigilance'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Date range',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'No date out',
                    value: 'no_date'
                  },
                  {
                    label: 'All dates out',
                    value: 'all_date'
                  }
                ],
                description:
                  'Publication date, unpublish date and transaction within the marked time period',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },

          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Comparables surface range',
                order: 100,
                value: null,
                values: [0, 500],
                description: 'Choose the surface range of comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Choose the distance of comparables',
            order: 300,
            filters: [
              {
                label: 'Maximum distance',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'On foot',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'By car',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Same neighborhood',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Mark the maximum distance of the comparables with respect to your asset'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Year of construction',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          },
          {
            id: 'main_features_comparables',
            label: 'Choose the range of comparables',
            order: 500,
            filters: []
          }
        ]
      },
      {
        label: 'Land',
        value: 8,
        assetFilters: [
          {
            id: 'property_type',
            order: 100,
            filters: [
              {
                label: 'Property type',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Urban land',
                    value: 38
                  },
                  {
                    label: 'Developable land',
                    value: 42
                  },
                  {
                    label: 'Agricultural soil',
                    value: 53
                  },
                  {
                    label: 'Indifferent',
                    value: 0
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'property_type'
              }
            ]
          },
          {
            id: 'area',
            order: 300,
            filters: [
              {
                data: {
                  value: 120,
                  format: 'area'
                },
                label: 'Total land area',
                order: 100,
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'buildable_area',
            filters: [
              {
                data: {
                  value: 120,
                  format: 'area'
                },
                label: 'Buildable area',
                order: 100,
                filter_type: 'input',
                internal_field: 'buildable_area'
              }
            ]
          },

          {
            id: 'access_type',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'By road',
                    value: 3
                  },
                  {
                    label: 'Urban street',
                    value: 4
                  },
                  {
                    label: 'By trail',
                    value: 2
                  },
                  {
                    label: 'By highway',
                    value: 1
                  }
                ],
                description: 'Access type',
                filter_type: 'multicheck',
                internal_field: 'access_type__in'
              }
            ]
          },

          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'Has electricity',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_electricity'
              },
              {
                label: 'Has natural gas',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_natural_gas'
              },
              {
                label: 'Pavement',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_pavement'
              },
              {
                label: 'Sewerage',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_sewerage'
              },
              {
                label: 'Street lighting',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_street_lighting'
              },
              {
                label: 'Has water',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_water'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Date range',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'No date out',
                    value: 'no_date'
                  },
                  {
                    label: 'All dates out',
                    value: 'all_date'
                  }
                ],
                description:
                  'Publication date, unpublish date and transaction within the marked time period',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Comparables surface range',
                order: 100,
                value: null,
                values: [0, 900000],
                description: 'Choose the surface range of comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Choose the distance of comparables',
            order: 300,
            filters: [
              {
                label: 'Maximum distance',
                order: 200,
                value: null,
                values: [
                  {
                    label: '1 km',
                    value: 1000
                  },

                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  },
                  {
                    label: '15 km',
                    value: 15000
                  },
                  {
                    label: '20 km',
                    value: 20000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'On foot',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'By car',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Same neighborhood',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Mark the maximum distance of the comparables with respect to your asset'
          }
        ]
      },
      {
        label: 'Garages',
        value: 7,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 9,
                  format: 'area'
                },
                label: 'Valuation surface',
                order: 100,
                description: 'Area of your asset for valuation',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Choose the characteristics of your asset',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Parking slot for moto (<8m2)',
                    value: 1
                  },
                  {
                    label: 'Parking slot for car (10-15m2)',
                    value: 2
                  },
                  {
                    label: 'Parking slot for car and moto (15-50m2)',
                    value: 3
                  },
                  {
                    label: 'Small parking (50-95m2)',
                    value: 4
                  },
                  {
                    label: 'Parking (>95m2)',
                    value: 5
                  }
                ],
                description: 'Parking space',
                filter_type: 'multicheck',
                internal_field: 'parking_type__in'
              }
            ]
          },
          {
            id: 'others_features',
            order: 300,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Automatic door',
                    value: 1
                  },
                  {
                    label: 'Surveillance and security services',
                    value: 2
                  },
                  {
                    label: 'Elevator access',
                    value: 3
                  },
                  {
                    label: 'Indoor',
                    value: 4
                  }
                ],
                description: 'Others',
                filter_type: 'multicheck',
                internal_field: 'features_choices__in'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Date range',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'No date out',
                    value: 'no_date'
                  },
                  {
                    label: 'All dates out',
                    value: 'all_date'
                  }
                ],
                description:
                  'Publication date, unpublish date and transaction within the marked time period',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Comparables surface range',
                order: 100,
                value: null,
                values: [0, 60],
                description: 'Choose the surface range of comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Choose the distance of comparables',
            order: 300,
            filters: [
              {
                label: 'Maximum distance',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'On foot',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'By car',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Same neighborhood',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Mark the maximum distance of the comparables with respect to your asset'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Year of construction',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Premises',
        value: 5,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Valuation surface',
                order: 100,
                description: 'Area of your asset for valuation',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'General conditions',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Needs refurbishment',
                    value: 5
                  },
                  {
                    label: 'Parcial updated',
                    value: 2
                  },
                  {
                    label: 'Good condition',
                    value: 1
                  },
                  {
                    label: 'Brand new',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'distribution',
            label: 'Type of distribution',
            order: 400,
            filters: [
              {
                order: 100,
                description: 'Number of rooms, from 0 to 100',
                filter_type: 'counter',
                internal_field: 'commercial_distribution'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Choose the characteristics of your asset',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Indifferent',
                    value: null
                  },
                  {
                    label: 'Shopping centre',
                    value: 23
                  },
                  {
                    label: 'At street level',
                    value: 39
                  },
                  {
                    label: 'Mezzanine',
                    value: 24
                  },
                  {
                    label: 'Underground',
                    value: 25
                  },
                  {
                    label: 'Others',
                    value: 26
                  }
                ],
                description: 'Location',
                filter_type: 'multicheck',
                internal_field: 'property_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 600,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Smoke vent',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_smoke_outlet'
              },
              {
                label: 'Heating',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_heating'
              },
              {
                label: 'Kitchen',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Showcase',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storefront'
              },
              {
                label: 'Corner prop.',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_on_the_corner'
              },
              {
                label: 'Facade',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_facade'
              },
              {
                label: 'Warehouse',
                order: 800,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Date range',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'No date out',
                    value: 'no_date'
                  },
                  {
                    label: 'All dates out',
                    value: 'all_date'
                  }
                ],
                description:
                  'Publication date, unpublish date and transaction within the marked time period',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Comparables surface range',
                order: 100,
                value: null,
                values: [0, 5000],
                description: 'Choose the surface range of comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Choose the distance of comparables',
            order: 300,
            filters: [
              {
                label: 'Maximum distance',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'On foot',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'By car',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Same neighborhood',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Mark the maximum distance of the comparables with respect to your asset'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Year of construction',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Storage',
        value: 11,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 4,
                  format: 'area'
                },
                label: 'Surface storage',
                order: 100,
                description: 'Area of your asset for valuation',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Choose the characteristics of your asset',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Loading bay',
                    value: 5
                  },
                  {
                    label: '24h access',
                    value: 6
                  }
                ],
                description: 'Characteristics',
                filter_type: 'multicheck',
                internal_field: 'features_choices__in'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Date range',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'No date out',
                    value: 'no_date'
                  },
                  {
                    label: 'All dates out',
                    value: 'all_date'
                  }
                ],
                description:
                  'Publication date, unpublish date and transaction within the marked time period',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Comparables surface range',
                order: 100,
                value: null,
                values: [0, 500],
                description: 'Choose the surface range of comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Choose the distance of comparables',
            order: 300,
            filters: [
              {
                label: 'Maximum distance',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'On foot',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'By car',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Same neighborhood',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Mark the maximum distance of the comparables with respect to your asset'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Year of construction',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Warehouses',
        value: 6,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 300,
                  format: 'area'
                },
                label: 'Valuation surface',
                order: 100,
                description: 'Area of your asset for valuation',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'General conditions',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Needs refurbishment',
                    value: 5
                  },
                  {
                    label: 'Parcial updated',
                    value: 2
                  },
                  {
                    label: 'Good condition',
                    value: 1
                  },
                  {
                    label: 'Brand new',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'distribution',
            label: 'Type of distribution',
            order: 400,
            filters: [
              {
                order: 100,
                description: 'Number of rooms, from 0 to 100',
                filter_type: 'counter',
                internal_field: 'commercial_distribution'
              }
            ]
          },
          {
            id: 'main_features',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Indifferent',
                    value: null
                  },
                  {
                    label: 'Shopping centre',
                    value: 23
                  },
                  {
                    label: 'At street level',
                    value: 39
                  },
                  {
                    label: 'Mezzanine',
                    value: 24
                  },
                  {
                    label: 'Underground',
                    value: 25
                  },
                  {
                    label: 'Others',
                    value: 26
                  }
                ],
                description: 'Location',
                filter_type: 'multicheck',
                internal_field: 'property_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 600,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Smoke vent',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_smoke_outlet'
              },
              {
                label: 'Heating',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_heating'
              },
              {
                label: 'Kitchen',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Showcase',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storefront'
              },
              {
                label: 'Corner prop.',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_on_the_corner'
              },
              {
                label: 'Facade',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_facade'
              },
              {
                label: 'Warehouse',
                order: 800,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Date range',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'No date out',
                    value: 'no_date'
                  },
                  {
                    label: 'All dates out',
                    value: 'all_date'
                  }
                ],
                description:
                  'Publication date, unpublish date and transaction within the marked time period',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Comparables surface range',
                order: 100,
                value: null,
                values: [300, 20000],
                description: 'Choose the surface range of comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Choose the distance of comparables',
            order: 300,
            filters: [
              {
                label: 'Maximum distance',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'On foot',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'By car',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Same neighborhood',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Mark the maximum distance of the comparables with respect to your asset'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Year of construction',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Offices',
        value: 2,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Valuation surface',
                order: 100,
                description: 'Area of your asset for valuation',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'floor',
            order: 200,
            filters: [
              {
                label: 'Number of office floors',
                order: 100,
                description: 'Number of floors covered by this office',
                filter_type: 'counter',
                internal_field: 'n_floors'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'General conditions',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Needs refurbishment',
                    value: 5
                  },
                  {
                    label: 'Parcial updated',
                    value: 2
                  },
                  {
                    label: 'Good condition',
                    value: 1
                  },
                  {
                    label: 'Brand new',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'parking_spaces',
            order: 400,
            filters: [
              {
                label: 'Parking spaces',
                order: 100,
                value: null,
                values: [0, 100],
                overflow: true,
                description: 'Number of parking spaces included in this price',
                filter_type: 'range',
                internal_field: 'parking_spaces__range'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Choose the characteristics of your asset',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Diaphanous distribution',
                    value: 1
                  },
                  {
                    label: 'Screens',
                    value: 4
                  },
                  {
                    label: 'Partitioned distribution',
                    value: 5
                  }
                ],
                description: 'Type of distribution',
                filter_type: 'multicheck',
                internal_field: 'offices_distribution__in'
              }
            ]
          },
          {
            id: 'orientation',
            order: 600,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'North',
                    value: 1
                  },
                  {
                    label: 'South',
                    value: 2
                  },
                  {
                    label: 'East',
                    value: 3
                  },
                  {
                    label: 'West',
                    value: 4
                  }
                ],
                description: 'Type of orientation',
                filter_type: 'multicheck',
                internal_field: 'orientation__in'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Elevator',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_elevator'
              },
              {
                label: 'Bathroom',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_bathroom'
              },
              {
                label: 'Kitchen',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Warehouse',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              },
              {
                label: 'Surveillance and security services',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_security_system'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Date range',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'No date out',
                    value: 'no_date'
                  },
                  {
                    label: 'All dates out',
                    value: 'all_date'
                  }
                ],
                description:
                  'Publication date, unpublish date and transaction within the marked time period',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Comparables surface range',
                order: 100,
                value: null,
                values: [0, 200000],
                description: 'Choose the surface range of comparables',
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Choose the distance of comparables',
            order: 300,
            filters: [
              {
                label: 'Maximum distance',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'On foot',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'By car',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Same neighborhood',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Mark the maximum distance of the comparables with respect to your asset'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Year of construction',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      }
    ],
    publishing_status: [
      {
        label: 'Publishing Status',
        order: 100,
        value: null,
        values: [
          {
            label: 'All',
            value: null
          },
          {
            label: 'Published',
            value: 1
          },
          {
            label: 'Unpublished',
            value: 0
          },
          {
            label: 'Sold',
            value: 2
          }
        ],
        filter_type: 'multiselect',
        internal_field: 'publishing_status__in'
      }
    ],
    publishing_status__in: [
      {
        label: 'Published',
        order: 100,
        value: 1,
        filter_type: 'chip-boolean',
        internal_field: 'published'
      },
      {
        label: 'Unpublished',
        order: 100,
        value: 0,
        values: [
          {
            tag: 'Closing uDA',
            title: 'Last revised price',
            value: 'closing_uda',
            description:
              'Adjustment on the last price of the comparable before unpublishing. uDA applies a price readjustment based on market data.'
          },
          {
            tag: 'Original',
            title: 'Last listing price',
            value: 'original',
            description:
              'Last seen price of the comparable before it was unpublished from the portal. Original price without retouching.'
          }
        ],
        filter_type: 'chip-select',
        internal_field: 'price_status'
      },
      {
        label: 'Sold',
        order: 100,
        value: 2,
        filter_type: 'chip-boolean',
        internal_field: 'sold'
      },
      {
        label: 'Appraisal',
        order: 100,
        value: 3,
        filter_type: 'chip-boolean',
        internal_field: 'appraisal'
      }
    ]
  },
  it: {
    listing_types: [
      {
        label: 'Residenziale',
        value: 1,
        assetFilters: [
          {
            id: 'use',
            order: 100,
            filters: [
              {
                label: 'Tipo di proprietà',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Attico',
                    value: 1
                  },
                  {
                    label: 'Duplex',
                    value: 2
                  },
                  {
                    label: 'Appartamento',
                    value: 4
                  },
                  {
                    label: 'Loft',
                    value: 7
                  },
                  {
                    label: 'Casa indipendente',
                    value: 3
                  },
                  {
                    label: 'Villete bifamiliari',
                    value: 5
                  },
                  {
                    label: 'Terraced House',
                    value: 6
                  },
                  {
                    label: 'Villa rustica',
                    value: 8
                  }
                ],
                description: 'Scegliere la tipologia di residenziale',
                filter_type: 'dropdown',
                internal_field: 'property_type'
              }
            ]
          },
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superficie di valutazione',
                order: 100,
                description: 'Superficie costruita con elementi comuni per la valutazione uDA.',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'floor',
            order: 300,
            filters: [
              {
                label: 'Pianta',
                order: 100,
                filter_type: 'counter',
                internal_field: 'floor'
              },
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Piano intermedio',
                    value: false
                  }
                ],
                filter_type: 'check',
                internal_field: 'middle_floor'
              }
            ]
          },
          {
            id: 'condition',
            order: 400,
            filters: [
              {
                label: 'Condizioni generali',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Necessita di ristrutturazione',
                    value: 5
                  },
                  {
                    label: 'Parziale aggiornato',
                    value: 2
                  },
                  {
                    label: 'Buono stato',
                    value: 1
                  },
                  {
                    label: 'Nuovo',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Scegliere le caratteristiche del bene',
            order: 600,
            filters: [
              {
                label: 'Locali',
                order: 100,
                filter_type: 'counter',
                internal_field: 'n_rooms'
              },
              {
                label: 'Bagni',
                order: 200,
                filter_type: 'counter',
                internal_field: 'n_baths'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'Ascensore',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_elevator'
              },
              {
                label: 'Magazzino',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storage'
              },
              {
                label: 'Terrazza',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_terrace'
              },
              {
                label: 'Piscina',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_pool'
              },
              {
                label: 'Garage',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_garage'
              },
              {
                label: 'AC',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Esterno',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_exterior'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'use',
            order: 100,
            filters: [
              {
                label: 'Tipo di proprietà',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Attico',
                    value: 1
                  },
                  {
                    label: 'Duplex',
                    value: 2
                  },
                  {
                    label: 'Appartamento',
                    value: 4
                  },
                  {
                    label: 'Loft',
                    value: 7
                  },
                  {
                    label: 'Casa indipendente',
                    value: 3
                  },
                  {
                    label: 'Villete bifamiliari',
                    value: 5
                  },
                  {
                    label: 'Terraced House',
                    value: 6
                  },
                  {
                    label: 'Villa rustica',
                    value: 8
                  }
                ],
                multiple: true,
                description: 'Scegliere la tipologia di residenziale',
                filter_type: 'dropdown',
                internal_field: 'property_type__in_comparables'
              }
            ]
          },
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervallo di date',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nessuna data di uscita',
                    value: 'no_date'
                  },
                  {
                    label: 'Tutte le date di uscita',
                    value: 'all_date'
                  }
                ],
                description: 'Per tutte le fonti pubblicate o commerciali',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Gamma di superfici comparabili',
                order: 100,
                value: null,
                values: [0, 1000],
                description: "Scegliere l'intervallo di superficie dei comparabili",
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Scegliere la distanza dei comparabili',
            order: 300,
            filters: [
              {
                label: 'Distanza massima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '100 m',
                    value: 100
                  },
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A piedi',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'In auto',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Stesso quartiere',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Contrassegnare la distanza massima dei comparabili rispetto al bene'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Anno di costruzione',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          },
          {
            id: 'main_features_comparables',
            label: "Scegliere l'intervallo dei comparabili",
            order: 500,
            filters: [
              {
                label: 'Locali',
                order: 100,
                value: null,
                values: [
                  {
                    label: '0',
                    value: 0
                  },
                  {
                    label: '1',
                    value: 1
                  },
                  {
                    label: '2',
                    value: 2
                  },
                  {
                    label: '3',
                    value: 3
                  },
                  {
                    label: '+4',
                    value: 4
                  }
                ],
                filter_type: 'multiselect',
                internal_field: 'n_rooms__in'
              },
              {
                label: 'Bagni',
                order: 200,
                value: null,
                values: [
                  {
                    label: '1',
                    value: 1
                  },
                  {
                    label: '2',
                    value: 2
                  },
                  {
                    label: '3',
                    value: 3
                  },
                  {
                    label: '+4',
                    value: 4
                  }
                ],
                filter_type: 'multiselect',
                internal_field: 'n_baths__in'
              }
            ],
            description:
              "Scegliere l'intervallo che si preferisce visualizzare nei risultati dei confronti"
          }
        ]
      },
      {
        label: 'Edifici',
        value: 12,
        order: 200,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superficie totale dell´edificio',
                order: 100,
                description: 'Superficie totale del terreno',
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Propiedades',
            order: 200,
            filters: [
              {
                label: 'Numero di beni',
                filter_type: 'counter',
                internal_field: 'n_houses'
              },
              {
                label: 'Numero di ascensori',
                filter_type: 'counter',
                internal_field: 'n_elevator'
              },
              {
                label: 'Numero di posti auto',
                filter_type: 'counter',
                internal_field: 'n_garages'
              }
            ]
          },
          {
            id: 'features',
            order: 300,
            filters: [
              {
                label: 'Ha inquilino',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Si',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_tenant'
              },
              {
                label: 'Ha sorveglianza',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Si',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_vigilance'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervallo di date',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nessuna data di uscita',
                    value: 'no_date'
                  },
                  {
                    label: 'Tutte le date di uscita',
                    value: 'all_date'
                  }
                ],
                description: 'Per tutte le fonti pubblicate o commerciali',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Gamma di superfici comparabili',
                order: 100,
                value: null,
                values: [0, 500],
                description: "Scegliere l'intervallo di superficie dei comparabili",
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Scegliere la distanza dei comparabili',
            order: 300,
            filters: [
              {
                label: 'Distanza massima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A piedi',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'In auto',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Stesso quartiere',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Contrassegnare la distanza massima dei comparabili rispetto al bene'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Anno di costruzione',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Terrenos',
        value: 8,
        assetFilters: [
          {
            id: 'property_type',
            order: 100,
            filters: [
              {
                label: 'Tipo di proprietà',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Terreno urbano',
                    value: 38
                  },
                  {
                    label: 'Terreno edificabile',
                    value: 42
                  },
                  {
                    label: 'Terreno agricolo',
                    value: 53
                  },
                  {
                    label: 'Indifferente',
                    value: 0
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'property_type'
              }
            ]
          },
          {
            id: 'area',
            order: 300,
            filters: [
              {
                data: {
                  value: 120,
                  format: 'area'
                },
                label: 'Superficie totale del terreno',
                order: 100,
                filter_type: 'input',
                internal_field: 'area'
              }
            ]
          },
          {
            id: 'buildable_area',
            filters: [
              {
                data: {
                  value: 120,
                  format: 'area'
                },
                label: 'Superficie edificabile',
                order: 100,
                filter_type: 'input',
                internal_field: 'buildable_area'
              }
            ]
          },

          {
            id: 'access_type',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Per strada',
                    value: 3
                  },
                  {
                    label: 'Via urbana',
                    value: 4
                  },
                  {
                    label: 'Per sentiero',
                    value: 2
                  },
                  {
                    label: 'Per autostrada',
                    value: 1
                  }
                ],
                description: 'Tipo di accesso',
                filter_type: 'multicheck',
                internal_field: 'access_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'Ha elettricità',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_electricity'
              },
              {
                label: 'Gas naturale',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_natural_gas'
              },
              {
                label: 'Ha marciapiede',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_pavement'
              },
              {
                label: 'Ha fognatura',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_sewerage'
              },
              {
                label: 'Via illuminata',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_street_lighting'
              },
              {
                label: 'Ha acqua',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sí',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_water'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervallo di date',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nessuna data di uscita',
                    value: 'no_date'
                  },
                  {
                    label: 'Tutte le date di uscita',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data di pubblicazione, disattivazione e transazione nel periodo temporale indicato',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Gamma di superfici comparabili',
                order: 100,
                value: null,
                values: [0, 900000],
                description: "Scegliere l'intervallo di superficie dei comparabili",
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Scegliere la distanza dei comparabili',
            order: 300,
            filters: [
              {
                label: 'Distanza massima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '1 km',
                    value: 1000
                  },

                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  },
                  {
                    label: '15 km',
                    value: 15000
                  },
                  {
                    label: '20 km',
                    value: 20000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A piedi',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'In auto',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Stesso quartiere',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Contrassegnare la distanza massima dei comparabili rispetto al bene'
          }
        ]
      },
      {
        label: 'Garage',
        value: 7,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 9,
                  format: 'area'
                },
                label: 'Superficie di valutazione',
                order: 100,
                description: 'Area del bene per la valutazione',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Scegliere le caratteristiche del bene',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Parcheggio per moto',
                    value: 1
                  },
                  {
                    label: 'Parcheggio per auto',
                    value: 2
                  },
                  {
                    label: 'Parcheggio per auto e moto',
                    value: 3
                  },
                  {
                    label: 'Parcheggio piccolo',
                    value: 4
                  },
                  {
                    label: 'Garage',
                    value: 5
                  }
                ],
                description: 'Garage',
                filter_type: 'multicheck',
                internal_field: 'parking_type__in'
              }
            ]
          },
          {
            id: 'others_features',
            order: 300,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Porta automatica',
                    value: 1
                  },
                  {
                    label: 'Servizi di sorveglianza e sicurezza',
                    value: 2
                  },
                  {
                    label: "Accesso all'ascensore",
                    value: 3
                  },
                  {
                    label: 'Interno',
                    value: 4
                  }
                ],
                description: 'Altre',
                filter_type: 'multicheck',
                internal_field: 'features_choices__in'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervallo di date',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nessuna data di uscita',
                    value: 'no_date'
                  },
                  {
                    label: 'Tutte le date di uscita',
                    value: 'all_date'
                  }
                ],
                description:
                  'Data di pubblicazione, disattivazione e transazione nel periodo temporale indicato',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Gamma di superfici comparabili',
                order: 100,
                value: null,
                values: [0, 60],
                description: "Scegliere l'intervallo di superficie dei comparabili",
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Scegliere la distanza dei comparabili',
            order: 300,
            filters: [
              {
                label: 'Distanza massima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A piedi',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'In auto',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Stesso quartiere',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Contrassegnare la distanza massima dei comparabili rispetto al bene'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Anno di costruzione',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Locali',
        value: 5,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superficie di valutazione',
                order: 100,
                description: 'Area del bene per la valutazione',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Condizioni generali',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Necessita di ristrutturazione',
                    value: 5
                  },
                  {
                    label: 'Parziale aggiornato',
                    value: 2
                  },
                  {
                    label: 'Buono stato',
                    value: 1
                  },
                  {
                    label: 'Nuovo',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'distribution',
            label: 'Tipo di distribuzione',
            order: 400,
            filters: [
              {
                order: 100,
                description: 'Numero di stanze, da 0 a 100',
                filter_type: 'counter',
                internal_field: 'commercial_distribution'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Scegliere le caratteristiche del bene',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Indifferente',
                    value: null
                  },
                  {
                    label: 'Centro commerciale',
                    value: 23
                  },
                  {
                    label: 'A livello di strada',
                    value: 39
                  },
                  {
                    label: 'Mezzanine',
                    value: 24
                  },
                  {
                    label: 'Sotterraneo',
                    value: 25
                  },
                  {
                    label: 'Altre',
                    value: 26
                  }
                ],
                description: 'Posizione',
                filter_type: 'multicheck',
                internal_field: 'property_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 600,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Bocchetta di fumo',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_smoke_outlet'
              },
              {
                label: 'Riscaldamento',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_heating'
              },
              {
                label: 'Cucina',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Vetrina',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storefront'
              },
              {
                label: 'Angolo prop.',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_on_the_corner'
              },
              {
                label: 'Facciata',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_facade'
              },
              {
                label: 'Magazzino',
                order: 800,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervallo di date',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nessuna data di uscita',
                    value: 'no_date'
                  },
                  {
                    label: 'Tutte le date di uscita',
                    value: 'all_date'
                  }
                ],
                description: 'Per tutte le fonti pubblicate o commerciali',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Gamma di superfici comparabili',
                order: 100,
                value: null,
                values: [0, 5000],
                description: "Scegliere l'intervallo di superficie dei comparabili",
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Scegliere la distanza dei comparabili',
            order: 300,
            filters: [
              {
                label: 'Distanza massima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A piedi',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'In auto',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Stesso quartiere',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Contrassegnare la distanza massima dei comparabili rispetto al bene'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Anno di costruzione',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Stoccaggio',
        value: 11,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 4,
                  format: 'area'
                },
                label: 'Deposito di superficie',
                order: 100,
                description: 'Area del bene per la valutazione',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Scegliere le caratteristiche del bene',
            order: 200,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Baia di carico',
                    value: 5
                  },
                  {
                    label: 'Accesso 24h',
                    value: 6
                  }
                ],
                description: 'Caratteristiche',
                filter_type: 'multicheck',
                internal_field: 'features_choices__in'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervallo di date',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nessuna data di uscita',
                    value: 'no_date'
                  },
                  {
                    label: 'Tutte le date di uscita',
                    value: 'all_date'
                  }
                ],
                description: 'Per tutte le fonti pubblicate o commerciali',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Gamma di superfici comparabili',
                order: 100,
                value: null,
                values: [0, 500],
                description: "Scegliere l'intervallo di superficie dei comparabili",
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Scegliere la distanza dei comparabili',
            order: 300,
            filters: [
              {
                label: 'Distanza massima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A piedi',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'In auto',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Stesso quartiere',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Contrassegnare la distanza massima dei comparabili rispetto al bene'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Anno di costruzione',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Magazzini',
        value: 6,
        assetFilters: [
          {
            id: 'area',
            order: 200,
            filters: [
              {
                data: {
                  value: 300,
                  format: 'area'
                },
                label: 'Superficie di valutazione',
                order: 100,
                description: 'Area del bene per la valutazione',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Condizioni generali',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Necessita di ristrutturazione',
                    value: 5
                  },
                  {
                    label: 'Parziale aggiornato',
                    value: 2
                  },
                  {
                    label: 'Buono stato',
                    value: 1
                  },
                  {
                    label: 'Nuovo',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'distribution',
            label: 'Tipo di distribuzione',
            order: 400,
            filters: [
              {
                order: 100,
                description: 'Numero di stanze, da 0 a 100',
                filter_type: 'counter',
                internal_field: 'commercial_distribution'
              }
            ]
          },
          {
            id: 'main_features',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Indifferente',
                    value: null
                  },
                  {
                    label: 'Centro commerciale',
                    value: 23
                  },
                  {
                    label: 'A livello di strada',
                    value: 39
                  },
                  {
                    label: 'Mezzanine',
                    value: 24
                  },
                  {
                    label: 'Sotterraneo',
                    value: 25
                  },
                  {
                    label: 'Altre',
                    value: 26
                  }
                ],
                description: 'Posizione',
                filter_type: 'multicheck',
                internal_field: 'property_type__in'
              }
            ]
          },
          {
            id: 'features',
            order: 600,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Bocchetta di fumo',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_smoke_outlet'
              },
              {
                label: 'Riscaldamento',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_heating'
              },
              {
                label: 'Cucina',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Vetrina',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_storefront'
              },
              {
                label: 'Angolo prop.',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'is_on_the_corner'
              },
              {
                label: 'Facciata',
                order: 700,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_facade'
              },
              {
                label: 'Magazzino',
                order: 800,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervallo di date',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nessuna data di uscita',
                    value: 'no_date'
                  },
                  {
                    label: 'Tutte le date di uscita',
                    value: 'all_date'
                  }
                ],
                description: 'Per tutte le fonti pubblicate o commerciali',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Gamma di superfici comparabili',
                order: 100,
                value: null,
                values: [300, 500000],
                description: "Scegliere l'intervallo di superficie dei comparabili",
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Scegliere la distanza dei comparabili',
            order: 300,
            filters: [
              {
                label: 'Distanza massima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A piedi',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'In auto',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Stesso quartiere',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Contrassegnare la distanza massima dei comparabili rispetto al bene'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Anno di costruzione',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      },
      {
        label: 'Uffici',
        value: 2,
        assetFilters: [
          {
            id: 'area',
            order: 100,
            filters: [
              {
                data: {
                  value: 90,
                  format: 'area'
                },
                label: 'Superficie di valutazione',
                order: 100,
                description: 'Area del bene per la valutazione',
                filter_type: 'input'
              }
            ]
          },
          {
            id: 'floor',
            order: 200,
            filters: [
              {
                label: "Numero di piani dell'ufficio",
                order: 100,
                description: 'Numero di piani coperti da questo ufficio',
                filter_type: 'counter',
                internal_field: 'n_floors'
              }
            ]
          },
          {
            id: 'condition',
            order: 300,
            filters: [
              {
                label: 'Condizioni generali',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Necessita di ristrutturazione',
                    value: 5
                  },
                  {
                    label: 'Parziale aggiornato',
                    value: 2
                  },
                  {
                    label: 'Buono stato',
                    value: 1
                  },
                  {
                    label: 'Nuovo',
                    value: 6
                  }
                ],
                filter_type: 'incremental',
                internal_field: 'build_status'
              }
            ]
          },
          {
            id: 'parking_spaces',
            order: 400,
            filters: [
              {
                label: 'Garage',
                order: 100,
                value: null,
                values: [0, 100],
                overflow: true,
                description: 'Numero di parcheggio incluso in questo prezzo',
                filter_type: 'range',
                internal_field: 'parking_spaces__range'
              }
            ]
          },
          {
            id: 'main_features',
            label: 'Scegliere le caratteristiche del bene',
            order: 500,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Distribuzione diafana',
                    value: 1
                  },
                  {
                    label: 'Schermi',
                    value: 4
                  },
                  {
                    label: 'Distribuzione suddivisa',
                    value: 5
                  }
                ],
                description: 'Tipo di distribuzione',
                filter_type: 'multicheck',
                internal_field: 'offices_distribution__in'
              }
            ]
          },
          {
            id: 'orientation',
            order: 600,
            filters: [
              {
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nord',
                    value: 1
                  },
                  {
                    label: 'Sud',
                    value: 2
                  },
                  {
                    label: 'Est',
                    value: 3
                  },
                  {
                    label: 'Ovest',
                    value: 4
                  }
                ],
                description: 'Tipo di orientamento',
                filter_type: 'multicheck',
                internal_field: 'orientation__in'
              }
            ]
          },
          {
            id: 'features',
            order: 700,
            filters: [
              {
                label: 'AC',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_air_conditioner'
              },
              {
                label: 'Ascensore',
                order: 200,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_elevator'
              },
              {
                label: 'Bagno',
                order: 300,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_bathroom'
              },
              {
                label: 'Cucina',
                order: 400,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_kitchen'
              },
              {
                label: 'Magazzino',
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_warehouse'
              },
              {
                label: 'Servizi di sorveglianza e sicurezza',
                order: 600,
                value: null,
                values: [
                  {
                    label: 'Sì',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ],
                filter_type: 'select',
                internal_field: 'has_security_system'
              }
            ]
          }
        ],
        comparablesFilters: [
          {
            id: 'date',
            order: 100,
            filters: [
              {
                label: 'Intervallo di date',
                order: 100,
                value: null,
                values: [
                  {
                    label: 'Nessuna data di uscita',
                    value: 'no_date'
                  },
                  {
                    label: 'Tutte le date di uscita',
                    value: 'all_date'
                  }
                ],
                description: 'Per tutte le fonti pubblicate o commerciali',
                filter_type: 'select',
                internal_field: 'date_main'
              },
              {
                data: {
                  format: 'months'
                },
                order: 200,
                value: null,
                values: [0, 60],
                filter_type: 'range',
                internal_field: 'date_main_range'
              }
            ]
          },
          {
            id: 'area__range',
            order: 200,
            filters: [
              {
                data: {
                  format: 'area'
                },
                label: 'Gamma di superfici comparabili',
                order: 100,
                value: null,
                values: [0, 200000],
                description: "Scegliere l'intervallo di superficie dei comparabili",
                filter_type: 'range',
                internal_field: 'area__range'
              }
            ]
          },
          {
            id: 'distances',
            label: 'Scegliere la distanza dei comparabili',
            order: 300,
            filters: [
              {
                label: 'Distanza massima',
                order: 200,
                value: null,
                values: [
                  {
                    label: '500 m',
                    value: 500
                  },
                  {
                    label: '1 km',
                    value: 1000
                  },
                  {
                    label: '2 km',
                    value: 2000
                  },
                  {
                    label: '5 km',
                    value: 5000
                  },
                  {
                    label: '10 km',
                    value: 10000
                  }
                ],
                filter_type: 'select',
                internal_field: 'geog__dwithin'
              },
              {
                label: 'A piedi',
                order: 300,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_on_foot'
              },
              {
                label: 'In auto',
                order: 400,
                value: null,
                values: [
                  {
                    label: '1 min',
                    value: 1
                  },
                  {
                    label: '5 min',
                    value: 5
                  },
                  {
                    label: '10 min',
                    value: 10
                  },
                  {
                    label: '15 min',
                    value: 15
                  },
                  {
                    label: '20 min',
                    value: 20
                  }
                ],
                filter_type: 'select',
                internal_field: 'distance_by_car'
              },
              {
                order: 500,
                value: null,
                values: [
                  {
                    label: 'Stesso quartiere',
                    value: null
                  }
                ],
                filter_type: 'check',
                internal_field: 'same_neighbour'
              }
            ],
            description: 'Contrassegnare la distanza massima dei comparabili rispetto al bene'
          },
          {
            id: 'construction_year',
            order: 400,
            filters: [
              {
                label: 'Anno di costruzione',
                order: 100,
                value: null,
                values: [1900, 2023],

                filter_type: 'range',
                internal_field: 'construction_year__range'
              }
            ]
          }
        ]
      }
    ],
    publishing_status: [
      {
        label: 'Publishing Status',
        order: 100,
        value: null,
        values: [
          {
            label: 'Tutti',
            value: null
          },
          {
            label: 'Pubblicato',
            value: 1
          },
          {
            label: 'Non pubblicato',
            value: 0
          },
          {
            label: 'Venduto',
            value: 2
          }
        ],
        filter_type: 'multiselect',
        internal_field: 'publishing_status__in'
      }
    ],
    filters_table: [
      {
        label: 'Similitudine',
        order: 100,
        value: null,
        values: [
          {
            label: 'Alto',
            value: 'high'
          },
          {
            label: 'Medio',
            value: 'medium'
          },
          {
            label: 'Basso',
            value: 'low'
          }
        ],
        filter_type: 'multiselect',
        internal_field: 'similarity__in'
      }
    ],
    publishing_status__in: [
      {
        label: 'Pubblicato',
        order: 100,
        value: 1,
        filter_type: 'chip-boolean',
        internal_field: 'published'
      },
      {
        label: 'Non pubblicato',
        order: 100,
        value: 0,
        values: [
          {
            tag: 'Closing uDA',
            title: 'Ultimo prezzo rivisto',
            value: 'closing_uda',
            description:
              "Adeguamento sull'ultimo prezzo del comparabile prima dell'annullamento della pubblicazione. uDA applica un riaggiustamento del prezzo in base ai dati di mercato"
          },
          {
            tag: 'Original',
            title: 'Ultimo prezzo di listino',
            value: 'original',
            description:
              "Prezzo visto l'ultima volta del comparabile prima che fosse annullato la pubblicazione dal portale. Prezzo originale senza ritocchi."
          }
        ],
        filter_type: 'chip-select',
        internal_field: 'price_status'
      },
      {
        label: 'Venduto',
        order: 100,
        value: 2,
        filter_type: 'chip-boolean',
        internal_field: 'sold'
      },
      {
        label: 'Valutazione',
        order: 100,
        value: 3,
        filter_type: 'chip-boolean',
        internal_field: 'appraisal'
      }
    ]
  }
};

export default FILTERS_CONFIG;
