import { NavigationBar, Loader } from '@uda/bento-components';
import styled from 'styled-components';

const StyledContainer = styled.div`
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    text-align: center;
    span {
      display: inline-block;
    }
  }
`;

const LoadingLayout = ({ full, children }) => {
  return (
    <>
      {full ? <NavigationBar loading /> : null}
      <StyledContainer>
        <div>
          <Loader size="small" />
          {children}
        </div>
      </StyledContainer>
    </>
  );
};

export default LoadingLayout;
