import {
  IconAnchor,
  IconBedroom,
  IconElevator,
  IconParking,
  IconStorage,
  IconSwimmingPool
} from '@uda/bento-components';

export const getIdentificationRow = asset => asset;

export const getCellAlign = field => {
  switch (field) {
    case 'asset_id':
    case 'location':
    case 'inspection':
    case 'use_review':
    case 'use':
    case 'typology_review':
    case 'typology':
    case 'cadastral_ref_1_review':
    case 'cadastral_ref_2_review':
    case 'listing_url':
    case 'vpo_review':
    case 'vpo':
    case 'vpo_end_review':
    case 'vpo_end':
    case 'year_built':
    case 'year_built_review':
    case 'current_rent_review':
    case 'current_rent':
    case 'end_rent_review':
    case 'rooms':
    case 'built_area_source':
    case 'land_area_source':
    case 'built_area':
    case 'land_area':
      return 'left';
    case 'listing_price':
    case 'occupancy_status_review':
    case 'occupancy_status':
    case 'milestones_status':
    case 'area':
      return 'right';
    case 'is_anchor':
    default:
      return 'center';
  }
};

export const getCellWidth = field => {
  switch (field) {
    case 'is_anchor':
      return { width: 56, minWidth: 56 };
    case 'use':
    case 'typology':
    case 'build_area':
    case 'land_area':
    case 'inspection':
    case 'listing_price':
      return { width: 121, minWidth: 121 };
    case 'use_review':
    case 'typology_review':
    case 'listing_url':
    case 'vpo_end':
    case 'occupancy_status_review':
    case 'building_quality':
    case 'whole_building':
    case 'missing_fol':
    case 'missing_cfo':
    case 'urbanistic_charges':
    case 'milestones_status':
    case 'stars':
      return { width: 150, minWidth: 150 };
    case 'vpo_end_review':
    case 'start_current_rent_review':
    case 'end_current_rent_review':
    case 'built_area_source':
    case 'land_area_source':
      return { width: 170, minWidth: 170 };
    case 'cadastral_ref_1_review':
    case 'cadastral_ref_2_review':
    case 'valuation_area':
      return { width: 200, minWidth: 200 };
    case 'land_status':
      return { width: 160, minWidth: 160 };
    case 'asset_id':
    case 'rooms':
    case 'vpo_review':
    case 'vpo':
    case 'current_rent':
      return { width: 100, minWidth: 100 };
    case 'interior_state_review':
    case 'interior_state':
    case 'year_built_review':
    case 'year_built':
    case 'occupancy_status':
    case 'start_current_rent':
    case 'end_current_rent':
      return { width: 120, minWidth: 120 };
    case 'floor_review':
    case 'floor':
    case 'door_review':
    case 'door':
    case 'block_review':
    case 'block':
    case 'bedrooms_review':
    case 'bedrooms':
    case 'parking_review':
    case 'parking_annex':
    case 'storage_review':
    case 'storage_annex':
    case 'lift':
    case 'swimming_pool':
      return { width: 90, minWidth: 90 };
    case 'area':
      return { width: 280, minWidth: 280 };

    case 'location':
    default:
      return { width: 110, minWidth: 110 };
  }
};

const getCellIcon = (field, sort) => {
  const iconColor = sort ? '#1778FB' : '#747D93';
  switch (field) {
    case 'is_anchor':
      return <IconAnchor customColor={iconColor} />;
    case 'bedrooms_review':
    case 'bedrooms':
      return <IconBedroom customColor={iconColor} />;
    case 'parking_review':
    case 'parking_annex':
      return <IconParking customColor={iconColor} />;
    case 'storage_review':
    case 'storage_annex':
      return <IconStorage customColor={iconColor} />;
    case 'lift':
      return <IconElevator customColor={iconColor} />;
    case 'swimming_pool':
      return <IconSwimmingPool customColor={iconColor} />;
    default:
      return null;
  }
};

const getCellRenderer = (field, table) => {
  switch (field) {
    case 'is_anchor':
      return table === 'details' ? 'selectCell' : 'iconCell';
    case 'cadastral_ref_1_review':
    case 'cadastral_ref_2_review':
    case 'listing_url':
    case 'listing_price':
    case 'floor_review':
    case 'door_review':
    case 'block_review':
    case 'bedrooms_review':
    case 'parking_review':
    case 'storage_review':
    case 'works_per_review':
    case 'vpo_end_review':
    case 'year_built_review':
    case 'start_current_rent_review':
    case 'end_current_rent_review':
    case 'urbanistic_charges':
    case 'current_rent_review':
    case 'rooms':
    case 'built_area':
    case 'land_area':
      return 'editCell';
    case 'lift':
    case 'swimming_pool':
    case 'interior_state_review':
    case 'interior_exterior':
    case 'use_review':
    case 'typology_review':
    case 'vpo_review':
    case 'building_quality':
    case 'whole_building':
    case 'missing_fol':
    case 'missing_cfo':
    case 'milestones_status':
    case 'land_status':
    case 'stars':
    case 'valuation_area':
    case 'occupancy_status_review':
    case 'built_area_source':
    case 'land_area_source':
      return 'selectCell';
    case 'use':
    case 'interior_state':
    case 'floor':
    case 'door':
    case 'block':
    case 'bedrooms':
    case 'parking_annex':
    case 'storage_annex':
    case 'works_per':
    case 'vpo':
    case 'vpo_end':
    case 'year_built':
    case 'occupancy_status':
    case 'current_rent':
    case 'start_current_rent':
    case 'end_current_rent':
      return 'customColor';
    case 'area':
      return 'areaCell';
    case 'typology':
    case 'inspection':
    case 'asset_id':
    case 'location':
    default:
      return 'singleCell';
  }
};

const getCellVariant = field => {
  switch (field) {
    default:
      return 'secondary';
  }
};

const getCellSortType = field => {
  switch (field) {
    case 'cadastral_ref_1_review':
    case 'cadastral_ref_2_review':
    case 'use_review':
    case 'typology_review':
      return null;
    case 'built_area':
    case 'land_area':
      return 'number';
    case 'is_anchor':
    case 'asset_id':
    case 'location':
    case 'inspection':
    case 'use':
    case 'typology':
    case 'area':
    case 'built_area_source':
    case 'land_area_source':
    default:
      return 'string';
  }
};

const getIsFullSelectLabel = field => {
  switch (field) {
    case 'built_area_source':
      return true;
    default:
      return false;
  }
};

export const apiInternDevelopmentTable = (data, table) => {
  return {
    columns: data.columns.map(e => ({
      ...e,
      ...getCellWidth(e.field),
      icon: getCellIcon(e.field, e.sortDirection),
      align: getCellAlign(e.field),
      cellRenderer: getCellRenderer(e.field, table),
      variant: getCellVariant(e.field),
      highlight: false,
      sortType: getCellSortType(e.field),
      fullSelectLabel: getIsFullSelectLabel(e.field),
      rounded: false
    })),
    rows: data.assets,
    pagination: data.pagination,
    masterData: data.master_data
  };
};

export const getNewTableValues = (rows, changes) => {
  rows[changes.index][changes.field] = changes.value;

  return rows;
};
