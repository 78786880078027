import { getLocalStorage } from '../utils';
import * as CONSTANTS from '../constants.js';

const getLastProductFromUrl = () => {
  const url = window.location.href;
  if (url.includes('asset')) return 'asset';
  if (url.includes('portfolio')) return 'portfolio';
  return 'opportunities';
};

export const getLastProduct = () => {
  const localStorageProduct = getLocalStorage(CONSTANTS.PRODUCT_KEY);
  return localStorageProduct ? localStorageProduct : getLastProductFromUrl();
};
