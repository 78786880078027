import { internCalculator } from './intern';

export const initialState = {
  values: {}
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'FETCH_OPPORTUNITIES_DETAIL_SUCCESS':
      return {
        ...state,
        values: internCalculator(payload.data.card.calculation_indicators, payload.data.card.main)
      };
    case 'CHANGE_CALCULATOR_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          [payload.id]: {
            ...state.values[payload.id],
            data: {
              ...state.values[payload.id].data,
              value: payload.value
            }
          }
        }
      };
    default:
      return state;
  }
};

export default reducer;
