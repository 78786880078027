import { Skeleton, Grid } from '@uda/bento-components';
import styled from 'styled-components';

const StyledSkeleton = styled(props => <Skeleton {...props} />)`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledGrid = styled(props => <Grid {...props} />)`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  grid-gap: ${({ theme }) => theme.spacings.small2};
  padding-top: 1px;

  > div {
    padding: ${({ theme }) => theme.spacings.small3};
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.shapes.borderRadiusMedium};
    &:first-child {
      border-color: ${({ theme }) => theme.color.charcoal400};
    }
  }
  header {
    display: flex;
    justify-content: space-between;
  }

  i {
    margin-bottom: ${({ theme }) => theme.spacings.small3};
  }
`;

const PaymentsCheckoutPlanSkeleton = ({ items = 2 }) => (
  <>
    <StyledSkeleton variant="text" height="36px" width="185px" />
    <StyledGrid columns="1fr 1fr">
      {[...Array(items)].map((e, i) => (
        <div key={i}>
          <header>
            <Skeleton variant="text" height="20px" width="35px" />
            <Skeleton variant="text" height="20px" width="41px" />
          </header>
          <Skeleton variant="text" height="20px" width="70%" />
          <Skeleton variant="text" height="20px" width="50%" />
        </div>
      ))}
    </StyledGrid>
  </>
);

export default PaymentsCheckoutPlanSkeleton;
