import {
  getCookie,
  getLocalStorage,
  removeCookie,
  removeLocalStorage,
  removeSessionStorage,
  setLocalStorage
} from '../utils';
import * as CONSTANTS from '../constants.js';
import { history } from '../App';
import { signOut, setRedirect } from '../Auth/actions/auth';
import { locale } from '../translations';
// import * as Sentry from '@sentry/react';
import { goToProduct, goToRedirection } from './redirections';
import USER_PAGES from '../routes/userPages';
import ASSET_PATHS from '../Asset/paths';
import OPPORTUNITIES_PATHS from '../Opportunities/paths';
import SMART_VALUATION_PATHS from '../SmartValuation/paths';

export const patchUser = ({ showNotification = true, ...user }) => ({
  type: 'UPDATE_USER',
  payload: {
    request: {
      method: 'PATCH',
      url: '/users/settings/',
      data: user
    },
    showNotification
  }
});

export const updateUser = user => async dispatch => {
  return await dispatch(patchUser(user));
};

export const initUser = () => async dispatch => {
  const action = await dispatch(fetchUser());

  if (action.type === 'FETCH_USER_SUCCESS' && !action.error) {
    const { products, email, default_product, company, plans } = action.payload.data;

    if (CONSTANTS.RESTRICTED_COMPANIES.includes(company) && plans === null) {
      const [product] = products;

      if (product === 'asset') history.push(ASSET_PATHS.welcome);
      if (product === 'smart-valuation') history.push(SMART_VALUATION_PATHS.welcome);
      else if (product === 'opportunities') history.push(OPPORTUNITIES_PATHS.base);
    } else {
      dispatch(checkUserProduct(products, default_product));
    }

    // Sentry set user
    // Sentry.setUser({ email });
    // setUserFroged(action.payload.data);
    // GTag({ user_id: email });
  } else {
    dispatch(signOut());
  }
};

export const fetchSmartValuationRestrictions = () => ({
  type: 'FETCH_SMARTVALUATION_RESTRICTIONS',
  payload: {
    request: {
      method: 'GET',
      url: `/users/me/products/asset/restrictions/`
    }
  }
});

// const setUserFroged = ({ email, company, name, tel, surname }) => {
//   if (!window.Froged) {
//     return;
//   }
//   window.Froged('set', {
//     ...(email && { userId: email }),
//     ...(email && { email }),
//     ...(company && { company }),
//     ...(tel && { phone: tel }),
//     ...(surname && { lastname: surname }),
//     ...(name && { name })
//   });
// };

export const fetchUser = () => ({
  type: 'FETCH_USER',
  payload: {
    request: {
      method: 'GET',
      url: '/users/me/'
    }
  }
});

export const checkUserIsLogged = token => dispatch => {
  token = token || getCookie(CONSTANTS.TOKEN_KEY) || getLocalStorage(CONSTANTS.TOKEN_KEY);
  const strapiToken =
    getCookie(CONSTANTS.STRAPI_TOKEN_KEY) || getLocalStorage(CONSTANTS.STRAPI_TOKEN_KEY);

  if (token) {
    dispatch({ type: 'SAVE_TOKEN', payload: token });
    dispatch({ type: 'SAVE_STRAPI_TOKEN', payload: strapiToken });
    dispatch(initUser());
    // dispatch(fetchStripeKey());
  } else {
    dispatch({ type: 'RESET_USER' });
  }
};

export const setLanguage = langKey => {
  const currentLanguage = langKey || getLocalStorage(CONSTANTS.LANG_KEY);

  // Sentry set tag
  // Sentry.setTag('lang', currentLanguage || locale);

  return {
    type: 'SET_LANGUAGE',
    payload: currentLanguage || locale
  };
};

export const checkUserProduct = (products, defaultProduct) => async (dispatch, getState) => {
  const redirect = getState().auth.redirect;
  const [productSlug, sectionSlug] = history.location.pathname.split('/').slice(1);

  const slug =
    productSlug === '_asset' ? 'asset' : productSlug === 'asset' ? 'smart-valuation' : productSlug;

  if (USER_PAGES.includes(slug)) return;

  if (redirect !== '/' && redirect !== '/sign-out' && redirect !== null && (slug || sectionSlug)) {
    dispatch(goToRedirection(slug, redirect));
    dispatch(setRedirect(null));
    return;
  }

  if (products.includes(slug) && sectionSlug) {
    setLocalStorage(CONSTANTS.PRODUCT_KEY, slug);
    return;
  }

  if (products.includes(slug)) {
    setLocalStorage(CONSTANTS.PRODUCT_KEY, slug);
  } else {
    const isRedirect = history.location.pathname === '/';
    const lastProduct = getLocalStorage(CONSTANTS.PRODUCT_KEY);
    const [firstProduct] = products;
    const altProduct = products.includes(defaultProduct) ? defaultProduct : firstProduct;

    const product = lastProduct && lastProduct !== 'undefined' ? lastProduct : altProduct;

    dispatch(goToProduct(product, isRedirect));
  }
};

export const resetUser = () => {
  removeCookie(CONSTANTS.TOKEN_KEY);
  removeLocalStorage(CONSTANTS.TOKEN_KEY);
  removeSessionStorage(CONSTANTS.TOKEN_KEY);
  removeCookie(CONSTANTS.STRAPI_TOKEN_KEY);
  removeLocalStorage(CONSTANTS.STRAPI_TOKEN_KEY);
  removeSessionStorage(CONSTANTS.STRAPI_TOKEN_KEY);
  removeLocalStorage(CONSTANTS.ASSET_USERJWT_KEY);
  removeLocalStorage(CONSTANTS.ASSET_SERVER_KEY);
  removeLocalStorage(CONSTANTS.ASSET_LANG_KEY);

  return {
    type: 'RESET_USER'
  };
};

export const fetchEnterpriseEmail = data => ({
  type: 'FETCH_ENTERPRISE_EMAIL',
  payload: {
    request: {
      method: 'POST',
      url: '/users/enterprise-form/',
      data
    }
  }
});

export const updateEnterpriseSentSuccess = () => ({
  type: 'UPDATE_ENTERPRISE_SENT',
  payload: {
    isEnterpriseFormSendedSuccessfully: null
  }
});

export const fetchStripeKey = () => ({
  type: 'FETCH_STRIPE_KEY',
  payload: {
    request: {
      method: 'GET',
      url: '/payments/config/'
    }
  }
});
