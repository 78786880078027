import { createApi } from '@reduxjs/toolkit/query/react';
import { httpRequest } from 'libs/rtkquery';
import { mapAddressPortals, mapActivesByPortalId } from './adapter';

export const cadastreAPI = createApi({
  reducerPath: 'cadastreApi',
  baseQuery: httpRequest(process.env.REACT_APP_API_V2_BASE_URL_COMPARABLES),
  endpoints: builder => ({
    getAddressPortals: builder.query({
      query: address => ({
        url: '/cadastral',
        params: {
          address
        }
      }),
      transformResponse: mapAddressPortals
    }),
    getActivesByPortalId: builder.query({
      query: portalId => ({
        url: '/cadastral-by-portal-id',
        params: {
          id_address_portal: portalId
        }
      }),
      transformResponse: mapActivesByPortalId
    })
  })
});

export const { useGetAddressPortalsQuery, useGetActivesByPortalIdQuery } = cadastreAPI;
