import styled, { useTheme } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import ProvincesDropdown from '../../components/ProvincesDropdown';
import { ButtonLink, IconAdd, IconClose, Grid } from '@uda/bento-components';
import { getValueFormatted } from '../../utils/formattedNumber';
import { useSelector } from 'react-redux';
import PaymentsCheckoutScopeSkeleton from './PaymentsCheckoutScopeSkeleton';

const StyledDescription = styled.p`
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const StyledTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacings.small1};
`;

const StyledContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium3};
`;

const StyledList = styled.ul`
  margin-bottom: ${({ theme }) => theme.spacings.small2};
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.color.redclay100};
  transition: background-color 100ms ease-in-out;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.color.redclay400};
  }
`;

const StyledWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const PaymentsCheckoutScope = ({
  isLoading,
  fullScope,
  handleChangeScope,
  scope,
  handleRemoveProvince,
  handleAddProvince,
  unitPrice,
  isAddingProvince
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const lang = useSelector(state => state.user.data.language);
  const price = unitPrice ? getValueFormatted(unitPrice, intl, lang, false, false) : '-';

  return (
    <StyledContainer>
      {isLoading ? (
        <PaymentsCheckoutScopeSkeleton />
      ) : (
        <div>
          <StyledTitle>
            <FormattedMessage id="payments.scope.title" />
          </StyledTitle>
          <StyledDescription>
            <FormattedMessage id="payments.scope.description" />
          </StyledDescription>
          <StyledList>
            {scope.length
              ? scope.map((province, index) => (
                  <Grid
                    columns={`1fr ${theme.spacings.small4}`}
                    gap={theme.spacings.small2}
                    key={province}
                  >
                    <ProvincesDropdown
                      selectedProvince={province}
                      provinceValues={fullScope}
                      handleChange={value => handleChangeScope(province, value)}
                      provincesToClean={scope}
                    />
                    {index > 0 ? (
                      <StyledWrapper>
                        <StyledButton onClick={() => handleRemoveProvince(province)}>
                          <IconClose customColor={theme.color.redclay500} />
                        </StyledButton>
                      </StyledWrapper>
                    ) : null}
                  </Grid>
                ))
              : null}
          </StyledList>
          {!isAddingProvince ? (
            <ButtonLink onClick={handleAddProvince} title={''} iconLeft={<IconAdd />}>
              <FormattedMessage id="payments.scope.add" values={{ price }} />
            </ButtonLink>
          ) : null}
        </div>
      )}
    </StyledContainer>
  );
};

export default PaymentsCheckoutScope;
