const TYPOLOGIES_ALLOWED = [
  'garage',
  'terraced_house',
  'detached_house',
  'building',
  'farm',
  'protected_land',
  'agricultural_land',
  'urban_land',
  'special_asset',
  'storage'
];

const TYPOLOGIES_ALLOWED_RURAL = ['farm', 'protected_land', 'agricultural_land', 'urban_land'];

const TYPOLOGIES_ALLOWED_OTHER = ['special_asset'];

function areNotNullOrUndefinedkeys(item, keys = []) {
  return Boolean(
    keys.reduce((acc, key) => {
      if (item[key] !== undefined && item[key] !== null) return acc + 1;
      return acc;
    }, 0)
  );
}

function countFrequencies(arr, key, defaultObj = {}) {
  return arr.reduce((counts, item) => {
    const formattedKey =
      item[key] && typeof item[key] === 'string' ? item[key].toLowerCase() : item[key];
    if (areNotNullOrUndefinedkeys(item, [key])) {
      counts[formattedKey] = (Number(counts[formattedKey]) || 0) + 1;
    }
    return counts;
  }, defaultObj);
}

function checkAnyTrue(arr, key) {
  return arr.some(item => item[key] === true);
}

function countStates(arr, key) {
  return countFrequencies(arr, key, { vandalized: '-', poor: '-', average: '-', good: '-' });
}

function calculateRentPerArea(arr, rentKey, areaKey, excludeUses, useKey) {
  let rentSum = 0,
    areaSum = 0;

  arr.forEach(item => {
    if (!excludeUses.includes(item[useKey])) {
      rentSum += Number(item[rentKey] || 0);
      areaSum += Number(item[areaKey] || 0);
    }
  });

  return areaSum !== 0 ? rentSum / areaSum : 0;
}

function countBedrooms(arr, key) {
  return arr.reduce(
    (counts, item) => {
      if (areNotNullOrUndefinedkeys(item, [key])) {
        let val = item[key];
        if (val > 4) val = '>4';
        counts[val] = (counts[val] || 0) + 1;
      }
      return counts;
    },
    { 1: '', 2: '', 3: '', 4: '', '>4': '' }
  );
}

function checkAnyValue(arr, key1, key2) {
  return arr.some(item => Boolean(item[key1] || item[key2]));
}

function getMostFrequentValues(countKeys, assets) {
  return countKeys.reduce((result, key) => {
    const frequencies = countFrequencies(assets, key);
    if (!Object.keys(frequencies)[0]) {
      result[key] = null;
    } else {
      result[key] = Object.keys(frequencies).reduce((a, b) =>
        frequencies[a] > frequencies[b] ? a : b
      );
    }
    return result;
  }, {});
}

function sumValues(arr, key) {
  const nonNullItems = arr.filter(item => item[key] !== null);
  if (nonNullItems.length === 0) {
    return null;
  }
  return nonNullItems.reduce((sum, item) => sum + Number(item[key] || 0), 0);
}

function getBooleans(keys, assets) {
  return keys.reduce((result, key) => {
    result[key] = checkAnyTrue(assets, key);
    return result;
  }, {});
}

function maxValues(arr, key) {
  return arr.reduce((max, item) => (item[key] > max ? item[key] : max), -Infinity);
}

function sum(a, b) {
  return a + b;
}

function division(a, b) {
  return a / b;
}

function transformKey(key) {
  if (!key) return key;
  return key.toLowerCase().split(' ').join('_');
}

function areasAndUnits(arr, useKey, areaKey, plotKey) {
  const result = arr.reduce((items, item) => {
    if (areNotNullOrUndefinedkeys(item, [useKey, areaKey])) {
      const type = getTypologyKey(item);
      const previousAreaSumValue = items[type]?.areaSum || 0;
      const previousPlotSumValue = items[type]?.plotSum || 0;
      const areaSum = sum(previousAreaSumValue, Number(item[areaKey]));
      const plotSum = sum(previousPlotSumValue, Number(item[plotKey]));
      const units = (items[type]?.units || 0) + 1;
      const unitsAnexConfig = { sumgarage: 'parking_annex' };

      items[type] = {
        areaSum,
        plotSum,
        units,
        unitsAnexed: items[type]?.unitsAnexed || 0
      };

      Object.entries(unitsAnexConfig).forEach(([subtype, key]) => {
        if (item[key]) {
          const unitsAnexed = (items[subtype]?.unitsAnexed || 0) + 1;
          items[subtype] = items[subtype] || {
            areaSum: 0,
            plotSum: 0,
            units: 0,
            unitsAnexed: 0
          };
          items[subtype].unitsAnexed = unitsAnexed;
        }
      });
    }
    return items;
  }, {});

  // Calcular el total de unitsAnexed
  const totalUnitsAnexed = Object.values(result).reduce(
    (total, typeData) => total + (typeData.unitsAnexed || 0),
    0
  );

  // Ajustar unitsAnexed en multi_family_housing
  if (result.multi_family_housing) {
    result.multi_family_housing.unitsAnexed = totalUnitsAnexed;
  }

  // Eliminar la entrada sumgarage y totalUnitsAnexed al final del objeto resultante
  delete result.sumgarage;
  delete result.totalUnitsAnexed;

  return result;
}

function getAdoptedValues(arr, typologyKey, marketKey, marketSqmKey, unitsAcc) {
  return arr.reduce((items, item, i) => {
    if (areNotNullOrUndefinedkeys(item, [typologyKey, marketKey])) {
      const type = getTypologyKey(item);
      const getPreviousSumValue = key => (items[type] && items[type][key]) || 0;
      const marketValue = sum(getPreviousSumValue('marketValue'), Number(item[marketKey]));
      const marketValueSqm = division(marketValue, unitsAcc[type].areaSum);
      const units = (items[type]?.units || 0) + 1;
      const marketValueUnit = division(marketValue, units);
      const estimatedRent = sum(getPreviousSumValue('estimatedRent'), Number(item.rent_value));
      const rentSqm = division(estimatedRent, unitsAcc[type].areaSum);
      const rentUnit = division(estimatedRent, units);

      items[type] = {
        marketValue,
        marketValueSqm,
        marketValueUnit,
        estimatedRent,
        rentUnit,
        rentSqm,
        units
      };
    }
    return items;
  }, {});
}

const getSellersAppraisal = (arr, typologyKey, sellerKey) => {
  return arr.reduce((items, item, i) => {
    if (areNotNullOrUndefinedkeys(item, [typologyKey, sellerKey])) {
      const type = getTypologyKey(item);
      const getPreviousSumValue = key => (items[type] && items[type][key]) || 0;
      const sellersAppraisal = sum(getPreviousSumValue('marketValue'), Number(item[sellerKey]));
      const fechaAnterior = new Date(items[type]?.oldestDate);
      const fechaActual = new Date(item.seller_av_date);

      items[type] = {
        sellersAppraisal,
        oldestDate: fechaAnterior < fechaActual ? items[type]?.oldestDate : item.seller_av_dt_date
      };
    }
    return items;
  }, {});
};

function getTypologyKey(item) {
  const typologyKey = transformKey(item.typology);
  const useKey = transformKey(item.use);
  const isSpecialCase = (use, typology) =>
    (use === 'residencial' || use === 'residential') && !TYPOLOGIES_ALLOWED.includes(typology);

  const isLandCase = typology => TYPOLOGIES_ALLOWED_RURAL.includes(typology);

  const isOtherCase = (use, typology) =>
    use === 'hospitality' || TYPOLOGIES_ALLOWED_OTHER.includes(typology);

  if (isSpecialCase(useKey, typologyKey)) {
    return 'multi_family_housing';
  } else if (isLandCase(typologyKey)) {
    return 'land';
  } else if (isOtherCase(useKey, typologyKey)) {
    return 'other';
  } else {
    return TYPOLOGIES_ALLOWED.includes(typologyKey) ? typologyKey : useKey;
  }
}

//DEJO COMENTADO ESTA FUNCION PORQUE SE USARA LUEGO PARA INFORME UDA
/* function marketComparables(comparables) {
  const defaultResponse = (typology) => ({
    asking: {
      sale: { count: 0, sqm_value: 0, average: 0 },
      rent: { count: 0, sqm_value: 0, average: 0 }
    },
    closing: {
      sale: { count: 0, sqm_value: 0, average: 0 },
      rent: { count: 0, sqm_value: 0, average: 0 }
    },
    subtotal: {
      asking: { rent: { count: 0, sqm_value: 0, average: 0 }, sale: { count: 0, sqm_value: 0, average: 0 } },
      closing: { rent: { count: 0, sqm_value: 0, average: 0 }, sale: { count: 0, sqm_value: 0, average: 0 } }
    }
  });

  const result = comparables.reduce((acc, item) => {
    const statusKey = item.status === 'Sold' ? 'closing' : 'asking';
    const key = getTypologyKey(item);
    const operationKey = transformKey(item.operation);
    if (!acc[key]) {
      acc[key] = defaultResponse(key);
    }

    acc[key][statusKey][operationKey].count++;
    acc[key][statusKey][operationKey].sqm_value += item.sqm_value;
    acc[key][statusKey][operationKey].average =
      acc[key][statusKey][operationKey].sqm_value / acc[key][statusKey][operationKey].count;

    // Inicializar el subtotal total si no ha sido inicializado
    if (!acc.subtotal) {
      acc.subtotal = {
        asking: { rent: { count: 0, sqm_value: 0, average: 0 }, sale: { count: 0, sqm_value: 0, average: 0 } },
        closing: { rent: { count: 0, sqm_value: 0, average: 0 }, sale: { count: 0, sqm_value: 0, average: 0 } }
      };
    }

    return acc;
  }, {});

  // Calcular el acumulado de averages por fuera del bucle principal
  const averagesAccumulated = {
    asking: { rent: 0, sale: 0 },
    closing: { rent: 0, sale: 0 }
  };

  // Iterar sobre los resultados y acumular averages
  for (const key in result) {
    const types = ['asking', 'closing'];
    types.forEach((statusKey) => {
      const operations = ['rent', 'sale'];
      operations.forEach((operationKey) => {
        averagesAccumulated[statusKey][operationKey] += result[key][statusKey][operationKey].average;
      });
    });
  }

  // Asignar el acumulado al subtotal con el mismo formato
  result.subtotal = {
    asking: { rent: { count: 0, sqm_value: 0, average: averagesAccumulated.asking.rent }, sale: { count: 0, sqm_value: 0, average: averagesAccumulated.asking.sale } },
    closing: { rent: { count: 0, sqm_value: 0, average: averagesAccumulated.closing.rent }, sale: { count: 0, sqm_value: 0, average: averagesAccumulated.closing.sale } }
  };

  return result;
} */

function marketComparables(comparables, assets) {
  const defaultResponse = typology => ({
    asking: {
      sale: { count: 0, sqm_value: 0, average: 0 },
      rent: { count: 0, sqm_value: 0, average: 0 }
    },
    closing: {
      sale: { count: 0, sqm_value: 0, average: 0 },
      rent: { count: 0, sqm_value: 0, average: 0 }
    },
    subtotal: {
      asking: {
        rent: { count: 0, sqm_value: 0, average: 0 },
        sale: { count: 0, sqm_value: 0, average: 0 }
      },
      closing: {
        rent: { count: 0, sqm_value: 0, average: 0 },
        sale: { count: 0, sqm_value: 0, average: 0 }
      }
    }
  });

  const result = assets.reduce((acc, asset) => {
    const matchingComparables = comparables.filter(comp => comp.anchor_id === asset.asset_id);

    matchingComparables.forEach(item => {
      const statusKey = item.status === 'Sold' ? 'closing' : 'asking';
      const key = getTypologyKey(asset); // Usar el item de assets
      const operationKey = transformKey(item.operation);
      if (!acc[key]) {
        acc[key] = defaultResponse(key);
      }

      acc[key][statusKey][operationKey].count++;
      acc[key][statusKey][operationKey].sqm_value += item.sqm_value;
      acc[key][statusKey][operationKey].average =
        acc[key][statusKey][operationKey].sqm_value / acc[key][statusKey][operationKey].count;

      // Inicializar el subtotal total si no ha sido inicializado
      if (!acc.subtotal) {
        acc.subtotal = {
          asking: {
            rent: { count: 0, sqm_value: 0, average: 0 },
            sale: { count: 0, sqm_value: 0, average: 0 }
          },
          closing: {
            rent: { count: 0, sqm_value: 0, average: 0 },
            sale: { count: 0, sqm_value: 0, average: 0 }
          }
        };
      }
    });

    return acc;
  }, {});

  // Calcular el acumulado de averages por fuera del bucle principal
  const averagesAccumulated = {
    asking: { rent: 0, sale: 0 },
    closing: { rent: 0, sale: 0 }
  };

  // Iterar sobre los resultados y acumular averages
  for (const key in result) {
    const types = ['asking', 'closing'];
    types.forEach(statusKey => {
      const operations = ['rent', 'sale'];
      operations.forEach(operationKey => {
        averagesAccumulated[statusKey][operationKey] +=
          result[key][statusKey][operationKey].average;
      });
    });
  }

  // Asignar el acumulado al subtotal con el mismo formato
  result.subtotal = {
    asking: {
      rent: { count: 0, sqm_value: 0, average: averagesAccumulated.asking.rent },
      sale: { count: 0, sqm_value: 0, average: averagesAccumulated.asking.sale }
    },
    closing: {
      rent: { count: 0, sqm_value: 0, average: averagesAccumulated.closing.rent },
      sale: { count: 0, sqm_value: 0, average: averagesAccumulated.closing.sale }
    }
  };

  return result;
}

function countCombinations(data, key1, key2) {
  let combinations = {};

  data.forEach(item => {
    let combinedKeys = `${item[key1]}-${item[key2]}`;
    if (!combinations[combinedKeys]) {
      combinations[combinedKeys] = {
        [key1]: item[key1],
        [key2]: item[key2],
        units: 0
      };
    }
    combinations[combinedKeys].units += 1;
  });

  return Object.values(combinations);
}

function addAvgByUse(areasAndUnits) {
  return Object.entries(areasAndUnits).reduce((acc, [key, value]) => {
    if (!value.areaSum) return acc;
    return { ...acc, [key]: { ...value, avg: division(value.areaSum, value.units) } };
  }, areasAndUnits);
}

export function calcComparablesSummary(comparables) {
  const areaKey = 'area';
  const priceKey = 'price';
  const priceM2Key = 'priceM2';
  if (!comparables || (comparables && !comparables.length)) return null;
  return comparables.reduce(
    (acc, item, i) => {
      if (areNotNullOrUndefinedkeys(item, [areaKey, priceKey, priceM2Key])) {
        const getMax = (key, accKey) => (item[key] > acc[accKey] ? item[key] : acc[accKey]);
        const getMin = (key, accKey) => (item[key] < acc[accKey] ? item[key] : acc[accKey]);
        const areaSum = sum(acc.areaSum || 0, Number(item[areaKey]));
        const areaAvg = division(areaSum, comparables.length);
        const priceSum = sum(acc.priceSum || 0, Number(item[priceKey]));
        const priceAvg = division(priceSum, comparables.length);
        const priceM2Sum = sum(acc.priceM2Sum || 0, Number(item[priceM2Key]));
        const priceM2Avg = division(priceM2Sum, comparables.length);

        return {
          priceSum,
          priceM2Sum,
          areaSum,
          area: areaAvg,
          maxArea: getMax('area', 'maxArea'),
          minArea: getMin('area', 'minArea'),
          price: priceAvg,
          maxPrice: getMax('price', 'maxPrice'),
          minPrice: getMin('price', 'minPrice'),
          priceM2: priceM2Avg,
          maxPriceM2: getMax('priceM2', 'maxPriceM2'),
          minPriceM2: getMin('priceM2', 'minPriceM2')
        };
      }
      return acc;
    },
    {
      area: 0,
      maxArea: 0,
      minArea: Number.MAX_SAFE_INTEGER,
      price: 0,
      maxPrice: 0,
      minPrice: Number.MAX_SAFE_INTEGER,
      priceM2: 0,
      maxPriceM2: 0,
      minPriceM2: Number.MAX_SAFE_INTEGER
    }
  );
}

export function calculateStats(assets, comparables) {
  const countKeys = ['occupancy_status', 'liquidity', 'use', 'building_quality'];
  const roomsKey = 'rooms';
  const bedroomsKey = 'bedrooms';
  const booleanKeys = [
    'urbanistic_issues',
    'lift',
    'pool',
    'conflictive_area',
    'bricked_up',
    'part_of_a_wip',
    'vpo',
    'start_current-rent',
    'end_current_rent',
    'missing_fol',
    'missing_cfo',
    'town_hall_enquiry'
  ];
  const stateKey = 'interior_state_of_repair';
  const rentKey = 'rent_value';
  const starsKey = 'stars';
  const areaKey = 'land_valuation_area';
  const excludeUses = ['garage', 'storage', null];
  const useKey = 'use';
  const areaCountKey = 'built_valuation_area';
  const useCount = countFrequencies(assets, useKey);
  const plotKey = 'land_valuation_area';
  const typologyKey = 'typology';
  const marketKey = 'market_value';
  const marketSqmKey = 'built_market_value';
  const sellerKey = 'seller_av_dt';

  const areasAndUnitsSum = addAvgByUse(areasAndUnits(assets, typologyKey, areaCountKey, plotKey));

  return {
    isPublished: checkAnyValue(assets, 'listing_price', 'listing_url'),
    mostFrequent: getMostFrequentValues(countKeys, assets),
    booleans: getBooleans(booleanKeys, assets),
    states: countStates(assets, stateKey),
    bedroomCounts: countBedrooms(assets, bedroomsKey),
    rentPerArea: calculateRentPerArea(assets, rentKey, areaKey, excludeUses, useKey),
    roomsSum: sumValues(assets, roomsKey),
    highestStar: maxValues(assets, starsKey),
    rentValueSum: sumValues(assets, rentKey),
    landStatus: countCombinations(assets, 'land_status', 'milestones_status'),
    useCount,
    areasAndUnitsSum,
    adoptedValues: getAdoptedValues(assets, typologyKey, marketKey, marketSqmKey, areasAndUnitsSum),
    sellersAppraisal: getSellersAppraisal(assets, typologyKey, sellerKey),
    marketComparables: marketComparables(comparables, assets)
  };
}
