import { truncate } from './truncateCoords';

export const getCoordPairsFromArray = coords => {
  return coords
    .map(parseFloat)
    .reduce((acc, val, i, arr) => (i % 2 ? acc : [...acc, arr.slice(i, i + 2)]), []);
};

export const getCoordinatesFromGeojson = (geojson, truncated = true) => {
  geojson = truncated ? truncate(geojson) : geojson;
  const result = geojson.features.map(e => {
    const coordinates = e.geometry.coordinates;

    if (coordinates[0] && coordinates[0].length > 2) {
      if (
        coordinates[0][coordinates[0].length - 1].toString() ===
        coordinates[0][coordinates[0].length - 2].toString()
      ) {
        coordinates[0].pop();
      }
    }
    return coordinates;
  });

  return result;
};
