import { useEffect, useState } from 'react';
import { Grid, TextLink } from '@uda/bento-components';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import useCalculateHeaderHeight from '../../utils/hooks/useCalculateHeaderHeight';
import {
  calculateOrder,
  clearCheckStatusPayment,
  handleCheckoutPayment,
  initCheckout
} from '../actions/payments';
import { updateEnterpriseSentSuccess } from '../../actions/user';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PaymentsCheckoutPlan from '../components/PaymentsCheckoutPlan';
import PaymentsCheckoutScope from '../components/PaymentsCheckoutScope';
import PaymentsCheckoutBilling from '../components/PaymentsCheckoutBilling';
import PaymentsCheckoutBillingTitles from '../components/PaymentCheckoutBillingTitles';
import PaymentsCheckoutOrder from '../components/PaymentsCheckoutOrder';
import PaymentsGoBack from '../components/PaymentsGoBack';
import EnterpriseForm from '../../components/EnterpriseForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { isValidSpanishId } from '../../utils/isValidSpanishId';
import { closeNotification } from '../../actions/notification';
import { getLocalStorage } from '../../utils';
import { PRODUCT_KEY } from '../../constants.js';
import { getValueFormatted } from '../../utils';
import LoadingLayout from '../../components/LoadingLayout';
import PATHS from '../../routes/paths';
import useFlag from '../../utils/hooks/useFlag';

const StyledLoadingText = styled.div`
  max-width: 370px;
  margin-top: ${({ theme }) => theme.spacings.medium1};
`;

const StyledParagraph = styled.p`
  margin-top: ${({ theme }) => theme.spacings.small2};
  ${({ theme }) => theme.texts.p1b};
  color: ${({ theme }) => theme.color.charcoal600};
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  height: calc(
    100vh - ${({ isScrollOnTop, offsetHeight }) => (isScrollOnTop ? `${offsetHeight}px` : '96px')}
  );
`;

const StyledGrid = styled(props => <Grid {...props} />)`
  overflow: auto;
  padding: ${({ theme }) => theme.spacings.medium5} ${({ theme }) => theme.spacings.small4} 0;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100;
    width: 100%;
    padding: ${({ theme }) => theme.spacings.small3} ${({ theme }) => theme.spacings.small4} 0;
  }
`;
const SupportTextContainer = styled.div`
  margin: ${({ theme }) => theme.spacings.small3} 0;
  text-align: right;
  > p {
    ${({ theme }) => theme.texts.p1}
    color: ${({ theme }) => theme.color.charcoal600}
  }
`;
const StyledContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium2};
`;

const DEFAULT_BOUNDARY = 48;

const PaymentsCheckoutContainer = () => {
  const product = getLocalStorage(PRODUCT_KEY);
  const [isAddingProvince, setIsAddingProvince] = useState(false);
  const [plan, setPlan] = useState(`basic-${product}`);
  const [scope, setScope] = useState([]);
  const [order, setOrder] = useState([]);
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  const lang = useSelector(state => state.user.data.language);
  const boundaries = useSelector(state => state.user.data.restrictions.opportunities.boundaries);
  const isScrollOnTop = useSelector(state => state.layout.isScrollOnTop);
  const { provincesAndAssets, plans, isLoading, total, isLoadingTotal, isProcessingPayment } =
    useSelector(state => state.payments);
  const [headerHeight] = useCalculateHeaderHeight();
  const [isEnterpriseModalOpen, setEnterpriseModalOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const intl = useIntl();

  const validationSchema = Yup.object({
    address: Yup.string().required(intl.formatMessage({ id: 'errors.required' })),
    country: Yup.string()
      .required(intl.formatMessage({ id: 'errors.required' }))
      .min(1),
    postal_code: Yup.string()
      .matches(/^\d+$/, intl.formatMessage({ id: 'errors.only_numbers' }))
      .required(intl.formatMessage({ id: 'errors.required' })),
    cif: Yup.string().test('valid-id', intl.formatMessage({ id: 'errors.invalid_cif' }), value =>
      isValidSpanishId(value)
    ),
    city: Yup.string()
      .matches(/^([^0-9]*)$/, intl.formatMessage({ id: 'errors.only_letters' }))
      .required(intl.formatMessage({ id: 'errors.required' }))
  });

  const formik = useFormik({
    initialValues: {
      company: '',
      cif: '',
      country: '',
      address: '',
      postal_code: ''
    },
    validationSchema: validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(false);
    }
  });

  useEffect(() => {
    dispatch(initCheckout());
    setPlan(`basic-${product}`);
    setOrder(boundaries.length);

    if (boundaries.length) {
      if (boundaries.includes('all')) {
        setScope([DEFAULT_BOUNDARY]);
      } else {
        setScope(boundaries);
      }
    } else {
      setScope([DEFAULT_BOUNDARY]);
    }

    dispatch(calculateOrder(`basic-${product}`, 1));

    return () => {
      dispatch(closeNotification());
      dispatch(clearCheckStatusPayment());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { isValid, dirty } = formik;
    setIsSubmitAvailable(scope.length && plan && isValid && dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, plan, scope]);

  useEffect(() => {
    if (plan && provincesAndAssets.length && scope.length && scope.every(s => s)) {
      setOrder(getOrders(scope, plan));
      dispatch(calculateOrder(plan, scope.filter(value => value).length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, plan, provincesAndAssets]);

  const getOrders = (scope, plan) =>
    scope.length &&
    plan &&
    scope.map(item => (item ? { label: getProvinceLabel(item), price: getPrice(plan) } : null));

  const getProvinceLabel = provinceId =>
    provincesAndAssets.length && provincesAndAssets.find(({ value }) => value === provinceId).label;

  const getPrice = planId =>
    plans &&
    getValueFormatted(
      plans[product].find(({ id }) => id === planId).data,
      intl,
      lang,
      false,
      false
    );

  const onSelectPlan = value => {
    setPlan(value);
  };

  const onScopeChange = (oldValue, newValue) => {
    setScope(scope.map(i => (i === oldValue ? newValue : i)));
    setIsAddingProvince(false);
  };

  const onAddProvince = () => {
    !scope.some(item => item === null) && setScope([...scope, null]);
    setIsAddingProvince(true);
  };

  const onRemoveProvince = value => {
    setScope(scope.filter(province => province !== value));
  };

  const handlePayClick = data => {
    dispatch(closeNotification());

    const billing = {};
    Object.keys(formik.values).forEach(key => {
      if (formik.values[key] !== '') {
        billing[key] = formik.values[key];
      }
    });

    dispatch(handleCheckoutPayment({ ...data, billing_info: billing, scope, plan }));
  };

  const handleOpenEnterpriseModal = () => {
    setEnterpriseModalOpen(true);
  };

  const handleCloseEnterpriseModal = () => {
    setEnterpriseModalOpen(false);
    dispatch(updateEnterpriseSentSuccess());
  };

  const fotocasaProvider = useFlag('fotocasa_provider');

  return (
    <StyledWrapper>
      {isProcessingPayment ? (
        <LoadingLayout>
          <StyledLoadingText>
            <h2>
              <FormattedMessage id="payments.summary.loading.title" />
            </h2>
            <StyledParagraph>
              <FormattedMessage id="payments.summary.loading.description" />
            </StyledParagraph>
          </StyledLoadingText>
        </LoadingLayout>
      ) : (
        <>
          <PaymentsGoBack />

          <StyledContent isScrollOnTop={isScrollOnTop} offsetHeight={headerHeight}>
            <StyledGrid columns="2fr minmax(400px, 1fr)" gap={theme.spacings.medium4}>
              <div>
                <PaymentsCheckoutPlan
                  isLoading={isLoading}
                  plans={plans && plans[product]}
                  plan={plan}
                  handleSelectPlan={onSelectPlan}
                  onOpenEnterpriseModal={handleOpenEnterpriseModal}
                />

                <PaymentsCheckoutScope
                  isLoading={isLoading || !provincesAndAssets.length}
                  unitPrice={plans ? plans[product].find(({ id }) => id === plan).data : null}
                  fullScope={provincesAndAssets}
                  handleChangeScope={onScopeChange}
                  scope={scope}
                  handleAddProvince={onAddProvince}
                  handleRemoveProvince={onRemoveProvince}
                  isAddingProvince={isAddingProvince}
                />
                <StyledContainer>
                  <PaymentsCheckoutBillingTitles isLoading={isLoading} />
                  <PaymentsCheckoutBilling isLoading={isLoading} formik={formik} />
                </StyledContainer>
              </div>

              <div>
                <PaymentsCheckoutOrder
                  isLoading={isLoading}
                  isLoadingTotal={isLoadingTotal && !scope.length}
                  total={total}
                  order={order}
                  formik={formik}
                  onPayClick={handlePayClick}
                  isSubmitAvailable={isSubmitAvailable}
                />
                <SupportTextContainer>
                  <p className="p1b">
                    <FormattedMessage
                      id="payments.help"
                      values={{
                        link: (
                          <TextLink
                            href={fotocasaProvider ? PATHS.contactUsFotocasa : PATHS.contactUs}
                            title={intl.formatMessage({ id: 'payments.contact' })}
                            className="p1b primary"
                            target="_blank"
                          >
                            <FormattedMessage id="payments.contact" />
                          </TextLink>
                        )
                      }}
                    />
                  </p>
                </SupportTextContainer>
              </div>
            </StyledGrid>
          </StyledContent>
        </>
      )}
      {isEnterpriseModalOpen ? (
        <EnterpriseForm isOpen={isEnterpriseModalOpen} onClose={handleCloseEnterpriseModal} />
      ) : null}
    </StyledWrapper>
  );
};

export default PaymentsCheckoutContainer;
