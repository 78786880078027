import styled from 'styled-components';

const Container = styled.div``;

const Support = () => (
  <Container>
    <h1>Support</h1>
  </Container>
);

export default Support;
