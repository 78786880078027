import { featureFlagsByProvider } from '../utils/featureFlags';

export const setInitFeatureFlags = () => dispatch => {
  const flags = featureFlagsByProvider();

  dispatch(setFeatureFlags(flags));
};

export const setFeatureFlags = flags => ({
  type: 'SET_FEATURE_FLAGS',
  payload: flags
});
