import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import defaultTheme from './theme';
import { locale } from '../translations';
import { LANG_KEY } from '../constants.js';
import { getLocalStorage, setLocalStorage } from '../utils/index';
import useThemeBreakpoints from '../utils/hooks/useThemeBreakpoints';

const AppContext = createContext();

export function AppProvider(props) {
  const [language, setLanguage] = useState(locale);
  const [theme, setTheme] = useState(defaultTheme);
  const breakpointUtils = useThemeBreakpoints();

  useEffect(() => {
    const localLanguage = getLocalStorage(LANG_KEY);
    setLanguage(localLanguage || locale);
  }, []);

  useEffect(() => {
    setLocalStorage(LANG_KEY, language);
  }, [language]);

  const value = useMemo(() => {
    return {
      language,
      setLanguage,
      theme: { ...theme, ...breakpointUtils },
      setTheme
    };
  }, [language, theme, breakpointUtils]);

  return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('AppContext should be called');
  }

  return context;
}
