import { createApi } from '@reduxjs/toolkit/query/react';
import { httpRequest } from 'libs/rtkquery';

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: httpRequest(process.env.REACT_APP_API_BASE_URL_USERS),
  endpoints: builder => ({
    getUsersSources: builder.query({
      query: () => ({
        url: '/sources/'
      })
    })
  })
});

export const { useGetUsersSourcesQuery } = usersAPI;
