import SMART_VALUATION_PATHS from '../paths';
import { getEstimatedValue } from '../utils/reports';

export const transformComparableToSelectedComparable = (comparable, operation) => ({
  id: comparable.id,
  id_uda: comparable.id_uda,
  price: comparable.price,
  operation,
  status: comparable?.status?.value,
  area: comparable.features.find(c => c.id === 'area').data.value,
  property_type: comparable.property_type
});

export const getAverageComparables = (data, area) => {
  return {
    uda: {
      ...data.uda,
      primary: {
        ...data?.uda.primary,
        value: getEstimatedValue(data.uda.secondary.value, area)
      },

      secondary: {
        ...data?.uda.secondary,
        value: Math.round(data.uda.secondary.value)
      }
    }
  };
};

export const getInitialColumnState = data => {
  return data.map(e => ({
    colId: e.field,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null
  }));
};

export const getCellAlign = field => {
  switch (field) {
    case 'id_uda':
    case 'id':
    case 'source':
    case 'vendor_type':
    case 'listing_type':
      return 'left';
    case 'distance':
    case 'price':
    case 'sqm_value':
    case 'area':
    case 'floor':
    case 'n_floor':
    case 'nbaths':
    case 'n_baths':
    case 'rooms':
    case 'n_rooms':
    case 'construction_year':
    case 'time_published':
      return 'right';
    default:
      return 'center';
  }
};

export const getCellWidth = field => {
  switch (field) {
    case 'selected':
      return { width: 35, minWidth: 35 };
    case 'rooms':
    case 'n_rooms':
    case 'nbaths':
    case 'n_baths':
    case 'is_exterior':
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'new_dev':
    case 'floor':
    case 'n_floor':
      return { width: 65, minWidth: 65 };
    case 'id_uda':
    case 'img':
    case 'area':
      return { width: 90, minWidth: 90 };
    case 'source':
    case 'distance':
    case 'listing_type':
    case 'similarity':
      return { width: 120, minWidth: 120 };
    case 'price':
    case 'date_in':
    case 'sqm_value':
    case 'construction_year':
      return { width: 130, minWidth: 130 };
    default:
      return { width: 140, minWidth: 140 };
  }
};

export const apiInternFiltersApplied = (filters = {}) => {
  return Object.fromEntries(Object.entries(filters).filter(([_, v]) => v != null));
};

export const getUrlToShare = id => {
  let envUrl;
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      envUrl = 'pulse';
      break;
    case 'staging':
      envUrl = 'pulse-pre';
      break;
    default:
      envUrl = 'pulse-dev';
      break;
  }

  return `https://${envUrl}.urbandataanalytics.com${SMART_VALUATION_PATHS.search}/${id}`;
};

const applyPriceModification = ({ priceData, priceSelected }) => {
  const types = {
    primary: 'value',
    secondary: 'value_sqm'
  };
  const applyPriceStructure = ({ data }, value) => ({
    data: { ...data, value: priceSelected?.data[value] }
  });
  let prices = {};

  Object.entries(types).forEach(([key, value]) => {
    if (priceData[key]) {
      prices = {
        ...prices,
        [key]: {
          ...applyPriceStructure(priceData[key], value)
        }
      };
    }
  });

  return { ...priceData, ...prices };
};

const applySqmModification = (sqmData, priceSelected) => {
  if (!priceSelected || !sqmData) return priceSelected;
  return {
    ...sqmData,
    primary: {
      ...sqmData?.primary,
      value: priceSelected?.value_sqm
    }
  };
};

export const isUnpublishedStatus = (status, statusFilters) => {
  const { value, label } = statusFilters.find(
    ({ internal_field }) => internal_field === 'price_status'
  );

  return status === value || status === label;
};
export const parseComparables = (comparables, publishingStatus) => {
  if (!publishingStatus?.price_status) return comparables;

  return comparables.map(comparable => {
    const priceSelected = comparable.price[publishingStatus.price_status];
    const hasToParseComparable =
      isUnpublishedStatus(comparable?.status?.value, publishingStatus.filters) && priceSelected;
    if (!hasToParseComparable) {
      return comparable;
    }

    return {
      ...comparable,
      price: applyPriceModification({
        priceData: comparable?.price,
        priceSelected
      }),
      ...(comparable?.sqm_value && {
        sqm_value: applySqmModification(comparable.sqm_value, priceSelected)
      })
    };
  });
};

export const applyPriceModificationToSelected = (selected, priceStatus) => {
  return selected.map(comparable => {
    const isUnpublished = comparable?.status === 0;
    let sqm_value = comparable?.price?.secondary?.data || comparable?.sqm_value?.primary;
    if (!isUnpublished) {
      return {
        ...comparable,
        price: comparable?.price?.primary?.data || comparable?.price?.primary,
        sqm_value
      };
    }

    const priceSelected = comparable.price[priceStatus];
    const price = priceSelected
      ? applyPriceModification({
          priceData: comparable?.price,
          priceSelected,
          layout: comparable.layout
        })
      : comparable?.price;

    if (priceSelected) {
      sqm_value = {
        ...sqm_value,
        value: priceSelected?.data?.value_sqm || priceSelected?.value_sqm
      };
    }

    return {
      ...comparable,
      price: price?.primary?.data || price.primary,
      sqm_value
    };
  });
};

export const setSelectedSources = sources => (!sources.length ? [null] : sources);

export const getStatusToDisable = selected => {
  const unrequiredSources = [41, 13]; // Century 21, Cierres(registro)
  const coincidences = selected.filter(source => !unrequiredSources.includes(source));
  let filtersToDisable = [];

  if (selected.length === 0 || selected[0] === null) return [];
  if (coincidences.length === 0) {
    filtersToDisable = ['published', 'price_status'];
  } else if (coincidences.length === selected.length) {
    filtersToDisable = ['sold'];
  }
  return filtersToDisable;
};
