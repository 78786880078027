import { getUrlToShare, setSelectedSources } from './adapter';
import { getDefaultValuesFilterByUse } from '../utils/getDefaultValuesFilterByUse';
import {
  SORTS_TO_REMOVE,
  LATLNG_DECIMALS,
  RESIDENTIAL_VALUE,
  SORTING_ITEMS,
  DEFAULT_DATE_MAIN_RANGE,
  DEFAULT_GEOG_DWITHIN
} from '../constants.js';
import { getAverageComparables } from './adapter';
import { getCadastralSuggestions } from 'SmartValuation/features/Search';

export const initialState = {
  reportList: [],
  isLoadingReportList: false,
  error: null,
  reportsStyles: {
    isLoadingLogo: false,
    isLoadingContactImage: false,
    companyLogo: null,
    contactImage: null,
    isLoadingReportsStyles: false,
    isLoadingReportStyle: false,
    reportsStyles: [],
    reportStyle: null
  },
  sortingGrid: { sort: 'date_main', order: 'asc' },
  sortableGridOptions: null,
  search: {
    isLoading: false,
    suggestions: null,
    selectedPortal: null,
    selectedActive: null,
    address: '',
    addressCoordinates: null
  },
  map: {
    data: {
      coordinates: null,
      distance: null,
      neighbourhood: null,
      comparables: null
    },
    competitors_geom__contained_by: null,
    bbox: null,
    popup: null,
    isPopupLoading: false,
    isMapLoading: false,
    isUserDrawing: false
  },
  layout: 'grid',
  comparables: {
    items: [],
    pagination: {
      pagesLoaded: []
    }
  },
  comparableDetails: null,
  valuation: null,
  popup: null,
  isLoading: false,
  uses: null,
  asset: null,
  report: {
    status: null,
    task_id: null,
    report_url: null,
    url: null,
    isLoading: false
  },
  filters: [],
  filtersTableApply: null,
  isLoadingFilters: false,
  sources: {
    selected: [null]
  },
  selectedStatus: [],
  publishingStatus: {
    price_status: '',
    sold: false,
    published: false,
    appraisal: false,
    unpublished: false,
    filters: []
  },
  advanceForm: {},
  indicators: {
    isLoading: false,
    data: null
  },
  selectedComparables: [],
  pagination: null,
  isLoadingComparables: false,
  averageComparables: {},
  operation: 1,
  urlToShare: null,
  isLoadingUrlToShare: false,
  searchedBefore: false
};

const reducer = (state = initialState, { type, payload, meta, ...action }) => {
  switch (type) {
    case 'RESET_STATE':
      return initialState;
    case 'GET_REPORTS_LIST':
      return {
        ...state,
        isLoadingReportList: true
      };
    case 'GET_REPORTS_LIST_SUCCESS':
      return {
        ...state,
        isLoadingReportList: false,
        reportList: payload.data
      };
    case 'GET_REPORTS_LIST_FAIL':
      return {
        ...state,
        isLoadingReportList: false,
        error: action.error.message
      };
    case 'RESET_REPORT_STYLE':
      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          reportStyle: null
        }
      };
    case 'SELECT_REPORT_STYLE_SUCCESS': {
      const id = Number(payload.config.url.split('/')[4]);
      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          reportsStyles: state.reportsStyles.reportsStyles.map(i => ({
            ...i,
            is_selected: i.id === id
          }))
        }
      };
    }

    case 'DELETE_REPORT_STYLE_SUCCESS': {
      const id = Number(payload.config.url.split('/')[4]);

      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          reportsStyles: state.reportsStyles.reportsStyles.filter(i => i.id !== id)
        }
      };
    }

    case 'UPDATE_COMPANY_LOGO':
      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          isLoadingLogo: true
        }
      };
    case 'UPDATE_COMPANY_LOGO_SUCCESS':
      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          isLoadingLogo: false,
          companyLogo: payload.data.url
        }
      };

      case 'UPDATE_CONTACT_IMAGE':
        return {
          ...state,
          reportsStyles: {
            ...state.reportsStyles,
            isLoadingContactImage: true
          }
        };
      case 'UPDATE_CONTACT_IMAGE_SUCCESS':
        return {
          ...state,
          reportsStyles: {
            ...state.reportsStyles,
            isLoadingContactImage: false,
            contactImage: payload.data.url
          }
        };
    case 'GET_REPORT_STYLE':
      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          isLoadingReportStyle: true
        }
      };
    case 'GET_REPORT_STYLE_SUCCESS':
      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          reportStyle: payload.data,
          isLoadingReportStyle: false
        }
      };
    case 'GET_REPORTS_STYLES':
      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          isLoadingReportsStyles: true
        }
      };
    case 'GET_REPORTS_STYLES_SUCCESS':
      return {
        ...state,
        reportsStyles: {
          ...state.reportsStyles,
          reportsStyles: payload.data,
          isLoadingReportsStyles: false
        }
      };
    case 'UPDATE_SORTING':
      return {
        ...state,
        sortingGrid: payload
      };

    case 'UPDATE_LAYOUT':
      return {
        ...state,
        layout: payload
      };
    case 'CHANGE_USE':
      const defaultFilters = state.filters.find(({ value }) => value === payload);
      if (state.search.selectedActive) return { ...state };
      return {
        ...state,
        advanceForm: getDefaultValuesFilterByUse(defaultFilters)
      };
    case 'CHANGE_OPERATION':
      return {
        ...state,
        operation: payload
      };

    case 'CHANGE_AVERAGE':
      return {
        ...state,
        averageComparables: {
          ...payload,
          ...(payload?.uda && getAverageComparables(payload, state.advanceForm.area))
        }
      };

    case 'FETCH_ASSET_VALUATION':
      return {
        ...state,
        valuation: null,
        isLoading: true
      };
    case 'FETCH_ASSET_VALUATION_SUCCESS':
      return {
        ...state,
        isLoading: false,
        valuation: {
          ...payload.data.card,
          info: payload.data.info
        }
      };
    case 'FETCH_ASSET_VALUATION_FAIL':
      return {
        ...state,
        isLoading: false,
        valuation: null
      };

    case 'FETCH_ASSET_URL':
      return {
        ...state,
        urlToShare: null,
        isLoadingUrlToShare: true
      };
    case 'FETCH_ASSET_URL_SUCCESS':
      return {
        ...state,
        urlToShare: payload.data.id ? getUrlToShare(payload.data.id) : null,
        isLoadingUrlToShare: false
      };
    case 'FETCH_ASSET_URL_FAIL':
      return {
        ...state,
        urlToShare: null,
        isLoadingUrlToShare: false
      };

    case 'FETCH_COMPARABLES_ITEMS':
      return {
        ...state,
        isLoadingComparables: true
      };
    case 'FETCH_COMPARABLES_ITEMS_SUCCESS':
      const shouldAppend = payload.config.reduxSourceAction.payload.shouldAppend;
      const items = payload.data.comparables;
      return {
        ...state,
        isLoadingComparables: false,
        pagination: payload.data.pagination,
        sortableGridOptions: getSortingFields(SORTING_ITEMS),
        comparables: {
          ...state.comparables,
          ...payload.data,
          pagination: {
            ...state?.comparables?.pagination,
            ...payload.data.pagination,
            pagesLoaded: shouldAppend
              ? [...state.comparables.pagination.pagesLoaded, payload?.data?.pagination?.page]
              : [payload?.data?.pagination?.page]
          },
          items: shouldAppend ? [...state.comparables.items, ...items] : items
        }
      };
    case 'FETCH_COMPARABLES_ITEMS_FAIL':
      return {
        ...state,
        isLoadingComparables: false,
        comparables: null
      };

    case 'FETCH_SEARCH_SUGGESTIONS':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true
        }
      };
    case 'FETCH_SEARCH_SUGGESTIONS_SUCCESS':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          suggestions: payload.data
        }
      };
    case 'FETCH_SEARCH_SUGGESTIONS_FAIL':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          suggestions: null
        }
      };
    case 'FETCH_CADASTRAL_SUGGESTIONS':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true
        }
      };
    case 'FETCH_CADASTRAL_SUGGESTIONS_SUCCESS':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          ...getCadastralSuggestions(payload.data)
        }
      };
    case 'FETCH_CADASTRAL_SUGGESTIONS_FAIL':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          ...getCadastralSuggestions()
        }
      };
    case 'SET_SELECTED_PORTAL':
      return {
        ...state,
        search: {
          ...state.search,
          selectedPortal: payload
        }
      };
    case 'SET_SEARCH':
      return {
        ...state,
        search: {
          ...state.search,
          ...payload
        }
      };
    case 'FETCH_COMPARABLES_MAP':
    case 'FETCH_COMPARABLES_MAP_SUCCESS':
      const { coordinates, ...rest } = payload?.data || {};

      return {
        ...state,
        map: {
          ...state.map,
          data: {
            ...state.map.data,
            ...rest
          },
          isMapLoading: false
        }
      };
    case 'FETCH_COMPARABLES_MAP_FAIL':
      return {
        ...state,
        map: {
          ...state.map,
          isMapLoading: false
        }
      };
    case 'RESET_COMPARABLES_MAP':
      return {
        ...state,
        map: {
          data: {
            ...state.map.data,
            distance: null,
            neighbourhood: null,
            comparables: null
          },
          competitors_geom__contained_by: null,
          bbox: null,
          popup: null,
          isPopupLoading: false,
          isMapLoading: false,
          isUserDrawing: false
        }
      };

    case 'UPDATE_COMPARABLE_SELECTION':
      return {
        ...state,
        selectedComparables: payload
      };

    case 'FETCH_COMPARABLE_DETAILS':
      return {
        ...state,
        isLoadingDetail: true,
        comparableDetails: null
      };
    case 'FETCH_COMPARABLE_DETAILS_SUCCESS':
      return {
        ...state,
        isLoadingDetail: false,
        comparableDetails: payload.data
      };
    case 'FETCH_COMPARABLE_DETAILS_FAIL':
    case 'RESET_COMPARABLE_DETAILS':
      return {
        ...state,
        isLoadingDetail: false,
        comparableDetails: {}
      };
    case 'RESET_SEARCH_SUGGESTIONS':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          suggestions: null
        }
      };

    case 'FETCH_ASSET_CARD':
    case 'FETCH_SMARTVALUATION_RESTRICTIONS':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_SMARTVALUATION_RESTRICTIONS_SUCCESS':
    case 'FETCH_SMARTVALUATION_RESTRICTIONS_FAIL':
      return {
        ...state,
        isLoading: false
      };
    case 'FETCH_ASSET_CARD_SUCCESS':
      let features = {};
      payload?.data?.features?.forEach(f => {
        features[f.id] = f.data.value;
      });

      return {
        ...state,
        isLoading: false,
        boundary_id: payload.data.address.boundary_id,
        asset: {
          ...payload.data,
          ...features,
          listing_type: payload.data.listing_type,
          lat: Number(payload.data.lat.toFixed(LATLNG_DECIMALS)),
          lon: Number(payload.data.lon.toFixed(LATLNG_DECIMALS))
        }
      };

    case 'FETCH_ASSET_CARD_FAIL':
      return {
        ...state,
        isLoading: false,
        asset: null
      };
    case 'FETCH_FILTERS':
      const { advanceForm } = state;

      const { listing_types, publishing_status__in } = payload;

      const tipologyFilters = Array.isArray(listing_types)
        ? listing_types.find(({ value }) => {
            return value === advanceForm.listing_type;
          })
        : {};

      return {
        ...state,
        isLoadingFilters: false,
        filters: listing_types,
        uses: listing_types.map(({ value, label }) => ({
          label,
          value
        })),
        advanceForm: { ...getDefaultValuesFilterByUse(tipologyFilters), ...advanceForm },
        publishingStatus: {
          ...state.publishingStatus,
          filters: publishing_status__in
        }
      };
    case 'CHANGE_FILTER_FORM':
      return {
        ...state,
        advanceForm: {
          ...state.advanceForm,
          ...payload
        }
      };
    case 'CHANGE_FILTER_SOURCE':
      return {
        ...state,
        sources: {
          ...state.sources,
          selected: setSelectedSources(payload.value)
        }
      };
    case 'CHANGE_FILTER_PUBLISHING_STATUS':
      return {
        ...state,
        publishingStatus: {
          ...state.publishingStatus,
          ...payload.value
        }
      };
    case 'CHANGE_FILTER_SELECTED_STATUS':
      return {
        ...state,
        selectedStatus: payload.value
      };
    case 'RESET_FILTERS':
      return {
        ...state,
        isLoadingFilters: false,
        advanceForm: getDefaultValuesFilterByUse(
          state.filters.find(f => f.value === state.advanceForm.listing_type)
        )
      };

      case 'RESET_FILTERS_MODAL':
        let newFiltersComparables =  state.filters.find(f => f.value === state.advanceForm.listing_type).comparablesFilters;
        const defaultDistanceByListingType = newFiltersComparables?.value === 1 ? DEFAULT_GEOG_DWITHIN : [1000];
        return {
          ...state,
          isLoadingFilters: false,
          advanceForm: {
            ...state.advanceForm,
            property_type__in_comparables: null,
            geog__dwithin: defaultDistanceByListingType,
            distance_on_foot: null,
            distance_by_car: null,
            date_main_range: DEFAULT_DATE_MAIN_RANGE,
            area__range: newFiltersComparables.find(elem => elem.id === 'area__range').filters[0].values,
            n_rooms__in: [],
            n_baths__in: [],
            same_neighbour: false,
            construction_year__range: newFiltersComparables.find(elem => elem.id === 'construction_year').filters[0].values,
          }
        };
      
    case 'SET_SMARTVALUATION_MAP_INFO':
      return {
        ...state,
        map: {
          ...state.map,
          ...payload
        }
      };
    case 'SET_MAP_POPUP_INFO':
      return {
        ...state,
        popup: payload.data
      };
    case 'REMOVE_POPUP_INFO':
      return {
        ...state,
        popup: null
      };

    case 'FETCH_INDICATORS':
      return {
        ...state,
        indicators: {
          ...state.indicators,
          isLoading: true
        }
      };
    case 'FETCH_INDICATORS_SUCCESS':
      return {
        ...state,
        indicators: {
          ...state.indicators,
          isLoading: false,
          kpis: {
            ...state.indicators.kpis,
            [payload.data.id]: {
              ...payload.data,
              operation: state.operation,
              boundary: state.boundary_id
            }
          }
        }
      };
    case 'FETCH_INDICATORS_FAIL':
      return {
        ...state,
        indicators: {
          ...state.indicators,
          isLoading: false
        }
      };


      case 'FETCH_REPORT_BY_TASK':
        return {
          ...state,
          report: {
            ...state.report,
            selectedReportType: payload.request.data.report_type,
            isLoading: true
          }
        };
  
        case 'FETCH_REPORT_BY_TASK_SUCCESS':
        return {
          ...state,
          report: {
            ...state.report,
            report_url: payload.data?.result?.url,
            url: payload.data?.result?.url,
            //isLoading: false
          }
        };

    case 'FETCH_REPORT_TASK':
      return {
        ...state,
        report: {
          ...state.report,
          selectedReportType: payload.request.data.report_type,
          isLoading: true
        }
      };
    case 'FETCH_REPORT_TASK_SUCCESS':
      return {
        ...state,
        report: {
          ...state.report,
          task_id: payload.data.task_id,
          report_url: payload.data.report_url,
          url: payload.data.report_url
        }
      };
    case 'FETCH_REPORT_TASK_FAIL':
      return {
        ...state,
        report: {
          ...state.report,
          isLoading: false
        }
      };
    case 'UPDATE_REPORT_TASK_INFO':
      return {
        ...state,
        report: {
          ...state.report,
          ...payload
        }
      };
    case 'UPDATE_COMPARABLES_AVM_STATUS':
      return {
        ...state,
        comparables: {
          ...state.comparables,
          items: payload
        }
      };
    case 'SET_SEARCHED_BEFORE':
      return {
        ...state,
        searchedBefore: payload
      }
    default:
      return state;
  }
};

export default reducer;

export const checkAvailableSources = data => {
  const disabledPlans = [
    16, 17, 13, 14, 36, 59, 37, 38, 26, 34, 35, 39, 27, 10, 11, 41, 30, 32, 42, 43, 44, 45, 46
  ];

  return data.map(d => (disabledPlans.includes(d.value) ? { ...d, disabled: true } : d));
};

const getSortingFields = data =>
  data.reduce(
    (obj, item) =>
      SORTS_TO_REMOVE.includes(item)
        ? obj
        : Object.assign(obj, {
            [item]: { label: `smartvaluation.sorting.${item}`, disabled: false }
          }),
    {}
  );
