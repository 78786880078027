import styled, { css } from 'styled-components';
import useFlag from '../../utils/hooks/useFlag';
import fotocasaLogo from '../../assets/images/logo-fotocasa-inversion.svg';
import pulseLogo from '../../assets/images/logo-positive.svg';

const StyledLogo = styled.div`
  position: absolute;
  width: 80%;
  max-width: 440px;
  height: 40px;
  top: 80px;
  left: 80px;

  img {
    height: 100%;
  }

  @media only screen and (max-height: 768px) {
    top: 56px;
    left: 56px;
  }

  ${({ breakpoint }) =>
    breakpoint === 's' &&
    css`
      height: 24px;
      top: 32px !important;
      left: auto !important;
    `};

  ${({ breakpoint }) =>
    breakpoint === 'm' &&
    css`
      height: 32px;
      top: 32px !important;
      left: 36px !important;
    `};
`;

const AuthLogo = ({ breakpoint }) => {
  const showFotocasaLogo = useFlag('show_fotocasa_logo');

  return (
    <StyledLogo breakpoint={breakpoint}>
      <img
        src={showFotocasaLogo ? fotocasaLogo : pulseLogo}
        alt={showFotocasaLogo ? `Colaboración con Fotocasa` : `Pulse`}
      />
    </StyledLogo>
  );
};

export default AuthLogo;
