import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './store';

// import { LicenseManager } from '@ag-grid-enterprise/core';
import App from './App';
// import { history } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AppProvider } from './context/index';
// import { Integrations } from '@sentry/tracing';
// import * as Sentry from '@sentry/react';
// import { ANALYTICS_COOKIE_KEY } from './constants.js';
// import { getCookie } from './utils/storage';

// LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_API_KEY);

// const userGivesPermissions = getCookie(ANALYTICS_COOKIE_KEY) === 'true';

// if (
//   process.env.NODE_ENV === 'production' &&
//   process.env.REACT_APP_SENTRY_RELEASE &&
//   userGivesPermissions
// ) {
  // const SENTRY_MAXBREADCRUMBS = 30;
  // Sentry.init({
  //   dsn: process.env.REACT_APP_SENTRY_DSN,
  //   environment: process.env.REACT_APP_ENVIRONMENT,
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  //       tracingOrigins: [process.env.REACT_APP_API_BASE_URL]
  //     })
  //   ],
  //   _experiments: {
  //     ensureNoCircularStructures: true
  //   },
  //   normalizeDepth: process.env.REACT_APP_SENTRY_NORMALIZE_DEPTH,
  //   tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  //   release: process.env.REACT_APP_SENTRY_RELEASE,
  //   maxBreadcrumbs: SENTRY_MAXBREADCRUMBS,
  //   denyUrls: [
  //     // mvt
  //     /.*(mvt|.mvt)$/gm,
  //     // Chrome extensions
  //     /extensions\//i,
  //     /^chrome:\/\//i
  //   ]
  // });
// }

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

ReactDOM.render(
  <Provider store={store}>
    <AppProvider>
      <App />
    </AppProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorkerRegistration.register({
  onUpdate: async registration => {
    console.warn('New version available!');
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting && registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});
