export const updateNotification = notification => ({
  type: 'UPDATE_NOTIFICATION',
  payload: notification
});

export const openNotification = (type, notification) => ({
  type: 'OPEN_NOTIFICATION',
  payload: { ...notification, isOpen: true, variant: type }
});

export const closeNotification = () => (dispatch, getState) => {
  const { isOpen } = getState().notification;
  if (isOpen)
    dispatch({
      type: 'CLOSE_NOTIFICATION'
    });
};

export const resetNotification = () => ({
  type: 'RESET_NOTIFICATION',
  payload: null
});
