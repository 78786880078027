import * as Yup from 'yup';
import { InputField, FormGroup } from '@uda/bento-components';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import tipImage from './../../assets/images/tip.svg';
import SettingsActions from './SettingsActions';

const StyledComponentWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    padding: ${({ theme: { spacings } }) => `${spacings.small3} ${spacings.medium1}`};
    margin-bottom: ${({ theme }) => theme.spacings.large2};
  }
`;

const PASSWORD_MIN_LENGTH = 8;

const StyledHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.small4};
`;

const StyledTipHeading = styled.h5`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.charcoal600};
  margin-bottom: ${({ theme }) => theme.spacings.small2};
`;

const StyledContainer = styled.div`
  max-width: 340px;
`;

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  > ul {
    color: ${({ theme }) => theme.color.charcoal600};
    font-size: 12px;
    margin-right: ${({ theme }) => theme.spacings.small3};
    padding-left: 10px;
    margin-left: 10px;

    > li {
      > p {
        ${({ theme }) => theme.texts.p2};
        color: ${({ theme }) => theme.color.charcoal600};
      }
    }
  }
`;

const SettingsPassword = ({ onSubmit }) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(PASSWORD_MIN_LENGTH, intl.formatMessage({ id: 'errors.min_password_8' }))
      .matches(/^(?=.*[a-z])/, intl.formatMessage({ id: 'errors.include_lowercase' }))
      .matches(/^(?=.*[A-Z])/, intl.formatMessage({ id: 'errors.include_uppercase' }))
      .matches(/^(?=.*[0-9])/, intl.formatMessage({ id: 'errors.include_number' }))
      .required(intl.formatMessage({ id: 'errors.required' })),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      intl.formatMessage({ id: 'errors.match_password' })
    )
  });

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: validationSchema,
    async onSubmit({ password }, { setSubmitting }) {
      await onSubmit({ password });
      setSubmitting(false);
    }
  });

  const { handleSubmit, isSubmitting, errors, touched, handleBlur, handleChange, values, dirty } =
    formik;

  const isSubmitDisabled =
    isSubmitting || !!errors.password || !!errors.passwordConfirmation || !dirty;

  function handleKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  return (
    <StyledComponentWrapper>
      <StyledHeading>
        <FormattedMessage id="settings.password.title" />
      </StyledHeading>
      <StyledContainer>
        <form
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit}
          className={isSubmitting ? 'loading' : null}
        >
          <FormGroup>
            <InputField
              autoComplete="off"
              disabled={isSubmitting}
              error={!!errors.password && !!touched.password && !!errors.password}
              help={
                (errors.password && touched.password && errors.password) ||
                intl.formatMessage({ id: 'auth.sign_up.password_helptext' })
              }
              label={intl.formatMessage({ id: 'settings.password.password' })}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({
                id: 'settings.password.password_placeholder'
              })}
              required
              type="password"
              value={values.password}
            />
          </FormGroup>
          <FormGroup>
            <InputField
              autoComplete="off"
              disabled={isSubmitting}
              error={
                !!errors.passwordConfirmation &&
                !!touched.passwordConfirmation &&
                !!errors.passwordConfirmation
              }
              help={
                (errors.passwordConfirmation &&
                  touched.passwordConfirmation &&
                  errors.passwordConfirmation) ||
                ''
              }
              label={intl.formatMessage({ id: 'settings.password.password_confirm' })}
              name="passwordConfirmation"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({
                id: 'settings.password.password_confirm_placeholder'
              })}
              required
              type="password"
              value={values.passwordConfirmation}
            />
          </FormGroup>
          <div>
            <StyledTipHeading>
              <FormattedMessage id="settings.password.subtitle" />
            </StyledTipHeading>
            <StyledContent>
              <ul>
                <li>
                  <p>
                    <FormattedMessage id="settings.password.first_tip" />
                  </p>
                </li>
                <li>
                  <p>
                    <FormattedMessage id="settings.password.second_tip" />
                  </p>
                </li>
                <li>
                  <p>
                    <FormattedMessage id="settings.password.third_tip" />
                  </p>
                </li>
              </ul>
              <figure>
                <img src={tipImage} alt="uDA Tip" />
              </figure>
            </StyledContent>
          </div>
          <SettingsActions isSubmitDisabled={isSubmitDisabled} isSubmitting={isSubmitting} />
        </form>
      </StyledContainer>
    </StyledComponentWrapper>
  );
};

export default SettingsPassword;
