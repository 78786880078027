import { SelectField, FormGroup } from '@uda/bento-components';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import SettingsActions from './SettingsActions';

const StyledComponentWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    padding: ${({ theme: { spacings } }) => `${spacings.small3} ${spacings.medium1}`};
    margin-bottom: ${({ theme }) => theme.spacings.large2};
  }
`;

const StyledHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.small4};
`;

const StyledContainer = styled.div`
  max-width: 340px;
`;
const SettingsLanguage = ({ initialState, onSubmit }) => {
  const { language } = initialState;
  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      language
    },
    async onSubmit({ language }, { setSubmitting }) {
      await onSubmit({ language });
      setSubmitting(false);
    }
  });

  const { handleSubmit, isSubmitting, handleBlur, values, dirty, setFieldValue } = formik;

  const isSubmitDisabled = isSubmitting || !dirty;

  return (
    <StyledComponentWrapper>
      <StyledHeading>
        <FormattedMessage id="settings.language.title" />
      </StyledHeading>
      <StyledContainer>
        <form onSubmit={handleSubmit} className={isSubmitting ? 'loading' : null}>
          <FormGroup>
            <SelectField
              autoComplete="off"
              disabled={isSubmitting}
              label={intl.formatMessage({ id: 'settings.language.select' })}
              defaultLabel={intl.formatMessage({ id: 'settings.language.select' })}
              options={[
                { value: 'en', label: intl.formatMessage({ id: 'languages.english' }) },
                { value: 'es', label: intl.formatMessage({ id: 'languages.spanish' }) },
                { value: 'pt', label: intl.formatMessage({ id: 'languages.portuguese' }) },
                { value: 'it', label: intl.formatMessage({ id: 'languages.italian' }) }
              ]}
              name="language"
              id="language"
              onBlur={handleBlur}
              onChange={value => setFieldValue('language', value)}
              required
              type="select"
              value={values.language}
            />
          </FormGroup>
          <SettingsActions isSubmitDisabled={isSubmitDisabled} isSubmitting={isSubmitting} />
        </form>
      </StyledContainer>
    </StyledComponentWrapper>
  );
};

export default SettingsLanguage;
