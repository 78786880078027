import styled, { useTheme } from 'styled-components';
import bg from '../../assets/images/fotocasaAside.png';
import logo from '../../assets/images/fotocasaAsideLogo.png';
import fotocasaGradient from '../../assets/images/fotocasaGradient.png';
import { FormattedMessage } from 'react-intl';
import { IconSuccess } from '@uda/bento-components';

const StyledAuthBrand = styled.aside`
  overflow: hidden;
  position: relative;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background-image: url(${bg});
  background-size: cover;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  background-image: url(${fotocasaGradient});
  background-size: cover;
  padding-top: 200px;

  img {
    max-width: 100%;
  }
`;

const StyledList = styled.ul`
  text-align: center;
  list-style: none;
  padding: ${({ theme }) => `20px 0 ${theme.spacings.large1} ${theme.spacings.medium5}}`};
`;

const StyledItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacings.small2};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 11px;
  color: ${({ theme }) => theme.color.white};
  font-size: 18px;
  font-weight: 500;
`;

const AuthBrandFotocasa = () => {
  const theme = useTheme();

  const tips = ['first', 'second', 'third'];
  return (
    <>
      <StyledAuthBrand>
        <Container>
          <img src={logo} alt="Pulse" />
          <StyledList>
            {tips.map((item, i) => (
              <StyledItem key={i}>
                <h4>
                  <IconSuccess customColor={theme.color.white} />
                </h4>
                <FormattedMessage id={`auth.sign_up.fotocasa.${item}`} />
              </StyledItem>
            ))}
          </StyledList>
        </Container>
      </StyledAuthBrand>
    </>
  );
};

export default AuthBrandFotocasa;
