import { getUrlParams } from './getUrlParams';

export const getCoordinatesFromUrl = () => {
  const urlParams = getUrlParams();
  const allowedCoordinates = ['lat', 'lon', 'address'];
  const coordinates = Object.entries(urlParams).reduce((obj, [param, value]) => {
    if (!allowedCoordinates.includes(param)) return obj;
    return { ...obj, [param]: param === 'listing_type' ? Number(value) : value };
  }, {});
  if (!allowedCoordinates.every(param => Object.keys(coordinates).includes(param))) return {};
  return coordinates;
};
