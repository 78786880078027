import { createApi } from '@reduxjs/toolkit/query/react';
import { httpStrapiRequest } from 'libs/rtkquery';
import { mapCompanyPersonalization } from './adapter';

export const strapiAPI = createApi({
  reducerPath: 'strapiApi',
  baseQuery: httpStrapiRequest(process.env.REACT_APP_STRAPI_URL),
  endpoints: builder => ({
    getPersonalization: builder.query({
      query: ({ product, company }) => ({
        url: `/api/${product}-personalizations?filters[company][$eq]=${company}&populate[theme][populate]=*&populate[assets][populate]=*&populate=features`
      }),
      transformResponse: mapCompanyPersonalization
    })
  })
});

export const { useGetPersonalizationQuery } = strapiAPI;
