export const initialState = {
  isLoading: false,
  tab: null,
  save_status: 'save',
  header: {
    developmentId: null,
    valuer_name: null,
    reviewType: null,
    status: null,
    permission: null
  }
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'UPDATE_DEVELOPMENT_TAB':
      return {
        ...state,
        tab: payload
      };
    case 'FETCH_DEVELOPMENT_DETAIL':
    case 'PATCH_DEVELOPMENT_STATUS':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_DEVELOPMENT_DETAIL_SUCCESS':
      return {
        ...state,
        isLoading: false,
        header: {
          ...state.header,
          developmentId: payload.data.group_id,
          valuer_name: payload.data.valuer_name,
          reviewType: payload.data.review_type,
          status: payload.data.status,
          permission: payload.data.permission
        }
      };
    case 'PATCH_DEVELOPMENT_STATUS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        header: {
          ...state.header,
          status: payload.data.status
        }
      };
    case 'FETCH_DEVELOPMENT_DETAIL_FAIL':
    case 'PATCH_DEVELOPMENT_STATUS_FAIL':
      return {
        ...state,
        isLoading: false
      };
    case 'FETCH_EDIT_COMPARABLES':
    case 'FETCH_EDIT_DEVELOPMENT_TABLE':
    case 'FETCH_EDIT_VALUATION_TABLE':
    case 'FETCH_EDIT_METHOD_TABLE':
    case 'FETCH_SAVE_VALUATION_DATA':
      return {
        ...state,
        save_status: 'saving'
      };
    case 'FETCH_EDIT_COMPARABLES_SUCCESS':
    case 'FETCH_EDIT_DEVELOPMENT_TABLE_SUCCESS':
    case 'FETCH_EDIT_VALUATION_TABLE_SUCCESS':
    case 'FETCH_EDIT_METHOD_TABLE_SUCCESS':
    case 'FETCH_SAVE_VALUATION_DATA_SUCCESS':
      return {
        ...state,
        save_status: 'saved'
      };
    case 'FETCH_EDIT_COMPARABLES_FAIL':
    case 'FETCH_EDIT_DEVELOPMENT_TABLE_FAIL':
    case 'FETCH_EDIT_VALUATION_TABLE_FAIL':
    case 'FETCH_EDIT_METHOD_TABLE_FAIL':
    case 'FETCH_SAVE_VALUATION_DATA_FAIL':
    case 'RESET_SAVE_BUTTON':
      return {
        ...state,
        save_status: 'save'
      };
    default:
      return state;
  }
};

export default reducer;
