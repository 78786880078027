export const getReversGeocodingAddress = (data = []) => {
  if (data.features && data.features.length) {
    const [main] = data.features;

    return main.place_name;
  }

  return null;
};

export const getAutocompleteAddresses = (data = []) => {
  if (data.features && data.features.length) {
    return data.features.map(feature => {
      const { center: coordinates, id, place_name: address, place_type, context } = feature;

      let city = null;
      if (context && context.length) {
        context.forEach(contextData => {
          if (contextData.id.startsWith('region')) {
            city = contextData.text;
          }
        });
      }

      return {
        coordinates,
        id,
        address,
        city,
        type: place_type[0]
      };
    });
  }
  return [];
};
