import { history } from '../../App';
import PATHS from '../../routes/paths';
import qs from 'query-string';
import * as CONSTANTS from '../constants.js';
import * as APP_CONSTANTS from '../../constants.js';
import { initialState } from '../reducers/portfolio';
import { portfolioUrl } from '../utils/portfolioUrl';
import { formatSortParams } from '../../utils/sort';
import { slugify } from '../../utils';

// table npl https://run.mocky.io/v3/1c6c01b0-504c-46b7-93b7-7290ef5f238a
// table reo https://run.mocky.io/v3/cb3a1256-c843-4de7-938c-b18f366c8502

// header npl https://run.mocky.io/v3/9e9e9b87-a8a6-496d-b2a5-f6f244af8474
// header reo https://run.mocky.io/v3/dbfa8d83-2173-4592-b429-0e5d88ec3c60

export const setCardsSort = sorting => ({
  type: 'PORTFOLIO_CARDS_SORT',
  payload: sorting
});

export const updateCardsSort =
  (portfolioId, sorting, { key, value }) =>
  dispatch => {
    const newSort = Object.assign({}, sorting, { sort: key, order: value });

    dispatch(setCardsSort(newSort));
    dispatch(loadAssets(portfolioId));
  };

export const setMapInfo = value => ({
  type: 'SET_PORTFOLIO_MAP_INFO',
  payload: value
});

export const toggleMapVisibility = () => (dispatch, getState) => {
  const visible = getState().portfolio.detail.map.visible;
  dispatch(setMapInfo({ visible: !visible }));
};

export const loadWidgetTable = (portfolioId, widgetId) => async (dispatch, getState) => {
  const sorting = getState().portfolio.detail.analytics.widgets[widgetId].table.sorting;
  const portfolioType = getState().portfolio.detail.header.data.portfolio_type;
  const order = formatSortParams(sorting);

  const params = {
    order
  };

  dispatch(fetchPortfolioDetailWidget(portfolioId, portfolioType, widgetId, params));
};

export const initPortfolioAnalytics = () => dispatch => {
  dispatch(initWidgets());
  dispatch(initAnalyticsFilters());
  dispatch(loadMap());
};

export const loadMap = () => (dispatch, getState) => {
  const portfolioId = getState().portfolio.detail.portfolioId;
  const { boundary_type, distribution } = getState().portfolio.detail.analytics.map.filters;

  dispatch(fetchPortfolioDetailMap(portfolioId, distribution, boundary_type));
};

export const initAnalyticsFilters = () => ({
  type: 'INIT_ANALYTICS_FILTERS',
  payload: null
});

export const updateAnalyticsFilter = filter => dispatch => {
  dispatch({
    type: 'UPDATE_ANALYTICS_FILTERS',
    payload: filter
  });

  dispatch(loadMap());
};

export const initWidgets = () => ({
  type: 'INIT_WIDGETS',
  payload: null
});

export const resetWidgets = () => ({
  type: 'RESET_WIDGETS',
  payload: null
});

export const resetPortfolioAnalytics = () => ({
  type: 'RESET_PORTFOLIO_ANALYTICS',
  payload: null
});

export const loadPortfolioDetail =
  ({ portfolioId, portfolioName }) =>
  dispatch => {
    dispatch({
      type: 'SET_PORTFOLIO_DETAIL',
      payload: `${portfolioId}-${portfolioName}`
    });

    dispatch(fetchPortfolioDetailHeader(portfolioId));
  };

export const changeAssetLayoutView = payload => dispatch => {
  dispatch(updatePortfolioDetailLayout(payload.value));
  dispatch(loadAssets(payload.portfolioId, false, 1));
};

export const setPortfolioDetailTab = tab => {
  return {
    type: 'UPDATE_PORTFOLIO_DETAIL_TAB',
    payload: tab
  };
};

export const updateIsFiltersOpen = isFiltersOpen => ({
  type: 'UPDATE_PORTFOLIO_LIST_GRID_FILTERS',
  payload: isFiltersOpen
});

export const updateIsSearchOpen = isSearchOpen => ({
  type: 'UPDATE_PORTFOLIO_LIST_SEARCH',
  payload: isSearchOpen
});

export const updatePortfolioDetailTab = tabId => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_PORTFOLIO_DETAIL_TAB',
    payload: tabId
  });
  const portfolioSlug = getState().portfolio.detail.portfolioSlug;

  history.replace(`${PATHS.portfolio}/${portfolioSlug}/${tabId}`);
};

export const fetchPortfolioDetailAssets = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_ASSETS',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/assets`,
        params: params
      }
    }
  };
};

export const fetchPortfolioDetail = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_ASSETS',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}`,
        params: params
      }
    }
  };
};

export const fetchPortfolioDetailAssetsCards = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_ASSETS_CARDS',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/assets?layout=grid`,
        params: params
      }
    }
  };
};

export const fetchPortfolioDetailAssetsCardsPage = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_ASSETS_CARDS_PAGE',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/assets?layout=grid`,
        params: params
      }
    }
  };
};

export const fetchPortfolioDetailDevelopments = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/developments`,
        params
      }
    }
  };
};

export const fetchPortfolioDetailAssetsPage = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_ASSETS_PAGE',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/assets`,
        params: params
      }
    }
  };
};

export const fetchPortfolioDetailDevelopmentsPage = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_PAGE',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/developments`,
        params: params
      }
    }
  };
};

export const fetchPortfolioDetailContracts = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_CONTRACTS',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/contracts`,
        params: params
      }
    }
  };
};

export const fetchPortfolioDetailContractsPage = (portfolioId, params) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_PAGE',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/contracts`,
        params: params
      }
    }
  };
};

export const fetchPortfolioList = ({ search, filters, ...params }) => {
  return {
    type: 'FETCH_PORTFOLIO_LIST',
    payload: {
      request: {
        method: 'POST',
        url: '/properties/pulse/portfolios/',
        params: params,
        data: { search, filters }
      }
    }
  };
};

export const fetchPortfolioListPage = ({ search, filters, ...params }) => {
  return {
    type: 'FETCH_PORTFOLIO_LIST_PAGE',
    payload: {
      request: {
        method: 'POST',
        url: '/properties/pulse/portfolios/',
        params: params,
        data: { search, filters }
      }
    }
  };
};

export const updatePortfolioDetailLayout = payload => {
  return {
    type: 'UPDATE_PORTFOLIO_DETAIL_LAYOUT',
    payload
  };
};

export const fetchPortfolioDetailWidget = (portfolioId, portfolioType, widgetId, params) => {
  const portfolioTypeParam = portfolioType === 'NPL' ? 'contracts' : 'assets';

  return {
    type: 'FETCH_PORTFOLIO_DETAIL_WIDGET',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/${portfolioTypeParam}/aggregations/${widgetId}/`,
        params
      },
      widgetId
    }
  };
};

export const setPortfolioDetailIsDownloading = isDownloading => {
  return {
    type: 'SET_PORTFOLIO_DETAIL_DOWNLOAD',
    payload: {
      isDownloading
    }
  };
};

export const fetchPortfolioDetailHeader = portfolioId => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_HEADER',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/`
      },
      portfolioId
    }
  };
};

export const fetchPortfolioDetailGenerateExcel = portfolioId => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_GENERATE_EXCEL',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/generate_excel`
      },
      portfolioId
    }
  };
};

export const fetchPortfolioDetailMap = (portfolioId, distribution, boundary_type) => {
  return {
    type: 'FETCH_PORTFOLIO_DETAIL_MAP',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/pulse/portfolios/${portfolioId}/assets/aggregations/${distribution}/geo.json?boundary_type=${APP_CONSTANTS.BOUNDARY_TYPE[boundary_type]}`
      }
    }
  };
};

export const fetchFiltersCollection = () => {
  return {
    type: 'FETCH_PORTFOLIO_FILTERS_COLLECTION',
    payload: {
      request: {
        method: 'GET',
        url: '/properties/pulse/portfolios/filters/'
      }
    }
  };
};

export const loadPortfolios =
  (appendPortfolios = false, page = 1) =>
  async (dispatch, getState) => {
    const { layout, tab, search, filtersApplied: filters } = getState().portfolio.list;
    const { sorting } = getState().portfolio.list[layout];
    let params = Object.assign({}, { filters, layout, type: tab, search });

    const order = formatSortParams(sorting);

    params = {
      ...params,
      order,
      page
    };

    dispatch(appendPortfolios ? fetchPortfolioListPage(params) : fetchPortfolioList(params));
  };

export const loadAssets =
  (portfolioId, appendAssets = false, page = 1) =>
  async (dispatch, getState) => {
    const layout = getState().portfolio.detail.layout;
    const { sorting } =
      layout === 'grid' ? getState().portfolio.detail.grid.list : getState().portfolio.detail.table;
    const order = formatSortParams(sorting);

    const params = {
      page,
      order
    };

    switch (layout) {
      case 'grid':
        dispatch(
          appendAssets
            ? fetchPortfolioDetailAssetsCardsPage(portfolioId, params)
            : fetchPortfolioDetailAssetsCards(portfolioId, params)
        );
        break;
      default:
        dispatch(
          appendAssets
            ? fetchPortfolioDetailAssetsPage(portfolioId, params)
            : fetchPortfolioDetailAssets(portfolioId, params)
        );
        break;
    }
  };

export const loadDevelopments =
  (portfolioId, appendDevelopments = false, page = 1) =>
  async (dispatch, getState) => {
    const { sorting } = getState().portfolio.detail.developments;
    const order = formatSortParams(sorting);

    const filter = getState().portfolio.detail.developments.filter;

    const params = {
      page,
      order,
      filter: filter ? filter : null
    };

    // Comment previous params and activate the line below to reset a failed filter.
    //const params = { page: 1, order: 'group_id_valuation_type' };

    dispatch(
      appendDevelopments
        ? fetchPortfolioDetailDevelopmentsPage(portfolioId, params)
        : fetchPortfolioDetailDevelopments(portfolioId, params)
    );
  };

export const loadContracts =
  (portfolioId, appendContracts = false, page = 1) =>
  async (dispatch, getState) => {
    const { sorting } = getState().portfolio.detail.contracts;
    const order = formatSortParams(sorting);

    const params = {
      page,
      order
    };

    dispatch(
      appendContracts
        ? fetchPortfolioDetailContractsPage(portfolioId, params)
        : fetchPortfolioDetailContracts(portfolioId, params)
    );
  };

export const resetPortfolioList = () => ({
  type: 'RESET_PORTFOLIO_LIST',
  payload: null
});

export const initPortfolios = () => (dispatch, getState) => {
  const {
    filters,
    actions: actionsFromLocation,
    layout: layoutFromLocation,
    tab: tabFromLocation,
    search: searchFromLocation
  } = getPropertiesFromLocation();

  const {
    layout: defaultLayout,
    tab: defaultTab,
    search: defaultSearch
  } = getState().portfolio.list;
  const {
    items: portfolios,
    rows,
    actions: defaultActions
  } = getState().portfolio.list[layoutFromLocation];

  const initActions = { ...defaultActions, ...actionsFromLocation };

  if (layoutFromLocation !== defaultLayout) {
    dispatch(setLayout(layoutFromLocation));
  }
  if (tabFromLocation !== defaultTab) {
    dispatch(setTab(tabFromLocation));
  }

  if (searchFromLocation !== defaultSearch) {
    dispatch(setSearch(searchFromLocation));
  }

  Object.keys(filters).length && dispatch(setListFilters(filters));
  Object.keys(initActions).length && dispatch(setListActions(initActions));

  dispatch(fetchFiltersCollection());
  if (layoutFromLocation === 'grid' && (!portfolios || portfolios.length === 0)) {
    dispatch(loadPortfolios());
  }
  if (layoutFromLocation === 'table' && (!rows || rows.length === 0)) {
    dispatch(loadPortfolios());
  }
};

export const updateFilters = filters => dispatch => {
  dispatch(setListFilters(filters));
  dispatch(loadPortfolios());
};

export const clearFilters = kpis => (dispatch, getState) => {
  const filtersApplied = { ...getState().portfolio.list.filtersApplied };
  kpis.forEach(kpi => delete filtersApplied[kpi]);

  dispatch(setListFilters(filtersApplied));
  dispatch(loadPortfolios());
};

export const clearAllFilters = () => dispatch => {
  dispatch(setListFilters({}));
  dispatch(loadPortfolios());
};

export const updateSort =
  (sorting, { key, value }) =>
  dispatch => {
    const newSort = Object.assign({}, sorting, { sort: key, order: value });
    Object.keys(sorting).length && dispatch(setListSort(newSort));

    dispatch(loadPortfolios());
  };

export const updateDetailSort =
  (sorting, { key, value }) =>
  (dispatch, getState) => {
    const portfolioId = getState().portfolio.detail.portfolioId;
    const newSort = Object.assign({}, sorting, { sort: key, order: value });
    Object.keys(sorting).length && dispatch(setDetailSort(newSort));

    dispatch(loadAssets(portfolioId));
  };

export const updateDevelopmentsSort =
  (sorting, { key, value }) =>
  (dispatch, getState) => {
    const portfolioId = getState().portfolio.detail.portfolioId;
    const newSort = Object.assign({}, sorting, { sort: key, order: value });

    Object.keys(sorting).length && dispatch(setDevelopmentsSort(newSort));

    dispatch(loadDevelopments(portfolioId));
  };

export const updateDevelopmentsFilter = filter => async (dispatch, getState) => {
  dispatch(setDevelopmentsFilter(filter));
};

export const updateContractsSort =
  (sorting, { key, value }) =>
  (dispatch, getState) => {
    const portfolioId = getState().portfolio.detail.portfolioId;
    const newSort = Object.assign({}, sorting, { sort: key, order: value });
    Object.keys(sorting).length && dispatch(setContractsSort(newSort));

    dispatch(loadContracts(portfolioId));
  };

export const updateWidgetTableSort =
  (portfolioId, widgetId, sorting, { key, value }) =>
  dispatch => {
    const newSort = Object.assign({}, sorting, { sort: key, order: value });
    Object.keys(sorting).length && dispatch(setWidgetTableSort(widgetId, newSort));

    dispatch(loadWidgetTable(portfolioId, widgetId));
  };

export const updateLayout = layout => (dispatch, getState) => {
  dispatch(setLayout(layout));
  if (layout === 'table' && getState().portfolio.list.tab === 'all') {
    dispatch(setTab('reo'));
  }
  dispatch(loadPortfolios());
};

export const updateTab = tab => dispatch => {
  dispatch(setTab(tab));
  dispatch(loadPortfolios());
};

export const updatePage = page => dispatch => {
  dispatch(loadPortfolios(true, page));
};

export const updateCardsPage = page => (dispatch, getState) => {
  const portfolioId = getState().portfolio.detail.portfolioId;
  dispatch(loadAssets(portfolioId, true, page, 'grid'));
};

export const updateDetailPage = page => (dispatch, getState) => {
  const portfolioId = getState().portfolio.detail.portfolioId;

  dispatch(loadAssets(portfolioId, true, page));
};

export const updateDevelopmentsPage = page => (dispatch, getState) => {
  const portfolioId = getState().portfolio.detail.portfolioId;

  dispatch(loadDevelopments(portfolioId, true, page));
};

export const updateContractsPage = page => (dispatch, getState) => {
  const portfolioId = getState().portfolio.detail.portfolioId;

  dispatch(loadContracts(portfolioId, true, page));
};

export const resetColumnState = () => async (dispatch, getState) => {
  const tab = getState().portfolio.list.tab;
  if (tab !== 'all') {
    const preference_code = getState().portfolio.list.table.preference_code;
    const action = await dispatch({
      type: 'UPDATE_PORTFOLIO_LIST_TABLE_SETTINGS',
      payload: {
        request: {
          method: 'DELETE',
          url: `/settings/preferences/${preference_code}/`
        }
      }
    });
    if (action.type === 'UPDATE_PORTFOLIO_LIST_TABLE_SETTINGS_SUCCESS') {
      dispatch({
        type: 'FETCH_PORTFOLIO_LIST_TABLE_SETTINGS',
        payload: {
          request: {
            method: 'GET',
            url: `/settings/preferences/${preference_code}/?fallback=true`
          }
        }
      });
    }
  }
};

export const updateColumnState = (field, value) => (dispatch, getState) => {
  dispatch({
    type: 'PORTFOLIO_LIST_TABLE_COLUMNS',
    payload: { field, value }
  });

  const tab = getState().portfolio.list.tab;

  if (field === 'columnState' && value !== null && tab !== 'all') {
    const code = getState().portfolio.list.table.preference_code;
    const columns = getState().portfolio.list.table.columns;
    const formattedColumns = value && apiInternColumn(value, columns);
    dispatch({
      type: 'UPDATE_PORTFOLIO_LIST_TABLE_SETTINGS',
      payload: {
        request: {
          method: 'POST',
          url: '/settings/preferences/',
          data: { code, value: formattedColumns }
        }
      }
    });
  }
};

export const resetDetailColumnState = () => async (dispatch, getState) => {
  const tab = getState().portfolio.detail.tab;
  if (tab !== 'analytics') {
    const preference_code = getState().portfolio.detail.table.preference_code;
    const action = await dispatch({
      type: 'UPDATE_PORTFOLIO_DETAIL_ASSETS_SETTINGS',
      payload: {
        request: {
          method: 'DELETE',
          url: `/settings/preferences/${preference_code}/`
        }
      }
    });

    if (action.type === 'UPDATE_PORTFOLIO_DETAIL_ASSETS_SETTINGS_SUCCESS') {
      dispatch({
        type: 'FETCH_PORTFOLIO_DETAIL_ASSETS_SETTINGS',
        payload: {
          request: {
            method: 'GET',
            url: `/settings/preferences/${preference_code}/?fallback=true`
          }
        }
      });
    }
  }
};

export const updateDetailColumnState = (field, value) => (dispatch, getState) => {
  dispatch({
    type: 'PORTFOLIO_DETAIL_TABLE_COLUMNS',
    payload: { field, value }
  });

  const tab = getState().portfolio.detail.tab;

  if (field === 'columnState' && value !== null && tab !== 'analytics') {
    const code = getState().portfolio.detail.table.preference_code;
    const columns = getState().portfolio.detail.table.columns;
    const formattedColumns = value && apiInternColumn(value, columns);
    dispatch({
      type: 'UPDATE_PORTFOLIO_LIST_TABLE_SETTINGS',
      payload: {
        request: {
          method: 'POST',
          url: '/settings/preferences/',
          data: { code, value: formattedColumns }
        }
      }
    });
  }
};

export const resetContractsColumnState = () => async (dispatch, getState) => {
  const tab = getState().portfolio.detail.tab;
  if (tab !== 'analytics') {
    const preference_code = getState().portfolio.detail.contracts.preference_code;
    const action = await dispatch({
      type: 'UPDATE_PORTFOLIO_DETAIL_CONTRACTS_SETTINGS',
      payload: {
        request: {
          method: 'DELETE',
          url: `/settings/preferences/${preference_code}/`
        }
      }
    });
    if (action.type === 'UPDATE_PORTFOLIO_DETAIL_CONTRACTS_SETTINGS_SUCCESS') {
      dispatch({
        type: 'FETCH_PORTFOLIO_DETAIL_CONTRACTS_SETTINGS',
        payload: {
          request: {
            method: 'GET',
            url: `/settings/preferences/${preference_code}/?fallback=true`
          }
        }
      });
    }
  }
};
export const updateContractsColumnState = (field, value) => (dispatch, getState) => {
  dispatch({
    type: 'PORTFOLIO_DETAIL_CONTRACTS_COLUMNS',
    payload: { field, value }
  });

  const tab = getState().portfolio.detail.tab;

  if (field === 'columnState' && value !== null && tab !== 'analytics') {
    const code = getState().portfolio.detail.contracts.preference_code;
    const columns = getState().portfolio.detail.contracts.columns;
    const formattedColumns = value && apiInternColumn(value, columns);
    dispatch({
      type: 'UPDATE_PORTFOLIO_LIST_TABLE_SETTINGS',
      payload: {
        request: {
          method: 'POST',
          url: '/settings/preferences/',
          data: { code, value: formattedColumns }
        }
      }
    });
  }
};

export const updateDevelopmentsColumnState = (field, value) => (dispatch, getState) => {
  dispatch({
    type: 'PORTFOLIO_DETAIL_TABLE_COLUMNS',
    payload: { field, value }
  });

  const tab = getState().portfolio.detail.tab;

  if (field === 'columnState' && value !== null && tab !== 'analytics') {
    const code = getState().portfolio.detail.developments.preference_code;
    const columns = getState().portfolio.detail.developments.columns;
    const formattedColumns = value && apiInternColumn(value, columns);
    dispatch({
      type: 'UPDATE_PORTFOLIO_LIST_TABLE_SETTINGS',
      payload: {
        request: {
          method: 'POST',
          url: '/settings/preferences/',
          data: { code, value: formattedColumns }
        }
      }
    });
  }
};

export const resetDevelopmentsColumnState = () => async (dispatch, getState) => {
  const tab = getState().portfolio.detail.tab;
  if (tab !== 'analytics') {
    const preference_code = getState().portfolio.detail.developments.preference_code;
    const action = await dispatch({
      type: 'UPDATE_PORTFOLIO_DETAIL_DEVELOPMENTS_SETTINGS',
      payload: {
        request: {
          method: 'DELETE',
          url: `/settings/preferences/${preference_code}/`
        }
      }
    });
    if (action.type === 'UPDATE_PORTFOLIO_DETAIL_DEVELOPMENTS_SETTINGS_SUCCESS') {
      dispatch({
        type: 'FETCH_PORTFOLIO_DETAIL_DEVELOPMENTS_SETTINGS',
        payload: {
          request: {
            method: 'GET',
            url: `/settings/preferences/${preference_code}/?fallback=true`
          }
        }
      });
    }
  }
};

export const successDownloadExcel = () => ({
  type: 'FETCH_PORTFOLIO_DETAIL_GENERATE_EXCEL_END'
});

export const createReportTask = portfolioId => (dispatch, getState) => {
  dispatch(fetchPortfolioDetailGenerateExcel(portfolioId));
};

export const fetchReportTaskStatus = portfolioId => async (dispatch, getState) => {
  const task_id = getState()?.portfolio?.detail?.header?.excel?.task_id;

  const action = {
    type: 'FETCH_REPORT_TASK_STATUS',
    payload: {
      request: {
        method: 'GET',
        url: `/properties/task_async/task/${task_id}/?portfolio_id=${portfolioId}`
      }
    }
  };

  const interval = setInterval(async () => {
    const response = await dispatch(action);
    if (response.payload.data.state === 'SUCCESS') {
      dispatch(successDownloadExcel());
      window.open(response.payload.data.xls_url, '_blank');
      clearInterval(interval);
    }
  }, 3000);
};

export const setListAction = (action, value) => ({
  type: 'PORTFOLIO_LIST_ACTION',
  payload: { action, value }
});

export const updateSearch = search => dispatch => {
  dispatch(setSearch(search));
  dispatch(loadPortfolios());
};

export const setListActions = actions => ({
  type: 'PORTFOLIO_LIST_ACTIONS',
  payload: actions
});

export const setLayout = layout => ({
  type: 'PORTFOLIO_LIST_LAYOUT',
  payload: layout
});

export const setTab = tab => ({
  type: 'PORTFOLIO_LIST_TAB',
  payload: tab
});

export const setSearch = search => ({
  type: 'PORTFOLIO_LIST_SEARCH',
  payload: search
});

export const setListFilters = filters => ({
  type: 'PORTFOLIO_LIST_FILTERS',
  payload: filters
});

export const setListSort = sorting => ({
  type: 'PORTFOLIO_LIST_SORT',
  payload: sorting
});

export const setDetailSort = sorting => ({
  type: 'PORTFOLIO_DETAIL_SORT',
  payload: sorting
});

export const setDevelopmentsSort = sorting => ({
  type: 'PORTFOLIO_DETAIL_DEVELOPMENTS_SORT',
  payload: sorting
});

export const setDevelopmentsFilter = filter => ({
  type: 'PORTFOLIO_DETAIL_DEVELOPMENTS_FILTER',
  payload: filter
});

export const setContractsSort = sorting => ({
  type: 'PORTFOLIO_CONTRACTS_SORT',
  payload: sorting
});

export const setWidgetTableSort = (widgetId, newSort) => ({
  type: 'PORTFOLIO_WIDGET_TABLE_SORT',
  payload: { sorting: newSort, widgetId }
});

export const resetDetail = () => ({
  type: 'PORTFOLIO_DETAIL_RESET',
  payload: null
});

export const actionsAndFiltersToHistory = data => (dispatch, getState) => {
  const { layout } = getState().portfolio.list;
  const { actions } = initialState.list[layout];

  const stateFlatten = {
    ...actions,
    search: initialState.list.search,
    layout: initialState.list.layout,
    type: initialState.list.tab
  };
  const dataFlatten = {
    ...data.actions,
    search: data.search,
    layout: data.layout,
    type: data.tab
  };

  let filters = {};
  Object.keys(data.filters).forEach(
    property => (filters[`filter::${property}`] = data.filters[property].join(','))
  );

  let params = Object.entries(dataFlatten).reduce(
    (params, [key, value]) =>
      Object.assign(
        params,
        stateFlatten.hasOwnProperty(key) && stateFlatten[key] === dataFlatten[key]
          ? {}
          : { [key]: value }
      ),
    {}
  );

  params = {
    ...params,
    ...filters
  };

  history.replace({
    pathname: PATHS.portfolio,
    search: qs.stringify(params, { encode: false })
  });
};

export const getPropertiesFromLocation = () => {
  const params = qs.parse(history.location.search);
  const layout = params.layout || initialState.list.layout;
  const tab = params.type || initialState.list.tab;
  const search = params.search || '';
  const actions = Object.keys(initialState.list[layout].actions || {});

  const result = {
    filters: {},
    actions: {},
    layout,
    tab,
    search
  };

  for (let [key, val] of Object.entries(params)) {
    if (actions.includes(key)) {
      result.actions[key] = val;
    }

    const match = key.match(/^filter::(.*)$/);
    if (match && match.length) {
      result.filters[match[1]] = val
        .split(',')
        .map(value => (isNaN(value) ? value : Number(value)));
    }
  }

  return Object.keys(result).length ? result : {};
};

export const setMap = map => ({
  type: 'SET_DETAIL_MAP',
  payload: map
});

export const setMapLoaded = isLoading => dispatch => {
  dispatch(setMap({ isLoading }));
};

export const getMapStylesByDistribution = ({ stops, distribution }) => {
  let classes = [];

  const mapColors = [...CONSTANTS.portfolio_map_colors[distribution]];

  const colorsMiddleIndex = Math.floor(mapColors.length / 2);
  const stopsMiddleIndex = Math.floor(stops.length / 2);

  const startIndex = colorsMiddleIndex - stopsMiddleIndex;
  const endIndex =
    stops.length % 2 === 0
      ? colorsMiddleIndex + stopsMiddleIndex
      : colorsMiddleIndex + stopsMiddleIndex + 1;

  const colors = mapColors.slice(startIndex, endIndex);
  classes = stops.flatMap((stop, index) => [stop, colors[index]]);

  return { classes, colors };
};

export const goToPortfolio = (portfolioName, portfolioId) => () => {
  history.push(portfolioUrl(portfolioName, portfolioId));
};

export const handleMapError = code => (dispatch, getState) => {
  const { error } = getState().portfolio.detail.analytics.map;

  if (!error.isDisplayed && code === 500) {
    dispatch(setMap({ error: { isDisplayed: true, code: code }, isLoading: true }));
  } else if (code === null) {
    dispatch(setMap({ error: { isDisplayed: false, code: null } }));
  }
};

export const goToDevelopment = data => () => {
  if (data?.valuation_type.toLowerCase() === 'manual') {
    const developmentId = data?.id;
    const url = `${history.location.pathname}/${slugify(developmentId)}`;

    window.open(url, '_blank');
  }
};

export const apiInternColumn = (columnState, columns) =>
  columnState
    .filter(({ colId }) => colId !== 'actions')
    .map(({ colId: id, hide, sort = [] }, i) => {
      const column = columns.find(col => col.field === id);
      return Object.assign(
        {},
        { id, hide, sort: column && column.sort ? column.sort : [], order: i }
      );
    });

export const fetchPortfoliosPermission = () => ({
  type: 'FETCH_PORTFOLIOS_PERMISSION',
  payload: {
    request: {
      method: 'GET',
      url: '/properties/pulse/portfolios/permission/'
    }
  }
});
