import styled from 'styled-components';
import uda from '../../assets/images/logo-poweredby.svg';
import bg from '../../assets/images/bg.jpg';
import udaPositive from '../../assets/images/logo-poweredby-positive.svg';

const StyledAuthBrand = styled.aside`
  overflow: hidden;
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacings.medium2};
  background-image: url(${bg});
  background-size: cover;

  @media only screen and (max-width: 720px) {
    position: inherit;
    min-height: auto;
    height: auto;
    background-image: none;
    padding: ${({ theme }) => theme.spacings.small4} 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media only screen and (max-width: 720px) {
      display: none;
    }
  }
`;

const Container = styled.div`
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media only screen and (max-width: 720px) {
    display: block;
    height: 100%;

    > picture {
      position: absolute;
      top: ${({ theme }) => theme.spacings.medium2};
      left: ${({ theme }) => theme.spacings.medium2};
    }
  }
`;

const Wrapper = styled.div`
  text-align: center;
  color: white;

  img {
    width: 140px;
  }

  @media only screen and (max-width: 720px) {
    display: none;
    height: 100%;
  }
`;

const AuthBrand = () => {
  return (
    <StyledAuthBrand>
      <Container>
        <Wrapper>
          <picture>
            <source srcSet={udaPositive} media="(max-width: 768px), (max-height: 700px)" />
            <img src={uda} alt="uDA" />
          </picture>
        </Wrapper>
      </Container>
    </StyledAuthBrand>
  );
};

export default AuthBrand;
