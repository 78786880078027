import * as Yup from 'yup';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputField, FormGroup, Button, IconArrowLeft } from '@uda/bento-components';
import { useFormik } from 'formik';
import PATHS from '../../routes/paths';
import { useAppContext } from '../../context';

const Heading = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  @media only screen and (max-width: 720px) {
    font-size: 24px;
  }
`;

const StyledAuthResetPasswordForm = styled.div`
  > a,
  > p {
    display: inline-block;
    margin-bottom: ${({ theme }) => theme.spacings.medium2};
  }
`;

const AuthResetPasswordForm = ({ resetPassword }) => {
  const intl = useIntl();

  const { theme } = useAppContext();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'errors.invalid_email' }))
      .required(intl.formatMessage({ id: 'errors.required' }))
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false
    },
    validationSchema: validationSchema,
    onSubmit({ email }, { setSubmitting }) {
      resetPassword({ email });
      setSubmitting(false);
    }
  });

  const { handleSubmit, isSubmitting, errors, touched, handleBlur, handleChange, values, dirty } =
    formik;

  return (
    <StyledAuthResetPasswordForm>
      <Link to={PATHS.signIn}>
        <IconArrowLeft size="large" customColor={theme.color.charcoal800} />
      </Link>
      <Heading>
        <FormattedMessage id="auth.reset_password.title" />
      </Heading>
      <p>
        <FormattedMessage id="auth.reset_password.description" />
      </p>
      <form onSubmit={handleSubmit} className={isSubmitting ? 'loading' : null}>
        <FormGroup>
          <InputField
            autoComplete="email"
            disabled={isSubmitting}
            error={!!errors.email && !!touched.email && !!errors.email}
            help={(errors.email && touched.email && errors.email) || ''}
            label={intl.formatMessage({ id: 'auth.reset_password.email' })}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'auth.reset_password.email_placeholder' })}
            required
            type="email"
            value={values.email}
          />
        </FormGroup>
        <Button
          type="submit"
          variant="primary"
          size="large"
          block
          disabled={isSubmitting || !!errors.email || !dirty}
        >
          <FormattedMessage id="auth.reset_password.reset" />
        </Button>
      </form>
    </StyledAuthResetPasswordForm>
  );
};

export default AuthResetPasswordForm;
