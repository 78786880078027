import {
  apiInternTable,
  getTypologiesFromFilters,
  getSortingFields,
  parseFilters,
  addFavourite,
  deleteFavourite
} from './apiIntern';

import { OPERATION_VALUES } from '../../constants';

export const initialState = {
  favourites: {
    comparables: null,
    pagination: null,
    sort: { sort: null, order: null },
    metadata: {
      sortable_field: null
    }
  },
  sources: null,
  filters: {
    vendorTypeIn: {},
    firstPriceRangeFilter: {}
  },
  isLoadingFavourites: false,
  isLoading: false,
  isLoadingFilters: false,
  isLoadingComparables: false,
  isLoadingDetail: false,
  error: null,
  typologies: [],

  duplicates: {
    comparables: [],
    initialColumnState: [],
    columnState: null
  },
  isLoadingDuplicates: false,
  search: { isLoading: false, suggestions: [] },
  data: {
    filters: {
      listing_type: 1,
      operation: OPERATION_VALUES.sale
    },

    params: {
      sort: { sort: null, order: null },
      page: null
    }
  },

  assets: {
    comparables: null,
    pagination: null,
    metadata: {
      sortable_field: null
    }
  },
  alerts: [],
  isLoadingAlerts: false,
  map: {
    data: {
      coordinates: null,
      typeOfLocation: null,
      distance: null,
      neighbourhood: null,
      comparables: null
    },
    drawPolygon: null,
    bbox: null,
    popup: null,
    isPopupLoading: false,
    isMapLoading: false,
    isUserDrawing: false
  },
  graphics: null,
  isLoadingGraphics: false,
  controlBar: {},
  comparableDetails: null,
  isLoadingByOperation: false,
  isSearchByLocation: false
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'CHANGE_OPERATION':
      return {
        ...state,
        operation: payload
      };
    case 'DELETE_ALERTS':
      const idAlert = Number(payload.request.url.split('/')[4]);
      return {
        ...state,
        alerts: state.alerts.comparables.filter(c => c.id !== idAlert)
      };
    case 'DOWNLOAD_FAVOURITES': {
      return {
        ...state,
        isLoadingDownload: true
      };
    }
    case 'DOWNLOAD_FAVOURITES_SUCCESS': {
      return {
        ...state,
        isLoadingDownload: false
      };
    }
    case 'FETCH_DUPLICATES': {
      return {
        ...state,
        isLoadingDuplicates: true
      };
    }
    case 'FETCH_DUPLICATES_SUCCESS': {
      return {
        ...state,
        isLoadingDuplicates: false,
        duplicates: apiInternTable(payload.data)
      };
    }
    case 'FETCH_DUPLICATES_FAIL': {
      return {
        ...state,
        isLoadingDuplicates: false,
        duplicates: {
          ...state.duplicates
        }
      };
    }
    case 'FETCH_ALERTS': {
      return {
        ...state,
        isLoadingAlerts: true
      };
    }

    case 'FETCH_ALERTS_SUCCESS': {
      return {
        ...state,
        isLoadingAlerts: false,
        alerts: payload.data
      };
    }

    case 'FETCH_ALERTS_FAIL': {
      return {
        ...state,
        isLoadingAlerts: false,
        error: payload
      };
    }
    case 'UPDATE_FAVOURITES_LIST':
      const comparables =
        state?.favourites?.comparables &&
        payload.reduce((acc, favouriteId) => {
          if (acc.find(fav => fav.id === favouriteId)) {
            return deleteFavourite(acc, favouriteId);
          }
          return addFavourite(acc, state?.assets?.comparables, favouriteId);
        }, state?.favourites?.comparables);
      return {
        ...state,
        favourites: {
          ...state.favourites,
          comparables
        }
      };
    case 'DELETE_FAVOURITE':
      const id = Number(payload.request.url.split('/')[4]);

      return {
        ...state,
        isLoadingFavourites: true,
        favourites: {
          ...state.favourites,
          comparables: deleteFavourite(state?.favourites?.comparables, id)
        }
      };
    case 'DELETE_FAVOURITE_SUCCESS': {
      return {
        ...state,
        isLoadingFavourites: false
      };
    }
    case 'DELETE_FAVOURITE_FAIL': {
      const comparable_id = Number(action.error.config.url.split('/')[4]);
      return {
        ...state,
        isLoadingFavourites: false,
        favourites: {
          ...state.favourites,
          comparables: addFavourite(
            state?.favourites?.comparables,
            state?.assets?.comparables,
            comparable_id
          )
        }
      };
    }
    case 'SAVE_FAVOURITE':
      return {
        ...state,
        isLoadingFavourites: true,
        favourites: {
          ...state.favourites,
          comparables: addFavourite(
            state?.favourites?.comparables,
            state?.assets?.comparables,
            payload.request.data.comparable_id
          )
        }
      };
    case 'SAVE_FAVOURITE_SUCCESS': {
      return {
        ...state,
        isLoadingFavourites: false
      };
    }
    case 'SAVE_FAVOURITE_FAIL': {
      const comparable_id = JSON.parse(action.error.config.data).comparable_id;
      return {
        ...state,
        isLoadingFavourites: false,
        favourites: {
          ...state.favourites,
          ...deleteFavourite(state?.favourites?.comparables, comparable_id)
        }
      };
    }
    case 'UPDATE_FAVOURITES_SORT': {
      return {
        ...state,
        favourites: {
          ...state.favourites,
          sort: payload
        }
      };
    }
    case 'FETCH_FAVOURITE': {
      return {
        ...state,
        isLoadingFavourites: true
      };
    }
    case 'FETCH_FAVOURITE_SUCCESS': {
      return {
        ...state,
        isLoadingFavourites: false,
        favourites: {
          ...state.favourites,
          ...payload.data,
          metadata: {
            sortable_field: getSortingFields(payload.data.metadata.sortable_field)
          }
        }
      };
    }
    case 'FETCH_FAVOURITE_FAIL': {
      return {
        ...state,
        isLoadingFavourites: false
      };
    }
    case 'SET_MAP_INFO': {
      return {
        ...state,
        map: { ...state.map, ...payload }
      };
    }
    case 'GET_FILTERS':
      return {
        ...state,
        isLoading: true,
        isLoadingFilters: true
      };
    case 'GET_FILTERS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        sources: {
          ...state.sources,
          values: payload?.data?.sources[0]?.values
        },
        filters: parseFilters(payload.data),
        typologies: getTypologiesFromFilters(payload.data)
      };
    case 'GET_FILTERS_FAIL':
      return {
        ...state,
        isLoading: false,
        filters: null,
        error: action.error.meta.message
      };
    case 'FETCH_SEARCH':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true
        }
      };
    case 'FETCH_SEARCH_SUCCESS':
      return {
        ...state,
        search: {
          isLoading: false,
          suggestions: payload.data
        }
      };
    case 'FETCH_SEARCH_FAIL':
      return {
        ...state,
        search: {
          isLoading: false,
          suggestions: []
        }
      };
    case 'FETCH_CAPTURE_ITEMS':
      return {
        ...state,
        isLoadingComparables: true
      };
    case 'FETCH_CAPTURE_ITEMS_SUCCESS':
      const shouldAppend = payload.config.shouldAppend;
      return {
        ...state,
        isLoadingComparables: false,
        assets: {
          ...state.assets,
          ...payload.data,
          metadata: {
            ...state.assets.metadata,
            sortable_field: getSortingFields(payload.data.metadata.sortable_field)
          },
          comparables: shouldAppend
            ? [...payload.data.comparables, ...state.assets.comparables]
            : payload.data.comparables
        }
      };
    case 'FETCH_CAPTURE_ITEMS_FAIL':
      return {
        ...state,
        isLoadingComparables: false,
        assets: {
          comparables: null,
          pagination: null
        }
      };
    case 'RESET_SEARCH_SUGGESTIONS':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          suggestions: null
        }
      };
    case 'FETCH_COMPARABLES_MAP':
      return {
        ...state,
        map: {
          ...state.map,
          isMapLoading: true
        }
      };
    case 'FETCH_COMPARABLES_MAP_SUCCESS':
      return {
        ...state,
        map: {
          ...state.map,
          data: payload.data,
          isMapLoading: false
        }
      };
    case 'FETCH_COMPARABLES_MAP_FAIL':
      return {
        ...state,
        map: {
          isMapLoading: false
        }
      };
    case 'RESET_COMPARABLES_MAP':
      return {
        ...state,
        map: {
          data: {
            coordinates: null,
            distance: null,
            neighbourhood: null,
            comparables: null
          },
          drawPolygon: null,
          bbox: null,
          popup: null,
          isPopupLoading: false,
          isMapLoading: false,
          isUserDrawing: false
        }
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };

    case 'FETCH_COMPARABLE_DETAILS':
      return {
        ...state,
        isLoadingDetail: true
      };
    case 'FETCH_COMPARABLE_DETAILS_SUCCESS':
      return {
        ...state,
        isLoadingDetail: false,
        comparableDetails: payload.data
      };
    case 'FETCH_COMPARABLE_DETAILS_FAIL':
    case 'RESET_COMPARABLE_DETAILS':
      return {
        ...state,
        isLoadingDetail: false,
        comparableDetails: null
      };
    case 'FETCH_MAP_POPUP_INFO':
      return {
        ...state,
        map: {
          ...state.map,
          isPopupLoading: true
        }
      };
    case 'FETCH_MAP_POPUP_INFO_SUCCESS':
      return {
        ...state,
        map: {
          ...state.map,
          popup: payload.data,
          isPopupLoading: false
        }
      };

    case 'FETCH_GRAPHICS':
      return {
        ...state,
        isLoadingGraphics: true
      };
    case 'FETCH_GRAPHICS_SUCCESS':
      return {
        ...state,
        isLoadingGraphics: false,
        graphics: payload.data
      };
    case 'FETCH_GRAPHICS_FAIL':
      return {
        ...state,
        isLoadingGraphics: false,
        graphics: action.error.meta.message
      };
    case 'LOADING_BY_OPERATION':
      return {
        ...state,
        isLoadingByOperation: payload
      };
      case 'SEARCH_BY_LOCATION':
      return {
        ...state,
        isSearchByLocation: payload
      };
    default:
      return state;
  }
};

export default reducer;
