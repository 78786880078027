const parseComparablesForMap = (comparables, params) => ({
  comparables: {
    properties: {},
    type: 'FeatureCollection',
    fetaures: comparables.map(comp => ({
      id: comp.id,
      geometry: {
        coordinates: comp.location.coordinates,
        type: 'Point'
      },
      properties: {
        source: comp.source_id,
        url: comp.url,
        is_accurate_address: comp.is_accurate_address
      },
      type: 'Feature'
    }))
  },
  coordinates: [params.lon, params.lat],
  distance: params.distance
});

const parseComparablesForList = (comparables, params, pagination) => ({
  applied_filters: params,
  comparables: comparables.map(comp => ({
    price: comp.last_price,
    geometry: comp.geometry
  })),
  metadata: {
    sortable_filed: [
      'distance',
      'date_out',
      'date_main',
      'date_in',
      'last_price',
      'last_pm2',
      'area',
      'uda_avm'
    ]
  },
  pagination: {
    ...pagination,
    page: pagination.page_current,
    page_size: pagination.num_results
  }
});

export const mapComparablesList = response => {
  const { results, query_params, pagination } = response;
  return {
    list: parseComparablesForList(results, query_params, pagination),
    map: parseComparablesForMap(results, query_params)
  };
};
