import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@uda/bento-components';
import AuthFooter from './AuthFooter';
import AuthBrand from './AuthBrand';
import AuthBrandFotocasa from './AuthBrandFotocasa';
import LoadingLayout from '../../components/LoadingLayout';
import NotificationTranslate from '../../components/Notifications/NotificationTranslate';
import { useBreakpoint } from '../../utils/hooks/useBreakpoint';
import PATHS from '../../routes/paths';
import { closeNotification } from '../../actions/notification';
import { STATUS_CODE } from '../../constants.js';
import { setLanguage } from '../../actions/user';
import { isLanguageSupported } from '../../translations';
import { getQueryParam } from '../../utils';
import { history } from '../../App';
import FeatureFlag from '../../components/FeatureFlag';
import useFlag from '../../utils/hooks/useFlag';
import AuthLogo from './AuthLogo';
import AuthTipsFotocasa from './AuthTipsFotocasa';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: auto;
  height: 100%;
  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const WrapperNotification = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.spacings.medium1};
  right: ${({ theme }) => theme.spacings.medium1};
  z-index: 3;
  transition: all 300ms ease-in-out;
  > aside {
    width: auto;
  }

  @media only screen and (max-width: 1023px) {
    top: 10px;
    right: 10px;
    left: 10px;
    bottom: unset;
    right: unset;
    width: calc(100% - 20px);
    position: fixed;
  }
`;

const WrapperChildren = styled.div`
  padding-top: ${({ theme }) => theme.spacings.medium2};
  margin-bottom: 90px;
  width: 80%;
  max-width: 440px;

  @media only screen and (max-width: 1023px) {
    padding-top: 100px;
  }
`;

const StyledGrid = styled(props => <Grid {...props} />)`
  height: 100vh;
  overflow: hidden;

  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
  }
`;

const transformErrors = error => {
  if (error.code === STATUS_CODE.UNAUTHORIZED && error.action === 'SIGN_IN_FAIL') {
    return {
      id: `auth.sign_in.fail`,
      values: {
        link: (
          <Link to={PATHS.resetPassword}>
            <FormattedMessage id={'auth.sign_in.reset_password'} />
          </Link>
        )
      }
    };
  }

  return { id: error.message };
};

const AuthLayout = ({ children }) => {
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification);
  const isLoading = useSelector(state => state.auth.token && !state.auth.isAuthenticated);
  const location = useLocation();
  let lang = getQueryParam(location, 'lang', null);

  useEffect(() => {
    if (lang && isLanguageSupported(lang)) {
      const query = new URLSearchParams(location.search);
      query.delete('lang');

      dispatch(setLanguage(lang));
      history.push(query.toString().length ? `${location.pathname}?${query}` : location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { isOpen, variant } = notification;
    isOpen && variant === 'error' && dispatch(closeNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleClose = () => {
    dispatch(closeNotification());
  };

  const { isOpen } = notification;
  const columns = breakpoint === 'm' || breakpoint === 's' ? '1fr 0% ' : '60% 40%';
  const isMobileOrTablet = breakpoint === 's' || breakpoint === 'm';
  const isFotocasaAside = useFlag('fotocasa_aside');

  return isLoading ? (
    <LoadingLayout full />
  ) : (
    <StyledGrid columns={columns} isFotocasaAside={isFotocasaAside}>
      <Wrapper>
        {isOpen ? (
          <WrapperNotification hasNotify={isOpen}>
            <NotificationTranslate
              transformErrors={transformErrors}
              notification={notification}
              onClose={handleClose}
            />
          </WrapperNotification>
        ) : null}

        <AuthLogo breakpoint={breakpoint} />

        <WrapperChildren>{children}</WrapperChildren>
        <AuthFooter />
      </Wrapper>
      {!isMobileOrTablet ? (
        <FeatureFlag name="fotocasa_aside">
          {isHidden => (isHidden ? <AuthBrandFotocasa /> : <AuthBrand />)}
        </FeatureFlag>
      ) : (
        <FeatureFlag name="fotocasa_aside">
          {isHidden => (isHidden ? <AuthTipsFotocasa /> : null)}
        </FeatureFlag>
      )}
    </StyledGrid>
  );
};

export default AuthLayout;
