import { Skeleton } from '@uda/bento-components';
import styled from 'styled-components';

const PlanWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.charcoal200};
  border: 1px solid ${({ theme }) => theme.color.charcoal300};
  border-radius: 4px;
  padding: 24px 32px;
  margin-bottom: 14px;

  @media only screen and (max-width: 768px) {
    padding: ${({ theme }) => theme.spacings.small3};
  }
`;

const StyledPlanHeading = styled(props => <Skeleton {...props} />)`
  margin-bottom: ${({ theme }) => theme.spacings.small3};
`;

const PlanContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 8px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column-reverse;
  }
`;
const PriceContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: 0;
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  > i:first-child {
    margin-right: 10px;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    align-self: flex-start;
    padding-bottom: ${({ theme }) => theme.spacings.small4};
  }
`;

const InvoicingData = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 28px;
  > i {
    margin-left: 24px;
  }
`;

const SettingsPlanSkeleton = (isMobile = true) => {
  return (
    <>
      <PlanWrapper>
        <StyledPlanHeading variant="text" width="70px" height="21px" />
        <PlanContainer>
          <StyledList>
            <StyledListItem>
              <Skeleton variant="circular" width="18px" />
              <Skeleton width="90%" variant="text" height="14px" />
            </StyledListItem>
            <StyledListItem>
              <Skeleton variant="circular" width="18px" />
              <Skeleton width="70%" variant="text" height="14px" />
            </StyledListItem>
            <StyledListItem>
              <Skeleton variant="circular" width="18px" />
              <Skeleton width="30%" variant="text" height="14px" />
            </StyledListItem>
          </StyledList>
          <PriceWrapper>
            <PriceContainer>
              <Skeleton width="80px" variant="text" height="36px" />
            </PriceContainer>
          </PriceWrapper>
        </PlanContainer>
      </PlanWrapper>

      <InvoicingData>
        <Skeleton width="140px" variant="text" height="18px" />
        <Skeleton width="180px" variant="text" height="18px" />
      </InvoicingData>
    </>
  );
};

export default SettingsPlanSkeleton;
