import FileSaver from 'file-saver';
import { formatSortParams, parseSortParams } from '../utils';
import qs from 'query-string';
import { history } from '../../App';
import PATHS from '../../routes/paths';
import { initialState } from '../reducers/capture';
import { openNotification } from '../../actions/notification';
import _ from 'lodash';

const ranges = [
  'area__range',
  'property_type__in',
  'construction_type__in',
  'features__in',
  'first_price__range',
  'source_id__in',
  'bbox',
  'n_baths__in',
  'n_rooms__in'
];
const string_ranges = ['vendor_type__in'];
const numbers = [
  'area',
  'lat',
  'lon',
  'listing_type',
  'geog__dwithin',
  'distance_on_foot',
  'distance_by_car',
  'operation'
];
const coordinates = ['competitors_geom__contained_by'];
const strings = ['search_value'];

export const getFiltersFromLocation = (intl, dataFilters) => (dispatch, getState) => {
  if (!window.location.href.includes('?')) dispatch(fetchComparablesItems(intl, dataFilters));

  const { data } = getState().capture;
  const { filters = {}, sort = {} } = getPropertiesFromLocation();
  const newData = {
    ...data,
    params: { ...data.params, sort: { ...data.params.sort, ...sort } },
    filters: { ...data.filters, ...filters }
  };

  if (!_.isEqual(data, newData)) {
    dispatch(
      setMapInfo({
        isUserDrawing: false,
        drawPolygon: filters?.competitors_geom__contained_by || null,
        bbox: filters?.bbox || null
      })
    );

    dispatch(updateData(newData));
  }
};

export const transformFilters = filters => {
  let result = {};
  if (!filters) return result;
  Object.entries(filters).forEach(([key, value]) => {
    if (!value && value !== 0) return;
    if (key.includes('has_') || key.includes('is_')) {
      result[key] = value[0] === 'true' || value[0] === true ? true : false;
    } else if (numbers.includes(key) && value) {
      result[key] = Number(value);
    } else if (value && value.length && ranges.includes(key)) {
      result[key] = value.map(item => item);
    } else if (value && value.length && string_ranges.includes(key)) {
      result[key] = value.map(item => String(item));
    } else if (
      Array.isArray(value) &&
      value.length &&
      key !== 'floor__in' &&
      key !== 'competitors_geom__contained_by'
    ) {
      result[key] = value.join(',');
    } else {
      result[key] = value;
    }
  });
  return result;
};

export const getFilters = () => ({
  type: 'GET_FILTERS',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p-capture/pulse/filters/`
    }
  }
});

export const fetchSearchSuggestions = ({ query }) => ({
  type: 'FETCH_SEARCH',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p-capture/pulse/autocomplete/`,
      params: { query }
    }
  }
});

export const updateAlert = (id, frequency) => ({
  type: 'UPDATE_ALERT',
  payload: {
    request: {
      method: 'PATCH',
      url: `/b4p-capture/pulse/alerts/${id}/`,
      data: {
        frequency
      }
    }
  }
});

export const deleteAlert = (id, intl) => async dispatch => {
  const response = await dispatch({
    type: 'DELETE_ALERT',
    payload: {
      request: {
        method: 'DELETE',
        url: `/b4p-capture/pulse/alerts/${id}/`
      }
    }
  });

  if (response.type.includes('_FAIL')) {
    dispatch(
      openNotification('error', {
        message: intl.formatMessage({
          id: `capture.notification.alerts.error_${response.error.meta.code}`
        })
      })
    );
  } else {
    dispatch(fetchAlerts(intl));
    dispatch(
      openNotification('success', {
        message: intl.formatMessage({
          id: `capture.notification.alerts.success_delete`
        })
      })
    );
  }
};

export const fetchAlerts = intl => async dispatch => {
  const response = await dispatch({
    type: 'FETCH_ALERTS',
    payload: {
      request: {
        method: 'GET',
        url: `/b4p-capture/pulse/alerts/`
      }
    }
  });

  if (response.type.includes('_FAIL')) {
    dispatch(
      openNotification('error', {
        message: intl.formatMessage({
          id: `capture.notification.alerts.error_${response.error.meta.code}`
        })
      })
    );
  }
};

export const resetSuggestions = () => ({
  type: 'RESET_SEARCH_SUGGESTIONS',
  payload: null
});

export const saveAlert =
  ({ name, filters }, intl) =>
  async dispatch => {
    const front_url = window.location.search.replace('?', '');
    const formattedFilters = transformFilters(filters);

    delete formattedFilters['search_value'];

    const response = await dispatch({
      type: 'SAVE_ALERT',
      payload: {
        request: {
          method: 'POST',
          url: `/b4p-capture/pulse/alerts/`,
          data: {
            name,
            filters: formattedFilters,
            front_url
          }
        }
      }
    });

    if (response.type.includes('_FAIL')) {
      dispatch(
        openNotification('error', {
          message: intl.formatMessage({
            id: `capture.notification.home.error_${response.error.meta.code}`
          })
        })
      );
    } else {
      dispatch(
        openNotification('success', {
          message: intl.formatMessage({
            id: `capture.notification.home.success_alert`
          })
        })
      );
    }
  };

export const fetchComparablesItems =
  (intl, { filters, params, shouldAppend = false }) =>
  dispatch => {
    const { bbox, ...rest } = filters;

    const data = transformFilters(rest);

    dispatch({
      type: 'FETCH_CAPTURE_ITEMS',
      payload: {
        request: {
          method: 'POST',
          url: `/b4p-capture/pulse/comparables/`,
          data,
          params: {
            ...params,
            sort: formatSortParams(params?.sort)
          },
          shouldAppend
        }
      }
    });
  };

export const getPropertiesFromLocation = () => {
  const params = qs.parse(history.location.search);
  const result = {
    filters: {},
    map: {},
    sort: {}
  };

  for (let [key, val] of Object.entries(params)) {
    if (key === 'sort') {
      result.sort =
        formatSortParams(initialState.data.params.sort) === val ? {} : parseSortParams(val);
    }

    const match = key.match(/^filter::(.*)$/);
    if (match && match.length) {
      if (numbers.includes(match[1])) {
        result.filters[match[1]] = Number(val);
      } else if (strings.includes(match[1])) {
        result.filters[match[1]] = val;
      } else if (ranges.includes(match[1]) && val.length) {
        result.filters[match[1]] = val.split(',').map(item => Number(item));
      } else if (string_ranges.includes(match[1]) && val.length) {
        result.filters[match[1]] = val.split(',').map(item => String(item));
      } else if (coordinates.includes(match[1]) && val.length) {
        const str = val.match(/\(.*?\)/g);
        result.filters[match[1]] = [
          str.map(e => {
            const [lat, lng] = e.replace(/[{()}]/g, '').split(',');
            return [Number(lat), Number(lng)];
          })
        ];
      } else if (Array.isArray(val)) {
        result.filters[match[1]] = val.map(item => item);
      } else {
        result.filters[match[1]] = val.split(',').map(value => value);
      }
    }
  }

  return Object.keys(result).length ? result : {};
};

export const transformFiltersToParams = payload => {
  let flatFilters = {};
  let flatParams = {};
  const { filters, params } = payload;
  const data = transformFilters(filters);
  const formatFilterParam = (property, value) => {
    if (value && value.length && [...ranges, ...string_ranges].includes(property)) {
      return value.join(',');
    }
    if (value && value.length && coordinates.includes(property)) {
      return `(${value[0].join('),(')})`;
    }
    return value;
  };

  if (params) {
    const { page, sort } = params;
    flatParams = {
      ...(page && { page }),
      ...(sort?.sort && { sort: formatSortParams(sort) })
    };
  }

  Object.keys(data).forEach(property => {
    flatFilters[`filter::${property}`] = formatFilterParam(property, data[property]);
  });

  const queryParams = {
    ...flatParams,
    ...flatFilters
  };

  history.replace({
    pathname: PATHS.capture,
    search: qs.stringify(queryParams, { encode: false })
  });
};

export const onChangeTipology = payload => async dispatch => {
  dispatch(onUpdateData({ filters: { ...payload } }));
};

export const onUpdateData = payload => async (dispatch, getState) => {
  const { data } = getState().capture;
  const dataToBeUpdated = { ...data, ...payload };
  transformFiltersToParams(dataToBeUpdated);
  return dispatch(updateData(dataToBeUpdated));
};

export const updateData = payload => ({
  type: 'UPDATE_DATA',
  payload
});

export const setMapInfo = payload => ({
  type: 'SET_MAP_INFO',
  payload
});

export const updateFavouritesSort = payload => ({
  type: 'UPDATE_FAVOURITES_SORT',
  payload
});

export const saveFavourite = comparable_id => ({
  type: 'SAVE_FAVOURITE',
  payload: {
    request: {
      method: 'POST',
      url: `/b4p-capture/pulse/favourites/`,
      data: {
        comparable_id
      }
    }
  }
});

export const downloadFavourites = params => async dispatch => {
  const response = await dispatch({
    type: 'DOWNLOAD_FAVOURITES',
    payload: {
      request: {
        method: 'GET',
        url: `/b4p-capture/pulse/comparables/download`,
        params,
        responseType: 'blob'
      }
    }
  });

  if (response.type === 'DOWNLOAD_FAVOURITES_SUCCESS') {
    FileSaver.saveAs(
      response.payload.data,
      `comparable_${params.lat}${params.lon}_smart-valuation.xlsx`
    );
  }
};

export const updateFavouriteList = (favourites = []) => ({
  type: 'UPDATE_FAVOURITES_LIST',
  payload: favourites
});

export const fetchFavourites = params => ({
  type: 'FETCH_FAVOURITE',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p-capture/pulse/favourites/`,
      params: {
        sort: params?.sort && formatSortParams(params.sort)
      }
    }
  }
});

export const deleteFavourite = comparable_id => ({
  type: 'DELETE_FAVOURITE',
  payload: {
    request: {
      method: 'DELETE',
      url: `/b4p-capture/pulse/favourites/${comparable_id}/`
    }
  }
});
export const fetchComparableDetails = id => dispatch => {
  dispatch(fetchDetailHeader(id));
  dispatch(fetchDuplicates(id));
  dispatch(fetchGraphics(id));
};

export const fetchDuplicates = id_uda => ({
  type: 'FETCH_DUPLICATES',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p-capture/pulse/history/${id_uda}`
    }
  }
});
export const fetchDetailHeader = id_uda => ({
  type: 'FETCH_COMPARABLE_DETAILS',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p-capture/pulse/comparables/${id_uda}/`
    }
  }
});

export const fetchGraphics = id_uda => ({
  type: 'FETCH_GRAPHICS',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p-capture/pulse/comparables/${id_uda}/graphics/`
    }
  }
});

export const fetchGeoReverseAddress = (lat, lon, country_codes) => async dispatch => {
  if (!lat || !lon || !country_codes) return '';

  const response = await dispatch({
    type: 'FETCH_REPORT_TASK_STATUS',
    payload: {
      request: {
        method: 'GET',
        url: `/b4p-capture/pulse/geocoding/reverse`,
        params: { lat, lon, countries: country_codes.join(',') }
      }
    }
  });
  return response?.payload?.data?.complete_address || '';
};

export const setLoadingFromOperation = payload => ({
  type: 'LOADING_BY_OPERATION',
  payload
});

export const setIsSearchByLocation = payload => ({
  type: 'SEARCH_BY_LOCATION',
  payload
});
