import styled from 'styled-components';

const AuthFooterStyle = styled.li`
  > button {
    padding: ${({ theme }) => theme.spacings.small2} ${({ theme }) => theme.spacings.small3};
    background-color: white;
    border-radius: ${({ theme }) => theme.shapes.borderRadiusSmall};
    ${({ theme }) => theme.texts.p2}
    transition: all 300ms ease-in-out;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: ${({ theme }) => theme.color.primary100};
      font-family: ${({ theme }) => theme.global.fontFamilyBold};
      color: ${({ theme }) => theme.color.primary500};
    }

    &::after {
      content: '';
      margin-left: 1rem;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      display: inline-block;
      background-color: ${({ theme }) => theme.color.primary500};
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    &.selected {
      background-color: ${({ theme }) => theme.color.primary100};
      border-radius: ${({ theme }) => theme.shapes.borderRadiusSmall};
      font-family: ${({ theme }) => theme.global.fontFamilyBold};
      color: ${({ theme }) => theme.color.primary500};
      display: flex;
      align-items: center;
      &::after {
        opacity: 1;
      }
    }
  }
`;

export default AuthFooterStyle;
