import { ButtonLink, Drawer, Avatar, List, NavListItem } from '@uda/bento-components';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Notifications from './Notifications/Notifications';
import StandardNavigationActions from './StandardNavigationActions';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { updateMobileMenu } from '../actions/layout';
import { gaEventClick } from '../utils';

const StyledMobileMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 11px;
`;

const StyledDrawer = styled(props => <Drawer {...props} />)`
  z-index: 11;
  background-color: ${({ theme }) => theme.color.charcoal200};
  overscroll-behavior: contain;
  height: calc(100% - ${({ offsetTop }) => `${offsetTop}px`});
  margin-top: ${({ offsetTop }) => `${offsetTop}px`};
  header {
    min-height: 57px;
  }
`;
const StyledUserMenu = styled.div`
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  min-width: 190px;
  align-items: center;
  border-radius: 4px;
`;

const StyledLinkList = styled(props => <List {...props} />)`
  > div {
    :last-child {
      border-bottom: none;
    }
  }
`;
const StyledListItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.charcoal300};
  margin: 0;
  :hover {
    background-color: transparent;
    cursor: auto;
  }
`;
const StyledButtonLink = styled(props => <ButtonLink {...props} />)`
  &:hover {
    background-color: transparent;
  }
`;
const StyledNavItem = styled(NavListItem)`
  padding: 12px 0 12px 0;
  > div {
    padding: 8px 0 !important;
    > a {
      font-size: ${({ theme }) => theme.components.textLinkFontSizeLarge};
      color: ${({ theme }) => theme.components.textLinkSecondaryColor};
      font-weight: ${props => props.theme.global.fontWeightMedium};

      &.active {
        color: ${({ theme }) => theme.components.textLinkPrimaryColor};
      }
    }
  }
`;
const StyledListContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 0 36px;
`;
const StyledUserName = styled.p`
  margin: 0 20px 0 12px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.charcoal600};
`;
const StandardActionsContainer = styled.div`
  padding: 16px 20px;
  width: 100%;
`;

const StyledProductList = styled.div`
  padding: 16px 20px;
  width: 100%;
  background-color: white;
`;

const avatarHolder = (name, surname, email) => (
  <StyledUserMenu>
    {name || surname ? (
      <Avatar size="medium">
        {name} {surname}
      </Avatar>
    ) : null}
    <StyledUserName>{name || surname ? `${name} ${surname}` : email}</StyledUserName>
  </StyledUserMenu>
);
const MobileMenu = ({ notifications, sectionLinks, children }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { name, surname, email } = useSelector(state => state.user.data);
  const { isNavigationOpen } = useSelector(state => state.layout);
  const handleClickEvent = category => gaEventClick(category, 'Pulse Navigation');

  const handleClick = () => {
    dispatch(updateMobileMenu(false));
  };
  return (
    <StyledMobileMenu>
      <Notifications notifications={notifications} separatorLocation="right" />
      <StyledButtonLink
        isOpen={isNavigationOpen}
        onClick={() => {
          dispatch(updateMobileMenu(!isNavigationOpen));
        }}
      >
        {isNavigationOpen ? (
          <FormattedMessage id="navbar.close" />
        ) : (
          <FormattedMessage id="navbar.menu" />
        )}
      </StyledButtonLink>
      <StyledDrawer
        header={avatarHolder(name, surname, email)}
        headerColor={'white'}
        closeButton={false}
        open={isNavigationOpen}
        position="right"
        offsetTop="48px"
        width="100vw"
        onClick={() => handleClick()}
      >
        <StyledProductList>{children}</StyledProductList>

        <StyledListContainer>
          <StyledLinkList>
            {sectionLinks.map(({ href, intlKey, disabled }, index) => {
              return (
                <StyledListItem key={index.toString()}>
                  {disabled ? (
                    <StyledNavItem className="disabled">
                      <FormattedMessage id={intlKey} />
                    </StyledNavItem>
                  ) : (
                    <StyledNavItem>
                      <NavLink
                        style={{ width: '100%' }}
                        exact
                        to={href}
                        activeClassName="active"
                        onClick={() => handleClickEvent(intl.formatMessage({ id: intlKey }))}
                      >
                        <FormattedMessage id={intlKey} />
                      </NavLink>
                    </StyledNavItem>
                  )}
                </StyledListItem>
              );
            })}
          </StyledLinkList>
        </StyledListContainer>

        <StandardActionsContainer>
          <StandardNavigationActions onClick={() => handleClick()} />
        </StandardActionsContainer>
      </StyledDrawer>
    </StyledMobileMenu>
  );
};

export default MobileMenu;
