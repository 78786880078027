import { useDispatch } from 'react-redux';
import AuthSignInForm from '../forms/AuthSignInForm';
import { handleGoogleSuccess, signIn } from '../actions/auth';
import { closeNotification, openNotification } from '../../actions/notification';

const AuthSignInContainer = () => {
  const dispatch = useDispatch();

  const handleSignIn = ({ email, password, remember }) => {
    return dispatch(signIn({ email, password, remember }));
  };

  const handleGoogleError = ({ error, details }) => {
    dispatch(openNotification('error', { message: details || `auth.sso.${error}` }));
  };

  const onGoogleSuccess = success => dispatch(handleGoogleSuccess(success));

  const handleCloseNotification = () => {
    dispatch(closeNotification());
  };

  return (
    <AuthSignInForm
      signIn={handleSignIn}
      handleGoogleSuccess={onGoogleSuccess}
      handleGoogleError={handleGoogleError}
      closeNotification={handleCloseNotification}
    />
  );
};

export default AuthSignInContainer;
