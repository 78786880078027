const BORROWER_TYPES = ['SME', 'Individuals', 'No Data'];

export const apiInternHeader = data => {
  switch (data.id) {
    case 'assets_count':
      data.label = 'Units';
      data.primary.format = 'number';
      break;
    case 'ap_portfolio_value':
      data.label = 'AV';
      data.primary.format = 'price';
      data.primary.currency = 'EUR';
      data.date.format = 'date';
      break;
    case 're_portfolio_value':
      data.label = 'RE';
      data.primary.format = 'price';
      data.primary.currency = 'EUR';
      data.date.format = 'date';
      break;
    case 'total_portfolio_value':
      data.label = 'Price';
      data.primary.format = 'price';
      data.primary.currency = 'EUR';
      break;
    case 'contracts':
      data.label = 'Contracts';
      data.primary.format = 'number';
      data.secondary.format = 'number';
      break;
    case 'ob':
      data.label = 'OB';
      data.primary.format = 'price';
      data.primary.currency = 'EUR';
      data.secondary.format = 'percent';
      break;
    case 'year':
      data.primary.format = 'string';
      break;
    default:
      break;
  }
  return data;
};

export const apiInternGrid = data => {
  switch (data.id) {
    case 're_portfolio_value':
      data.label = 'RE Value';
      data.data.currency = 'EUR';
      data.data.format = 'price';
      break;
    case 'ap_portfolio_value':
      data.label = 'AV';
      data.data.currency = 'EUR';
      data.data.format = 'price';
      break;
    case 'total_portfolio_value':
      data.label = 'Price';
      data.data.currency = 'EUR';
      data.data.format = 'price';
      break;
    case 'ob':
      data.label = 'OB';
      data.data.currency = 'EUR';
      data.data.format = 'price';
      break;
    case 'total_av':
      data.label = 'AV';
      data.data.currency = 'EUR';
      data.data.format = 'price';
      break;
    case 'total_contract_debt':
      data.label = 'Contracts';
      data.data.format = 'number';
      break;
    case 'assets_count':
      data.label = 'Units';
      data.data.format = 'number';
      break;
    default:
      break;
  }
  return data;
};

export const apiInternGridSegments = data => {
  switch (data.id) {
    case 'av_location':
      data.label = 'AV By Location';
      data.graph.label = 'AV By Segment';
      break;
    case 'av_use':
      data.label = 'Av By Use';
      data.graph.label = 'AV By Subuse';
      break;
    case 'location':
      data.label = 'AV By Location';
      break;
    case 'borrower':
      data.label = 'DEBTOR TYPE';
      data.values = data.values.map((item, index) => {
        item.category = BORROWER_TYPES[index];
        return item;
      });
      break;
    default:
      break;
  }
  return data;
};

export const apiInternGridValues = data => {
  switch (data.id) {
    case 'ap_value':
      data.label = 'Appraisal Value';
      data.discount.format = 'percent';
      data.data.currency = 'EUR';
      data.data.format = 'price';

      for (const o in data.meta) {
        const metas = data.meta;
        metas.sort((a, b) => (a.order > b.order ? 1 : -1));
        switch (metas[o].id) {
          case 'ap_value_date':
            metas[o].data.format = 'date';
            break;
          case 'ap_price_unit':
            metas[o].data.format = 'price';
            metas[o].data.currency = 'EUR';
            metas[o].data.unit = true;
            break;
          default:
            break;
        }
      }

      break;
    case 're_value':
      data.label = 'Real Estate Value';
      data.data.currency = 'EUR';
      data.data.format = 'price';

      for (const o in data.meta) {
        const metas = data.meta;
        metas.sort((a, b) => (a.order > b.order ? 1 : -1));
        switch (metas[o].id) {
          case 're_value_date':
            metas[o].data.format = 'date';
            break;
          case 're_price_unit':
            metas[o].data.format = 'price';
            metas[o].data.currency = 'EUR';
            metas[o].data.unit = true;
            break;
          default:
            break;
        }
      }
      break;
    case 'units':
      data.label = 'Units';
      data.data.format = 'number';

      for (const o in data.meta) {
        const metas = data.meta;
        metas.sort((a, b) => (a.order > b.order ? 1 : -1));
        switch (metas[o].id) {
          case 'area':
            metas[o].data.format = 'area';
            metas[o].data.unit = true;
            break;
          default:
            break;
        }
      }
      break;
    case 'loan_value':
      data.label = 'Loan to Value';
      data.data.format = 'percent';
      for (const o in data.meta) {
        const metas = data.meta;
        metas.sort((a, b) => (a.order > b.order ? 1 : -1));
        switch (metas[o].id) {
          case 'loan_meta':
            metas[o].data.format = 'percent_lien';
            break;
          default:
            break;
        }
      }
      break;
    case 'contracts':
      data.label = 'Contracts';
      data.data.format = 'number';
      for (const o in data.meta) {
        const metas = data.meta;
        metas.sort((a, b) => (a.order > b.order ? 1 : -1));
        switch (metas[o].id) {
          case 'contracts_price':
            metas[o].data.format = 'price';
            metas[o].data.currency = 'EUR';
            metas[o].data.unit = true;
            break;
          case 'origination_date':
            metas[o].data.format = 'date';
            break;
          case 'default_date':
            metas[o].data.format = 'date';
            break;
          default:
            break;
        }
      }
      break;
    case 'colaterals':
      data.label = 'Collaterals';
      data.data.format = 'number';

      for (const o in data.meta) {
        const metas = data.meta;
        metas.sort((a, b) => (a.order > b.order ? 1 : -1));

        switch (metas[o].id) {
          case 'units_contract':
            metas[o].data.format = 'unit';
            metas[o].data.contract = true;
            break;
          case 'colaterals_price_unit':
            metas[o].data.format = 'price';
            metas[o].data.currency = 'EUR';
            metas[o].data.unit = true;
            break;
          default:
            break;
        }
      }
      break;
    default:
      break;
  }
  return data;
};

export const apiInternGridDrivers = data => {
  switch (data.id) {
    case 'liquidity':
      data.label = 'Liquidity';
      data.data.format = 'string';
      break;
    case 'cycle':
      data.label = 'Cycle';
      data.data.format = 'string';
      break;
    case 'risk':
      data.label = 'Risk';
      data.data.format = 'string';
      break;
    case 'affordability':
      data.label = 'Affordability';
      data.data.format = 'percent';
      break;
    default:
      break;
  }
  return data;
};

export const apiInternCellLabel = id => {
  switch (id) {
    case 'develop_id':
      return 'Develop ID';
    case 'location':
      return 'Registry';
    case 'built_year':
      return 'Built year';
    case 'review_type':
      return 'Review type';
    case 'valuation_type':
      return 'Valuation type';
    case 'valuer_name':
      return 'Valuer';
    case 'last_update':
      return 'Last update';
    case 'valuation_status':
      return 'Status';
    case 're':
      return 'RE value';
    case 'avm':
      return 'AVM';
    case 'av':
      return 'AV';
    case 'segment':
      return 'AV by segment';
    case 'typology':
    case 'av_use':
      return 'AV by use';
    case 'av_subuse':
      return 'AV by subuse';
    case 'affordability':
      return 'Afford.';
    case 'liquidity':
      return 'Liquid.';
    case 'transaction_date':
      return 'Transact.';
    case 'portfolio_type':
      return 'Type';
    case 'av_location':
      return 'AV by location';
    case 'av_main':
      return 'AV';
    case 're_main':
      return 'RE value';
    case 'units_main':
    case 'units_dashboard':
      return 'Units';
    case 'colaterals':
      return 'Collat.';
    case 'default_date':
      return 'Def. date';
    case 'origin_date':
      return 'Ori. date';
    case 'ltv_lien_1':
      return 'LTV lien 1';
    case 'year':
      return 'Date';
    case 'asset_id':
      return 'Asset ID';
    case 'asset_segment':
      return 'Segment';
    case 'asset_typology':
    case 'develop_typology':
      return 'Typology';
    case 'asset_av':
      return 'AV';
    case 'asset_re_value':
      return 'RE value';
    case 'asset_avm':
      return 'AVM';
    case 'full_address':
      return 'Full address';
    case 'usable_area':
      return 'Usable area';
    case 'cadastral_area':
      return 'Cadastral area';
    case 'proindividual':
      return 'Proindiv.';
    case 'ownership':
      return 'Ownersh.';
    case 'monthly_rate':
      return 'Monthly rate';
    case 'id_client_asset':
      return 'ID client asset';
    case 'id_client_contract':
      return 'ID client cont.';
    case 'residence':
      return '1st/2nd resi';
    case 'discount':
      return 'Disc.';
    case 'av_dashboard':
      return 'AV';
    case 're_value_dashboard':
      return 'RE value';
    case 'collaterals_dashboard':
      return 'COL.';
    case 'ob_dashboard':
      return 'OB';
    case 'ob_contract_dashboard':
      return 'OB/contract';
    case 'ltv_dashboard':
      return 'LTV';
    case 'client_contract_id':
      return 'Client contract ID';
    case 'contract_status':
      return 'Status';
    case 'contract_insolvency':
      return 'Insolvency';
    case 'contract_judicial':
      return 'Judicial';
    case 'av_lien_1':
      return 'AV LIEN 1';
    case 'contract_debtor':
      return 'Debtor';
    case 'col_lien_1':
      return 'Col. LIEN 1';
    case 'contract_type':
      return 'Type';
    default:
      return id.charAt(0).toUpperCase() + id.slice(1);
  }
};

export const apiInternTreeTableRows = rows =>
  rows.map(row => {
    Object.keys(row).forEach(k => {
      switch (k) {
        case 'collaterals_dashboard':
        case 'ob_contract_dashboard':
          row[k].primary.format = 'number';
          break;
        case 'units_dashboard':
          row[k].primary.format = 'number';
          break;
        case 'discount':
          row[k].primary.format = 'percent';
          break;
        case 'av_dashboard':
        case 're_value_dashboard':
        case 'ob_dashboard':
          row[k].primary.format = 'price';
          row[k].primary.currency = 'EUR';
          row[k].secondary[0].format = 'percent';
          break;
        case 'ltv_dashboard':
          row[k].primary.format = 'percent';
          break;
        default:
          break;
      }
    });
    return row;
  });

export const apiInternTableRows = rows =>
  rows.map(row => {
    Object.keys(row).forEach(k => {
      switch (k) {
        case 'name':
        case 'liquidity':
        case 'cycle':
        case 'risk':
        case 'seller':
        case 'client':
        case 'asset_id':
        case 'province':
        case 'asset_segment':
        case 'region':
        case 'id_client_asset':
        case 'id_client_contract':
        case 'residence':
        case 'latitude':
        case 'longitude':
        case 'contract_status':
        case 'contract_debtor':
        case 'contract_type':
        case 'valuation_status':
        case 'valuation_type':
        case 'review_type':
          row[k].primary.format = 'string';
          break;
        case 'locality':
        case 'location':
        case 'asset_typology':
        case 'develop_typology':
        case 'full_address':
        case 'valuer_name':
          row[k].primary.format = 'string';
          row[k].secondary[0].format = 'string';
          break;
        case 'area':
        case 'usable_area':
        case 'cadastral_area':
          row[k].primary.format = 'area';
          break;
        case 'av_location':
        case 'av_use':
        case 'segment':
        case 'borrower':
        case 'typology':
        case 'av_subuse':
          row[k].primary.format = 'string';
          row[k].secondary[0].format = 'percent';
          break;
        case 'units':
        case 'units_dashboard':
        case 'collaterals_dashboard':
        case 'ob_contract_dashboard':
        case 'col_lien_1':
          row[k].primary.format = 'number';
          break;
        case 'av':
        case 're':
        case 'av_main':
        case 'asset_avm':
          row[k].primary.format = 'price';
          row[k].primary.currency = 'EUR';
          row[k].secondary[0].format = 'date';
          break;
        case 'price':
        case 'ob':
        case 'monthly_rate':
        case 'upb':
        case 'av_lien_1':
          row[k].primary.format = 'price';
          row[k].primary.currency = 'EUR';
          break;
        case 'year':
          row[k].primary.format = 'year';
          break;
        case 'transaction_date':
        case 'built_year':
          row[k].primary.format = 'year';
          break;
        case 'default_date':
        case 'origin_date':
          row[k].primary.format = 'date';
          break;
        case 'affordability':
        case 'ltv_dashboard':
        case 'discount':
        case 'insolvency':
        case 'judicial':
        case 'ltv':
        case 'ltv_lien_1':
          row[k].primary.format = 'percent';
          break;
        case 're_main':
        case 'asset_av':
        case 'asset_re_value':
          row[k].primary.format = 'price';
          row[k].primary.currency = 'EUR';
          row[k].secondary[0].format = 'date';
          row[k].secondary[1].format = 'percent';
          break;
        case 'units_main':
          row[k].primary.format = 'number';
          row[k].secondary[0].format = 'area';
          row[k].secondary[0].unit = true;
          break;
        case 'avm':
          row[k].primary.format = 'price';
          row[k].primary.currency = 'EUR';
          row[k].secondary[0].format = 'date';
          row[k].secondary[1].format = 'percent';
          row[k].secondary[1].delta = true;
          break;
        case 'accuracy':
          row[k].secondary[0].format = 'string';
          break;
        case 'contracts':
          row[k].primary.format = 'number';
          row[k].secondary[0].format = 'price';
          row[k].secondary[0].currency = 'EUR';
          row[k].secondary[0].contract = true;
          break;
        case 'last_update':
          row[k].primary.format = 'date';
          row[k].primary.type = 'full';
          row[k].secondary = [{ format: 'date', type: 'time', value: row[k].primary.value }];
          break;
        case 'colaterals':
          row[k].primary.format = 'number';
          row[k].secondary[0].format = 'number';
          row[k].secondary[0].contract = true;
          break;
        case 'vpo':
        case 'proindividual':
        case 'ownership':
        case 'rented':
        case 'squatted':
        case 'contract_insolvency':
        case 'contract_judicial':
          row[k].primary.format = 'boolean';
          break;
        case 'av_dashboard':
        case 're_value_dashboard':
        case 'ob_dashboard':
          row[k].primary.format = 'price';
          row[k].primary.currency = 'EUR';
          row[k].secondary[0].format = 'percent';
          break;
        case 'develop_id':
          row[k].primary.format = 'string';
          row[k].secondary[0].format = 'number';
          row[k].secondary[0].units = true;
          break;
        case 'client_contract_id':
          row[k].primary.format = 'string';
          row[k].secondary[0].format = 'number';
          row[k].secondary[0].collaterals = true;
          break;
        default:
          break;
      }
    });
    return row;
  });

export const apiInternSetSortType = id => {
  switch (id) {
    case 'liquidity':
    case 'transaction_date':
    case 'portfolio_type':
    case 'av_location':
    case 'cycle':
    case 'risk':
    case 'av_use':
    case 'colaterals':
    case 'asset_segment':
    case 'asset_typology':
    case 'develop_typology':
    case 'full_address':
    case 'residence':
    case 'name':
    case 'seller':
    case 'client':
    case 'segment':
    case 'borrower':
    case 'typology':
    case 'province':
    case 'locality':
    case 'region':
    case 'vpo':
    case 'proindividual':
    case 'ownership':
    case 'rented':
    case 'squatted':
    case 'contract_status':
    case 'contract_debtor':
    case 'contract_type':
    case 'contract_insolvency':
    case 'contract_judicial':
    case 'av_subuse':
    case 'valuation_status':
    case 'location':
    case 'review_type':
    case 'valuation_type':
    case 'valuer_name':
      return 'string';
    case 'year':
    case 'default_date':
    case 'origin_date':
    case 'built_year':
    case 'last_update':
      return 'date';
    default:
      return 'number';
  }
};

export const apiInternAssetCardsList = data =>
  data
    .map(item => {
      switch (item.id) {
        case 'assessment_price':
          item.primary.format = 'price';
          item.primary.currency = 'EUR';
          item.secondary.format = 'price';
          item.secondary.currency = 'EUR';
          item.secondary.area = true;
          break;
        case 'discount':
          item.primary.format = 'percent';
          item.primary.delta = true;
          item.primary.discount = true;
          break;
        case 'asset_avm':
          item.primary.format = 'price';
          item.primary.currency = 'EUR';
          break;
        case 'ef_monthly_rent':
          item.primary.format = 'price';
          item.primary.currency = 'EUR';
          break;
        case 'affordability':
          item.data.format = 'percent';
          item.data.delta = true;
          break;
        case 'floor':
          item.order = 400;
          item.data.format = 'ordinal';
          item.data.floor = true;
          break;
        case 'area':
          item.data.format = 'area';
          item.order = 300;
          break;
        case 'bedrooms':
          item.data.format = 'number';
          item.data.bedrooms = true;
          item.order = 100;
          break;
        case 'bathrooms':
          item.data.format = 'number';
          item.data.bathrooms = true;
          item.order = 200;
          break;
        default:
          break;
      }
      return item;
    })
    .sort((a, b) => (a.order > b.order ? 1 : -1));
