import { useSelector } from 'react-redux';

const useFlag = key => {
  try {
    return useSelector(state => state.flags.includes(key));
  } catch (e) {
    console.error(e);
  }
};

export default useFlag;
