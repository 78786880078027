import { stringHasNumber } from '../../utils/';

export const apiInternList = cards =>
  cards.map(card => {
    card.main = card.main
      .map(item => {
        switch (item.id) {
          case 'asking':
            item.label = 'opportunities.list.card.asking_price';
            item.primary.format = 'price';
            item.primary.currency = 'EUR';
            item.secondary.format = 'price';
            item.secondary.currency = 'EUR';
            item.secondary.area = true;
            break;
          case 'estimated':
            item.label = 'opportunities.list.card.estimated_price';
            item.primary.format = 'price';
            item.primary.currency = 'EUR';
            item.order = 100;
            item.data = item.primary;
            card.kpis = [...card.kpis, item];
            break;
          case 'price_gap':
            item.label = null;
            item.primary.format = 'percent';
            item.primary.delta = true;
            item.primary.price_gap = true;
            break;
          default:
            break;
        }
        return item;
      })
      .filter(({ id }) => id !== 'estimated');

    card.gross_yield = card.kpis.find(item => {
      if (item.id === 'gross_yield') {
        item.label = 'opportunities.list.card.gross_yield';
        item.data.format = 'percent';
        return item;
      }
      return null;
    });

    card.kpis = card.kpis
      .map(item => {
        switch (item.id) {
          case 'rent':
            item.label = 'opportunities.list.card.rent_per_month';
            item.data.format = 'price';
            item.data.currency = 'EUR';
            item.order = 200;
            break;
          case 'appreciation':
            item.label = 'opportunities.list.card.price_appreciation';
            item.data.format = 'percent';
            item.data.delta = true;
            item.data.yoy = true;
            item.order = 300;
            break;
          case 'time_market':
            item.label = 'opportunities.list.card.time_to_market';
            item.data.format = 'weeks';
            break;
          case 'estimated_mortgage':
            item.label = 'opportunities.list.card.estimated_mortgage';
            item.data.format = 'price';
            item.data.currency = 'EUR';
            item.data.decimals = 0;
            item.order = 400;
            break;
          default:
            break;
        }
        return item;
      })
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .filter(({ id }) => id !== 'time_market')
      .filter(({ id }) => id !== 'gross_yield')
      .filter(({ id }) => id !== 'appreciation')
      .filter(({ id }) => id !== 'estimated');

    card.properties = card.properties
      .map(item => {
        switch (item.id) {
          case 'floor':
            item.order = 400;
            item.data.format = 'ordinal';
            item.data.floor = true;
            break;
          case 'area':
            item.data.format = 'area';
            item.order = 300;
            break;
          case 'bedrooms':
            item.data.format = 'number';
            item.data.bedrooms = true;
            item.order = 100;
            break;
          case 'bathrooms':
            item.data.format = 'number';
            item.data.bathrooms = true;
            item.order = 200;
            break;
          default:
            break;
        }
        return item;
      })
      .sort((a, b) => (a.order > b.order ? 1 : -1));

    // imagenes mas pequeñas para fotocasa y evitar cargar la original
    card.source_images = card.source_images.map(item => {
      if (~item.indexOf('inmofactory.com') || ~item.indexOf('fotocasa.es')) {
        item = item.replace(
          'https://d.inmofactory.com/',
          'https://static.inmofactory.com/images/inmofactory/documents/'
        );
        item = item.replace('?rule=original', '');
        item = item.replace('https://d.fotocasa.es/', 'https://static.fotocasa.es/images/');

        item = `${item}?rule=web_412x257_jpg_70`;
      }
      return item;
    });

    return card;
  });

export const apiInternDetail = detail => {
  detail.address.district = stringHasNumber(detail.address.district)
    ? null
    : detail.address.district;

  detail.card.main = detail.card.main.map(item => {
    switch (item.id) {
      case 'asking':
        item.primary.data.format = 'price';
        item.primary.data.currency = 'EUR';
        item.secondary = item.secondary.map(second => {
          switch (second.id) {
            case 'price_sqm':
              second.data.format = 'price';
              second.data.currency = 'EUR';
              second.data.area = true;
              break;
            case 'gap':
              second.data.label = null;
              second.data.format = 'percent';
              second.data.delta = true;
              second.data.price_gap = true;
              break;
            default:
              break;
          }
          return second;
        });
        break;
      case 'gross_yield':
        item.primary.data.format = 'percent';
        break;
      default:
        break;
    }
    return item;
  });

  detail.header.features = detail.header.features
    .map(item => {
      switch (item.id) {
        case 'size':
          item.data.format = 'area';
          item.order = 300;
          break;
        case 'floor':
          item.data.format = 'ordinal';
          item.data.floor = true;
          item.order = 400;
          break;
        case 'bathrooms':
          item.data.format = 'number';
          item.data.bathrooms = true;
          item.order = 200;
          break;
        case 'bedrooms':
          item.data.format = 'number';
          item.data.bedrooms = true;
          item.order = 100;
          break;
        default:
          break;
      }
      return item;
    })
    .sort((a, b) => (a.order > b.order ? 1 : -1));

  detail.header.source_images = detail.header.source_images.map(item => {
    if (~item.indexOf('inmofactory.com') || ~item.indexOf('fotocasa.es')) {
      item = item.replace(
        'https://d.inmofactory.com/',
        'https://static.inmofactory.com/images/inmofactory/documents/'
      );
      item = item.replace('?rule=original', '');
      item = item.replace('https://d.fotocasa.es/', 'https://static.fotocasa.es/images/');

      item = `${item}?rule=web_948x542_jpg_70`;
    }
    return item;
  });

  return detail;
};

export const apiInternMapPopup = popup => {
  popup.address.district = stringHasNumber(popup.address.district) ? null : popup.address.district;

  popup.gross_yield = popup.main.find(item => {
    if (item.id === 'gross_yield') {
      item.label = 'opportunities.list.card.gross_yield';
      item.primary.data.format = 'percent';
      return item;
    }
    return null;
  });

  popup.main = popup.main
    .map(item => {
      switch (item.id) {
        case 'asking':
          item.label = 'opportunities.list.card.asking_price';
          item.primary.data.format = 'price';
          item.primary.data.currency = 'EUR';
          item.secondary = item.secondary
            .map(second => {
              switch (second.id) {
                case 'price_sqm':
                  second.data.format = 'price';
                  second.data.currency = 'EUR';
                  second.data.area = true;
                  break;
                case 'gap':
                  second.data.label = null;
                  second.data.format = 'percent';
                  second.data.delta = true;
                  second.data.price_gap = true;
                  break;
                default:
                  break;
              }
              return second;
            })
            .filter(({ id }) => id !== 'gap');
          break;
        case 'gross_yield':
          item.label = 'opportunities.list.card.gross_yield';
          item.primary.data.format = 'percent';
          break;
        default:
          break;
      }
      return item;
    })
    .filter(({ id }) => id !== 'gross_yield');

  popup.properties = popup.properties
    .map(item => {
      switch (item.id) {
        case 'floor':
          item.data.format = 'ordinal';
          item.data.floor = true;
          item.order = 400;
          break;
        case 'area':
          item.data.format = 'area';
          item.order = 300;
          break;
        case 'bedrooms':
          item.data.format = 'number';
          item.data.bedrooms = true;
          item.order = 100;
          break;
        case 'bathrooms':
          item.data.format = 'number';
          item.data.bathrooms = true;
          item.order = 200;
          break;
        default:
          break;
      }
      return item;
    })
    .sort((a, b) => (a.order > b.order ? 1 : -1));

  return popup;
};

export const apiInternDetailTab = tab => {
  if (tab.sociodemographics) {
    tab.sociodemographics.kpis =
      tab.sociodemographics.kpis &&
      tab.sociodemographics.kpis.sort((a, b) => (a.order > b.order ? 1 : -1));
  }

  tab.main_drivers =
    tab.main_drivers &&
    tab.main_drivers.map(item => {
      switch (item.id) {
        case 'liquidity':
          item.data.format = 'string';
          item.values = ['/', 'D', 'C', 'B', 'A'];
          break;
        case 'risk':
          item.data.format = 'string';
          item.values = ['D', 'C', 'CCC', 'B', 'BBB', 'A', 'AAA'];
          break;
        case 'cycle':
          item.data.format = 'string';
          item.values = ['Crisis', 'Falling', 'Peak', 'Rising'];
          break;
        case 'affordability':
          item.data.format = 'percent';
          break;
        default:
          break;
      }
      return item;
    });

  // tab.graphs =
  //   tab.graphs &&
  //   tab.graphs.map(item => {
  //     switch (item.id) {
  //       case 'price_dynamics':
  //         // item.label = 'Price dynamics';
  //         // item.primary.data.format = 'price';
  //         // item.primary.data.area = true;
  //         // item.primary.data.currency = 'EUR';
  //         // item.secondary.data.format = 'percent';
  //         // item.secondary.data.delta = true;
  //         // item.secondary.data.yoy = true;
  //         break;
  //       case 'gross_yield':
  //         // item.label = 'Gross yield';
  //         // item.primary.data.format = 'percent';
  //         break;
  //       default:
  //         break;
  //     }
  //     return item;
  //   });

  if (tab.realestate) {
    tab.realestate.kpis =
      tab.realestate.kpis &&
      tab.realestate.kpis
        .map(item => {
          switch (item.id) {
            case 'dwellings_transactions':
            case 'dwellings_total':
            case 'listed_dwellings':
              item.values = item.values.map(i => {
                i.data.format = 'number';
                return i;
              });
              break;
            case 'absorptions':
              item.values = item.values.map(i => {
                i.data.format = 'percent';
                return i;
              });
              break;
            case 'time_to_market':
              item.values = item.values.map(i => {
                i.data.format = 'weeks';
                return i;
              });
              break;
            case 'construction_year':
              item.values = item.values.map(i => {
                i.data.format = 'year';
                return i;
              });
              break;

            default:
              break;
          }
          return item;
        })
        .sort((a, b) => (a.order > b.order ? 1 : -1));
  }

  return tab;
};
