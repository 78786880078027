import styled from "styled-components";

export const ExtensionLoginContainer = styled.div`
    display: flex;
    height: 80vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
`

export const ExtensionLoginContent = styled.div`
    display: flex;
    padding: auto 0;
    flex-direction: column;
    row-gap: 30px;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

export const ExtensionLoginMainText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: 37.5px;
`