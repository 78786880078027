import { getDefaultPOI } from '../utils';
import { getTimeLeftFormatted } from '../utils/getTimeformatted';

export const initialState = {
  isUserFromUda: true,
  data: {
    name: '',
    surname: '',
    avatar: '',
    email: '',
    language: '',
    products: [],
    portfolios: [],
    company: null,
    plans: null,
    payment_issues: null,
    tel: '',
    opportunities: { boundaries: [] },
    restrictions: {
      asset: {
        duration: { end_date: null }
      }
    },
    billing_info: {
      company: '',
      cif: '',
      country: '',
      city: '',
      address: '',
      postal_code: ''
    }
  },
  isEnterpriseFormSendedSuccessfully: null,
  isSettingsOpen: false,
  defaultLocation: {
    address: '',
    lat: 0,
    lon: 0
  }
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'FETCH_ENTERPRISE_EMAIL_SUCCESS':
    case 'FETCH_ENTERPRISE_EMAIL_FAIL':
      return {
        ...state,
        isEnterpriseFormSendedSuccessfully: true
      };
    case 'UPDATE_ENTERPRISE_SENT':
      return {
        ...state,
        isEnterpriseFormSendedSuccessfully: null
      };
    case 'UPDATE_USER':
    case 'UPDATE_USER_FAIL':
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };
    case 'FETCH_SMARTVALUATION_RESTRICTIONS_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          restrictions: {
            ...state.data.restrictions,
            ...transformSmartValuationRestrictions(
              payload.data,
              state.data.language,
              state.data.plans
            )
          }
        }
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          ...JSON.parse(payload.config.data)
        },
        isSettingsOpen: false
      };

    case 'FETCH_USER_SUCCESS':
      return {
        ...state,
        isUserFromUda: payload.data.email.includes('uda'),
        data: {
          ...state.data,
          ...payload.data,
          products: payload.data.products
        },
        defaultLocation: getDefaultPOI(payload.data.country_codes)
      };
    case 'RESET_USER':
      return {
        ...initialState,
        data: {
          ...initialState.data,
          language: state.data.language
        }
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        data: {
          ...state.data,
          language: payload
        }
      };

    default:
      return state;
  }
};

export default reducer;

const transformSmartValuationRestrictions = (data, lang, userPlans) => {
  if (data) {
    Object.keys(data).map((key, i) => {
      return (data[key] = {
        ...data[key],
        isFreePlan: userPlans[key].includes('free'),
        duration: {
          ...data[key].duration,
          endDate: new Intl.DateTimeFormat(lang).format(data[key].duration.end_date),
          timeLeft: getTimeLeftFormatted(data[key].duration.end_date, lang)
        }
      });
    });
  } else {
    return data;
  }

  return data;
};
