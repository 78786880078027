import { PORTFOLIO_TYPES } from '../../constants.js';
import * as CONSTANTS from '../constants.js';
/* istanbul ignore file */

const InitialStateWidgetTable = {
  isLoading: false,
  initialColumnState: null,
  columnState: null,
  sorting: {
    sort: 'av',
    order: 'desc'
  },
  columns: [],
  rows: []
};

export const initialAnalyticsWidgets = (portfolio_type, variant) => {
  const data = {
    [PORTFOLIO_TYPES.REO]: {
      value: {
        region: {
          view: 'table',
          table: {
            ...InitialStateWidgetTable
          }
        },
        use: {
          view: 'table',
          table: {
            ...InitialStateWidgetTable
          }
        },
        segment: {
          view: 'table',
          table: {
            ...InitialStateWidgetTable
          }
        }
      }
    },
    [PORTFOLIO_TYPES.NPL]: {
      value: {
        location: {
          view: 'table',
          table: {
            ...InitialStateWidgetTable
          }
        },
        borrower: {
          view: 'table',
          table: {
            ...InitialStateWidgetTable,
            sorting: {
              sort: 'ob',
              order: 'asc'
            }
          }
        },
        legal: {
          view: 'table',
          table: {
            ...InitialStateWidgetTable,
            sorting: {
              sort: 'ob',
              order: 'asc'
            }
          }
        }
      }
    }
  };

  return data[portfolio_type][variant];
};

export const initialAnalyticsFilters = (portfolio_type, variant) => {
  const data = {
    [PORTFOLIO_TYPES.REO]: {
      value: {
        distributions: ['av', 'rev'],
        boundary_types: ['province', 'region'],
        filters: {
          distribution: CONSTANTS.DEFEAULT_DISTRIBUTION,
          boundary_type: CONSTANTS.DEFAULT_BOUNDARY_TYPE
        }
      }
    },
    [PORTFOLIO_TYPES.NPL]: {
      value: {
        distributions: ['av'],
        boundary_types: ['province', 'region'],
        filters: {
          distribution: CONSTANTS.DEFEAULT_DISTRIBUTION,
          boundary_type: CONSTANTS.DEFAULT_BOUNDARY_TYPE
        }
      }
    }
  };
  return data[portfolio_type][variant];
};
