import { Modal, Button } from '@uda/bento-components';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledModal = styled(props => <Modal {...props} />)`
  @media only screen and (max-width: 600px) {
    &.is-mobile {
      position: absolute;
      bottom: 0;
    }
  }
`;

const Content = styled.div`
  padding: 0 16px;
`;
const StyledHeader = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.charcoal700};
`;

const StyledText = styled.p`
  color: ${({ theme }) => theme.color.charcoal600};
  margin: 0 0 24px 0;
`;

const StyledContent = styled.footer`
  background-color: white;
  padding-top: ${({ theme }) => theme.spacings.small3};
  padding-bottom: ${({ theme }) => theme.spacings.medium1};
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: ${({ theme }) => theme.spacings.medium2};
  width: 100%;
  > button {
    margin-left: ${({ theme }) => theme.spacings.small2};
    width: 120px;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    right: 0;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    padding-top: 12px;
    padding-bottom: 12px;

    > button {
      margin: 0 ${({ theme }) => theme.spacings.small1};
      width: 140px;
      height: 48px;
    }
  }
`;

const SettingsCancelProvince = ({
  isOpen,
  onSubmit,
  provinceIdToRemove,
  onClose,
  isMobile = false,
  isLoading
}) => {
  const { label, id } = provinceIdToRemove;
  return (
    <StyledModal
      className={'is-mobile'}
      style={{ maxWidth: '480px' }}
      onClose={onClose}
      enableClickOutside={false}
      header={
        <StyledHeader>
          <FormattedMessage id="settings.plan.removing" />
        </StyledHeader>
      }
      closable={isMobile ? false : true}
      footer={
        <StyledContent>
          <Button variant="secondary" block onClick={onClose}>
            <FormattedMessage id="settings.plan.cancel_removal" />
          </Button>
          <Button
            type="submit"
            onClick={() => onSubmit(id)}
            variant="dangerPrimary"
            disabled={isLoading}
            loading={isLoading}
          >
            <FormattedMessage id="settings.plan.confirm" />
          </Button>
        </StyledContent>
      }
      isOpen={isOpen}
      zIndex={2000}
    >
      <Content>
        <StyledText>
          <FormattedMessage
            id="settings.plan.removing_description"
            values={{ province: <strong>{label}</strong> }}
          />
        </StyledText>
        <StyledText></StyledText>
      </Content>
    </StyledModal>
  );
};

export default SettingsCancelProvince;
