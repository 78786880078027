export const builtLandValueGetter =
  field =>
  ({ data }) => {
    const main = data[field].edit.value;

    const built = data.built_area.primary.value;
    const land = data.land_area.primary.value;

    const primary = main && built ? main / built : null;
    const secondary = main && land ? main / land : null;

    return {
      primary: {
        value: primary,
        format: 'price',
        currency: 'EUR',
        area: true
      },
      secondary: [
        {
          value: secondary,
          format: 'price',
          currency: 'EUR',
          area: true
        }
      ]
    };
  };

export const grossYieldValueGetter = ({ data }) => {
  const rent_value = data.rent_value.edit.value;
  const market_value = data.market_value.edit.value;

  const value = rent_value && market_value ? ((rent_value * 12) / market_value) * 100 : null;

  return {
    primary: {
      value: value,
      format: 'percent',
      decimals: 1
    }
  };
};
