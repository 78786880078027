export const getCadastralSuggestions = data => {
  const cadastralData = {
    cadastralData: {},
    portals: []
  };
  if (!data) return cadastralData;
  cadastralData.portals = Object.keys(data);
  cadastralData.cadastralData = data;

  return cadastralData;
};
