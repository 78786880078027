import { useState } from 'react';
import { TextLink, Modal, Button, Checkbox } from '@uda/bento-components';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { setCookie } from '../utils/storage';
import * as CONSTANTS from '../constants.js';
import PATHS from '../routes/paths';

const StyledModal = styled(props => <Modal {...props} />)`
  max-width: ${({ shouldBeSmall }) => (shouldBeSmall ? '432px' : '720px')};
  > div {
    margin-top: 24px;
    height: auto;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 60%;
  }
  @media only screen and (max-width: 600px) {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 100%;
  }
`;

const StyledModalContainer = styled.div`
  padding: ${({ theme }) =>
    `0px ${theme.spacings.small4} ${theme.spacings.small4} ${theme.spacings.small4}`};

  > div > p {
    ${({ theme }) => theme.texts.p1};
    color: ${({ theme }) => theme.color.charcoal600};
    > a {
      ${({ theme }) => theme.texts.p1b};
      color: ${({ theme }) => theme.color.primary500};
    }
  }
  > label {
    margin-top: ${({ theme }) => theme.spacings.small4};
    margin-bottom: ${({ theme }) => theme.spacings.small2};

    > div:last-child {
      display: block;
      margin-left: ${({ theme }) => theme.spacings.small2};
      margin-top: ${({ theme }) => theme.spacings.small1};
    }
  }
`;

const StyledText = styled.p`
  ${({ theme }) => theme.texts.p2};
  color: ${({ theme }) => theme.color.charcoal600};
  margin-bottom: ${({ theme }) => theme.spacings.small3};
  ul {
    margin-left: 18px;
    li {
      margin: 10px 0;
    }
  }
`;

const StyledFooter = styled.div`
  background-color: white;
  padding-bottom: ${({ theme }) => theme.spacings.medium1};
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: ${({ theme }) => theme.spacings.medium2};
  width: 100%;
  > button {
    margin-left: ${({ theme }) => theme.spacings.small2};
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    right: 0;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    padding-top: 12px;
    padding-bottom: 12px;

    > button {
      margin: 0 ${({ theme }) => theme.spacings.small1};
      height: 48px;
    }
  }
`;

const StyledModalContent = styled.div`
  padding: 0 25px;

  ul {
    margin-left: 18px;
  }
`;

const CookiesModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [showCustomCofing, setShowCustomConfig] = useState(false);
  const [cookiesDetail, setCookiesDetail] = useState({
    register: true,
    analytics: false,
    ads: false
  });

  const handleChangeCookiesDetail = (id, value) =>
    setCookiesDetail({ ...cookiesDetail, [id]: value });

  const handlePrimaryCTA = () => {
    setCookie(CONSTANTS.REGISTER_COOKIE_KEY, showCustomCofing ? cookiesDetail.register : true, {
      expires: 365
    });
    setCookie(CONSTANTS.ANALYTICS_COOKIE_KEY, showCustomCofing ? cookiesDetail.analytics : true, {
      expires: 365
    });
    setCookie(CONSTANTS.ADS_COOKIE_KEY, showCustomCofing ? cookiesDetail.ads : true, {
      expires: 365
    });
    setIsModalOpen(false);
    window.location.reload();
  };

  const intl = useIntl();
  return (
    <StyledModal
      shouldBeSmall={!showCustomCofing}
      header={
        <h3>
          <FormattedMessage id="cookies.cookies_modal.title" />
        </h3>
      }
      isOpen={isModalOpen}
      closable={false}
      onClose={() => {}}
      footer={
        <StyledFooter>
          <Button variant="secondary" onClick={() => setShowCustomConfig(!showCustomCofing)}>
            <FormattedMessage id={`cookies.${showCustomCofing ? 'back' : 'configure'}`} />
          </Button>
          <Button onClick={handlePrimaryCTA}>
            <FormattedMessage id={`cookies.${showCustomCofing ? 'save' : 'save_close'}`} />
          </Button>
        </StyledFooter>
      }
    >
      <StyledModalContainer>
        {!showCustomCofing ? (
          <div>
            <p>
              <FormattedMessage
                id="cookies.description"
                values={{
                  cookies_configure_cta: (
                    <TextLink
                      onClick={() => setShowCustomConfig(true)}
                      title={intl.formatMessage({ id: 'cookies.cookies_configure_cta' })}
                      className="primary"
                    >
                      <FormattedMessage id="cookies.cookies_configure_cta" />
                    </TextLink>
                  )
                }}
              />
            </p>
          </div>
        ) : (
          <>
            <p>
              <FormattedMessage id="cookies.cookies_modal.h1" />
            </p>
            <StyledText>
              <FormattedMessage id="cookies.cookies_modal.p1_1" />
            </StyledText>
            <StyledText>
              <FormattedMessage id="cookies.cookies_modal.p1_2" />
            </StyledText>
            <StyledText>
              <FormattedMessage id="cookies.cookies_modal.p1_3" />
            </StyledText>
            <Checkbox
              checked={true}
              disabled
              name="cookie_register"
              size="small"
              label={intl.formatMessage({ id: 'cookies.cookies_modal.h2' })}
              onChange={(v, i) => handleChangeCookiesDetail('register', i)}
              value={cookiesDetail.register}
            />
            <StyledModalContent>
              <StyledText>
                <FormattedMessage id="cookies.cookies_modal.p2_1" />
              </StyledText>
              <ul>
                <li>
                  <StyledText>
                    <FormattedMessage id="cookies.cookies_modal.p2_2" />
                  </StyledText>
                </li>
                <li>
                  <StyledText>
                    <FormattedMessage id="cookies.cookies_modal.p2_3" />
                  </StyledText>
                </li>
              </ul>
            </StyledModalContent>
            <Checkbox
              checked={cookiesDetail.analytics ? 'checked' : false}
              name="cookie_analytics"
              size="small"
              label={intl.formatMessage({ id: 'cookies.cookies_modal.h3' })}
              onChange={(v, i) => handleChangeCookiesDetail('analytics', i)}
              value={cookiesDetail.analytics}
            />
            <StyledModalContent>
              <StyledText>
                <FormattedMessage id="cookies.cookies_modal.p3_1" />
              </StyledText>
              <ul>
                <li>
                  <StyledText>
                    <FormattedMessage id="cookies.cookies_modal.p3_2" />
                  </StyledText>
                </li>
                <li>
                  <StyledText>
                    <FormattedMessage id="cookies.cookies_modal.p3_3" />
                  </StyledText>
                </li>
                <li>
                  <StyledText>
                    <FormattedMessage id="cookies.cookies_modal.p3_4" />
                  </StyledText>
                </li>
              </ul>
            </StyledModalContent>
            <Checkbox
              checked={cookiesDetail.ads ? 'checked' : false}
              name="cookie_ads"
              size="small"
              label={intl.formatMessage({ id: 'cookies.cookies_modal.h4' })}
              onChange={(v, i) => handleChangeCookiesDetail('ads', i)}
              value={cookiesDetail.ads}
            />
            <StyledModalContent>
              <StyledText>
                <FormattedMessage id="cookies.cookies_modal.p4_1" />
              </StyledText>
            </StyledModalContent>

            <p>
              <FormattedMessage id="cookies.cookies_modal.p4_2" />
            </p>
            <StyledText>
              <FormattedMessage id="cookies.cookies_modal.p4_3" />
            </StyledText>
            <p>
              <FormattedMessage id="cookies.cookies_modal.p4_4" />
            </p>
            <StyledText>
              <FormattedMessage id="cookies.cookies_modal.p4_5" />
            </StyledText>
            <p>
              <FormattedMessage id="cookies.cookies_modal.p4_6" />
            </p>
            <StyledText>
              <FormattedMessage id="cookies.cookies_modal.p4_7" />
              <ul>
                <li>
                  <p>
                    <TextLink
                      href="https://support.google.com/chrome/answer/95647?hl=en"
                      title="Google Chrome Cookies"
                      className="primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Google Chrome
                    </TextLink>
                  </p>
                </li>
                <li>
                  <p>
                    <TextLink
                      href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                      title="Explorer Cookies"
                      className="primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Explorer
                    </TextLink>
                  </p>
                </li>
                <li>
                  <p>
                    <TextLink
                      href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                      title="Firefox Cookies"
                      className="primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Firefox
                    </TextLink>
                  </p>
                </li>
                <li>
                  <p>
                    <TextLink
                      href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                      title="Safari Cookies"
                      className="primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Safari
                    </TextLink>
                  </p>
                </li>
              </ul>
            </StyledText>
            <p>
              <FormattedMessage id="cookies.cookies_modal.p4_8" />
            </p>
            <StyledText>
              <FormattedMessage id="cookies.cookies_modal.p4_9" />
            </StyledText>
            <p>
              <FormattedMessage id="cookies.cookies_modal.p4_10" />
            </p>
            <StyledText>
              <FormattedMessage id="cookies.cookies_modal.p4_11" />
            </StyledText>
            <p>
              <FormattedMessage id="cookies.cookies_modal.p4_12" />
            </p>
            <StyledText>
              <FormattedMessage
                id="cookies.cookies_modal.p4_13"
                values={{
                  policy_url: (
                    <TextLink
                      href={PATHS.privacyPolicy}
                      title={intl.formatMessage({ id: 'paths.privacy' })}
                      className="primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage id="paths.privacy" />
                    </TextLink>
                  )
                }}
              />
            </StyledText>
          </>
        )}
      </StyledModalContainer>
    </StyledModal>
  );
};

export default CookiesModal;
