export const initialState = {
  isLoading: null,
  opportunities: {
    boundaries: null,
    assets: {
      max: null,
      visited: null,
      remaining: null
    }
  }
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'FETCH_RESTRICTIONS':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_RESTRICTIONS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        ...payload.data
      };
    case 'SET_OPPORTUNITIES_ASSETS_RESTRICTIONS':
      return {
        ...state,
        opportunities: {
          ...state.opportunities,
          assets: {
            ...state.opportunities.assets,
            ...payload
          }
        }
      };
    default:
      return state;
  }
};

export default reducer;
