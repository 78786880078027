import PATHS from '../routes/paths';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AppLayout from '../components/AppLayout';
import { setRedirect } from '../Auth/actions/auth';
import { history } from '../App';
import { checkPrivateProduct } from '../actions/redirections';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const [productSlug] = history.location.pathname.split('/').slice(1);

  if (!isAuthenticated) {
    dispatch(setRedirect(location.pathname + location.search));
  }

  if (productSlug && productSlug !== 'sign-out') {
    dispatch(checkPrivateProduct(productSlug));
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <AppLayout>
            <Component {...props} />
          </AppLayout>
        ) : (
          <Redirect to={PATHS.signIn} />
        )
      }
    />
  );
};

export default PrivateRoute;
