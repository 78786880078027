import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Notification } from '@uda/bento-components';
import { useDispatch } from 'react-redux';
import { closeNotification } from '../../actions/notification';

const NotificationTranslate = ({ transformErrors, notification, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { error, isOpen, duration, ...notificationProps } = notification;
  const intlData = error ? transformErrors(error) : { id: notification.message };
  const messageIntlExists = !!intl.messages[intlData.id];

  useEffect(() => {
    if (isOpen === true && duration) {
      setTimeout(() => {
        dispatch(closeNotification());
      }, duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Notification {...notificationProps} onClose={onClose}>
      {messageIntlExists ? <FormattedMessage {...intlData} /> : intlData.id}
    </Notification>
  );
};

export default NotificationTranslate;
