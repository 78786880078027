import { getUrlParams } from './getUrlParams';

export const getCadastralReferenceFromUrl = () => {
  const urlParams = getUrlParams();

  const allowedReference = ['reference'];

  const cadastralReference = Object.entries(urlParams).reduce((obj, [param, value]) => {
    if (!allowedReference.includes(param)) return obj;
    return { ...obj, [param]: param === 'reference' && value };
  }, {});

  if (!allowedReference.every(param => Object.keys(cadastralReference).includes(param))) return {};
  return cadastralReference;
};
