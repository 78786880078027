import React from 'react';
import { ExtensionLoginContainer, ExtensionLoginContent, ExtensionLoginMainText } from './extensionLogin.styles';
import logo from '../../assets/images/logo-extension.svg';

function ExtensionLogin() {
  return (
        <ExtensionLoginContainer>
            <ExtensionLoginContent>
                <img src={logo} />
                <ExtensionLoginMainText>
                    ¡Has accedido con éxito! <br></br>
                    Ahora para seguir en la Extension, puedes cerrar esta ventana y volver al portal que estabas.
                </ExtensionLoginMainText>
            </ExtensionLoginContent>
        </ExtensionLoginContainer>
  )
}

export default ExtensionLogin