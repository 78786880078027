import * as CONSTANTS from '../constants.js';
import {
  IconGlobal,
  IconGrid,
  IconMapPin,
  IconNewHome,
  IconPhoto,
  List,
  NavListItem
} from '@uda/bento-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { goToProduct } from '../actions/redirections';
import { useBreakpoint } from '../../src/utils/hooks/useBreakpoint';

const NavigationMenuProducts = ({ productKey, size = 'large' }) => {
  const dispatch = useDispatch();
  const { products } = useSelector(state => state.user.data);
  const handleClickProduct = productId => dispatch(goToProduct(productId));
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 's';

  const menu = [
    {
      id: 'smart-valuation',
      leftContent: <IconMapPin />,
      children: <FormattedMessage id={`products.asset.asset`} />
    },
    {
      id: 'capture',
      leftContent: <IconPhoto />,
      children: <FormattedMessage id={`products.capture.capture`} />
    },
    {
      id: 'opportunities',
      leftContent: <IconNewHome />,
      children: <FormattedMessage id={`products.opportunities.opportunities`} />
    },
    {
      id: 'portfolio',
      leftContent: <IconGrid />,
      children: <FormattedMessage id={`products.portfolio.portfolio`} />
    },
    {
      id: 'market',
      leftContent: <IconGlobal />,
      children: <FormattedMessage id={`products.market.market`} />
    },
    {
      id: 'asset',
      leftContent: <IconMapPin />,
      children: (
        <>
          Original <FormattedMessage id={`products.asset.asset`} />
        </>
      )
    }
  ];
  const menuMobile = [
    {
      id: 'smart-valuation',
      leftContent: <IconMapPin />,
      children: <FormattedMessage id={`products.asset.asset`} />
    },
    {
      id: 'capture',
      leftContent: <IconPhoto />,
      children: <FormattedMessage id={`products.capture.capture`} />
    },
    {
      id: 'opportunities',
      leftContent: <IconNewHome />,
      children: <FormattedMessage id={`products.opportunities.opportunities`} />
    },
    {
      id: 'portfolio',
      leftContent: <IconGrid />,
      children: <FormattedMessage id={`products.portfolio.portfolio`} />
    },
    {
      id: 'market',
      leftContent: <IconGlobal />,
      children: <FormattedMessage id={`products.market.market`} />
    }
  ];
  const menuConfig = isMobile ? menuMobile : menu;
  return (
    <List size={size}>
      {menuConfig.map(({ id, ...other }) => {
        const isDisabled = !products.includes(id);
        return (
          <NavListItem
            key={id}
            active={id === productKey}
            {...(!isDisabled && {
              onClick: () => !isDisabled && handleClickProduct(CONSTANTS.PRODUCT[id])
            })}
            disabled={isDisabled}
            {...other}
          />
        );
      })}
    </List>
  );
};

export default NavigationMenuProducts;
