import AuthChangePasswordForm from '../forms/AuthChangePasswordForm';
import { useDispatch } from 'react-redux';
import PATHS from '../../routes/paths';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { changePassword } from '../actions/auth';
import { useEffect } from 'react';

const AuthChangePasswordContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { token } = queryString.parse(location.search);

  useEffect(() => {
    if (!token) {
      history.push(PATHS.base);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleChangePassword = ({ password }) => {
    dispatch(changePassword({ password, token }));
  };

  return <AuthChangePasswordForm changePassword={handleChangePassword} />;
};

export default AuthChangePasswordContainer;
